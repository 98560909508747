import { Tooltip, TooltipProps } from "antd";
import { ReactNode } from "react";
import { stopPropagation } from "utils/javascript";

export const GraphTooltip: React.FC<{ width?: string } & TooltipProps> = (
  props
) => (
  <Tooltip
    {...props}
    title={<div onClick={stopPropagation}>{props.title as ReactNode}</div>}
    styles={{
      body: { width: props.width, color: "#000000", fontSize: "13px" },
    }}
    color="#ffffff"
  />
);
