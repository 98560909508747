import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Typography } from "antd";
import { RightAlignedDiv } from "components/divs";
import React, { PropsWithChildren } from "react";
import { colors } from "styles/variables";

import { formatTimeRemaining, useSessionTimeout } from "./useSessionTimeout";

const SessionManager: React.FC<PropsWithChildren<object>> = ({ children }) => {
  const { isModalVisible, isSessionWarning, displayTime, closeModal, signOut } =
    useSessionTimeout();

  return (
    <>
      {children}
      <Modal
        title={
          isSessionWarning
            ? "Session Duration Warning"
            : "Session Inactivity Warning"
        }
        open={isModalVisible}
        onCancel={closeModal}
        footer={false}
        maskClosable={false}
        destroyOnClose
      >
        <Typography.Text
          style={{
            display: "flex",
            marginBottom: "20px",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <ExclamationCircleOutlined
            style={{ fontSize: 24, color: colors.themeColors.red }}
          />
          <div>
            <Typography.Text>
              For security reasons, your session will automatically end in{" "}
              <Typography.Text strong type="warning">
                {formatTimeRemaining(displayTime)}
              </Typography.Text>
            </Typography.Text>
            <br />
            <Typography.Text type="secondary">
              {isSessionWarning
                ? "This is due to reaching the maximum session duration limit."
                : "This is due to an extended period of inactivity."}
            </Typography.Text>
          </div>
        </Typography.Text>
        <RightAlignedDiv>
          <Space>
            {!isSessionWarning && (
              <Button key="extend" type="primary" onClick={closeModal}>
                Stay Logged In
              </Button>
            )}
            <Button key="logout" onClick={signOut}>
              Log Out
            </Button>
          </Space>
        </RightAlignedDiv>
      </Modal>
    </>
  );
};

export default SessionManager;
