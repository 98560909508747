import {
  CheckCircleTwoTone,
  ClockCircleOutlined,
  CloseCircleTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import { List, Progress } from "antd";
import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { ErrorDisplay } from "components/Error";
import { AuthzButton } from "components/common/AuthzButton";
import { FlexDiv } from "components/divs";
import { useFlags } from "launchdarkly-react-client-sdk";
import { round, sortBy } from "lodash";
import * as React from "react";
import { useCallback, useContext, useMemo } from "react";
import styled from "styled-components";
import { colors } from "styles/variables";
import { assertNever } from "utils/assert";

import {
  FirestoreDoc,
  useFirestoreCollection,
} from "../../../providers/FirestoreProvider";
import {
  AssessmentJob,
  AssessmentProgress,
  AssessmentProgressStatus,
  AssessmentProgressTypeDescription,
  AssessmentProgressTypes,
} from "../../../shared/types/assessment/job";
import { Tenant } from "../../Login";
import { StyledListInfo, StyledStatus } from "../styles";
import { TargetsList } from "./Targets";

type Props = {
  id: string;
  job: AssessmentJob;
};

type AssessmentSummaryProps = {
  showRun: boolean;
};

const StyledSummaryList = styled(List<FirestoreDoc<AssessmentProgress>>)`
  margin-bottom: 10px;
  .ant-list-item {
    padding: 0;
  }
  .ant-list-item-meta-title {
    margin-bottom: 0;
  }
`;
const completedCheckCircle = <CheckCircleTwoTone twoToneColor="#52c41a" />;
const erroredCloseCircle = (
  <CloseCircleTwoTone twoToneColor={colors.themeColors.red} />
);

export const statusToIcon = (status: AssessmentProgressStatus) => {
  switch (status) {
    case "COMPLETED":
      return completedCheckCircle;
    case "ERRORED":
      return erroredCloseCircle;
    case "IN_PROGRESS":
      return <ClockCircleOutlined />;
    case "NOT_STARTED":
      return <ClockCircleOutlined />;
    default:
      assertNever(status);
  }
};

export const JobSummary: React.FC<Props> = ({ id, job }) => {
  const tenantId = useContext(Tenant);
  const flags = useFlags();
  const { docs: auditProgressDocs } =
    useFirestoreCollection<AssessmentProgress>(
      `o/${tenantId}/job-state/${id}/progress`,
      { live: true }
    );
  const orderedProgress = useMemo(
    () =>
      sortBy(auditProgressDocs ?? [], (a) =>
        AssessmentProgressTypes.indexOf(a.data.name)
      ).filter((a) => flags.assessmentManage || a.id !== "MANAGEMENT"),
    [auditProgressDocs, flags.assessmentManage]
  );

  const renderListItem = useCallback(
    (item: FirestoreDoc<AssessmentProgress>) => {
      const element = (
        <StyledStatus>
          {item.data.state === "COMPLETED" ? (
            completedCheckCircle
          ) : job.status === "ERRORED" ? (
            erroredCloseCircle
          ) : item.data.state === "NOT_STARTED" ? (
            <ClockCircleOutlined />
          ) : (
            <SyncOutlined spin />
          )}
          <StyledListInfo>
            {item.data.state !== "COMPLETED" &&
            item.data.state !== "NOT_STARTED" ? (
              <>
                <div style={{ flex: "1" }}>
                  {
                    AssessmentProgressTypeDescription[item.data.name]
                      ?.inProgress
                  }
                </div>
                <div style={{ flex: "1", marginLeft: "5px" }}>
                  <Progress
                    percent={round(item.data.progress * 100)}
                    status="active"
                    trailColor="#ffffff"
                  />
                  <div>{item.data.message}</div>
                </div>
              </>
            ) : (
              <div>
                {
                  AssessmentProgressTypeDescription[item.data.name]?.[
                    item.data.state === "COMPLETED" ? "completed" : "inProgress"
                  ]
                }
              </div>
            )}
          </StyledListInfo>
        </StyledStatus>
      );
      return (
        <List.Item>
          <List.Item.Meta title={element} />
        </List.Item>
      );
    },
    [job]
  );

  return (
    <>
      <StyledSummaryList
        itemLayout="horizontal"
        size="small"
        split={false}
        loading={!auditProgressDocs}
        dataSource={orderedProgress}
        renderItem={renderListItem}
      />
      {job.status === "ERRORED" && <ErrorDisplay error={job.error?.message} />}
    </>
  );
};

export const AssessmentSummary: React.FC<AssessmentSummaryProps> = (props) =>
  //TODO: Pass in current, runAssessmentNow, and assessment instead of using useContext
  {
    const { current, runAssessmentNow, assessment } = useContext(
      SelectedEnvironmentContext
    );
    const { showRun } = props;
    const currentJobDoc = current.doc?.data;
    const currentAssessmentDoc = assessment.doc;
    return (
      <FlexDiv style={{ gap: "0.5em" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "70%" }}>
          {currentJobDoc && (
            <JobSummary job={currentJobDoc} id={currentJobDoc.jobId} />
          )}
          {showRun && (
            <AuthzButton
              roles={["owner", "iamOwner"]}
              type="primary"
              disabledProps={!current.isCompleted}
              onClick={runAssessmentNow}
              style={{ maxWidth: 300 }}
            >
              Run unscheduled assessment now
            </AuthzButton>
          )}
        </div>
        <div style={{ width: "30%" }}>
          <TargetsList targets={currentAssessmentDoc?.data.targets ?? []} />
        </div>
      </FlexDiv>
    );
  };
