import { capitalize } from "lodash";
import pluralize from "pluralize";

import { AppPaths } from "../routes/constants";
import { assertNever } from "../types";
import {
  IdentityType,
  MetaTargetNodeTypes,
  TargetNodeTypes,
} from "../types/assessment/data";
import { Finding } from "../types/assessment/finding";
import { MonitorPriority } from "../types/assessment/monitor";
import { AppContext, appHost } from "../types/environment";

export const DEFAULT_AUTHN_LOOKBACK_DAYS = 40;

export const USAGE_LOOKBACK_DAYS = 90;

export const ASSESSMENT_TARGET_REGEX = /^[\w\-_.\s]+$/;

export const AWS_RESOURCE_ARN = /arn:.*?:([^:]+):.*?:.*?:((.+?)[/:])?(.*)/;

export const DefaultTitles = {
  condition: "Condition",
  credential: "Credential",
  consumer: "Consumer",
  grant: "Grant",
  identity: "Identity",
  lateral: "Lateral",
  permission: "Permission",
  privilegeSet: "Permissions",
  resource: "Resource",
  risk: "Risk",
  usage: "Permissions",
};

export const providerTitles = {
  all: {
    grant: "Grant",
    privileges: "Privileges",
    permissionSet: "AWS permission set",
    privilegeSet: "Role / policy",
  },
  aws: {
    grant: "AWS IAM policy assignment",
    privileges: "Actions",
    permissionSet: "AWS permission set",
    privilegeSet: "AWS IAM policy",
  },
  "azure-ad": {
    grant: "Azure role assignment",
    privileges: "Permissions",
    privilegeSet: "Azure role",
  },
  gcp: {
    grant: "GCP role binding",
    privileges: "Permissions",
    privilegeSet: "GCP role",
  },
  k8s: {
    grant: "Kubernetes role assignment",
    privileges: "Verbs",
    privilegeSet: "Kubernetes role",
  },
  okta: {
    grant: "Okta role assignment",
    privileges: "Permissions",
    privilegeSet: "Administrator role",
  },
  workspace: {
    grant: "Workspace role assignment",
    privileges: "Privileges",
    privilegeSet: "Workspace role",
  },
};

export type IamOption = { value: string; label: string };
export const iamShowOptions = (mode: "all" | "item"): IamOption[] => {
  const target =
    mode === "item"
      ? TargetNodeTypes
      : mode === "all"
      ? MetaTargetNodeTypes
      : assertNever(mode);
  return target.map((t) => ({
    value: t,
    label: capitalize(pluralize(t)),
  }));
};

export const identityTypeToLabel: Record<IdentityType, string> = {
  "aws-iam-role": "AWS IAM role",
  "aws-permission-set-role": "AWS role from permission set",
  domain: "Everyone in a domain",
  federated: "Federated identity",
  group: "Directory group",
  "logged-in": "Every authenticated user on the Internet",
  public: "Everyone on the Internet",
  "service-account": "Service account",
  unknown: "(unknown principal type)",
  user: "Directory user",
};

export const toLink = (
  { environment }: AppContext,
  orgSlug: string,
  finding: Finding
) =>
  `${appHost(environment)}/o/${orgSlug}/${
    AppPaths.Posture
  }/monitors/${encodeURIComponent(finding.monitorId)}/findings/${String(
    finding.findingId
  )}?scope=${encodeURIComponent(finding.scopeKey)}`;

export const MonitorRanking: Record<MonitorPriority, number> = {
  CRITICAL: 0,
  HIGH: 1,
  MEDIUM: 2,
  LOW: 3,
};
export const priorityLabels: Record<MonitorPriority, string> = {
  CRITICAL: "Urgent",
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
};
