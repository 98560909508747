import { ShareAltOutlined, TableOutlined } from "@ant-design/icons";
import { Alert, Grid, Segmented, Spin } from "antd";
import { TableProps } from "antd/lib/table";
import { GraphProcessingStep } from "components/Assessment/components/GraphStep";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import {
  ResizableTable,
  ResizableTableColumnsType,
  ResizableTableKeys,
} from "components/ResizableTable";
import { Mask } from "components/common/Mask";
import { useLocalStorage } from "hooks/useLocalStorage";
import { isEqual, omit } from "lodash";
import {
  ReactElement,
  ReactNode,
  memo,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";
import {
  AggregatedGraph,
  AggregatedNode,
  aggregate,
} from "shared/graph/aggregate";
import { DiscoverMatch, discoverPaths } from "shared/graph/discover";
import { NodePredicate, NodeSearch } from "shared/graph/search";
import {
  DEFAULT_GRAPH_SEARCH_SETTINGS,
  DISCOVER_ONLY_DEFAULT_SETTINGS,
  GraphSearchSettings,
} from "shared/graph/settings";
import { DirectedGraph, Reducers } from "shared/graph/types";
import { TIME_TO_DEBOUNCE, sleep } from "shared/util/sleep";
import styled from "styled-components";
import { staticError } from "utils/console";

import { useGuardedEffect } from "../../hooks/useGuardedEffect";
import {
  DiscoverVisualization,
  DiscoverVisualizationProps,
} from "./DiscoverVisualization";
import { GraphSearch, GraphSearchProps } from "./GraphSearch";
import {
  GraphSearchSettingsControl,
  GraphSearchSettingsDisables,
} from "./GraphSearchSettings";
import { Prefix } from "./Prefix";

export interface GraphTableProps<
  G extends object,
  K extends keyof G,
  A extends object,
> extends Omit<GraphSearchProps, "isSearching">,
    TableProps<AggregatedNode<G, K, A>> {
  columns: ResizableTableColumnsType<AggregatedNode<G, K, A>>;
  visualize: Omit<DiscoverVisualizationProps<G>, "matches" | "settings">;
  exporter: (selected: AggregatedNode<G, K, A>[]) => ReactNode;
  from: NodePredicate<G>;
  graph: DirectedGraph<G>;
  reducers: Reducers<G, A>;
  search: NodeSearch<G>[];
  searchExtra?: React.ReactNode;
  settingsDisables?: GraphSearchSettingsDisables;
  onSearch?: (nodes: AggregatedNode<G, K, A>[]) => void;
  onSelection?: (items: AggregatedNode<G, K, A>[]) => void;
}

const TableWrapper = styled.div`
  .ant-table.ant-table-small {
    font-size: 13px;
    tr > td {
      padding: 6px 6px;
    }
  }
  .ant-pagination {
    font-size: 13px;
  }
  .ant-pagination-options .ant-select {
    font-size: 13px;
  }
`;

const GraphTableInternal = <
  G extends object,
  K extends keyof G,
  A extends object,
>({
  columns,
  controls,
  exporter,
  from,
  frozen,
  graph,
  onSelection,
  onSearch,
  reducers,
  search,
  searchExtra,
  settingsDisables,
  scopeNode,
  showOptions,
  showCreateMonitor,
  visualize,
  ...tableProps
}: GraphTableProps<G, K, A>): ReactElement => {
  const { step, setStep } = useContext(ScopeContext);
  const [matches, setMatches] = useState<DiscoverMatch<DirectedGraph<G>>[]>();
  const [searched, setSearched] = useState<AggregatedGraph<G, A>>();
  const [aggregated, setAggregated] = useState<
    AggregatedGraph<G, A> | undefined
  >(undefined);

  // graphKey is a monotonically increasing number is used as the key on the visualization
  // component. When the key changes, the visualization component will completely unmount and
  // remount into a new lifecycle. This is useful for resetting the visualization including
  // re-centering the graph
  const [graphKey, updateGraphKey] = useReducer((k) => k + 1, 0);

  // Keep track of selected rows, as only selected rows will be exported
  const [selected, setSelected] = useState<AggregatedNode<G, K, A>[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  const [settings, setSettings] = useLocalStorage<GraphSearchSettings>(
    "graph-search-settings",
    DEFAULT_GRAPH_SEARCH_SETTINGS
  );

  const { xl } = Grid.useBreakpoint();

  // Used to render a load in progress for the table/graph
  const [isLoading, setIsLoading] = useState(false);

  // Used to render a search in-progress indicator in the search bar;
  // note that we can't render the spinner in the table, as it would
  // clear table state (filtering, pagination, etc.)
  const [isSearching, setIsSearching] = useState(false);
  // Ensure that table display matches graph data structure by hiding
  // table when search has not yet updated
  const [renderShow, setRenderShow] = useState<string>();

  useGuardedEffect(
    (cancellation) => async () => {
      setAggregated(undefined);
      setStep("aggregating");
      setIsLoading(true);
      // Debounce state update
      await sleep(TIME_TO_DEBOUNCE);
      if (cancellation.isCancelled) return;

      const before = performance.now();
      const result = await aggregate(graph, reducers, { cancellation });
      if (cancellation.isCancelled) return;
      // TODO: send to analytics
      // eslint-disable-next-line no-console
      console.log(
        "Time to aggregate graph",
        (performance.now() - before).toFixed(1),
        "ms"
      );
      setIsLoading(false);
      setStep("done");
      setAggregated(result);
    },
    [reducers, graph, setStep],
    staticError // nosemgrep use-static-error-handler-in-use-guarded-effect
  );

  const warnings = useMemo(
    () => search.flatMap((s) => s.warnings ?? []),
    [search]
  );

  const nodeToStopOn = useCallback(
    (stopOn: boolean | undefined) =>
      stopOn !== undefined ? (stopOn ? ["lateral"] : []) : settings.stopOn,
    [settings.stopOn]
  );

  const pathSettings = useMemo(
    () =>
      controls.display === "graph"
        ? // Need +1 to display warning to user
          {
            ...settings,
            maxPaths: settings.maxPaths + 1,
            stopOn: nodeToStopOn(settingsDisables?.stopOn),
          }
        : {
            ...DISCOVER_ONLY_DEFAULT_SETTINGS,
            stopOn: nodeToStopOn(settingsDisables?.stopOn),
          },
    [controls.display, nodeToStopOn, settings, settingsDisables?.stopOn]
  );
  const pathDisables = useMemo(
    () => ({
      ...settingsDisables,
      ...(controls.display === "graph"
        ? {}
        : ({ maxPaths: true, maxResults: true } as const)),
    }),
    [controls.display, settingsDisables]
  );

  useGuardedEffect(
    (cancellation) => async () => {
      if (!aggregated) return;
      // Clear selection on search / show update to prevent filtered rows from staying in
      // selection; this is less complicated than re-filtering the selection
      setSelected([]);
      setSelectedKeys([]);
      setIsSearching(true);
      // Debounce state update
      await sleep(TIME_TO_DEBOUNCE);
      if (cancellation.isCancelled) return;
      const beforeSearch = performance.now();
      const result = await discoverPaths({
        cancellation,
        from,
        graph: aggregated,
        search,
        settings: pathSettings,
      });
      if (cancellation.isCancelled) return;
      // antd recursively flattens objects; it can take ant many seconds to flatten
      // just one page of top-level nodes; prevent this by removing the children altogether
      const noChildren = result.map(({ node }) =>
        omit(node, "children")
      ) as AggregatedNode<G, K, A>[];
      // TODO: send to analytics
      // eslint-disable-next-line no-console
      console.log(
        "Time to search graph",
        (performance.now() - beforeSearch).toFixed(1),
        "ms"
      );
      setIsSearching(false);
      setMatches(result);
      setRenderShow(controls.show);
      if (isEqual(noChildren, searched?.nodes)) return;
      setSearched({ nodes: noChildren });
      onSearch?.(noChildren);
      updateGraphKey();
    },
    [aggregated, from, search, controls, searched, onSearch, pathSettings],
    staticError
  );

  const registerSelection = useCallback(
    (keys: React.Key[], values: AggregatedNode<G, K, A>[]) => {
      setSelectedKeys(keys);
      setSelected(values);
      onSelection?.(values);
    },
    [onSelection]
  );

  const exporterElement = useMemo(
    () =>
      exporter((selected.length ? selected : (searched?.nodes as any)) ?? []),
    [exporter, searched, selected]
  );

  const breakpoints = Grid.useBreakpoint();
  const visualizationSize = useMemo(() => {
    const { md, lg, xl } = breakpoints;
    const height = "max(500px, calc(100vh - 90px))";
    if (xl) return { width: `calc(100vw - 260px)`, height };
    return {
      width: `calc(100vw - ${lg ? "250px" : md ? "130px" : "110px"})`,
      height,
    };
  }, [breakpoints]);

  return step === "done" ? (
    <div
      data-testid="graph-table"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        maxWidth: "1200px",
        minWidth: "600px",
      }}
    >
      {!searched ? (
        <div style={{ height: "430px" }}>
          <Spin />
        </div>
      ) : (
        <>
          <GraphSearch
            controls={controls}
            frozen={frozen}
            isSearching={isSearching}
            scopeNode={scopeNode}
            searchExtra={
              <div
                style={{
                  display: "inline-flex",
                  gap: "0.4em",
                }}
              >
                {searchExtra}
                <Prefix
                  prefix="View"
                  textWidth={xl ? undefined : "md"}
                  style={{ width: "unset" }}
                >
                  <Segmented
                    value={controls.display ?? "table"}
                    options={[
                      {
                        value: "table",
                        label: (
                          <>
                            <TableOutlined /> Table
                          </>
                        ),
                      },
                      {
                        value: "graph",
                        label: (
                          <>
                            <ShareAltOutlined /> Graph
                          </>
                        ),
                      },
                    ]}
                    onChange={controls.onChangeDisplay as any}
                  />
                </Prefix>
                <GraphSearchSettingsControl
                  disables={pathDisables}
                  settings={settings}
                  setSettings={setSettings}
                />
                {exporterElement}
              </div>
            }
            showOptions={showOptions}
            showCreateMonitor={showCreateMonitor}
          />
          {warnings.length ? (
            <Alert
              type="warning"
              description={warnings.map((w, ix) => (
                <li key={ix}>{w}</li>
              ))}
            />
          ) : null}
          {controls.show !== renderShow ? (
            <Spin />
          ) : (
            <Mask
              show={isSearching}
              // Ease mask on search so that quick searches don't flash the mask
              maskStyle={{
                transition: "background-color 0.1s 0.2s ease",
                width:
                  controls.display === "graph"
                    ? visualizationSize.width
                    : "100%",
                height:
                  controls.display === "graph"
                    ? visualizationSize.height
                    : "100%",
              }}
            >
              {controls.display === "graph" ? (
                <DiscoverVisualization
                  key={graphKey}
                  matches={matches ?? []}
                  settings={settings}
                  {...visualize}
                  viewStyle={visualizationSize}
                />
              ) : (
                // Always fall back to table display
                <TableWrapper>
                  <ResizableTable
                    dataSource={(searched?.nodes || []) as AggregatedNode<G, K, A>[]}
                    columns={columns}
                    size="small"
                    expandable={{ showExpandColumn: false }}
                    tableId={ResizableTableKeys.graph}
                    data-testid="graph-table-inner-table"
                    loading={isLoading}
                    rowSelection={{
                      onChange: registerSelection,
                      selectedRowKeys: selectedKeys,
                      type: "checkbox",
                      columnWidth: 15,
                    }}
                    // Sorter tooltips block other more important parts of the UI (like query reference)
                    showSorterTooltip={false}
                    {...{ pagination: { hideOnSinglePage: true } }}
                    {...tableProps}
                  />
                </TableWrapper>
              )}
            </Mask>
          )}
        </>
      )}
    </div>
  ) : (
    <GraphProcessingStep step={step} />
  );
};

const MemoizedGraphTableInternal = memo(GraphTableInternal);
MemoizedGraphTableInternal.displayName = "GraphTable";
export const GraphTable =
  MemoizedGraphTableInternal as typeof GraphTableInternal;
