import { Button, Typography } from "antd";
import { useNavigateWithEnv } from "components/Assessment/hooks/useNavigateWithEnv";
import { formatDistance } from "date-fns";
import { useCallback } from "react";
import { Permission } from "shared/types/permission";

import { requestDescription } from "../requestUtils";
import { PermissionLocation } from "./PermissionLocation";

const DetailsLink: React.FC<{ requestId: string }> = ({ requestId }) => {
  const navigate = useNavigateWithEnv();

  const showRequestInfo = useCallback(() => {
    navigate(requestId);
  }, [navigate, requestId]);
  return (
    <Button type="link" onClick={showRequestInfo}>
      <Typography.Text style={{ textDecoration: "underline" }}>
        Details
      </Typography.Text>
    </Button>
  );
};

export const PermissionPreview: React.FC<{
  requestor: string;
  verb: string;
  reason?: string;
  permission: Permission;
  createdAt?: number;
  approverType?: string;
  approverDetail?: string;
  requestId?: string;
}> = ({
  requestor,
  verb,
  reason,
  permission,
  createdAt: approvedAt,
  approverType,
  approverDetail,
  requestId,
}) => (
  <div>
    <Typography.Paragraph style={!reason ? { marginBottom: 0 } : {}}>
      <Typography.Text code>{requestor}</Typography.Text> {verb}{" "}
      <Typography.Text strong>{requestDescription(permission)}</Typography.Text>{" "}
      in <PermissionLocation permission={permission} />.
      {approvedAt && (
        <Typography.Text>
          {" "}
          Approved {formatDistance(approvedAt, Date.now())} ago by{" "}
          <Typography.Text code={approverType !== "evidence"}>
            {approverDetail}
          </Typography.Text>
          . {!reason && requestId && <DetailsLink requestId={requestId} />}
        </Typography.Text>
      )}
    </Typography.Paragraph>
    {reason && (
      <Typography.Paragraph style={{ marginBottom: 0 }}>
        <Typography.Text type="secondary">Reason: {reason}</Typography.Text>.{" "}
        {requestId && <DetailsLink requestId={requestId} />}
      </Typography.Paragraph>
    )}
  </div>
);
