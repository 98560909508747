import { Handle } from "@xyflow/react";
import { ClipDiv } from "components/divs";
import React, { useMemo } from "react";
import { AssessmentNodes } from "shared/types/assessment/data";
import styled from "styled-components";

import { RendererParams } from "../../contexts/GraphContext";
import { CustomAssessmentNodeProps } from "./NodeRenderer";
import { NodeTitler } from "./NodeText";

const NodeDiv = styled.div<{
  height: number;
  width: number;
}>`
  display: flex;
  align-items: center;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  overflow: clip;
`;

export const CustomNodeBase: React.FC<{
  nodeProps: CustomAssessmentNodeProps<keyof AssessmentNodes>;
  rendererParams: RendererParams;
  children: React.ReactNode;
  height: number;
  width: number;
}> = ({ children, nodeProps, rendererParams, height, width }) => {
  const {
    data: { node },
  } = nodeProps;
  const { provider } = rendererParams;

  const titler = useMemo(() => NodeTitler(provider), [provider]);
  const title = useMemo(() => titler(node), [node, titler]);

  return (
    <NodeDiv height={height} width={width}>
      {children ? (
        <ClipDiv maxWidth={`${width}px`}>{children}</ClipDiv>
      ) : (
        <div>{title}</div>
      )}
    </NodeDiv>
  );
};

export const CustomNodeHeader: React.FC<{
  title: string;
  IconComponent?: React.ElementType;
  height: number;
  width: number;
}> = ({ title, IconComponent, height, width }) => (
  <NodeDiv height={height} width={width}>
    {IconComponent ? <IconComponent style={{ fontSize: "18px" }} /> : null}
    <span style={{ fontWeight: 500, fontSize: "14px" }}>{title}</span>
  </NodeDiv>
);

const NodeBodyDiv = styled.div<{
  border: string;
  backgroundColor: string;
  height: number;
  width: number;
}>`
  border: ${(props) => props.border};
  background-color: ${(props) => props.backgroundColor};
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
  overflow: clip;
`;

export const CustomNodeBody: React.FC<{
  children: React.ReactNode;
  borderColor: string;
  backgroundColor: string;
  nodeProps: CustomAssessmentNodeProps<keyof AssessmentNodes>;
  height: number;
  width: number;
}> = ({ children, borderColor, backgroundColor, nodeProps, height, width }) => {
  const { sourcePosition, targetPosition } = nodeProps;
  return (
    <NodeBodyDiv
      border={`1px solid ${borderColor}`}
      backgroundColor={backgroundColor}
      height={height}
      width={width}
    >
      {children}
      {targetPosition ? (
        <Handle type="target" position={targetPosition} />
      ) : null}
      {sourcePosition ? (
        <Handle type="source" position={sourcePosition} />
      ) : null}
    </NodeBodyDiv>
  );
};
