import { Card, Flex, Skeleton, Space, Typography } from "antd";
import { VerticalSpacedDiv } from "components/divs";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { colors } from "styles/variables";

import { ShowMore } from "./common/ShowMore";

const PaneCard = styled(Card)`
  .ant-card-body {
    height: 100%;
    padding: 12px;
  }
`;

export const Pane: React.FC<{
  children?: React.ReactNode;
  title: React.ReactNode;
  subtitle?: string;

  showMore?: {
    text: string;
    onClick: () => void;
  };
  style?: React.CSSProperties;
  tooltipPayload?: React.ReactNode;
  isLoading?: boolean;
  subtitleType?: "text" | "title";
  additionalControls?: React.ReactNode;
}> = ({
  children,
  subtitle,
  title,
  showMore,
  style,
  tooltipPayload,
  isLoading,
  subtitleType = "title",
  additionalControls,
}) => {
  const [showButton, setShowButton] = useState(false);
  const handleMouseEnter = useCallback(
    (_event: React.MouseEvent<HTMLDivElement>) => setShowButton(true),
    [setShowButton]
  );
  const handleMouseLeave = useCallback(
    (_event: React.MouseEvent<HTMLDivElement>) => setShowButton(false),
    [setShowButton]
  );

  return (
    <PaneCard
      className="info-pane"
      style={{
        borderRadius: "4px",
        border: `1px solid ${colors.neutral["15"]}`,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        height: "100%",
        overflowY: "hidden",
        overflowX: "hidden",
        padding: "0px",
        ...style,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <VerticalSpacedDiv style={{ gap: "3px", height: "100%", width: "100%" }}>
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <Space
            style={{
              alignContent: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {isLoading ? (
              <Skeleton.Input active />
            ) : (
              <Typography.Title
                level={4}
                ellipsis={{ rows: 2 }}
                style={{ marginBlock: 0 }}
              >
                {title}
              </Typography.Title>
            )}
            {tooltipPayload}
            {additionalControls && (
              <Flex align="center" gap="small">
                {additionalControls}
              </Flex>
            )}
          </Space>
          <Flex align="center">
            {showMore && (
              <ShowMore
                visible={showButton}
                text={showMore.text}
                onClick={showMore.onClick}
              />
            )}
          </Flex>
        </Space>

        {!isLoading &&
          (subtitleType === "title" ? (
            <Typography.Title
              style={{ color: `${colors.neutral["40"]}`, marginTop: 0 }}
              level={5}
            >
              {subtitle}
            </Typography.Title>
          ) : (
            <Typography.Text>{subtitle}</Typography.Text>
          ))}
        {children}
      </VerticalSpacedDiv>
    </PaneCard>
  );
};
