import { gold } from "@ant-design/colors";
import { CloudServerOutlined } from "@ant-design/icons";
import { ConnectedNode } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";

import { Resource } from "../../cells/Resource";
import { resourceLabels } from "../resource/labels";
import {
  DefaultNodeHeaderHeight,
  DefaultNodeOneLineBodyHeight,
  DefaultNodeWidth,
} from "./shared";

export const CustomResourceNodeParameters = {
  type: "resource" as const,
  titleText: (node: ConnectedNode<AssessmentNodes, "resource">) => {
    const { data } = node;
    const info = resourceLabels(data, true);
    const { service, type } = info;
    return type ? `${service} ${type}` : service;
  },
  IconComponent: CloudServerOutlined,
  bodyBorderColor: gold[3],
  bodyBackgroundColor: gold[2],
  bodyContent: (node: ConnectedNode<AssessmentNodes, "resource">) => (
    <Resource resource={node} shortPath />
  ),
  bodySize: () => DefaultNodeOneLineBodyHeight,
  headerSize: () => DefaultNodeHeaderHeight,
  width: () => DefaultNodeWidth,
};
