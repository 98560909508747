import { InfoCircleTwoTone } from "@ant-design/icons";
import { Spin, Typography } from "antd";
import Link from "antd/lib/typography/Link";
import { GraphTooltip } from "components/GraphTooltip";
import { GithubIcon } from "components/Integrations/Github/Github";
import { JiraIcon } from "components/Integrations/Jira/Jira";
import { FirestoreDoc } from "providers/FirestoreProvider";
import { Finding } from "shared/types/assessment/finding";

export const FindingAssignment: React.FC<{
  finding: FirestoreDoc<Finding>;
}> = ({ finding }) => {
  const { assignment, issue, remediation } = finding.data;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "baseline",
        gap: "1em",
      }}
    >
      {assignment &&
        (assignment.status === "complete" && assignment.assignee ? (
          <Typography.Paragraph>
            Assigned to {assignment.assignee}{" "}
            <GraphTooltip
              title={
                <div>
                  This findings was assigned to {assignment.assignee} for the
                  following reason:
                  <br />
                  {assignment.reason}
                </div>
              }
            >
              <InfoCircleTwoTone />
            </GraphTooltip>
          </Typography.Paragraph>
        ) : assignment.status === "assigning" ? (
          <Typography.Paragraph>
            <Spin /> Determining owner
          </Typography.Paragraph>
        ) : assignment.status === "remediating" ? (
          <Typography.Paragraph>
            <Spin /> Remediating finding
          </Typography.Paragraph>
        ) : assignment.status === "tracking" ? (
          <Typography.Paragraph>
            <Spin /> Creating issue
          </Typography.Paragraph>
        ) : null)}
      <div
        style={{
          gap: "1em",
          display: "flex",
        }}
      >
        {issue && (
          <Link target="_blank" href={issue.link}>
            {JiraIcon}&nbsp;View issue
          </Link>
        )}
        {remediation && (
          <Link target="_blank" href={remediation.link}>
            {GithubIcon}&nbsp;View PR
          </Link>
        )}
      </div>
    </div>
  );
};
