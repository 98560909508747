import {
  Col,
  Divider,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
  message,
} from "antd";
import { useAuthFetch } from "components/Login/hook";
import { AuthzButton } from "components/common/AuthzButton";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  timeUnitLabelOptions,
  timeUnitLabels,
} from "shared/permission-requests/util";
import { handleErrorWithToastMessage } from "utils/error";

import { useFirestoreDoc } from "../../providers/FirestoreProvider";
import {
  DEFAULT_MAX_ACCESS_DURATION,
  WORKFLOW_CONFIGURATION_COLLECTION,
} from "../../shared/configuration/constant";
import { WorkflowConfiguration } from "../../shared/configuration/types";
import { Tenant } from "../Login";

const style: React.CSSProperties = { padding: "8px 0", flex: "1  1  0px" };

export const MaxAccessDuration: React.FC = () => {
  const tenantId = useContext(Tenant);

  const optionsDoc = useFirestoreDoc<WorkflowConfiguration>(
    `o/${tenantId}/${WORKFLOW_CONFIGURATION_COLLECTION}/latest`,
    {
      live: true,
    }
  );

  const [time, setTime] = useState<number | null>(1);
  const [timeUnit, setTimeUnit] = useState<keyof typeof timeUnitLabels>("m");

  useEffect(() => {
    const maxAccessDuration =
      optionsDoc.doc?.data.maxAccessDuration || DEFAULT_MAX_ACCESS_DURATION;
    if (maxAccessDuration) {
      setTime(maxAccessDuration.time);
      setTimeUnit(maxAccessDuration.unit);
    }
  }, [optionsDoc.doc]);

  const authFetch = useAuthFetch(handleErrorWithToastMessage);

  const setMaximumAccessDuration = useCallback(async () => {
    if (time && timeUnit) {
      const response = authFetch(
        `settings/max-access-duration/unit/${timeUnit}/time/${time}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response) return;
      message.success("Maximum access duration set");
    }
  }, [time, timeUnit, authFetch]);

  return (
    <div>
      <Typography.Title level={4}>Maximum Access Duration</Typography.Title>
      <div>
        <Typography.Text type="secondary">
          Set the maximum allowable duration for access requests.
        </Typography.Text>
        <br />
        {optionsDoc.loading ? (
          <Skeleton.Input active block={true} />
        ) : (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <div style={style}>
                <Typography.Text>Maximum Access Duration: </Typography.Text>
                <Space>
                  <Space.Compact>
                    <InputNumber min={1} value={time} onChange={setTime} />
                    <Select
                      options={timeUnitLabelOptions}
                      value={timeUnit}
                      onChange={setTimeUnit}
                      style={{ width: 97 }}
                    />
                  </Space.Compact>
                  <Divider />
                  <AuthzButton
                    roles={["owner"]}
                    type="primary"
                    onClick={setMaximumAccessDuration}
                  >
                    Set
                  </AuthzButton>
                </Space>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};
