import { ReloadOutlined } from "@ant-design/icons";
import { Button, Select, Space, Spin, Typography } from "antd";
import { GraphTooltip } from "components/GraphTooltip";
import * as firestore from "firebase/firestore";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import * as firestoreProvider from "../../../providers/FirestoreProvider";
import { Cancellation } from "../../../utils/cancel";
import { ErrorDisplay } from "../../Error";
import { useAuthFetch, useHasRole } from "../../Login/hook";
import { Tenant } from "../../Login/util";
import { OAuth2IntegrationCard } from "../IntegrationCard";

export const PagerdutyIconUrl = "https://pagerduty.com/favicon.ico";

type PagerdutyIntegration = {
  selectedPolicyIds: string[];
  lastUpdatedAt?: number;
  updateRequestedAt?: number;
};

type PagerdutyPolicy = {
  name: string;
  id: string;
};

/** Selects schedules that cause auto-approval, and syncs these to Firestore */
const PagerdutyScheduleSelect: React.FC<{
  integration: firestoreProvider.KnownFirestoreDoc<PagerdutyIntegration>;
}> = ({ integration }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState<any>();
  const authFetch = useAuthFetch(setError);
  const canEdit = useHasRole("owner");

  const onRefresh = useCallback(
    async (cancellation?: Cancellation) => {
      if (!canEdit) return;
      setIsRefreshing(true);
      try {
        await authFetch("integrations/pagerduty/refresh", {
          method: "POST",
          cancellation,
        });
      } finally {
        setIsRefreshing(false);
      }
    },
    [authFetch, canEdit]
  );

  const { docs: policies } =
    firestoreProvider.useFirestoreCollection<PagerdutyPolicy>(
      `integrations/pagerduty/escalation_policies`,
      { live: true, tenantAware: true }
    );
  const options = useMemo(
    () =>
      policies?.map((p) => (
        <Select.Option key={p.data.id} label={p.data.name}>
          {p.data.name}
        </Select.Option>
      )),
    [policies]
  );
  const currentlyWrittenIds = integration.doc?.data.selectedPolicyIds ?? [];
  const onUpdate = useMemo(
    () => async (selected: string[]) => {
      setIsUpdating(true);
      await firestore.updateDoc(integration.ref, {
        selectedPolicyIds: selected,
      } as PagerdutyIntegration);
      setIsUpdating(false);
    },
    [integration.ref]
  );

  /* Refresh on load / install */
  useEffect(() => {
    const cancellation = new Cancellation();
    onRefresh(cancellation);
    return cancellation.cancel;
  }, [onRefresh]);

  const onClickRefresh = useCallback(() => onRefresh(), [onRefresh]);

  return error ? (
    <ErrorDisplay
      title="Could not fetch Pagerduty escalation policies"
      error={error}
    />
  ) : (
    <Space direction="vertical" size="middle">
      <div>
        Select escalation policies for auto-approval:
        <div style={{ display: "flex" }}>
          {isUpdating || isRefreshing || options === undefined ? (
            <Spin />
          ) : (
            <>
              <Select
                mode="multiple"
                allowClear
                disabled={!canEdit}
                style={{ flexGrow: 1 }}
                placeholder="None selected"
                defaultValue={currentlyWrittenIds}
                onChange={onUpdate}
                optionFilterProp="label"
              >
                {options}
              </Select>
              {canEdit && (
                <GraphTooltip title="Fetch latest escalation policies from Pagerduty">
                  <Button onClick={onClickRefresh}>
                    <ReloadOutlined />
                  </Button>
                </GraphTooltip>
              )}
            </>
          )}
        </div>
      </div>
      <Typography.Text type="secondary">
        (Auto-approvals grant permissions for 1 hour)
      </Typography.Text>
    </Space>
  );
};

export const Pagerduty: React.FC<object> = () => {
  const tenantId = useContext(Tenant);
  const integrationData =
    firestoreProvider.useFirestoreDoc<PagerdutyIntegration>(
      `o/${tenantId}/integrations/pagerduty`,
      { live: true }
    );
  return (
    <OAuth2IntegrationCard
      integration="pagerduty"
      integrationDoc={integrationData.doc}
      mode="pkce"
      logo={PagerdutyIconUrl}
    >
      <PagerdutyScheduleSelect integration={integrationData} />
    </OAuth2IntegrationCard>
  );
};
