import { orange } from "@ant-design/colors";
import { UnlockOutlined } from "@ant-design/icons";
import pluralize from "pluralize";
import { providerTitles } from "shared/assessment/constants";
import { ConnectedNode } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";

import { RendererParams } from "../../../contexts/GraphContext";
import { PrivilegeLink } from "../../cells/PrivilegeLink";
import {
  DefaultNodeHeaderHeight,
  DefaultNodeOneLineBodyHeight,
  DefaultNodeWidth,
} from "./shared";

export const CustomPrivilegeNodeParameters = {
  type: "privilege" as const,
  titleText: (
    _: ConnectedNode<AssessmentNodes, "privilege">,
    rendererParams: RendererParams
  ) => {
    const { provider } = rendererParams;
    return pluralize.singular(providerTitles[provider].privileges);
  },
  IconComponent: UnlockOutlined,
  bodyBorderColor: orange[2],
  bodyBackgroundColor: orange[1],
  bodyContent: (
    node: ConnectedNode<AssessmentNodes, "privilege">,
    rendererParams: RendererParams
  ) => {
    const { provider } = rendererParams;

    const content =
      provider === "all" ? (
        <>{node.key}</>
      ) : (
        <PrivilegeLink
          key={node.key}
          privilege={node.key}
          provider={provider}
        />
      );
    return content;
  },
  bodySize: () => DefaultNodeOneLineBodyHeight,
  headerSize: () => DefaultNodeHeaderHeight,
  width: () => DefaultNodeWidth,
};
