import { Menu } from "antd";
import { ENV_SETTINGS_ROLES } from "components/App/roles";
import { EnvironmentDocsContext } from "components/Environment/contexts/EnvironmentDocsContext";
import { UserAuthz } from "components/Login";
import { useContext, useMemo } from "react";
import React from "react";
import { Outlet, useLocation } from "react-router";
import { AppPaths } from "shared/routes/constants";

import { LinkWithEnvironment } from "../components/LinkWithEnvironment";
import { NavigateWithEnvironment } from "../components/NavigateWithEnvironment";
import { SettingsPaths } from "../components/Settings/paths";

const item = (path: string, label: string) => ({
  key: path,
  label: <LinkWithEnvironment to={path}>{label}</LinkWithEnvironment>,
});

const ViewerTab = item(SettingsPaths.EnvironmentStatus, "Status");

const PrivilegedTabs = [
  item(SettingsPaths.EnvironmentConfiguration, "Configuration"),
  item(SettingsPaths.Monitors, "Monitors"),
];

export const Settings: React.FC<object> = () => {
  const { pathname } = useLocation();
  const userAuthz = useContext(UserAuthz);

  const selected = useMemo(
    () => pathname.split("/").at(-1) ?? "settings",
    [pathname]
  );

  const { hasEnvironments } = useContext(EnvironmentDocsContext);

  const navItems = useMemo(
    () => [
      ViewerTab,
      ...(ENV_SETTINGS_ROLES.some((r) => userAuthz.has(r))
        ? PrivilegedTabs
        : []),
    ],
    [userAuthz]
  );

  return hasEnvironments ? (
    navItems.length > 1 ? (
      <div style={{ maxWidth: "800px" }}>
        <Menu
          mode="horizontal"
          items={navItems}
          selectedKeys={[selected]}
          style={{ marginBottom: "1em" }}
        />
        <Outlet />
      </div>
    ) : (
      <Outlet />
    )
  ) : (
    <NavigateWithEnvironment to={`../${AppPaths.Dashboard}`} />
  );
};
