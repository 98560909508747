import { ProfileOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import { AllIntegrationLogos } from "components/Integrations/IntegrationLogo";
import {
  RoutingRulePopover,
  StyledRoutingRulePreview,
} from "components/Jit/Routing/styles";
import { IdpGroup } from "shared/types/workflow/types";

type Props = {
  idpGroups: IdpGroup[];
  isApproval?: boolean;
  showPopover?: boolean;
};

export const IdpGroupPreview: React.FC<{
  idpGroup: IdpGroup;
  showPopover?: boolean;
}> = ({ idpGroup, showPopover = true }) => {
  return (
    <RoutingRulePopover
      text={idpGroup.label}
      icon={AllIntegrationLogos[idpGroup.directory]}
      popoverContent={showPopover ? idpGroup.id : undefined}
    />
  );
};

export const IdpGroupsPreview: React.FC<Props> = ({
  idpGroups,
  isApproval = false,
  showPopover = true,
}) => {
  return (
    <Space direction="vertical">
      {isApproval ? (
        <Typography.Paragraph strong={true} className="label">
          Approval by{" "}
        </Typography.Paragraph>
      ) : (
        ""
      )}

      {idpGroups.map((group) => (
        <IdpGroupPreview
          key={group.id}
          idpGroup={group}
          showPopover={showPopover}
        />
      ))}
    </Space>
  );
};

export const GenericIdpGroupPreview: React.FC<{ isApproval?: boolean }> = ({
  isApproval,
}) => (
  <StyledRoutingRulePreview>
    <ProfileOutlined />
    <span>{`${
      isApproval ? "Approval by any" : "Any"
    } user in a group from an Identity Provider`}</span>
  </StyledRoutingRulePreview>
);
