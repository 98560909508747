import { CSSProperties, ReactNode } from "react";

import { StyledPivotPrefix, StyledPrefixWrapper } from "./styles";

export const Prefix: React.FC<
  React.PropsWithChildren<{
    prefix: ReactNode;
    style?: CSSProperties;
    textWidth?: "lg" | "md" | "sm";
  }>
> = ({ children, prefix, style, textWidth }) => (
  <StyledPrefixWrapper style={style}>
    <StyledPivotPrefix textWidth={textWidth}>{prefix}</StyledPivotPrefix>
    {children}
  </StyledPrefixWrapper>
);
