import { Alert, Select, Spin } from "antd";
import {
  StyledFormItem,
  standardFormItemCols,
} from "components/common/forms/styles";
import { compact } from "lodash";
import { useCallback, useMemo } from "react";
import { directoryLabels } from "shared/types/workflow/constants";
import { IdpGroup, IdpGroups } from "shared/types/workflow/types";
import { useInstalledDirectories } from "store/resources/DirectoriesProvider";

import { buildIdpGroupId, parseIdpGroupId } from "../utils";
import { IdpGroupPreview } from "./previews/IdpGroupPreview";

type Props = {
  value: IdpGroups;
  onChange: (value: IdpGroups) => void;
};

export const IdpGroupInput: React.FC<Props> = ({ value, onChange }) => {
  const { allAvailableGroups, availableGroups, isLoadingGroups } =
    useInstalledDirectories();

  const handleGroupChange = useCallback(
    (groupIds: string[]) => {
      const groups: IdpGroup[] = groupIds.map((groupId) => {
        const group = parseIdpGroupId(groupId);
        return {
          ...group,
          label: availableGroups[group.directory].groups[groupId].label,
        };
      });
      onChange({
        type: "group",
        groups,
      });
    },
    [onChange, availableGroups]
  );

  const groupValue = useMemo(
    () =>
      compact(
        value.groups.map((g) => (g.id === "" ? undefined : buildIdpGroupId(g)))
      ),
    [value]
  );

  const groupOptions = useMemo(
    () =>
      allAvailableGroups.map((ag) => ({
        label: <IdpGroupPreview idpGroup={ag} showPopover={false} />,
        group: ag,
        value: buildIdpGroupId(ag),
      })),
    [allAvailableGroups]
  );

  const groupSearch = useCallback(
    (term: string, option: (typeof groupOptions)[number] | undefined) =>
      term.length >= 3 &&
      !!option?.group.label.toLowerCase().includes(term.toLowerCase()),
    []
  );

  const invalidGroupIdWarning = useMemo(() => {
    if (value) {
      const { groups } = value;
      // Find one group that is not in allAvailableGroups
      // We don't have to find all since this validation runs on each group added
      const warningGroup = groups.find(
        (group) =>
          group.id && !allAvailableGroups.some((g) => group.id === g.id)
      );
      if (warningGroup) {
        const directoryLabel = directoryLabels[warningGroup.directory];

        return (
          <Alert
            message="Invalid group"
            description={
              <>
                A group with ID <code>{warningGroup.id}</code> could not be
                found in {directoryLabel}. It may have been deleted in{" "}
                {directoryLabel}. Please check that the group exists, or select
                a new group.
              </>
            }
            type="warning"
            showIcon
          />
        );
      }
    }

    return undefined;
  }, [value, allAvailableGroups]);

  return isLoadingGroups ? (
    <div>
      <Spin tip="Loading groups..." />
    </div>
  ) : (
    <StyledFormItem label="Group" {...standardFormItemCols}>
      <Select
        options={groupOptions}
        value={groupValue}
        onChange={handleGroupChange}
        showSearch
        filterOption={groupSearch}
        placeholder="Select groups"
        mode="multiple"
        allowClear
      />
      {invalidGroupIdWarning ? (
        <div style={{ marginTop: 5 }}>{invalidGroupIdWarning}</div>
      ) : (
        ""
      )}
    </StyledFormItem>
  );
};
