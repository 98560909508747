import { Skeleton, Typography } from "antd";
import { PermissionLocation } from "components/Jit/Requests/components/PermissionLocation";
import { requestDescription } from "components/Jit/Requests/requestUtils";
import {
  ResizableTable,
  ResizableTableColumnsType,
  ResizableTableKeys,
} from "components/ResizableTable";
import { map, maxBy, orderBy, sum, toPairs, values } from "lodash";
import React from "react";
import { PermissionRequest, PermissionType } from "shared/types/permission";

import { GrantToRequestorRecord } from "../../types";

interface GrantData {
  permission: PermissionRequest;
  grant: string;
  totalRequests: number;
  topUser: {
    user: string | null;
    count: number;
  };
  type: PermissionType;
  uniqueUsers: number;
}

const getTopRequests = (
  requestorMap: Record<string, GrantToRequestorRecord>
): GrantData[] => {
  return orderBy(
    map(requestorMap, (users, grant) => {
      const { countsByRequestor, permission } = users;
      const totalRequests = sum(values(countsByRequestor));
      const topUser = maxBy(
        toPairs(countsByRequestor),
        ([, count]) => count
      ) || [null, 0];

      const allUsers = toPairs(countsByRequestor).map(([user, count]) => ({
        user,
        count,
      }));

      return {
        permission,
        grant,
        totalRequests,
        topUser: { user: topUser[0], count: topUser[1] },
        uniqueUsers: allUsers.length,
        allUsers,
        type: grant.split("_")[0] as PermissionType,
      };
    }),
    "totalRequests",
    "desc"
  ).slice(0, 4);
};

const columns: ResizableTableColumnsType<GrantData> = [
  {
    title: "Request",
    dataIndex: "permission",
    key: "permission",
    render: (permission: PermissionRequest) => (
      <>
        <Typography.Text>{requestDescription(permission)}</Typography.Text>
        <span> in </span>
        <PermissionLocation permission={permission} />
      </>
    ),
    width: 420,
  },
  {
    title: "Total",
    dataIndex: "totalRequests",
    key: "totalRequests",
    render: (text: number) => <Typography.Text>{text}</Typography.Text>,
    width: 80,
  },
  {
    title: "Most frequent requestor",
    dataIndex: "topUser",
    key: "topUser",
    render: ({ count, user }: GrantData["topUser"]) => {
      return (
        <span>
          {user} ({count})
        </span>
      );
    },
    width: 250,
  },
];

export const FrequentRequestsTable: React.FC<{
  requestorMap: Record<string, GrantToRequestorRecord>;
  isLoading?: boolean;
}> = ({ requestorMap, isLoading }) => {
  const data = getTopRequests(requestorMap);

  return isLoading ? (
    <Skeleton active />
  ) : (
    <ResizableTable
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey="grant"
      tableId={ResizableTableKeys.jitStatistics}
    />
  );
};
