import {
  DatabaseOutlined,
  KeyOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Skeleton, Typography } from "antd";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { useInventoryCounts } from "components/Assessment/hooks/useInventoryCounts";
import { useNavigateWithEnv } from "components/Assessment/hooks/useNavigateWithEnv";
import {
  mockInventoryData,
  mockNodeCount,
} from "components/Dashboard/zeroStateData/inventory-zero-state";
import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { VerticalDiv } from "components/divs";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useScreen } from "hooks/useScreen";
import { capitalize, sortBy, sum } from "lodash";
import pluralize from "pluralize";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { Formatter } from "recharts/types/component/DefaultLegendContent";
import { generateInventoryData } from "shared/assessment/inventory";
import { countNodes } from "shared/assessment/inventory";
import { DirectedGraph } from "shared/graph/types";
import { AppPaths } from "shared/routes/constants";
import { TargetNodeType } from "shared/types/assessment/data";
import { AssessmentNodes } from "shared/types/assessment/data";
import { colors } from "styles/variables";
import { staticError } from "utils/console";

import { Pane } from "../Pane";
import { FilterStatistic } from "../common/FilterStatistic";

const graphColors = [
  colors.neutral["60"],
  colors.graphColors.darkGreen,
  colors.themeColors.darkBlue,
  colors.graphColors.purple,
  colors.graphColors.pink,
  colors.themeColors.veryLightBlue,
  colors.themeColors.lightYellow,
  colors.themeColors.blueGreen,
  colors.themeColors.lightGreen,
];

const identityTypeIcons: Record<TargetNodeType, JSX.Element> = {
  identity: <UserOutlined />,
  grant: <SolutionOutlined />,
  credential: <KeyOutlined rotate={315} />,
  resource: <DatabaseOutlined />,
};

const TargetNodeTypeDisplayOrder: Exclude<TargetNodeType, "resource">[] = [
  "identity",
  "grant",
  "credential",
];

const targetNodeTypesDisplayText = (type: TargetNodeType) =>
  capitalize(pluralize(type));

export type CountEntry = {
  name: string;
  value: number;
  fill: string | undefined;
};
const InventoryPieChart: React.FC<
  PropsWithChildren<{
    data: CountEntry[];
  }>
> = ({ children, data }) => {
  const { xxl, xl, lg } = useScreen();

  const formatter: Formatter = useCallback(
    (value: number, entry) => (
      <Typography.Text
        style={{
          display: "inline",
          fontSize: "small",
        }}
      >{`${value} (${entry.payload?.value})`}</Typography.Text>
    ),
    []
  );

  return (
    <ResponsiveContainer width="100%">
      <PieChart>
        <Pie
          dataKey="value"
          data={data}
          innerRadius={"30%"}
          activeIndex={0}
          outerRadius={"90%"}
          fill="#82ca9d"
          isAnimationActive={false}
          width={100}
        >
          {children}
        </Pie>
        <Legend
          align={xxl ? "right" : xl ? "center" : lg ? "right" : "center"}
          verticalAlign={
            xxl ? "middle" : xl ? "bottom" : lg ? "middle" : "bottom"
          }
          layout={
            xxl
              ? "vertical"
              : xl
              ? "horizontal"
              : lg
              ? "vertical"
              : "horizontal"
          }
          formatter={formatter}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

const MockGraph: React.FC<object> = () => (
  <InventoryPieChart data={mockInventoryData as CountEntry[]} />
);

export const Graph: React.FC<{
  type: Exclude<TargetNodeType, "resource">;
  graph: DirectedGraph<AssessmentNodes> | undefined;
  nodeCount: number;
}> = ({ type, graph, nodeCount }) => {
  const { assessment, last } = useContext(SelectedEnvironmentContext);
  const { doc: allInventoryCounts, loading } = useInventoryCounts();
  const [counts, setCounts] = useState<CountEntry[]>([]);

  const allLinks = useMemo(() => {
    return assessment.doc
      ? generateInventoryData(assessment.doc.data.targets)
      : undefined;
  }, [assessment]);

  // Function to get counts from inventory counts
  const getCountsFromInventory = useCallback(() => {
    if (!allInventoryCounts || !allInventoryCounts[type] || !allLinks) {
      return [];
    }

    const categoryTotalLabel = Object.values(allLinks[type]).find(
      (link) => link.show === type && link.where === ""
    )?.label;

    return sortBy(
      Object.entries(allInventoryCounts[type])
        .filter(([label, count]) => label !== categoryTotalLabel && count > 0)
        .map(([label, count], index) => ({
          name: label,
          value: count,
          fill: graphColors[index % (graphColors.length - 1)],
        })),
      "name"
    );
  }, [allInventoryCounts, type, allLinks]);

  // Function to get counts by traversing the graph
  const getCountsFromGraph = useCallback(async () => {
    if (!allLinks?.[type] || !graph) {
      return [];
    }

    let categorizedCount = 0;
    const data = await Promise.all(
      Object.values(allLinks[type]).map(async (link, index) => {
        const count = (await countNodes(link, graph)).nodes.length;
        categorizedCount += count;

        return {
          name: link.label,
          value: count,
          fill: graphColors[index % (graphColors.length - 1)],
        } as CountEntry;
      })
    );

    if (categorizedCount - nodeCount < nodeCount) {
      data.push({
        name: "Uncategorized",
        value: nodeCount - (categorizedCount - nodeCount),
        fill: graphColors[data.length % graphColors.length],
      });
    }

    return sortBy(
      data.filter(
        (entry) =>
          entry.name !== targetNodeTypesDisplayText(type) && entry.value > 0
      ),
      "name"
    );
  }, [allLinks, graph, type, nodeCount]);

  // Use inventory counts if available, otherwise fall back to graph traversal
  useGuardedEffect(
    (cancellation) => async () => {
      if (loading) return;
      const inventoryCounts = getCountsFromInventory();

      if (inventoryCounts.length > 0) {
        cancellation.guard(setCounts)(inventoryCounts);
      } else if (graph) {
        const graphCounts = await getCountsFromGraph();
        cancellation.guard(setCounts)(graphCounts);
      } else {
        cancellation.guard(setCounts)([]);
      }
    },
    [
      getCountsFromInventory,
      getCountsFromGraph,
      graph,
      type,
      assessment,
      last,
      loading,
    ],
    staticError
  );

  return <InventoryPieChart data={counts} />;
};

const ItemSelector: React.FC<{
  handleClick: (value: Exclude<TargetNodeType, "resource">) => void;
  icon: JSX.Element;
  selected: boolean;
  type: Exclude<TargetNodeType, "resource">;
}> = ({ type, handleClick, selected, icon }) => {
  const onClick = useCallback(() => {
    handleClick(type);
  }, [handleClick, type]);
  return (
    <FilterStatistic
      category={targetNodeTypesDisplayText(type)}
      color={colors.neutral["20"]}
      icon={icon}
      selected={selected}
      textColor={colors.neutral["00"]}
      onClick={onClick}
      showEye={false}
    />
  );
};

const targetNodeTooltips: Record<TargetNodeType, string> = {
  identity: "An identity is an entity that can take action on your system.",
  grant:
    "Grants are assignments of privileges on a set of resources to an identity.",
  credential:
    "Credentials are the material that an actor uses to assume an identity.",
  resource:
    "A resource is any physical or digital asset that is valuable to an organization.",
};
export const ItemChart: React.FC<{ hasEnvironments: boolean }> = ({
  hasEnvironments,
}) => {
  const { assessment } = useContext(SelectedEnvironmentContext);
  const { step, graph } = useContext(ScopeContext);

  const { doc: allInventoryCounts, loading } = useInventoryCounts();

  const [nodeCount, setNodeCount] = useState<number>(0);

  const allLinks = useMemo(() => {
    return assessment.doc
      ? generateInventoryData(assessment.doc.data.targets)
      : undefined;
  }, [assessment]);

  const isLoading = useMemo(() => {
    // If allInventoryCounts is defined, we only need allLinks
    if (loading) return true;
    if (allInventoryCounts !== undefined) {
      const loading = hasEnvironments && !allLinks;
      return loading;
    }

    return (
      hasEnvironments &&
      (!["aggregating", "done"].includes(step) || !allLinks || !graph)
    );
  }, [loading, allInventoryCounts, hasEnvironments, step, allLinks, graph]);

  const [inventoryType, setInventoryType] =
    useState<Exclude<TargetNodeType, "resource">>("identity");

  useEffect(() => {
    const calculateNodeCount = async () => {
      if (!hasEnvironments) {
        setNodeCount(mockNodeCount);
        return;
      }

      if (allLinks && allInventoryCounts?.[inventoryType]) {
        const categoryTotalLabel = Object.values(allLinks[inventoryType]).find(
          (link) => link.show === inventoryType && link.where === ""
        )?.label;

        const count = sum(
          Object.entries(allInventoryCounts[inventoryType])
            .filter(([label]) => label !== categoryTotalLabel)
            .map(([_, count]) => count)
        );

        setNodeCount(count);
      } else if (graph && allLinks) {
        // TODO: remove this once we have all assessments writing inventory count metrics
        let totalCount = 0;

        await Promise.all(
          Object.values(allLinks[inventoryType])
            .filter(
              (link) => link.label !== targetNodeTypesDisplayText(inventoryType)
            )
            .map(async (link) => {
              const result = await countNodes(link, graph);
              totalCount += result.nodes.length;
            })
        );

        setNodeCount(totalCount);
      } else {
        setNodeCount(0);
      }
    };

    calculateNodeCount();
  }, [hasEnvironments, allInventoryCounts, inventoryType, allLinks, graph]);

  const navigate = useNavigateWithEnv();
  const handleViewFindings = useCallback(() => {
    navigate(`../${AppPaths.Inventory}`);
  }, [navigate]);

  return (
    <Pane
      title={`${nodeCount} Total ${targetNodeTypesDisplayText(inventoryType)}`}
      style={{ width: "100%", minWidth: "10em" }}
      showMore={{ text: "View in Inventory", onClick: handleViewFindings }}
      subtitle={targetNodeTooltips[inventoryType]}
      isLoading={isLoading}
      subtitleType="text"
    >
      <div style={{ display: "flex", height: "100%", width: "100%" }}>
        <VerticalDiv
          style={{ width: "30%", gap: "16px", justifyContent: "center" }}
        >
          {TargetNodeTypeDisplayOrder.map((t) =>
            isLoading ? (
              <Skeleton.Input active style={{ marginTop: "1em" }} key={t} />
            ) : (
              <ItemSelector
                key={t}
                type={t}
                handleClick={setInventoryType}
                selected={inventoryType === t}
                icon={identityTypeIcons[t]}
              />
            )
          )}
        </VerticalDiv>
        <div style={{ width: "70%" }}>
          {!isLoading ? (
            hasEnvironments ? (
              <Graph type={inventoryType} graph={graph} nodeCount={nodeCount} />
            ) : (
              <MockGraph />
            )
          ) : (
            <Skeleton
              active
              avatar={{
                size: 150,
              }}
              style={{ padding: "3em" }}
            />
          )}
        </div>
      </div>
    </Pane>
  );
};
