import { DurationOption, WorkflowConfiguration } from "./types";

export const DEFAULT_WORKFLOW_CONFIG: WorkflowConfiguration = {
  options: [
    { time: 5, unit: "m", value: "5 minutes" },
    { time: 1, unit: "h", value: "1 hour" },
    { time: 24, unit: "h", value: "1 day" },
    { time: 168, unit: "h", value: "1 week" },
    { time: 720, unit: "h", value: "30 days" },
  ],
};

export const DEFAULT_MAX_ACCESS_DURATION: DurationOption = {
  time: 180,
  unit: "d",
  value: "180 days",
};

export const DURATION_OPTIONS_SECONDS = {
  m: 60,
  h: 60 * 60,
  d: 60 * 60 * 24,
  w: 60 * 60 * 24 * 7,
};

export const WORKFLOW_CONFIGURATION_COLLECTION = "workflow-configuration";
export const AUDIT_LOG_COLLECTION = "audit-events";

export enum SSO_OIDC_PROVIDERS {
  "azure" = "azure-oidc",
  "google" = "google-oidc",
  "okta" = "okta-oidc",
}
