import pluralize from "pluralize";
import { DefaultTitles } from "shared/assessment/constants";
import { CredentialNode } from "shared/types/assessment/data/credential";

import { ExportColumnType } from "../../AssessmentExport";
import { CredentialDisplay } from "../../cells/Credential";
import { IdentityCell, identityCellValue } from "../../cells/Identity";
import { LastAuthenticated } from "../../cells/LastAuthenticated";
import { LastRotated } from "../../cells/LastRotated";
import { RiskAggregate, riskSorter } from "../../cells/RiskAggregate";
import { epochMillisExport, genericExport } from "../../export";
import { numberSorter, stringSorter } from "../../sort";
import { AssessmentColumnProps } from "../columns";

const replaceNaN = (value: number, fallback: number) =>
  isNaN(value) ? fallback : value;

export const credentialColumns = (
  props: AssessmentColumnProps
): ExportColumnType<CredentialNode>[] => [
  {
    key: "credential",
    ellipsis: true,
    title: DefaultTitles.credential,
    sorter: (left, right) => stringSorter(left.key, right.key),
    render: (_, node) =>
      node.key && (
        <CredentialDisplay credential={node.data} id={node.key} {...props} />
      ),
    export: genericExport((node) =>
      node.data.type === "key" ? `${node.key}` : node.data.type
    ),
    width: 200,
  },
  {
    key: "identity",
    title: DefaultTitles.identity,
    ellipsis: true,
    sorter: (left, right) =>
      stringSorter(
        left.aggregates.identity[0].id,
        right.aggregates.identity[0].id
      ),
    render: (_, node) => (
      <IdentityCell identity={node.aggregates.identity[0]} {...props} />
    ),
    export: genericExport((node) =>
      identityCellValue(node.aggregates.identity[0])
    ),
    width: 200,
  },
  {
    key: "lastAuthnTime",
    title: "Last Used",
    ellipsis: true,
    sorter: (left, right) =>
      // Ordering is reversed because it looks back in time
      numberSorter(
        replaceNaN(right.data.lastAuthnTime, 0),
        replaceNaN(left.data.lastAuthnTime, 0)
      ),
    render: (_, node) => (
      <LastAuthenticated
        identity={node.aggregates.identity[0]}
        last={node.data.lastAuthnTime}
        createdAt={node.data.createdTime}
        lookback={node.data.maxAuthnLookbackDays}
      />
    ),
    export: epochMillisExport((node) => node.data.lastAuthnTime, undefined),
    width: 130,
  },
  {
    key: "lastRotatedTime",
    title: "Last Rotated",
    sorter: (left, right) =>
      // Cf. lastAuthnTime sorting
      numberSorter(
        replaceNaN(right.data.createdTime ?? 0, 0),
        replaceNaN(left.data.createdTime ?? 0, 0)
      ),
    render: (_, node) => <LastRotated credential={node.data} />,
    export: epochMillisExport((node) => node.data.createdTime, "unknown"),
    width: 130,
  },
  {
    key: "risk",
    title: pluralize.plural(DefaultTitles.risk),
    sorter: (left, right) =>
      riskSorter(left.aggregates.risks, right.aggregates.risks),
    render: (_, node) => <RiskAggregate risks={node.aggregates.risks} />,
    export: genericExport((node) => node.aggregates.risks),
    width: 100,
    minWidth: 90,
  },
];
