import { iamShowOptions } from "shared/assessment/constants";
import { TargetNodeType } from "shared/types/assessment/data";
import {
  QueryParamConfig,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

export type Controls = {
  display: string;
  where: string;
  show: TargetNodeType;
};

export const useControls = () => {
  const [controls, setControls] = useQueryParams({
    display: withDefault(createEnumParam(["graph", "table"]), "table"),
    where: withDefault(StringParam, ""),
    show: withDefault(
      createEnumParam(
        Object.values(iamShowOptions("item")).map((v) => v.value)
      ),
      "identity"
    ) as QueryParamConfig<TargetNodeType>,
  });

  return { controls, setControls };
};
