import { purple } from "@ant-design/colors";
import { RendererParams } from "components/Assessment/contexts/GraphContext";
import { providerTitles } from "shared/assessment/constants";
import { ConnectedNode } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";

import { CellInner } from "../../cells/Inner";
import {
  DefaultNodeHeaderHeight,
  DefaultNodeOneLineBodyHeight,
  DefaultNodeWidth,
} from "./shared";

export const CustomPrivilegeSetNodeParameters = {
  type: "privilegeSet" as const,
  titleText: (
    node: ConnectedNode<AssessmentNodes, "privilegeSet">,
    rendererParams: RendererParams
  ) => {
    const { provider } = rendererParams;
    return providerTitles[node.data.provider ?? provider].privilegeSet;
  },
  bodyBorderColor: purple[2],
  bodyBackgroundColor: purple[1],
  bodyContent: (node: ConnectedNode<AssessmentNodes, "privilegeSet">) => (
    <CellInner>{node.key}</CellInner>
  ),
  bodySize: () => DefaultNodeOneLineBodyHeight,
  headerSize: () => DefaultNodeHeaderHeight,
  width: () => DefaultNodeWidth,
};
