import styled from "styled-components";

export const StyledPrefixWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1em;
  width: 100%;
`;

export const StyledPivotPrefix = styled.span<{
  textWidth?: "lg" | "md" | "sm";
}>`
  font-weight: 600;
  width: ${(props) =>
    props.textWidth === "lg"
      ? "5em"
      : props.textWidth === "md"
      ? "4em"
      : props.textWidth === "sm"
      ? "3em"
      : "unset"};
`;
