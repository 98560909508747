import { EditOutlined } from "@ant-design/icons";
import { Alert, Button, Grid, Spin, Typography } from "antd";
import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { DateHistogram } from "components/Histogram/Histogram";
import { Tenant } from "components/Login";
import { ResizableTableColumnsType } from "components/ResizableTable";
import { VerticalSpacedDiv } from "components/divs";
import { subDays } from "date-fns";
import { User } from "firebase/auth";
import { useFlags } from "launchdarkly-react-client-sdk";
import { compact, maxBy, sortBy } from "lodash";
import pluralize from "pluralize";
import { FirestoreDoc, useFirestoreDoc } from "providers/FirestoreProvider";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { toAssessmentPath } from "shared/assessment/helper";
import { ConnectedNode, DirectedGraph, Node } from "shared/graph/types";
import { fromGrantKey } from "shared/integrations/resources/gcloud/assessment";
import { AppPaths } from "shared/routes/constants";
import { ALL_SCOPE_SENTINEL, toKey } from "shared/types/assessment";
import { AnyNode, AssessmentNodes } from "shared/types/assessment/data";
import {
  Finding,
  FindingNode,
  FindingState,
} from "shared/types/assessment/finding";
import { Monitor, MonitorScope } from "shared/types/assessment/monitor";
import styled from "styled-components";
import {
  QueryParamProvider,
  StringParam,
  useQueryParam,
} from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { Heading } from "../../Heading";
import { LegacyButtonGroup } from "../../common/LegacyButtonGroup";
import { AssessmentGraph } from "../components/AssessmentGraph";
import { EditMonitorModal } from "../components/EditMonitorModal";
import { FindingsAssignModal } from "../components/FindingsAssignModal";
import { FindingsDatePicker } from "../components/FindingsDatePicker";
import { FindingsFixOrRevertModal } from "../components/FindingsFixOrRevertModal";
import { FindingsIgnoreModal } from "../components/FindingsIgnoreModal";
import { FindingsManageModal } from "../components/FindingsManageModal";
import { FindingsSelect } from "../components/FindingsSelect";
import { ScopeScoring, ScopeSelect } from "../components/ScopeSelect";
import { DetailsColumn } from "../components/cells/DetailsColumn";
import { SinceColumn } from "../components/cells/SinceColumn";
import { MonitorPriority } from "../components/monitor/MonitorSeverity";
import { PriorityTooltip } from "../components/monitor/PriorityTooltip";
import {
  FindingsContext,
  MAX_FINDINGS,
  MonitorWithFindings,
} from "../contexts/FindingsContext";
import { EmptyDefaultGraph, ScopeContext } from "../contexts/ScopeContext";
import { useControls } from "../hooks/useControls";
import { useNavigateWithEnv } from "../hooks/useNavigateWithEnv";
import { useTracker } from "../hooks/useTracker";
import { FindingDetail } from "./FindingDetail";

export type MonitorActionProps = {
  actOn: FirestoreDoc<Finding>[];
  allNodes: AnyNode[] | undefined;
  count: number;
  findingFor: (node: AnyNode) => FirestoreDoc<Finding> | undefined;
  integration: MonitorScope;
  monitor: MonitorWithFindings;
  selectedNodes: AnyNode[] | undefined;
  state: FindingState;
  user?: User;
};

export const MonitorResults: React.FC = () => {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <MonitorResultsContent />
    </QueryParamProvider>
  );
};

const hasFixModal = (
  integration: MonitorScope,
  monitor: Monitor | undefined,
  state: FindingState
) =>
  !!integration &&
  !!monitor &&
  !!(
    (monitor.fix?.[integration] && state === "open") ||
    (monitor.revert?.[integration] && state === "resolved")
  );

const hasManageModal = (
  integration: MonitorScope,
  monitor: Monitor | undefined
) => {
  return !!integration && !!monitor && monitor.management;
};
const hasIgnoreToggle = (state: FindingState) =>
  state === "open" || state === "ignored";

const hasAssignModal = (
  state: FindingState,
  findings: FirestoreDoc<Finding>[]
) => state === "open" && findings.find((f) => !f.data.issue);

const syntheticNode = (
  node: FindingNode
): ConnectedNode<AssessmentNodes, keyof AssessmentNodes> | undefined => {
  let out;
  switch (node.type) {
    case "grant":
      {
        out = { ...node, data: fromGrantKey(node.key) };
      }
      break;
    case "identity":
      out = { ...node, data: {} };
      break;
    default:
      return undefined;
  }
  return { ...out, children: [], parents: [] };
};

const ActionBar: React.FC<Omit<MonitorActionProps, "actOn" | "count">> = (
  props
) => {
  const {
    assessmentFindingAssignment: hasAssignment,
    assessmentManage: hasManagement,
  } = useFlags();
  const { count, actOn } = useMemo(() => {
    const count = props.selectedNodes?.length ?? 0;
    const nodes = count ? props.selectedNodes : props.allNodes;
    const actOn = compact((nodes ?? []).map(props.findingFor));
    return { count, actOn };
  }, [props]);
  const tracker = useTracker();

  const innerProps = useMemo(
    () => ({ ...props, actOn, count }),
    [props, actOn, count]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
      }}
    >
      <LegacyButtonGroup>
        {hasAssignment && !!tracker && hasAssignModal(props.state, actOn) && (
          <FindingsAssignModal {...innerProps} />
        )}
        {hasIgnoreToggle(props.state) && (
          <FindingsIgnoreModal {...innerProps} />
        )}
        {hasFixModal(props.integration, props.monitor, props.state) && (
          <FindingsFixOrRevertModal {...innerProps} />
        )}
        {hasManagement && hasManageModal(props.integration, props.monitor) && (
          <FindingsManageModal {...innerProps} />
        )}
      </LegacyButtonGroup>
      <div
        style={{
          borderTopRightRadius: "2px",
          borderBottomRightRadius: "2px",
          minWidth: "6.5em",
          maxWidth: "10em",
          padding: "5px",
          backgroundColor: "#f5f5f5",
        }}
      >
        {count || "All"}
        &nbsp;{pluralize("findings", count)}
      </div>
    </div>
  );
};

// Styled components
const FilterControlsContainer = styled.div<{ flexWrap?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: ${(props) => (props.flexWrap ? "nowrap" : "wrap")};
`;

const MonitorResultsContent: React.FC = () => {
  const { monitorId, findingId, orgSlug } = useParams();
  const [nodeKey, setNodeKey] = useQueryParam("node", StringParam);

  const { current, details, runAssessmentNow, last } = useContext(
    SelectedEnvironmentContext
  );
  const assessmentId = details?.assessmentId;
  const tenantId = useContext(Tenant);
  const {
    loading,
    range,
    selectedMonitor: monitor,
    setRange,
    state: findingsState,
  } = useContext(FindingsContext);
  const { graph, integration, scopeKey, validScopeKeys } =
    useContext(ScopeContext);
  const { controls, setControls } = useControls();
  const navigate = useNavigateWithEnv();
  const [searchedNodes, setSearchedNodes] = useState<AnyNode[]>();
  const [selectedNodes, setSelectedNodes] = useState<AnyNode[]>();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const closeEditModal = useCallback(() => setEditModalOpen(false), []);
  const openEditModal = useCallback(() => setEditModalOpen(true), []);

  // Clear selected nodes on scope change
  useEffect(() => {
    setSelectedNodes([]);
  }, [scopeKey]);

  const { xl } = Grid.useBreakpoint();

  const monitorControls = useMemo(
    () => ({
      where: (monitor?.search ?? []).map((s) => s.term).join(" "),
      show: monitor?.show ?? "grant",
    }),
    [monitor]
  );

  const inferredControls = useMemo(() => {
    return { ...controls, show: monitorControls.show };
  }, [controls, monitorControls]);

  const findings = useMemo(() => monitor?.scopedFindings, [monitor]);

  const filteredGraph = useMemo<
    DirectedGraph<AssessmentNodes> | undefined
  >(() => {
    if (!monitorId || !graph) return undefined;
    if (!findings) return { nodes: [] };
    const nodes = sortBy(
      findings.map((d) => d.data.node),
      "key"
    );
    return {
      nodes: compact(
        nodes.map(
          (n) =>
            graph.nodes.find((nn) => n.key === nn.key && n.type === nn.type) ??
            syntheticNode(n)
        )
      ),
    };
  }, [monitorId, graph, findings]);

  const openedFindingsNode = useMemo(() => {
    if (!nodeKey) return undefined;
    return searchedNodes?.find((n) => n.key === nodeKey);
  }, [nodeKey, searchedNodes]);

  // TODO: Improve performance if this ever matters
  const findingFor = useCallback(
    (node: Node<any, any>) =>
      findings?.find(
        (d) => d.data.node.key === node.key && d.data.node.type === node.type
      ),
    [findings]
  );

  const extraColumns = useMemo(():
    | ResizableTableColumnsType<AnyNode>
    | undefined => {
    if (!assessmentId || !orgSlug || !monitorId || !findings) return undefined;

    return [
      DetailsColumn((node) => {
        const linkId = findingFor(node)?.id;

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set("node", node.key);

        return linkId
          ? {
              disabled: linkId === findingId,
              key: linkId,
              to: `/o/${orgSlug}/${
                AppPaths.Posture
              }/monitors/${monitorId}/findings/${linkId}?${searchParams.toString()}`,
            }
          : undefined;
      }),
      SinceColumn(findingsState, (node) => findingFor(node)?.data.trigger.at),
    ];
  }, [
    assessmentId,
    findingFor,
    findingId,
    findings,
    findingsState,
    monitorId,
    orgSlug,
  ]);

  // Clean up node param when drawer closes
  useEffect(() => {
    if (!findingId && nodeKey) {
      setNodeKey(undefined);
    }
  }, [findingId, nodeKey, setNodeKey]);

  // Is undefined unless user has navigated to a findings page
  const findingDoc = useFirestoreDoc<Finding>(
    findingId && assessmentId
      ? `${toAssessmentPath(tenantId, assessmentId)}/findings/${findingId}`
      : undefined,
    { live: true }
  );

  const unevaluatedMonitorText = useMemo(() => {
    if (monitor) {
      if (monitor.scopedFindings?.length === 0) {
        return `This monitor has not been evaluated yet. Results for this monitor will be available after the next assessment job.`;
      } else {
        return `This monitor's search term has been updated, and the results on the page are out of date. Updated results for this monitor will be available after the next assessment job.`;
      }
    }
    return "";
  }, [monitor]);

  const hasBulkSelect = useMemo(
    () =>
      hasIgnoreToggle(findingsState) ||
      hasFixModal(integration, monitor, findingsState),
    [findingsState, integration, monitor]
  );

  const frozen = useMemo(
    () => ({
      show: monitorControls.show,
      // If a finding is resolved, then the monitor search will no longer match the finding node.
      // Ensure resolved findings are actually displayed by removing the frozen where. Note that this
      // means that the graph display will not show any attack path.
      terms: findingsState === "resolved" ? "" : monitorControls.where,
    }),
    [findingsState, monitorControls]
  );

  // Rank scopes by number of applicable findings
  const scopeScoring: ScopeScoring = useMemo(() => {
    if (!monitor) {
      return () => ({ value: 0, label: "0" });
    }
    return (scope) => {
      const k = toKey(scope);
      const count = monitor.countsByScope[k] ?? 0;
      return { value: count, label: String(count) };
    };
  }, [monitor]);

  const convertDate = useCallback(
    ({ data }: FirestoreDoc<Finding>) => new Date(data.trigger.at),
    []
  );

  // If no scope is selected, or the "all" scope is selected, but this UI does not support
  // the "all" scope, redirect to the most highly scored scope, or the first scope if there
  // is no scoring.
  // nosemgrep use-useguardedeffect
  useEffect(() => {
    // Do not change selected scope if it is currently valid
    if (
      !last.doc?.data.scope?.length ||
      !scopeScoring ||
      monitor?.scopes?.includes(ALL_SCOPE_SENTINEL) ||
      (scopeKey !== ALL_SCOPE_SENTINEL && validScopeKeys.has(scopeKey))
    )
      return;
    const next = maxBy(last.doc.data.scope, (s) => scopeScoring(s).value);

    // Dirty hack:
    // `setScopeKey` does not use the correct path when this effect is triggered on navigation.
    // Therefore, manually use `navigate` to update scope
    // If the monitor does not include the next scope, then do not update the scope
    if (next && monitor?.scopes.includes(next.integration)) {
      const search = new URLSearchParams(window.location.search);
      search.set("scope", toKey(next));
      navigate(`.?${search}`, { replace: true });
    }
  }, [
    last.doc,
    scopeKey,
    navigate,
    validScopeKeys,
    scopeScoring,
    monitor?.scopes,
  ]);

  const scopeNode = useMemo(
    () =>
      monitor ? (
        <ScopeSelect
          includeAll={true}
          scoring={scopeScoring}
          scopesToInclude={monitor.scopes}
          wide
        />
      ) : null,
    [monitor, scopeScoring]
  );

  const settingsDisables = useMemo(
    () => ({ stopOn: !(scopeKey === ALL_SCOPE_SENTINEL) }),
    [scopeKey]
  );

  return monitorId && monitor ? (
    <>
      <EditMonitorModal
        editModalOpen={editModalOpen}
        closeEditModal={closeEditModal}
        modalMonitor={monitor}
      />
      <VerticalSpacedDiv style={{ maxWidth: "1200px" }}>
        <div>
          <Heading
            title={
              <>
                {monitor.label}{" "}
                {monitor.isCustom && (
                  <Button type="text" onClick={openEditModal}>
                    <EditOutlined
                      style={{
                        fontSize: "20px",
                        verticalAlign: "middle",
                      }}
                    />
                  </Button>
                )}
              </>
            }
          />
          {monitor.description && (
            <Typography.Paragraph>{monitor.description}</Typography.Paragraph>
          )}
          <Typography.Paragraph>
            This monitor is marked as <MonitorPriority monitor={monitor} />{" "}
            priority.&nbsp;
            <PriorityTooltip />
          </Typography.Paragraph>
        </div>
        {monitor.hasBeenEvaluated === false && (
          <Alert
            type="warning"
            description={unevaluatedMonitorText}
            message="One more thing..."
            action={
              current.isInProgress ? (
                <Typography.Text type="secondary">
                  Assessment job in progress
                </Typography.Text>
              ) : (
                <Button
                  type="default"
                  disabled={!current.isCompleted}
                  onClick={runAssessmentNow}
                >
                  Run an assessment now
                </Button>
              )
            }
            style={{
              marginBottom: 16,
              color: "#614700",
              alignItems: "center",
            }}
          />
        )}
        {findings && (
          <DateHistogram
            data={loading ? undefined : findings} // Smoothly update on findings select
            value={convertDate}
            range={[
              range[0] ?? subDays(new Date(), 30),
              range[1] ?? new Date(),
            ]}
            setRange={setRange}
            label={findingsState === "open" ? "new" : findingsState} // Date on open finding is when it is new
          />
        )}
        <FilterControlsContainer flexWrap={xl}>
          <FindingsSelect />
          <FindingsDatePicker />
          {hasBulkSelect && (
            <ActionBar
              allNodes={searchedNodes}
              findingFor={findingFor}
              integration={integration}
              monitor={monitor}
              selectedNodes={selectedNodes}
              state={findingsState}
            />
          )}
        </FilterControlsContainer>

        {(monitor.countsByScope[scopeKey] ?? 0) > MAX_FINDINGS && (
          <Alert
            type="warning"
            description={
              MAX_FINDINGS > monitor.scopedFindings.length
                ? `Showing the filtered findings from the first ${MAX_FINDINGS} findings.`
                : `Too many findings. Showing only the first ${monitor.scopedFindings.length}.`
            }
          />
        )}
        <AssessmentGraph
          graph={filteredGraph ?? EmptyDefaultGraph}
          controls={inferredControls}
          onControls={setControls}
          onSearch={setSearchedNodes}
          scopeNode={scopeNode}
          onSelection={hasBulkSelect ? setSelectedNodes : undefined}
          settingsDisables={settingsDisables}
          scopeKey={scopeKey}
          frozen={frozen}
          extraColumns={extraColumns}
        />
      </VerticalSpacedDiv>
      {findingId && (
        <FindingDetail node={openedFindingsNode} finding={findingDoc} />
      )}
    </>
  ) : (
    <Spin />
  );
};
