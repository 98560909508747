/** Returns if a settled promise result is rejected
 *
 * Usage:
 * ```
 * const results = await Promise.allSettled(...);
 * const rejected = results.find(isRejectedSettledPromise);
 * if (rejected) {
 *   ...
 * }
 * ```
 */
export const isRejectedSettledPromise = (
  result: PromiseSettledResult<any>
): result is PromiseRejectedResult => result.status === "rejected";

export const isa = <T>(allowed: readonly T[], value: any): value is T =>
  (allowed as any[]).includes(value);

export const isKeyOf = <T>(allowed: T, value: any): value is keyof T =>
  !!allowed &&
  typeof value === "string" &&
  !!Object.getOwnPropertyDescriptor(allowed, value);

export const isDefined = <T>(value: T): value is NonNullable<T> =>
  value !== undefined && value !== null;

export const isArrayOf = <T>(
  value: any,
  checker: (value: any) => boolean
): value is T[] => Array.isArray(value) && value.every(checker);
