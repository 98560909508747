import { LinkOutlined } from "@ant-design/icons";
import { Modal, Typography } from "antd";
import { ButtonProps } from "antd/lib/button";
import { UserRole } from "components/Login";
import { AuthzButton } from "components/common/AuthzButton";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { AppPaths } from "shared/routes/constants";
import { AssessmentScopeIntegration } from "shared/types/assessment";
import { widetype } from "shared/util/collections";

import { NewEnvironmentForm } from "../components/NewEnvironmentForm";
import { EnvironmentCreationProvider } from "../contexts/EnvironmentCreationContext";

export const CREATE_ENV_ROLES: UserRole[] = ["owner", "iamOwner"];
export const CreateEnvironment: React.FC<{
  installed: Record<AssessmentScopeIntegration, boolean>;

  buttonCopy?: JSX.Element | string;
  buttonStyle?: React.CSSProperties;
  buttonProps?: Omit<ButtonProps, "onClick">;
  onClick?: () => void;
}> = ({ installed, buttonCopy, buttonProps, onClick }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = useCallback(() => setModalOpen(false), [setModalOpen]);
  const handleClick = useCallback(() => {
    setModalOpen(true);
    onClick?.();
  }, [onClick, setModalOpen]);

  const { orgSlug } = useParams();

  const installedIntegrations = useMemo(
    () =>
      widetype.keys(installed).filter((integration) => installed[integration]),
    [installed]
  );

  return (
    <>
      <AuthzButton
        roles={CREATE_ENV_ROLES}
        type="primary"
        onClick={handleClick}
        {...buttonProps}
        style={{ margin: "5px", padding: "0 5px 0 5px", ...buttonProps?.style }}
      >
        {buttonCopy || "Create"}
      </AuthzButton>
      <Modal
        open={modalOpen}
        destroyOnClose // Clear new assessment form on close
        maskClosable={false}
        footer={false}
        onCancel={closeModal}
      >
        {installedIntegrations.length > 0 ? (
          <>
            <Typography.Title level={4}>
              Create New Environment
            </Typography.Title>
            <EnvironmentCreationProvider>
              <NewEnvironmentForm
                setFormOpen={setModalOpen}
                installedIntegrations={installedIntegrations}
              />
            </EnvironmentCreationProvider>
          </>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Title level={4}>
              No Available Integrations
            </Typography.Title>
            <Typography.Text>
              To create an environment, you&apos;ll first need to install an
              &ldquo;IAM assessment&rdquo; integration.
            </Typography.Text>
            <div style={{ display: "flex", placeContent: "center" }}>
              <div>
                <Link
                  style={{
                    margin: "2em",
                    width: "12em",
                    textAlign: "center",
                  }}
                  to={`/o/${orgSlug}/${AppPaths.Integrations}`}
                >
                  Install Integrations
                </Link>
              </div>
              <div>
                <Link
                  style={{
                    margin: "2em",
                    width: "12em",
                    textAlign: "center",
                  }}
                  to={`https://docs.p0.dev/iam-assessment/running-an-iam-assessment`}
                  target="_blank"
                >
                  Read Docs <LinkOutlined />
                </Link>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
