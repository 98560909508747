import { App, ConfigProvider, message } from "antd";
import { ThemeConfig } from "antd/lib";
import React from "react";
import { colors } from "styles/variables";

const theme: ThemeConfig = {
  cssVar: true,
  token: {
    fontFamily: "Inter, -apple-system, BlinkMacSystemFont, sans-serif",
    borderRadius: 2,
    colorPrimary: colors.primary["60"],
  },
  components: {},
};

message.config({
  maxCount: 3,
  getContainer: () => document.body,
  duration: 3,
});

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ConfigProvider theme={theme}>
      <App>{children}</App>
    </ConfigProvider>
  );
};
