import { FindingsContext } from "components/Assessment/contexts/FindingsContext";
import {
  MonitorPriorityCounts,
  MonitorPriorityCountsWithBins,
} from "components/Dashboard/types";
import {
  SelectedEnvironment,
  SelectedEnvironmentContext,
} from "components/Environment/contexts/SelectedEnvironmentContext";
import { Tenant } from "components/Login";
import { startOfDay, startOfWeek, subWeeks } from "date-fns";
import {
  QueryConstraint,
  Timestamp,
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { compact, map, pick, sortBy, uniq } from "lodash";
import { DB } from "providers/FirestoreProvider";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { toAssessmentPath } from "shared/assessment/helper";
import { AssessmentNodes } from "shared/types/assessment/data";
import { FindingState } from "shared/types/assessment/finding";
import {
  Monitor,
  MonitorPriority,
  monitorPriorities,
} from "shared/types/assessment/monitor";
import { assertNever } from "utils/assert";

interface QueryResult {
  count: number;
  metadata: {
    priority: MonitorPriority;
    state: FindingState;
    weekStart: number;
    monitorIds: string[];
  };
}

type WeekResult = Record<FindingState, number>;

const INITIAL_MONITOR_COUNTS: MonitorPriorityCountsWithBins = {
  bins: [],
  CRITICAL: { byTimestamp: [], totalCount: 0 },
  HIGH: { byTimestamp: [], totalCount: 0 },
  MEDIUM: { byTimestamp: [], totalCount: 0 },
  LOW: { byTimestamp: [], totalCount: 0 },
};

export const WEEKS_TO_FETCH = 13;

type WhereConstraints = {
  monitorIds: string[];
  state: FindingState;
  weekStart: number;
  weekEnd: number;
  priority: MonitorPriority;
};

type WeekMapKey = `${MonitorPriority}_${number}`;

/**
 * The goal is to find all findings that were open during each week,
 * incorporating both resolved and ignored findings.
 *
 * This is a bit tricky because we need to account for the fact that
 * a finding can be resolved and then reopened.
 *
 * We do this by using both the firstSeen and trigger.at/ignored.at timestamps
 * to determine if a finding was open during a given week.
 *
 * Note: We still cannot account for findings that were resolved and then re-opened
 * during the same week.
 */
const generateWhereConstraints = ({
  monitorIds,
  state,
  weekEnd,
}: WhereConstraints): QueryConstraint[] => {
  const constraints: QueryConstraint[] = [];

  constraints.push(where("monitorId", "in", monitorIds));
  constraints.push(where("state", "==", state));
  constraints.push(where("firstSeen", "<=", weekEnd));

  switch (state) {
    case "open":
      break;

    case "resolved":
      constraints.push(where("trigger.at", ">", weekEnd));
      break;

    case "ignored":
      constraints.push(where("ignore.at", ">", weekEnd));
      break;

    default:
      throw assertNever(state);
  }

  return constraints;
};

/**
 * Groups monitors by their priority level
 */
const groupMonitorsByPriority = (monitors: Record<string, Monitor>) => {
  const monitorsByPriority: Record<string, string[]> = {};
  Object.entries(monitors).forEach(([label, monitor]) => {
    if (!monitorsByPriority[monitor.priority]) {
      monitorsByPriority[monitor.priority] = [];
    }
    monitorsByPriority[monitor.priority].push(label);
  });
  return monitorsByPriority;
};

/**
 * Generates constraints for the past N weeks
 */
const generateAllConstraints = (monitors: Record<string, Monitor>) => {
  const now = Date.now();
  const queries: WhereConstraints[] = [];
  const monitorsByPriority = groupMonitorsByPriority(monitors);

  // Generate queries for each week
  for (let weekIndex = 0; weekIndex < WEEKS_TO_FETCH; weekIndex++) {
    const weekEndDate = subWeeks(now, weekIndex);
    const weekEndTs = weekEndDate.getTime();
    const weekStartTs = startOfWeek(weekEndTs).getTime();

    // For each week, generate queries for each priority and state
    for (const priority of monitorPriorities) {
      const monitorIds = monitorsByPriority[priority] || [];
      if (!monitorIds.length) continue;

      for (const state of FindingState) {
        queries.push({
          monitorIds,
          state,
          weekStart: weekStartTs,
          weekEnd: weekEndTs,
          priority,
        });
      }
    }
  }

  return queries;
};

/**
 * Generates Firebase queries plus metadata for each query
 */
export const generateQueries = (
  monitors: Record<string, Monitor<AssessmentNodes, any>>
) => {
  const constraints = generateAllConstraints(monitors);
  return constraints.map((constraint) => ({
    constraints: generateWhereConstraints(constraint),
    metadata: {
      weekStart: constraint.weekStart,
      priority: constraint.priority,
      state: constraint.state,
      monitorIds: constraint.monitorIds,
    },
  }));
};

const buildWeekMap = (
  results: PromiseSettledResult<QueryResult>[]
): Map<WeekMapKey, WeekResult> => {
  const weekMap = new Map<WeekMapKey, WeekResult>();

  for (const result of results) {
    if (result.status === "fulfilled") {
      const { count, metadata } = result.value;
      const { priority, state, weekStart } = metadata;
      const key: WeekMapKey = `${priority}_${weekStart}`;

      const bucket = weekMap.get(key) ?? {
        open: 0,
        ignored: 0,
        resolved: 0,
      };

      bucket[state] += count;
      weekMap.set(key, bucket);
    }
  }

  return weekMap;
};

const getSortedWeekTimestamps = (weekMap: Map<WeekMapKey, WeekResult>) => {
  const allKeys = [...weekMap.keys()];
  const numbers = map(allKeys, (k) => safeParseWeekTimestamp(k));
  const uniqueNumbers = compact(uniq(numbers));
  return sortBy(uniqueNumbers);
};

const safeParseWeekTimestamp = (key: WeekMapKey): number | undefined => {
  const parts = key.split("_");
  // handle invalid keys
  if (parts.length !== 2) {
    return;
  }

  const [_priority, timestampStr] = parts;
  const timestamp = Number(timestampStr);
  // handle invalid timestamps
  if (Number.isNaN(timestamp)) {
    return;
  }
  return timestamp;
};

const processResults = (
  results: PromiseSettledResult<QueryResult>[],
  selected: SelectedEnvironment
): [number, MonitorPriorityCountsWithBins] => {
  const weekMap = buildWeekMap(results);

  const allWeekTimestamps = getSortedWeekTimestamps(weekMap);

  const bins = allWeekTimestamps.map((ts) =>
    startOfDay(new Date(ts)).getTime()
  );

  const newCounts: MonitorPriorityCounts = {
    CRITICAL: { byTimestamp: Array(bins.length).fill(0), totalCount: 0 },
    HIGH: { byTimestamp: Array(bins.length).fill(0), totalCount: 0 },
    MEDIUM: { byTimestamp: Array(bins.length).fill(0), totalCount: 0 },
    LOW: { byTimestamp: Array(bins.length).fill(0), totalCount: 0 },
  };

  let minDate = Date.now();
  // for each priority, accumulate the counts for each week
  monitorPriorities.forEach((priority) => {
    allWeekTimestamps.forEach((weekTs, idx) => {
      const key: WeekMapKey = `${priority}_${weekTs}`;
      const data = weekMap.get(key);

      if (data) {
        const weekTotal = FindingState.reduce((acc, state) => {
          return acc + data[state];
        }, 0);

        newCounts[priority].byTimestamp[idx] = weekTotal;

        if (weekTotal > 0) {
          minDate = Math.min(minDate, weekTs);
        }
      }
    });

    // The totalCount is the most recent week's tally
    const lastIndex = newCounts[priority].byTimestamp.length - 1;

    if (lastIndex >= 0) {
      newCounts[priority].totalCount =
        newCounts[priority].byTimestamp[lastIndex];
    }
  });

  const newCountsWithBins: MonitorPriorityCountsWithBins = {
    ...newCounts,
    bins,
  };

  const anchor = selected.assessment.doc?.data.frequency?.anchorDate;
  const startDate = anchor
    ? // Could have 0 findings after job start
      Math.min((anchor as Timestamp).toMillis(), minDate)
    : minDate;

  return [startDate, newCountsWithBins];
};

/**
 * Return all open findings, and any findings resolved or ignored since sinceTimestamp.
 *
 * The hook  accepts an optional list of monitorIds. If provided, only those monitors will be considered.
 */
export const useDashboardFindings = (filterMonitorIds?: string[]) => {
  const tenant = useContext(Tenant);
  const selected = useContext(SelectedEnvironmentContext);
  const { allMonitors } = useContext(FindingsContext);
  const { details } = selected;
  const assessmentId = details?.assessmentId;
  const [monitorsByPriority, setMonitorsByPriority] =
    useState<MonitorPriorityCountsWithBins>(INITIAL_MONITOR_COUNTS);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [startDate, setStartDate] = useState<number>(Date.now());
  const assessmentPath = useMemo(
    () =>
      tenant && assessmentId
        ? toAssessmentPath(tenant, assessmentId)
        : undefined,
    [tenant, assessmentId]
  );

  // Combine preset monitors with custom monitors and optionally filter by provided monitorIds
  const monitors = useMemo(() => {
    if (filterMonitorIds && filterMonitorIds.length > 0) {
      return pick(allMonitors, filterMonitorIds);
    }
    return allMonitors;
  }, [filterMonitorIds, allMonitors]);

  const queries = useMemo(() => generateQueries(monitors), [monitors]);

  const fetchCounts = useCallback(async () => {
    if (!tenant || !assessmentPath || !selected) return;

    setLoading(true);
    setError(null);
    const start = performance.now();

    try {
      const results = await Promise.allSettled(
        queries.map(async ({ constraints, metadata }) => {
          const findingsRef = collection(DB, assessmentPath, "findings");
          const q = query(findingsRef, ...constraints);
          const snapshot = await getCountFromServer(q);

          return {
            count: snapshot.data().count,
            metadata,
          };
        })
      );

      const [startDate, output] = processResults(results, selected);

      setStartDate(startDate);
      setMonitorsByPriority(output);
    } catch (err) {
      setError(err as Error);
    } finally {
      // eslint-disable-next-line no-console
      console.log(
        "Time to load finding counts",
        (performance.now() - start).toFixed(1),
        "ms"
      );
      setLoading(false);
    }
  }, [tenant, assessmentPath, queries, selected]);

  useEffect(() => {
    if (tenant && assessmentId) {
      fetchCounts();
    }
  }, [fetchCounts, tenant, assessmentId]);

  return {
    loading,
    monitorsByPriority,
    error,
    startDate,
  };
};
