import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import React from "react";

import { getEnvironment } from "./utils/environment";

const ENABLED_ENVIRONMENTS = ["stage", "prod", "prod-cna-central"];

export const SentryMonitor: React.FC<React.PropsWithChildren<object>> = ({
  children,
}) => {
  const environment = getEnvironment().environment;
  if (ENABLED_ENVIRONMENTS.includes(environment)) {
    Sentry.init({
      dsn: "https://8373a08544294b8fb5e230a234ff615a@o4504696266883072.ingest.sentry.io/4504970240327680",
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        new CaptureConsole({ levels: ["error"] }),
        new Sentry.Replay(),
      ],
    });
    return <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>;
  }
  return <>{children}</>;
};
