import { blue } from "@ant-design/colors";
import { SolutionOutlined } from "@ant-design/icons";
import { GraphTooltip } from "components/GraphTooltip";
import { grantNodeToPrivilegeSet } from "shared/assessment/render";
import { ConnectedNode } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";

import { RendererParams } from "../../../contexts/GraphContext";
import { IdentityCell } from "../../cells/Identity";
import { CellInner } from "../../cells/Inner";
import { Resource } from "../../cells/Resource";
import { providerTitleElements } from "../grant/shared";
import {
  DefaultNodeHeaderHeight,
  DefaultNodeTwoLineBodyHeight,
  DefaultNodeWidth,
} from "./shared";

export const CustomGrantNodeParameters = {
  type: "grant" as const,
  titleText: (node: ConnectedNode<AssessmentNodes, "grant">) => {
    return providerTitleElements[node.data.provider].grant;
  },
  IconComponent: SolutionOutlined,
  bodyBorderColor: blue[1],
  bodyBackgroundColor: blue[0],
  bodyContent: (
    node: ConnectedNode<AssessmentNodes, "grant">,
    rendererParams: RendererParams
  ) => {
    const { currentLocaleNode, options } = rendererParams;
    const getMain = (node: ConnectedNode<AssessmentNodes, "grant">) => {
      const main =
        !options?.hideThis &&
        currentLocaleNode?.type === "grant" &&
        currentLocaleNode?.key === node.key
          ? "This grant"
          : grantNodeToPrivilegeSet(node);
      return main;
    };

    const main = getMain(node);
    const content = (
      <>
        <CellInner>
          <Resource resource={node} index={0} />
        </CellInner>
        <CellInner style={{ paddingLeft: "8px" }}>
          <span style={{ fontStyle: "italic", paddingLeft: "10px" }}>
            {main}
          </span>
        </CellInner>
      </>
    );
    const detail = (
      <CellInner>
        {content} to{" "}
        <IdentityCell
          identity={{
            ...node.data,
            label: node.data.principal,
            type: node.data.principalType,
          }}
        />
      </CellInner>
    );

    return (
      <>
        {options?.detailed ? (
          detail
        ) : options?.noHover ? (
          content
        ) : (
          <GraphTooltip width="400px" title={detail}>
            {content}
          </GraphTooltip>
        )}
      </>
    );
  },
  bodySize: () => DefaultNodeTwoLineBodyHeight,
  headerSize: () => DefaultNodeHeaderHeight,
  width: () => DefaultNodeWidth,
};
