import { Col, Row, Select, Space } from "antd";
import { GrantToRequestorRecord } from "components/Dashboard/types";
import {
  mockRequestorMap,
  mockRequests,
} from "components/Dashboard/zeroStateData/requests-zero-state";
import {
  directories,
  resourceIntegrations,
} from "components/Integrations/constants";
import {
  Integrations,
  IntegrationsContext,
} from "components/Jit/Requests/contexts/IntegrationsContext";
import { useRequests } from "components/Jit/Requests/hooks/useRequests";
import {
  permissionAccount,
  requestDescription,
} from "components/Jit/Requests/requestUtils";
import { formatISO, subDays } from "date-fns";
import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { PermissionRequest } from "shared/types/permission";
import { RequestStatuses } from "shared/types/request-status";

import { Pane } from "../Pane";
import { GrayTitle } from "../common/GrayTitle";
import { PanelErrorBoundary } from "../common/PanelErrorBoundary";
import { FrequentRequestsTable } from "./FrequentRequestsTable";
import { JitSummaryStats } from "./JitSummaryStats";

const DEFAULT_LOOKBACK_DAYS = 30;

const createRequestorMap = (
  requests: { data: PermissionRequest }[],
  integrations: Integrations,
  lookbackDays: number
): Record<string, GrantToRequestorRecord> => {
  const allIntegrations = [...resourceIntegrations({}), ...directories];

  const requestorMap: Record<string, GrantToRequestorRecord> = {};

  for (const { data: requestData } of requests) {
    if (
      new Date(requestData.requestedTimestamp) <
      subDays(new Date(Date.now()), lookbackDays)
    ) {
      continue;
    }

    const { type, requestor } = requestData;

    const location =
      permissionAccount(requestData, integrations) ||
      allIntegrations.find((i) => i.key === type)?.label;

    // Find end resource for the request
    const resource = requestDescription(requestData)?.replace(/.*\| /, "");

    const serializedKey = `${type}_${location}_${resource}`;

    if (requestor) {
      requestorMap[serializedKey] = requestorMap[serializedKey] || {
        countsByRequestor: {},
        permission: requestData,
      };

      requestorMap[serializedKey].countsByRequestor[requestor] =
        (requestorMap[serializedKey].countsByRequestor[requestor] ?? 0) + 1;

      requestorMap[serializedKey].permission = requestData;
    }
  }

  return requestorMap;
};

export const JitPanels: React.FC<{
  breakpoint: boolean;
  hasRequests: boolean;
}> = ({ breakpoint: lg, hasRequests }) => {
  const { orgSlug } = useParams();

  const [lookbackDays, setLookbackDays] = useState(DEFAULT_LOOKBACK_DAYS);

  const today = new Date();
  const daysAgo = subDays(today, lookbackDays);
  const formattedDate = formatISO(daysAgo);
  const navigate = useNavigate();
  const integrations = useContext(IntegrationsContext);

  const onNavigateToJit = useCallback(
    () =>
      navigate(
        `/o/${orgSlug}/jit/history?requestedStart=${encodeURIComponent(
          formattedDate
        )}`
      ),
    [navigate, formattedDate, orgSlug]
  );

  const { requests, loading } = useRequests(
    RequestStatuses,
    // double the lookback days to show change over time period
    lookbackDays * 2
  );

  const requestorMap = useMemo(
    () => createRequestorMap(requests, integrations, lookbackDays),
    [requests, integrations, lookbackDays]
  );

  const JitSummary = (
    <Pane
      title="Summary"
      showMore={{
        text: "View in Just-in-Time",
        onClick: onNavigateToJit,
      }}
    >
      <PanelErrorBoundary title="Summary">
        <JitSummaryStats
          requestorMap={hasRequests ? requestorMap : mockRequestorMap}
          data={hasRequests ? requests : mockRequests}
          isLoading={loading}
          lookbackDays={lookbackDays}
        />
      </PanelErrorBoundary>
    </Pane>
  );

  const FrequentRequests = (
    <Pane
      title="Frequent Requests"
      showMore={{
        text: "View in Just-in-Time",
        onClick: onNavigateToJit,
      }}
    >
      <PanelErrorBoundary title="Frequent Request">
        <FrequentRequestsTable
          requestorMap={hasRequests ? requestorMap : mockRequestorMap}
          isLoading={loading}
        />
      </PanelErrorBoundary>
    </Pane>
  );

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Space
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
        size="small"
      >
        <GrayTitle title="Just-in-Time" />
        <Select
          value={lookbackDays}
          onChange={setLookbackDays}
          style={{ width: 120, marginBottom: "1em" }}
          options={[
            { value: 3, label: "3 days" },
            { value: 7, label: "7 days" },
            { value: 14, label: "14 days" },
            { value: 30, label: "30 days" },
            { value: 60, label: "60 days" },
            { value: 90, label: "90 days" },
          ]}
        />
      </Space>
      <Row gutter={16}>
        <Col span={lg ? 8 : 24}>{JitSummary}</Col>
        {lg && <Col span={16}>{FrequentRequests}</Col>}
      </Row>
      {!lg && (
        <Row gutter={16}>
          <Col span={24}>{FrequentRequests}</Col>
        </Row>
      )}
    </Space>
  );
};
