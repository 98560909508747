import { Button, Input, Modal, Typography } from "antd";
import { FindingsContext } from "components/Assessment/contexts/FindingsContext";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { JiraIcon } from "components/Integrations/Jira/Jira";
import { AuthFetch } from "components/Login/hook";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";
import { FirestoreDoc } from "providers/FirestoreProvider";
import { ReactNode, useContext, useMemo, useState } from "react";
import { useCallback } from "react";
import { AnyNode } from "shared/types/assessment/data";
import { Finding } from "shared/types/assessment/finding";

import { LegacyButtonGroup } from "../../../common/LegacyButtonGroup";
import { FindingsFixOrRevertCommands } from "../FindingsFixOrRevertCommands";
import { FindingAssignment } from "./FindingAssignment";
import { useFindingsStateUpdates } from "./useFindingsStateUpdates";

export const FindingStateEditor: React.FC<{
  finding: FirestoreDoc<Finding>;
  authFetch: AuthFetch;
  node: AnyNode | undefined;
}> = ({ finding, authFetch, node }) => {
  const { selectedMonitor: monitor } = useContext(FindingsContext);
  const { integration } = useContext(ScopeContext);
  const { state, ignore, trigger } = finding.data;
  const {
    assessmentManage: _hasManage,
    assessmentFindingAssignment: hasAssignment,
    assessmentUnassignForDemo: hasUnassign,
  } = useFlags();

  const isFixable = useMemo(
    () => !!monitor && monitor.fix?.[integration] && state === "open",
    [monitor, state, integration]
  );
  const isRevertible = useMemo(
    () => !!monitor && monitor.revert?.[integration] && state === "resolved",
    [monitor, state, integration]
  );
  const [modalContent, setModalContent] = useState<ReactNode>();

  const {
    assignFindings,
    ignoreFindings,
    unassignFindings,
    unignoreFindings,
    updateFindingsNotes,
  } = useFindingsStateUpdates(authFetch);

  const onClickIgnore = useCallback(
    () => ignoreFindings([finding]),
    [ignoreFindings, finding]
  );
  const onClickUnignore = useCallback(
    () => unignoreFindings([finding]),
    [unignoreFindings, finding]
  );
  const onClickChangeNotes = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) =>
      updateFindingsNotes([finding], event),
    [updateFindingsNotes, finding]
  );
  const onClickUnassign = useCallback(
    () => unassignFindings([finding]),
    [unassignFindings, finding]
  );

  const onClickAssign = useCallback(
    () => assignFindings([finding]),
    [assignFindings, finding]
  );

  const onClickFixOrRevert = useCallback(() => {
    if (!monitor || !node) return;
    setModalContent(
      <FindingsFixOrRevertCommands
        monitor={monitor}
        nodes={[node]}
        findingState={state}
      />
    );
  }, [node, monitor, state]);

  const onCancelFix = useCallback(() => {
    setModalContent(null);
  }, []);

  const action = (
    <LegacyButtonGroup>
      {hasAssignment ? (
        finding.data.assignment && hasUnassign ? (
          // TODO: choose icon based on installed tracking integration
          <Button
            onClick={onClickUnassign}
            disabled={finding.data.assignment.status !== "complete"}
            style={{ display: "block" }}
          >
            {JiraIcon}&nbsp;Unassign{" "}
          </Button>
        ) : (
          <Button onClick={onClickAssign} disabled={!!finding.data.assignment}>
            {JiraIcon}&nbsp;Assign{" "}
          </Button>
        )
      ) : undefined}
      {state === "open" ? (
        <>
          <Button onClick={onClickIgnore}>🔇&nbsp;Ignore</Button>
          {isFixable && (
            <Button onClick={onClickFixOrRevert}>🔧&nbsp;Fix</Button>
          )}
        </>
      ) : state === "ignored" ? (
        <Button onClick={onClickUnignore}>🔊&nbsp;Un-ignore</Button>
      ) : isRevertible ? (
        <Button onClick={onClickFixOrRevert}>🔧&nbsp;Revert</Button>
      ) : null}
    </LegacyButtonGroup>
  );

  return (
    <div
      style={{
        marginBottom: "1em",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          gap: "1em",
        }}
      >
        <Typography.Paragraph>
          {state === "resolved"
            ? `Fixed ${moment(trigger.at).fromNow()}`
            : state === "ignored"
            ? `Ignored ${moment(ignore?.at).fromNow()} by ${ignore?.by}`
            : `${trigger.type === "new" ? "Discovered" : "Regressed"} ${moment(
                trigger.at
              ).fromNow()}`}
        </Typography.Paragraph>
        {action}
      </div>
      {hasAssignment && <FindingAssignment finding={finding} />}
      <label>Enter any notes here:</label>
      <Input.TextArea
        defaultValue={ignore?.justification}
        onChange={onClickChangeNotes}
      />
      {ignore && (
        <Typography.Text type="secondary" style={{ fontSize: "small" }}>
          Last updated {moment(ignore.at).fromNow()} by {ignore.by}
        </Typography.Text>
      )}
      <Modal
        maskClosable={false}
        open={!!modalContent}
        onCancel={onCancelFix}
        width={"60em"} // Similar to a terminal window
        footer={false}
      >
        {modalContent}
      </Modal>
    </div>
  );
};
