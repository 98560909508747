import { RendererParams } from "components/Assessment/contexts/GraphContext";
import { ConnectedNode } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";

import { CustomConditionNodeParameters } from "./condition";
import { CustomConsumerNodeParameters } from "./consumer";
import { CustomCredentialNodeParameters } from "./credential";
import { CustomGrantNodeParameters } from "./grant";
import { CustomIdentityNodeParameters } from "./identity";
import { CustomLateralNodeParameters } from "./lateral";
import { CustomPrivilegeNodeParameters } from "./permission";
import { CustomPermissionSetNodeParameters } from "./permissionSet";
import { CustomPrivilegeSetNodeParameters } from "./privilegeSet";
import { CustomResourceNodeParameters } from "./resource";
import { CustomRiskNodeParameters } from "./risk";
import { CustomUsageNodeParameters } from "./usage";

// CustomAssessmentNodeParameters fully describes how a custom node should be rendered
export type CustomAssessmentNodeParameters<T extends keyof AssessmentNodes> = {
  type: T;
  titleText: (
    node: ConnectedNode<AssessmentNodes, T>,
    rendererParams: RendererParams
  ) => string;
  IconComponent?: React.ElementType;
  bodyBorderColor: string;
  bodyBackgroundColor: string;
  bodyContent: (
    node: ConnectedNode<AssessmentNodes, T>,
    rendererParams: RendererParams
  ) => React.ReactNode;
  bodySize: () => number;
  headerSize: () => number;
  width: () => number;
};

// AssessmentNodeCatalog is a mapping of node types to their corresponding CustomAssessmentNodeParameters
// The renderer and sizers are generated from these parameters
export const AssessmentNodeCatalog: {
  [K in keyof AssessmentNodes]: CustomAssessmentNodeParameters<K>;
} = {
  condition: CustomConditionNodeParameters,
  credential: CustomCredentialNodeParameters,
  consumer: CustomConsumerNodeParameters,
  grant: CustomGrantNodeParameters,
  lateral: CustomLateralNodeParameters,
  usage: CustomUsageNodeParameters,
  privilege: CustomPrivilegeNodeParameters,
  permissionSet: CustomPermissionSetNodeParameters,
  privilegeSet: CustomPrivilegeSetNodeParameters,
  identity: CustomIdentityNodeParameters,
  resource: CustomResourceNodeParameters,
  risk: CustomRiskNodeParameters,
};
