import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { useFirestoreDoc } from "providers/FirestoreProvider";
import { useContext } from "react";

type Metrics = {
  inventoryCounts: Record<string, Record<string, number>>;
  jobId: string;
  requestedAt: string;
};

export const useInventoryCounts = () => {
  const { assessment, last } = useContext(SelectedEnvironmentContext);

  const { doc: inventoryCountsDoc, loading } = useFirestoreDoc<Metrics>(
    `iam-assessments/${assessment.doc?.id}/metrics/${last.doc?.data.jobId}`,
    {
      live: false,
      tenantAware: true,
    }
  );

  return { doc: inventoryCountsDoc?.data?.inventoryCounts, loading };
};
