import { addDays, differenceInDays, format, parse, startOfDay } from "date-fns";
import { capitalize } from "lodash";
import { ReactElement, useCallback, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { CategoricalChartFunc } from "recharts/types/chart/generateCategoricalChart";

const FORMAT = "P"; // Locale date-only format; e.g. MM/dd/yy in US

const isPrimaryButton = (event: MouseEvent) =>
  // Mouse button state is stored in bitflag; see https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
  (event.buttons & 1) === 1;

/** Renders time-series data in a histogram.
 *
 * User can select displayed date ranges by dragging a selection over the histogram.
 *
 * Data are always displayed in day increments.
 */
export const DateHistogram = <T,>({
  range,
  setRange,
  data,
  value,
  label,
}: {
  range: [Date, Date];
  setRange?: (range: [Date, Date]) => void;
  data: T[] | undefined;
  value: (datum: T) => Date;
  label: string;
}): ReactElement | null => {
  const [chartData, setChartData] =
    useState<{ day: string; value: number }[]>();
  const [referenceStart, setReferenceStart] = useState<Date>();
  const [referenceEnd, setReferenceEnd] = useState<Date>();

  useEffect(() => {
    // Avoid setting graph to 0 when loading new data
    if (data === undefined) return;
    const coerced = {
      start: startOfDay(range[0]),
      end: addDays(startOfDay(range[1]), 1),
    };
    const nBins = differenceInDays(coerced.end, coerced.start);
    const counts: number[] = Array(nBins).fill(0);
    const ticks = counts.map((_, ix) =>
      format(addDays(coerced.start, ix), FORMAT)
    );
    for (const datum of data) {
      const ix = differenceInDays(startOfDay(value(datum)), coerced.start);
      counts[ix]++;
    }
    const chartData = ticks.map((day, ix) => ({ day, value: counts[ix] }));
    setChartData(chartData);
  }, [data, range, value]);

  const renderLabel = useCallback(
    (value: number) => [String(value), capitalize(label)],
    [label]
  );

  // --- Handles time scale selection
  const onBarDown: CategoricalChartFunc = useCallback(
    (state, event) => {
      if (!isPrimaryButton(event)) return; // Mouse is not "down"
      if (!state.activePayload?.[0].payload.day) return;
      event.stopPropagation();
      event.preventDefault();
      setReferenceStart(
        parse(state.activePayload?.[0].payload.day, FORMAT, range[0])
      );
    },
    [range]
  );
  const onBarMove: CategoricalChartFunc = useCallback(
    (state, event) => {
      if (!isPrimaryButton(event)) return; // Mouse is not "down"
      if (!state.activePayload?.[0].payload.day) return;
      event.stopPropagation();
      event.preventDefault();
      setReferenceEnd(
        parse(state.activePayload?.[0].payload.day, FORMAT, range[0])
      );
    },
    [range]
  );
  const onBarUp: CategoricalChartFunc = useCallback(
    (state, event) => {
      if (isPrimaryButton(event)) return; // Mouse is still "down", a different button was released
      event.stopPropagation();
      event.preventDefault();
      if (!referenceStart || !state.activePayload) return;
      setReferenceStart(undefined);
      setReferenceEnd(undefined);
      if (!referenceEnd || referenceEnd < referenceStart) return; // Invalid range
      setRange?.([referenceStart, referenceEnd]);
    },
    [referenceEnd, referenceStart, setRange]
  );
  const onBarLeave: CategoricalChartFunc = useCallback(() => {
    setReferenceStart(undefined);
    setReferenceEnd(undefined);
  }, []);

  return chartData ? (
    <div style={{ maxWidth: "100%", minWidth: "600px" }}>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart
          data={chartData}
          onMouseDown={setRange && onBarDown}
          onMouseUp={setRange && onBarUp}
          onMouseLeave={onBarLeave}
          onMouseMove={onBarMove}
        >
          <XAxis dataKey="day" />
          <Tooltip formatter={renderLabel} />
          <Bar dataKey="value" fill="#1890ff" />
          {
            // Preview of range that would be selected on mouse release
            referenceEnd && referenceStart && (
              <ReferenceArea
                x1={format(referenceStart, FORMAT)}
                x2={format(referenceEnd, FORMAT)}
              />
            )
          }
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : null;
};
