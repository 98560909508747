import {
  TeamOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { StyledRoutingRulePreview } from "components/Jit/Routing/styles";
import { RequestorRule } from "shared/types/workflow/types";

import { GenericIdpGroupPreview, IdpGroupsPreview } from "./IdpGroupPreview";
import { SomeRulePopover } from "./SomeRulePreview";

export type RequestorRulePreview =
  | RequestorRule
  | {
      type: "genericGroup";
    };

type Props = {
  requestor: RequestorRulePreview;
};

export const RequestorPreview: React.FC<Props> = ({ requestor }) => {
  return requestor.type === "genericGroup" ? (
    <GenericIdpGroupPreview />
  ) : requestor.type === "any" ? (
    <StyledRoutingRulePreview>
      <TeamOutlined />
      <span>Any requestor</span>
    </StyledRoutingRulePreview>
  ) : requestor.type === "some" ? (
    <SomeRulePopover
      integrationText="Requestor via another rule"
      icon={<UserSwitchOutlined />}
    />
  ) : requestor.type === "user" ? (
    <StyledRoutingRulePreview>
      <UserOutlined />
      <span>{requestor.uid}</span>
    </StyledRoutingRulePreview>
  ) : (
    <IdpGroupsPreview idpGroups={requestor.groups} />
  );
};
