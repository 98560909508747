import { Typography } from "antd";
import { format, formatDistanceToNow, formatISO } from "date-fns";

const { Text } = Typography;

export const Timestamp: React.FC<{ at: number; copyable?: boolean }> = ({
  at,
  copyable,
}) =>
  at ? (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <Text copyable={copyable ? { text: formatISO(at) } : false}>
          {format(at, "PPP")}
        </Text>
      </div>
      <div>
        <Text type="secondary" style={{ fontSize: "small" }}>
          {formatDistanceToNow(at, { addSuffix: true })}
        </Text>
      </div>
    </div>
  ) : (
    <Text type="secondary">(Never)</Text>
  );
