import { ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Spin,
  Switch,
  Typography,
} from "antd";
import { BaseOptionType } from "antd/lib/select";
import {
  ContentSizeDiv,
  RightAlignedDiv,
  VerticalSpacedDiv,
} from "components/divs";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import {
  FrontendComponentInstaller,
  FrontendInstallContext,
  FrontendInstallDomain,
} from "install/types";
import { capitalize, cloneDeep, isEmpty, pick } from "lodash";
import {
  ChangeEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ElementInstaller, Option, OptionsElement } from "shared/install/types";
import { IntegrationStatus } from "shared/integrations/shared";
import styled from "styled-components";
import { Cancellation } from "utils/cancel";
import { staticError } from "utils/console";

import { NEW_SENTINEL } from "./Component";

// TODO: Type properly
type FormProps = {
  /** The item or field being configured */
  config: any;
  context: FrontendInstallContext<any>;
  /** If true, this and all child elements will be disabled */
  allDisabled?: boolean;
  /** The ID of the item being configured */
  id: string;
  /** This field's frontend installer */
  installer?: FrontendComponentInstaller<any>;
  /** True to indicate that an API request is underway */
  isFetching: boolean;
  /** Callback to refresh the data used by a this form field */
  refresh?: ((value: any) => void) | undefined;
  /** This field's component schema */
  schema: Record<string, any>;
  /** Callback to execute to update this field's value */
  setConfig: ((value: any) => void) | undefined;
  /** The item's current installation phase:
   *
   * - configure: Item is already installed in customer environment, user is configuring the item
   * - new: Item is not yet created, user is starting installation
   */
  step: "configure" | "new";
};

type HelpProps = Pick<FormProps, "config" | "context" | "id"> & {
  element?: any;
  installer?: ElementInstaller<FrontendInstallDomain<any>, any, any, any>;
  item: any;
};

type StringElementProps = Omit<ItemFormProps, "configKey">;

type SwitchElementProps = Omit<ItemFormProps, "configKey" | "element">;

type DynamicElementProps = Omit<ItemFormProps, "configKey">;

type GeneratedElementProps = Omit<
  ItemFormProps,
  "configKey" | "element" | "setConfig"
>;

type EncryptedElementMode = "hash" | "input";

type EncryptedElementProps = Omit<ItemFormProps, "configKey"> & {
  state: IntegrationStatus;
};

type OptionElementProps = Omit<
  ItemFormProps,
  "element" | "installer" | "schema"
> & {
  element: OptionsElement<Record<string, Option<any>>>;
  installer?: ElementInstaller<FrontendInstallDomain<any>, any, any, any>;
};

type ComponentFormProps = FormProps;

type ItemFormProps = Omit<FormProps, "installer" | "schema"> & {
  configKey: string;
  element: any;
  /** True if this element only is disabled (inner elements may still be enabled) */
  disabled: boolean;
  installer?: ElementInstaller<FrontendInstallDomain<any>, any, any, any>;
  item: any;
};

const DynamicSelect = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
`;

const OptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
  min-height: 32px;
  max-width: 38em;

  .ant-form-item {
    margin-bottom: 5px;
    .ant-input:not(:last-child) {
      margin-bottom: 5px;
    }
    .ant-select:last-child {
      margin-bottom: 15px;
    }
  }

  .p0-description {
    width: 35em;
    .ant-typography code {
      white-space: nowrap;
    }
    margin-bottom: 8px;
  }

  .p0-string-element {
    gap: 0.2em;
    width: 35em;
  }

  .p0-switch-element {
    margin-bottom: 15px;
  }
`;

const StyledRadio = styled(Radio)`
  .ant-wave-target {
    align-self: flex-start;
    margin-top: 0.3em;
  }
`;

const useComponentPrerequisites = (
  props: Pick<ItemFormProps, "context" | "installer">
) => {
  const { context, installer } = props;
  const [messages, setMessages] = useState<string>();

  useGuardedEffect(
    (cancellation) => async () => {
      if (!installer || !("prerequisiteMessages" in installer)) return;
      const messages = await installer.prerequisiteMessages?.(context);
      cancellation.guard(setMessages)(messages);
    },
    [context, installer],
    staticError
  );

  return { messages };
};

const Description = (props: HelpProps) => {
  const { config, context, element, id, installer, item } = props;
  const description = useMemo(
    () =>
      installer?.labeler?.(context, id, item, config) ?? element.description,
    [installer, context, id, item, config, element.description]
  );

  return description ? (
    <div className="p0-description">
      <Typography.Text type="secondary">{description}</Typography.Text>
    </div>
  ) : null;
};

const StringElement = (props: StringElementProps) => {
  const { config, setConfig, element, disabled } = props;
  const { messages } = useComponentPrerequisites(props);
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setConfig?.(event.target.value);
    },
    [setConfig]
  );

  const InputElement = element.multiline ? Input.TextArea : Input;
  return (
    <VerticalSpacedDiv className="p0-string-element">
      {messages}
      <InputElement
        value={config}
        onChange={onChange}
        disabled={disabled || !!messages}
      />
    </VerticalSpacedDiv>
  );
};

/**
 *  EncryptedElement is a component that allows the user to view and edit an encrypted string.
 * The user can switch between view and edit mode using a switch.
 * when in view mode, the user can see the encrypted string, and when in edit mode,the prefilled value is cleared.
 * @param props
 * @returns
 */
const EncryptedElement = (props: EncryptedElementProps) => {
  const { config, setConfig, state, disabled } = props;
  const [mode, setMode] = useState<EncryptedElementMode>(
    state === "installed" && config?.hash ? "hash" : "input"
  );
  const [value, setValue] = useState<string | undefined>(config?.hash);
  const [oldValue] = useState<string | undefined>(config?.hash);
  const [newValue, setNewValue] = useState<string | undefined>(
    config?.clearText
  );

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // empty values should be considered as undefined
      setConfig?.({
        hash: oldValue,
        clearText: event.target.value !== "" ? event.target.value : undefined,
      });
      setValue(event.target.value);
      setNewValue(event.target.value);
    },
    [setConfig, setValue, setNewValue, oldValue]
  );
  // Reset the value when switching between view and edit mode
  // any change in the switch clears the value
  const changeMode = useCallback(() => {
    if (mode === "input") {
      setMode("hash");
      setValue(oldValue);
      setConfig?.({ hash: oldValue });
    } else {
      setMode("input");
      setValue(newValue);
      if (newValue !== "") setConfig?.({ ...config, clearText: newValue });
    }
  }, [mode, config, setMode, setConfig, oldValue, newValue]);

  return (
    <VerticalSpacedDiv className="p0-string-element">
      <div>
        <Input
          value={value}
          onChange={onChange}
          disabled={disabled || mode === "hash"}
        />
        {state === "installed" && (
          <RightAlignedDiv>
            <ContentSizeDiv>{`${capitalize(mode)}`}&nbsp;</ContentSizeDiv>
            <Switch
              onChange={changeMode}
              style={newValue ? { backgroundColor: "orange" } : undefined}
            />
          </RightAlignedDiv>
        )}
      </div>
    </VerticalSpacedDiv>
  );
};

const DynamicElement = (props: DynamicElementProps) => {
  const { config, context, disabled, id, installer, setConfig } = props;
  const { messages } = useComponentPrerequisites(props);
  const [options, setOptions] = useState<BaseOptionType[]>();

  // Hack to prevent auto-close of antd select after click
  const onClickSelect = useCallback((event: MouseEvent) => {
    event.preventDefault();
  }, []);

  const onSelect = useCallback(
    (value: string) => {
      setConfig?.(value);
    },
    [setConfig]
  );

  const refreshOptions = useCallback(
    async (cancellation?: Cancellation) => {
      if (installer && "optionProvider" in installer) {
        const items = await installer.optionProvider(context, id);
        if (cancellation?.isCancelled) return;
        const options = items.map(({ id, label }) => ({
          value: id,
          label: label ?? id,
        }));
        setOptions(options);
      } else setOptions([]);
    },
    [context, id, installer]
  );

  useGuardedEffect(
    (cancellation) => async () => refreshOptions(cancellation),
    [context, installer, refreshOptions],
    staticError
  );

  useEffect(() => {
    if (!config && options?.[0]) setConfig?.(options?.[0]?.value);
  }, [config, options, setConfig]);

  // filter should be done based on description as
  // default search behavior searches for ids only
  const filterOption = useCallback(
    (input: string, option?: BaseOptionType) =>
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase()),
    []
  );
  const reload = useMemo(() => {
    if (installer && "refresh" in installer) {
      return () => {
        installer.refresh?.(props.context, props.id);
        refreshOptions();
      };
    }
  }, [installer, props.context, props.id, refreshOptions]);

  return (
    <>
      {messages}
      {options ? (
        <DynamicSelect>
          <Select
            disabled={disabled || !!messages}
            filterOption={filterOption}
            onChange={onSelect}
            onClick={onClickSelect}
            options={options}
            showSearch
            value={config}
          />
          {!disabled && reload && (
            <Form.Item key="refresh">
              <Button type="default" onClick={reload}>
                <ReloadOutlined />
              </Button>
            </Form.Item>
          )}
        </DynamicSelect>
      ) : (
        <Spin />
      )}
    </>
  );
};

const GeneratedElement = ({ config }: GeneratedElementProps) => (
  <VerticalSpacedDiv className="p0-string-element">
    <Input value={config} disabled />
  </VerticalSpacedDiv>
);

const SwitchElement = (props: SwitchElementProps) => {
  const { config, setConfig, disabled } = props;
  const onChange = useCallback(
    (checked: boolean) => {
      setConfig?.(checked);
    },
    [setConfig]
  );

  useEffect(() => {
    if (config === undefined) setConfig?.(false);
  }, [config, setConfig]);

  return (
    <div className="p0-switch-element">
      <Switch checked={config} disabled={disabled} onChange={onChange} />
    </div>
  );
};

const OptionElement = (props: OptionElementProps) => {
  const { config, context, disabled, element, installer, setConfig } = props;
  const [disables, setDisables] = useState<Record<string, ReactNode>>();

  const setOption = useCallback(
    (type: string) =>
      setConfig?.({
        ...pick(config, Object.keys(element.options[type].schema)),
        type,
      }),
    [config, element.options, setConfig]
  );

  const onClickRadio = useCallback(
    (event: RadioChangeEvent) => {
      setOption(event.target.value);
    },
    [setOption]
  );

  // Prevent rendering loop by assigning console.error to a static value
  const logError = useCallback((message: string) => console.error(message), []);

  useEffect(() => {
    if (!config?.type) setOption(element.default);
  }, [config?.type, element.default, setOption]);

  useGuardedEffect(
    (cancellation) => async () => {
      const promises = Object.keys(element.options).map(async (key) => {
        const optionInstaller =
          installer && "options" in installer
            ? installer.options[key]
            : undefined;
        const message = await optionInstaller?.prerequisiteMessages?.(context);
        return [key, message];
      });
      const results = await Promise.all(promises);
      cancellation.guard(setDisables)(Object.fromEntries(results));
    },
    [context, element, installer],
    logError
  );

  return disables ? (
    <>
      <Radio.Group
        disabled={disabled}
        value={config?.type}
        onChange={onClickRadio}
      >
        <VerticalSpacedDiv style={{ gap: "0.0em" }}>
          {Object.entries(element.options)
            .filter(([_key, option]) => !option.hidden)
            .map(([key, option]) => (
              <StyledRadio key={key} value={key} disabled={!!disables[key]}>
                <OptionDiv>
                  <div>{option.label}</div>
                  {option.description && (
                    <div className="p0-description">
                      <Typography.Text type="secondary">
                        {option.description}
                      </Typography.Text>
                    </div>
                  )}
                </OptionDiv>
                {disables[key]}
                {config?.type === key && (
                  <ComponentForm
                    {...props}
                    installer={
                      installer && "options" in installer
                        ? (installer.options[key] as any)
                        : undefined
                    }
                    schema={option.schema}
                  />
                )}
              </StyledRadio>
            ))}
        </VerticalSpacedDiv>
      </Radio.Group>
    </>
  ) : (
    <Spin />
  );
};

const ItemForm = (props: ItemFormProps) => {
  const {
    config,
    configKey: key,
    context,
    element,
    id,
    installer,
    setConfig,
  } = props;
  // This element is disabled if:
  // - the parent element is disabled for all children (props.disabled)
  // - the element is only configurable on "new", and we're not on that step
  const disabled =
    props.allDisabled || (element.step === "new" && props.step !== "new");

  const [error, setError] = useState<ReactNode>();
  const setItem = useCallback(
    async (value: any) => {
      setConfig?.({ ...config, [key]: value });
      const elementMessage = (await element?.validator?.(
        id === NEW_SENTINEL ? value : id,
        value
      )) as string;

      const getErrorDisplay = () => {
        if (!elementMessage) return undefined;
        if (installer && installer.errorElement) {
          return installer.errorElement(context, id);
        }
        return elementMessage;
      };

      setError(getErrorDisplay());
    },
    [config, context, element, id, installer, key, setConfig]
  );
  const subProps = {
    ...props,
    config: config?.[key],
    disabled,
    setConfig: setItem,
  };

  return element.type === "hidden" ? null : (
    <OptionDiv key={key}>
      <Form.Item
        label={element.label}
        validateStatus={error ? "error" : undefined}
        extra={error}
      >
        <Description {...props} />
        {element.type === "select" ? (
          <OptionElement {...subProps} />
        ) : element.type === "string" ? (
          <StringElement {...subProps} />
        ) : element.type === "encrypted" ? (
          <EncryptedElement {...subProps} state={config.state} />
        ) : element.type === "dynamic" ? (
          <DynamicElement {...subProps} />
        ) : element.type === "generated" ? (
          <GeneratedElement {...subProps} />
        ) : element.type === "switch" ? (
          <SwitchElement {...subProps} />
        ) : null}
      </Form.Item>
    </OptionDiv>
  );
};

const ComponentForm = (props: ComponentFormProps) => {
  const { installer, schema, config } = props;
  return (
    <>
      {Object.entries(schema).map(([key, element]) => (
        <ItemForm
          {...props}
          config={config}
          configKey={key}
          element={element}
          installer={
            installer && "items" in installer ? installer.items[key] : {}
          }
          item={config}
          key={key}
          disabled={!!props.allDisabled}
        />
      ))}
    </>
  );
};

/** Recursively render the configuration UI for an install component. */
export const ConfigForm = (props: FormProps) => {
  const { config, allDisabled, schema, setConfig } = props;
  const [built, setBuilt] = useState(cloneDeep(config));
  const onClickNext = useCallback(() => setConfig?.(built), [built, setConfig]);

  return (
    <Form layout="vertical" preserve>
      {
        // By pushing the isFetching spinner to within the ConfigForm, we ensure
        // that we do not reset the form during fetches
        props.isFetching ? (
          <Spin />
        ) : (
          <>
            {isEmpty(schema) ? (
              <div style={{ marginBottom: "12px" }}>
                No configuration options exist for this item
              </div>
            ) : (
              <ComponentForm {...props} config={built} setConfig={setBuilt} />
            )}
            {!allDisabled &&
              !(config.state === "installed" && isEmpty(schema)) && (
                <Form.Item key="submit">
                  <Button
                    type="primary"
                    onClick={onClickNext}
                    // TODO: make work with initial defaults
                    disabled={isEmpty(built) && !isEmpty(schema)}
                  >
                    {config.state === "configure"
                      ? "Finish"
                      : config.state === "installed"
                      ? "Update"
                      : "Next"}
                  </Button>
                </Form.Item>
              )}
          </>
        )
      }
    </Form>
  );
};
