import { capitalize } from "lodash";
import pluralize from "pluralize";
import {
  AwsResourceGenerated,
  AwsSpec,
} from "shared/integrations/resources/aws/accesses";
import { arnInfo } from "shared/integrations/resources/aws/util";
import { AzureSpec } from "shared/integrations/resources/azure/accesses";
import {
  GcloudResourceSpec,
  GcloudSpec,
} from "shared/integrations/resources/gcloud/accesses";
import { GithubSpec } from "shared/integrations/resources/github/accesses";
import { KubernetesSpec } from "shared/integrations/resources/kubernetes/types";
import { PgSpec, PgSql } from "shared/integrations/resources/postgres/types";
import {
  SnowflakeSpec,
  SnowflakeSqlText,
} from "shared/integrations/resources/snowflake/types";
import { SshSpec } from "shared/integrations/resources/ssh/accesses";
import { AwsSshSessionSpec } from "shared/integrations/resources/ssh/accesses/aws";
import { isKnownProviderSshSessionSpec } from "shared/integrations/resources/ssh/util";
import { assertNever } from "shared/types";
import {
  StagedPermissionRequest,
  isPermissionSpec,
} from "shared/types/permission";

type GeneratedDescription =
  | {
      content: string;
      label: string;
      isHidden?: boolean;
    }[]
  | undefined;

export const generatedDescription = (
  request: StagedPermissionRequest
): GeneratedDescription => {
  const { type } = request;

  switch (type) {
    case "aws":
      return buildAwsGeneratedDescription(request);
    case "gcloud":
      return buildGcloudGeneratedDescription(request as GcloudSpec); // TODO @ENG-4014: remove cast after migration
    case "snowflake":
      return buildSnowflakeGeneratedDescription(request);
    case "k8s":
      return buildK8sGeneratedDescription(request);
    case "pg":
      return buildPgGeneratedDescription(request);
    case "ssh":
      return buildSshGeneratedDescription(request);
    case "azure":
      return buildAzureIamGeneratedDescription(request);
    case "github":
      return buildGithubGeneratedDescription(request);
    case "azure-ad":
    case "okta":
    case "workspace":
      return undefined; // Directory groups do not have any generated content
    default:
      console.warn("Got unexpected permission request", {
        type,
      });
      // Don't want to interrupt control flow for older permission requests,
      // so just make type assertion; not run-time assertion
      const _tpe: never = type;
  }
};

const buildAzureIamGeneratedDescription = (request: AzureSpec) => [
  {
    label: `Role Assignment ID`,
    content: request.permission.roleAssignmentId,
  },
];

const buildAwsGeneratedDescription = (request: AwsSpec) => {
  switch (request.access) {
    case "policy": {
      try {
        // If the ARN is invalid, we do not display the generated content
        const info = arnInfo(request.generated.arn);
        return [
          {
            label: `${capitalize(info.resourceType)}`,
            content: request.generated.name,
          },
        ];
      } catch (err) {
        return undefined;
      }
    }
    case "resource": {
      return buildAwsResourceGenerated(request.generated);
    }
    default: // No other AWS requests have generated content
      return undefined;
  }
};

const buildSnowflakeGeneratedDescription = (request: SnowflakeSpec) => {
  if (isPermissionSpec<SnowflakeSqlText>("snowflake", "sqlText", request)) {
    return [
      { label: "Role", content: request.generated.role },
      ...(request.generated.neededRoles
        ? [
            {
              label: "Additional granted roles",
              content: request.generated.neededRoles.join(", "),
            },
          ]
        : []),
    ];
  }

  return undefined;
};

const buildPgGeneratedDescription = (request: PgSpec) => {
  return [
    {
      label: "User created by P0",
      content: request.generated.userCreatedByP0.toString(),
    },
    ...(isPermissionSpec<PgSql>("pg", "sqlQuery", request)
      ? [
          {
            label: "Role",
            content: request.generated.customRoleInfo.roleName,
          },
        ]
      : []),
  ];
};

const buildK8sGeneratedDescription = (request: KubernetesSpec) => {
  return [
    { label: "Kubernetes Role", content: request.generated.role },
    ...(request.generated.permissionSetRoleArn
      ? [
          {
            label: "AWS Role of Permission Set",
            content: request.generated.permissionSetRoleArn,
          },
        ]
      : []),
    ...(request.generated.eksGenerated
      ? [
          {
            label: "AWS Role",
            content: request.generated.eksGenerated.name,
          },
          {
            label: "AWS Policy",
            content: request.generated.eksGenerated.policyName,
          },
        ]
      : []),
  ];
};

const buildGcloudGeneratedDescription = (request: GcloudSpec) => {
  switch (request.access) {
    case "role":
      return;
    case "resource":
      return buildGcloudResourceGenerated(request.generated);
    case "permission":
      return [
        {
          label: "Role",
          content: request.generated.role,
        },
      ];
    default:
      throw assertNever(request);
  }
};

const buildGcloudResourceGenerated = (
  generated: GcloudResourceSpec["generated"]
) => [
  {
    label: pluralize("Role", generated.roles.length),
    content: generated.roles.map((r) => r.role).join(", "),
  },
];

const buildAwsResourceGenerated = (generated: AwsResourceGenerated) => {
  try {
    // If the ARN is invalid, we do not display the generated content
    const info = arnInfo(generated.arn);
    return [
      {
        label: `${capitalize(info.resourceType)}`,
        content: generated.name,
      },
      { label: "Policy", content: generated.policyName },
    ];
  } catch (err) {
    return;
  }
};

const buildSshGeneratedDescription = (request: SshSpec) => {
  if (request.access === "all" || request.access === "group") return;
  if (!isKnownProviderSshSessionSpec(request.permission)) return;

  if (request.permission.provider === "gcloud") {
    return buildGcloudResourceGenerated(
      request.generated as GcloudResourceSpec["generated"]
    );
  }
  if (request.permission.provider === "aws") {
    const generated = request.generated as AwsSshSessionSpec["generated"];

    const resources = buildAwsResourceGenerated(generated.resource) ?? [];
    const linuxUsernameEntry = {
      label: `Linux Username`,
      content: generated.linuxUserName,
    };
    const publicKeyEntry = generated.publicKey
      ? [
          {
            label: "Public Key",
            content: generated.publicKey,
            isHidden: true,
          },
        ]
      : [];

    return [...resources, linuxUsernameEntry, ...publicKeyEntry];
  }

  return;
};

const buildGithubGeneratedDescription = (request: GithubSpec) => {
  return [
    {
      label: "Username",
      content: request.permission.username,
    },
  ];
};
