import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { Tenant } from "components/Login";
import { differenceInDays, startOfMonth } from "date-fns";
import {
  average,
  collection,
  getAggregateFromServer,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { size } from "lodash";
import { DB } from "providers/FirestoreProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { toAssessmentPath } from "shared/assessment/helper";
import { DAYS } from "shared/time";
import { Finding } from "shared/types/assessment/finding";
import { Monitor, MonitorPriority } from "shared/types/assessment/monitor";

const groupMonitorsByPriority = (
  monitors: Record<MonitorPriority, Monitor>
) => {
  const monitorsByPriority: Record<string, string[]> = {};
  Object.entries(monitors).forEach(([label, monitor]) => {
    if (!monitorsByPriority[monitor.priority]) {
      monitorsByPriority[monitor.priority] = [];
    }
    monitorsByPriority[monitor.priority].push(label);
  });
  return monitorsByPriority;
};

export const useFindingMetrics = (monitors: Record<string, Monitor>) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const [criticalFindings, setCriticalFindings] = useState(0);
  const [highFindings, setHighFindings] = useState(0);

  const [oldestFinding, setOldestFinding] = useState<Finding | null>(null);
  const [oldestFindingAgeDays, setOldestFindingAgeDays] = useState(0);

  const [avgTimeOpenDays, setAvgTimeOpenDays] = useState(0);

  const [newCriticalHighThisMonth, setNewCriticalHighThisMonth] = useState(0);

  const [hasMetrics, setHasMetrics] = useState(false);

  const [newestFindings, setNewestFindings] = useState<Finding[]>([]);
  const tenant = useContext(Tenant);
  const { assessment } = useContext(SelectedEnvironmentContext);
  const assessmentId = assessment.doc?.id;

  const assessmentPath = useMemo(() => {
    return tenant && assessmentId
      ? toAssessmentPath(tenant, assessmentId)
      : undefined;
  }, [tenant, assessmentId]);

  useEffect(() => {
    if (!assessmentPath || !size(monitors)) {
      return;
    }

    const fetchMetrics = async () => {
      const groupedMonitors = groupMonitorsByPriority(monitors);

      const criticalMonitorIds = groupedMonitors["CRITICAL"] || [];
      const highMonitorIds = groupedMonitors["HIGH"] || [];

      // Combine for high and critical monitors
      const highAndCriticalMonitorIds = [
        ...highMonitorIds,
        ...criticalMonitorIds,
      ];

      try {
        setError(null);
        const now = Date.now();

        // Get open high and critical findings count
        const openStateConstraint = where("state", "==", "open");
        const highAndCriticalConstraint = where(
          "monitorId",
          "in",
          highAndCriticalMonitorIds
        );

        const highConstraint = where("monitorId", "in", highMonitorIds);
        const criticalConstraint = where("monitorId", "in", criticalMonitorIds);

        const highFindingsQuery = query(
          collection(DB, assessmentPath, "findings"),
          highConstraint,
          openStateConstraint
        );

        const criticalFindingsQuery = query(
          collection(DB, assessmentPath, "findings"),
          criticalConstraint,
          openStateConstraint
        );

        const oldestFindingQuery = query(
          collection(DB, assessmentPath, "findings"),
          highAndCriticalConstraint,
          openStateConstraint,
          orderBy("firstSeen", "asc"),
          limit(1)
        );

        const newestFiveQuery = query(
          collection(DB, assessmentPath, "findings"),
          highAndCriticalConstraint,
          openStateConstraint,
          orderBy("firstSeen", "desc"),
          limit(5)
        );

        // Query for new critical/high findings from this month
        const thisMonthTs = startOfMonth(now).getTime();

        const thisMonthQuery = query(
          collection(DB, assessmentPath, "findings"),
          highAndCriticalConstraint,
          openStateConstraint,
          where("firstSeen", ">=", thisMonthTs)
        );

        // Get average age of open high and critical findings
        const averageFirstSeenQuery = query(
          collection(DB, assessmentPath, "findings"),
          openStateConstraint,
          highAndCriticalConstraint
        );

        const [
          highCountSnap,
          criticalCountSnap,
          oldestSnap,
          newestSnap,
          thisMonthSnap,
          averageFirstSeen,
        ] = await Promise.all([
          getCountFromServer(highFindingsQuery),
          getCountFromServer(criticalFindingsQuery),
          getDocs(oldestFindingQuery),
          getDocs(newestFiveQuery),
          getCountFromServer(thisMonthQuery),
          getAggregateFromServer(averageFirstSeenQuery, {
            averageFirstSeen: average("firstSeen"),
          }),
        ]);

        let oldestDoc: Finding | null = null;
        let oldestAgeDays = 0;

        if (!oldestSnap.empty) {
          const doc = oldestSnap.docs[0];
          oldestDoc = doc.data() as Finding;
          oldestAgeDays = differenceInDays(now, oldestDoc?.firstSeen);
        }

        const newestDocs = newestSnap.docs.map((d) => d.data() as Finding);
        const totalHighFindingsCount = highCountSnap.data().count;
        const totalCriticalFindingsCount = criticalCountSnap.data().count;
        const newCriticalHighCount = thisMonthSnap.data().count;

        let approximateAvgTimeOpenDays = 0;
        const averageFirstSeenAverage =
          averageFirstSeen.data()?.averageFirstSeen;

        if (averageFirstSeenAverage) {
          const duration = now - averageFirstSeenAverage;
          approximateAvgTimeOpenDays = Math.round(duration / DAYS);
        }

        setCriticalFindings(totalCriticalFindingsCount);
        setHighFindings(totalHighFindingsCount);
        setOldestFinding(oldestDoc);
        setOldestFindingAgeDays(oldestAgeDays);
        setNewestFindings(newestDocs);
        setNewCriticalHighThisMonth(newCriticalHighCount);
        setAvgTimeOpenDays(approximateAvgTimeOpenDays);

        const hasSomeMetrics =
          totalCriticalFindingsCount > 0 ||
          totalHighFindingsCount > 0 ||
          oldestAgeDays > 0 ||
          approximateAvgTimeOpenDays > 0 ||
          newCriticalHighCount > 0;

        setHasMetrics(hasSomeMetrics);

        setLoading(false);
      } catch (err) {
        const error = err as Error;
        console.error("Error fetching findings metrics:", error);
        setLoading(false);
      }
    };

    fetchMetrics();
  }, [monitors, tenant, assessmentPath]);

  return {
    loading,
    error,
    criticalFindings,
    highFindings,
    oldestFindingAgeDays,
    avgTimeOpenDays,
    newCriticalHighThisMonth,
    oldestFinding,
    newestFindings,
    hasMetrics,
  };
};
