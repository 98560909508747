import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { useContext } from "react";
import { AppPaths } from "shared/routes/constants";
import { TargetNodeType } from "shared/types/assessment/data";

import { LinkWithEnvironment } from "../LinkWithEnvironment";

/**
 * A link to a node in the inventory.
 *
 * @param node - The node to link to.
 * @param scopeOverride - An optional scope to override the default scope. Intended
 * for when you want to move from All Scopes to a specific scope.
 *
 */
export const NodeLink: React.FC<{
  node: { key: string; type: TargetNodeType };
  scopeOverride?: string;
}> = ({ node, scopeOverride }) => {
  const { scopeKey } = useContext(ScopeContext);
  return (
    <LinkWithEnvironment
      to={`${AppPaths.Inventory}/${node.type}/${encodeURIComponent(
        node.key
      )}?show=${node.type}&where=${encodeURIComponent(
        `${node.type}="${node.key}"`
      )}&scope=${encodeURIComponent(scopeOverride ?? scopeKey)}`}
      withTenant={true}
    >
      view
    </LinkWithEnvironment>
  );
};
