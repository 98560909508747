import { WarningTwoTone } from "@ant-design/icons";
import { Select, Typography } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { GraphTooltip } from "components/GraphTooltip";
import { ClipDiv } from "components/divs";
import { max, sortBy } from "lodash";
import { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router";
import {
  ALL_SCOPE_SENTINEL,
  ItemAssessmentScope,
  toKey,
} from "shared/types/assessment";
import { MetaTargetNodeTypes } from "shared/types/assessment/data";
import { MonitorScope } from "shared/types/assessment/monitor";

import { ScopeContext } from "../contexts/ScopeContext";
import { useControls } from "../hooks/useControls";
import { useNavigateWithEnv } from "../hooks/useNavigateWithEnv";
import { targetLogo } from "./TargetLogo";
import { scopeLabel } from "./Targets";

const { Text } = Typography;

/** Ranks scopes by a prioritization score
 *
 * E.g., the number of findings for this scope
 */
export type ScopeScoring = (scope: ItemAssessmentScope) => {
  value: number;
  label: string;
};

export type ScopeSelectProps = {
  includeAll?: boolean;
  scoring?: ScopeScoring;
  scopesToInclude?: ReadonlyArray<MonitorScope>;
  wide?: boolean;
};

export const ScopeSelect: React.FC<ScopeSelectProps> = ({
  includeAll,
  scoring,
  scopesToInclude,
  wide,
}) => {
  const navigate = useNavigateWithEnv();
  const { nodeType, nodeKey, findingId } = useParams();
  const { controls } = useControls();
  const { scopeKey, setScopeKey, setGraph } = useContext(ScopeContext);
  const { last } = useContext(SelectedEnvironmentContext);

  const onSelect = useCallback(
    (value: string) => {
      // Close any open drawers
      if ((nodeType && nodeKey) || findingId) {
        navigate(
          { pathname: "../..", search: `?scope=${value}` },
          { relative: "path" }
        );
      } else {
        setScopeKey(value);
      }
      setGraph(undefined);
    },
    [findingId, navigate, nodeKey, nodeType, setGraph, setScopeKey]
  );

  const options = useMemo(() => {
    // On older job documents scope could be an object
    const available = last.doc?.data.scope ?? [];
    // Sort in reverse so highest score is first
    const scopes = scoring
      ? sortBy(available, (s) => -scoring(s).value)
      : available;

    let options: (DefaultOptionType & { displayText: string })[] = [];
    if (
      !scopesToInclude ||
      scopesToInclude.some((scope) => scope !== ALL_SCOPE_SENTINEL)
    ) {
      options = scopes
        .filter((s) => scopesToInclude?.includes(s.integration) ?? true)
        .map((s) => {
          const innerText = `${s.id}${
            s.testIndex !== undefined ? `:${s.testIndex}` : ""
          }`;

          const textWithLogo = (
            <>
              {targetLogo(s.integration)}
              &nbsp;
              {innerText}
            </>
          );

          return {
            value: toKey(s),
            displayText: innerText,
            label: (
              <GraphTooltip
                title={textWithLogo}
                mouseEnterDelay={0.5}
                placement="right"
              >
                <ClipDiv maxWidth="unset">
                  {scoring ? (
                    <Text type="secondary">
                      {String(scoring(s).label)}:&nbsp;
                    </Text>
                  ) : null}
                  {textWithLogo}
                </ClipDiv>
              </GraphTooltip>
            ),
          };
        });
    }

    // scopesToInclude only applies to the MonitorResults page where
    // the monitor only applies to a specific set of scopes.
    // If scopesToInclude isn't passed at all, allow All Targets to be added.
    if (
      includeAll &&
      (!scopesToInclude || scopesToInclude?.includes(ALL_SCOPE_SENTINEL)) &&
      MetaTargetNodeTypes.some((type) => type === controls.show)
    ) {
      options = [
        {
          value: ALL_SCOPE_SENTINEL,
          label: <div>All targets</div>,
          displayText: "All targets",
        },
        ...options,
      ];
    }
    return options;
  }, [
    last.doc?.data.scope,
    scoring,
    scopesToInclude,
    includeAll,
    controls.show,
  ]);

  const notInstalled = useMemo(
    () =>
      last.doc?.data.scope.find(
        (s) => toKey(s) === scopeKey && s.installState === "NOT_INSTALLED"
      ),
    [last.doc, scopeKey]
  );
  const width = useMemo(() => {
    // Take the value of the longest option and multiply by 0.75
    // If there are no options, use 20 as the default width
    // The +5 is a hack to account for the width of the CSP icon and the score
    return `${
      (max(options.map((o) => o?.displayText?.length ?? 0)) ?? 20) +
      (scoring ? 4 : 2)
    }ch`;
  }, [options, scoring]);

  return options.length ? (
    <div>
      <Select
        showSearch
        value={scopeKey}
        onChange={onSelect}
        options={options}
        popupMatchSelectWidth
        style={{
          alignSelf: "flex-start",
          width: width,
          maxWidth: wide ? "40em" : "21em",
          minWidth: "16em",
        }}
        dropdownStyle={{
          width,
          maxWidth: "40em",
          minWidth: "16em",
        }}
      />
      {notInstalled && (
        <GraphTooltip
          title={`P0 could not find a valid installation for this target. This ${
            scopeLabel[notInstalled.integration].project
          } was not assessed.`}
        >
          &nbsp; <WarningTwoTone twoToneColor="#ffe58f" />
        </GraphTooltip>
      )}
    </div>
  ) : null;
};
