import { InfoCircleTwoTone } from "@ant-design/icons";
import { Select } from "antd";
import Link from "antd/lib/typography/Link";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { TooltipCode } from "components/TooltipCode";
import { SpaceBetweenDiv } from "components/divs";
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IamOption } from "shared/assessment/constants";

import { SearchInput } from "../../components/antd";
import { GraphTooltip } from "../GraphTooltip";
import { CreateMonitorButton } from "./Buttons/CreateMonitor";
import { InteractiveTermBuilder } from "./InteractiveTermBuilder";
import { Prefix } from "./Prefix";

export interface GraphSearchProps {
  controls: {
    display: string;
    terms: string;
    show: string;
    onChangeDisplay: (value: string) => void;
    onChangeTerms: (value: string) => void;
    onChangeShow: (value: string) => void;
  };
  frozen?: {
    display?: boolean;
    terms?: string;
    show?: string;
    help?: ReactNode;
  };
  isSearching: boolean;
  searchExtra?: ReactNode;
  scopeNode?: ReactNode;
  showOptions: IamOption[];
  showCreateMonitor?: boolean;
}

const GraphSearchHelp: React.FC<object> = () => (
  <div>
    <p>Anything you type here will filter displayed data:</p>
    <ul>
      <li>
        <TooltipCode>keyword</TooltipCode>&nbsp;&minus;&nbsp;Show data
        containing <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>column:</TooltipCode>&nbsp;&minus;&nbsp;Show data where{" "}
        <TooltipCode>column</TooltipCode> has content.
      </li>
      <li>
        <TooltipCode>column:keyword</TooltipCode>
        &nbsp;&minus;&nbsp;Show data data where{" "}
        <TooltipCode>column</TooltipCode> is linked to{" "}
        <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>column=keyword</TooltipCode>
        &nbsp;&minus;&nbsp;Show data data where{" "}
        <TooltipCode>column</TooltipCode> contains{" "}
        <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>!keyword</TooltipCode>&nbsp;&minus;&nbsp;Show data with at
        least one value not matching <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>column:!keyword</TooltipCode>&nbsp;&minus;&nbsp;Show data
        where <TooltipCode>column</TooltipCode> is linked to at least one value
        not matching <TooltipCode>keyword</TooltipCode>.
      </li>
      <li>
        <TooltipCode>^term</TooltipCode>&nbsp;&minus;&nbsp;Removes items
        matching the <TooltipCode>term</TooltipCode> query from search results.
      </li>
    </ul>
    <p>
      For detailed help, see the{" "}
      {/* Deliberately keep referrer here; we'll use it for analytics */}
      <Link
        href="https://docs.p0.dev/iam-assessment/query-search"
        rel="noopener"
        target="docs"
      >
        query-search reference
      </Link>
      .
    </p>
  </div>
);

export const GraphSearch = ({
  controls,
  frozen,
  isSearching,
  scopeNode,
  searchExtra,
  showOptions,
  showCreateMonitor,
}: GraphSearchProps) => {
  const [showLoading, setShowLoading] = useState(false);
  const [staged, setStaged] = useState<string>();
  const { onChangeShow, onChangeTerms } = controls;
  const { graph } = useContext(ScopeContext);
  const stageSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setStaged(event.target.value),
    []
  );
  const updateTerms = useCallback(() => {
    if (staged) onChangeTerms(staged);
  }, [onChangeTerms, staged]);

  const onAddInteractive = useCallback(
    (term: string) => onChangeTerms(`${controls.terms} ${term}`.trim()),
    [controls.terms, onChangeTerms]
  );

  useEffect(() => {
    setShowLoading(false);
  }, [controls.show]);

  useEffect(() => {
    setStaged(undefined);
  }, [controls.terms]);

  const handleChangeShow = useCallback(
    (value: string) => {
      setShowLoading(true);
      onChangeShow(value);
    },
    [onChangeShow]
  );

  return (
    <div
      style={{
        display: "grid",
        gap: "0.5em",
        flexGrow: "1",
      }}
    >
      <SpaceBetweenDiv style={{ alignItems: "center", width: "100%" }}>
        <div
          style={{ display: "inline-flex", alignItems: "baseline", gap: "1em" }}
        >
          <Prefix prefix="Show" textWidth="md">
            <Select
              style={{ minWidth: "8em" }}
              disabled={!!frozen?.show}
              options={showOptions}
              onChange={handleChangeShow}
              value={controls.show}
              loading={showLoading}
            />
          </Prefix>
          {scopeNode && <Prefix prefix="from">{scopeNode}</Prefix>}
        </div>
        {searchExtra}
      </SpaceBetweenDiv>
      <Prefix
        prefix={
          <GraphTooltip title={<GraphSearchHelp />} width="500px">
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Where&nbsp;
              <InfoCircleTwoTone />
            </div>
          </GraphTooltip>
        }
        textWidth="md"
      >
        <div style={{ display: "flex", width: "100%" }}>
          <SearchInput
            enterButton={staged !== undefined}
            enterKeyHint="search"
            loading={isSearching}
            onChange={stageSearch}
            onPressEnter={updateTerms}
            onSearch={updateTerms}
            placeholder={
              frozen?.terms
                ? undefined
                : "Enter search terms or click +, then press Enter"
            }
            style={{
              marginBottom: "unset",
              minWidth: "20em",
              maxWidth: "100%",
              marginRight: 0,
            }}
            value={staged ?? controls.terms}
          />
          <InteractiveTermBuilder onAddTerm={onAddInteractive} />
        </div>
        {showCreateMonitor && <CreateMonitorButton disabled={!graph} />}
      </Prefix>
    </div>
  );
};
