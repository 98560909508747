import styled from "styled-components";

/**
 * Creates an encapsulating dive for text with a link.
 * Should be used in combination with {@link LinkNameSpan} and {@link LinkDetailsSpan}.
 *
 * e.g.
 *
 * <LinkDiv>
 *  <LinkNameSpan />
 *  <LinkDetailsSpan />
 * </LinkDiv
 *
 */
export const LinkDiv = styled.div`
  display: flex;
  gap: 0.125em;
  align-items: center;
`;

/**
 * Text for the link name.
 */
export const LinkNameSpan = styled.span`
  flex-shrink: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

/**
 * Should contain the icon and any additional details for the link.
 * This will not be ellipsis-clipped.
 */
export const LinkDetailsSpan = styled.span`
  flex-shrink: 0;
`;
