import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Empty,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Typography,
  message,
} from "antd";
import { useNavigateWithEnv } from "components/Assessment/hooks/useNavigateWithEnv";
import { useSelectedEnvironment } from "components/Assessment/hooks/useSelectedEnvironment";
import { AssessmentSettingsSection } from "components/Assessment/styles";
import { EnvironmentDocsContext } from "components/Environment/contexts/EnvironmentDocsContext";
import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { GraphTooltip } from "components/GraphTooltip";
import { AuthFetch, useAuthFetch } from "components/Login/hook";
import { AuthzButton } from "components/common/AuthzButton";
import { deleteField, serverTimestamp, updateDoc } from "firebase/firestore";
import { compact, size } from "lodash";
import pluralize from "pluralize";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  Controller,
  ControllerProps,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useParams } from "react-router";
import { AppPaths } from "shared/routes/constants";
import {
  AssessmentScope,
  AssessmentScopeIntegration,
} from "shared/types/assessment";
import { colors } from "styles/variables";

import { useInstalledIntegrations } from "../../../hooks/useInstalledIntegrations";
import {
  FrequencyAmount,
  FrequencyInputGroup,
  FrequencyInputs,
  FrequencyUnit,
} from "../../NewEnvironmentForm";
import { TargetsList } from "../../Targets";

const ITEM_TITLE_LEVEL = 5;

const { Title, Paragraph } = Typography;

type AssessmentEditProps = {
  authFetch: AuthFetch;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  submitting: boolean;
  updateAssessment: (data: object) => Promise<void>;
};

const EditAssignmentSection: React.FC<AssessmentEditProps> = (props) => {
  const { updateAssessment } = props;
  const { assessment } = useContext(SelectedEnvironmentContext);

  const contactInfoTitle = useMemo(
    () =>
      compact([
        assessment.doc?.data.targets.find((t) => t.integration === "gcloud") &&
          "For GCP, this is the project's technical contact.",
        assessment.doc?.data.targets.find((t) => t.integration === "aws") &&
          "For AWS, this is the account's operations contact.",
      ]).join("\n"),
    [assessment.doc]
  );

  const saveAssignmentValue = useCallback(
    async (event: RadioChangeEvent) => {
      const { value } = event.target;
      if (value === "contact" || value === "manual") {
        await updateAssessment({ assignBy: { type: value } });
      }
    },
    [updateAssessment]
  );

  return assessment.doc ? (
    <AssessmentSettingsSection>
      <Title level={ITEM_TITLE_LEVEL}>Assignment</Title>
      <Paragraph>
        Determines how issue owners are determined when you assign findings.
      </Paragraph>
      <Radio.Group
        defaultValue={assessment.doc.data.assignBy?.type ?? "manual"}
        onChange={saveAssignmentValue}
        style={{ display: "flex", flexDirection: "column", gap: "12px" }}
      >
        {/* Explicitly leaving out VCS and tag strategies for now as those are experimental */}
        <Radio value="manual">Manually</Radio>
        <Radio value="contact">
          Resource contact{" "}
          <GraphTooltip title={contactInfoTitle}>
            <InfoCircleOutlined />
          </GraphTooltip>
        </Radio>
      </Radio.Group>
    </AssessmentSettingsSection>
  ) : null;
};

const DangerZone: React.FC = () => {
  const authFetch = useAuthFetch();
  const navigate = useNavigateWithEnv();
  const { orgSlug } = useParams();

  const { assessment } = useContext(SelectedEnvironmentContext);
  const { all } = useContext(EnvironmentDocsContext);

  const [deleting, setDeleting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [_, setSelectedEnvironment] = useSelectedEnvironment();

  const handleDelete = useCallback(async () => {
    const getNextEnvironment = (val: string) => {
      const allValues = Array.from(all.values());
      const remainingEnvs = allValues.filter((env) => env.assessmentId !== val);
      if (!size(remainingEnvs)) {
        return null;
      } else {
        return remainingEnvs.at(-1);
      }
    };

    const idToDelete = assessment.doc?.id;
    if (!idToDelete) {
      return;
    }
    setDeleting(true);
    const response = await authFetch(`assessment/${idToDelete}`, {
      method: "DELETE",
    });
    if (response?.ok) {
      message.success("Assessment deleted");
      const nextEnv = getNextEnvironment(idToDelete);
      if (nextEnv) {
        setSelectedEnvironment(nextEnv.assessmentId);

        message.info(`Redirecting to ${nextEnv.label}`);
      } else {
        navigate(`/o/${orgSlug}/${AppPaths.Dashboard}`);
      }
    } else {
      message.error("Failed to delete assessment");
    }
    setShowModal(false);
    setDeleting(false);
  }, [
    assessment.doc?.id,
    authFetch,
    all,
    setSelectedEnvironment,
    navigate,
    orgSlug,
  ]);

  const openModal = useCallback(() => setShowModal(true), []);
  const closeModal = useCallback(() => setShowModal(false), []);

  return (
    <AssessmentSettingsSection>
      <Title level={ITEM_TITLE_LEVEL}>Danger Zone</Title>
      <Paragraph>
        <AuthzButton
          roles={["owner", "iamOwner"]}
          type="default"
          danger
          onClick={openModal}
        >
          Delete Environment
        </AuthzButton>
      </Paragraph>
      <Modal
        open={showModal}
        footer={
          <>
            <Button type="default" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              type="primary"
              danger
              onClick={handleDelete}
              loading={deleting}
            >
              Delete Assessment
            </Button>
          </>
        }
      >
        <Title level={ITEM_TITLE_LEVEL}>Delete</Title>
        <Paragraph>
          Are you sure you want to delete this environment? This action cannot
          be undone.
        </Paragraph>
      </Modal>
    </AssessmentSettingsSection>
  );
};

export const EditNameSection: React.FC<AssessmentEditProps> = ({
  submitting,
  updateAssessment,
}) => {
  const { details } = useContext(SelectedEnvironmentContext);
  const [editing, setEditing] = useState(false);
  const [nameForm] = Form.useForm<{ name: string }>();

  const name = details?.label;

  const toggleEdit = useCallback(() => {
    // When toggling to edit mode, set the current name as the initial value.
    if (!editing) {
      nameForm.setFieldsValue({ name });
    }
    setEditing((prev) => !prev);
  }, [editing, name, nameForm]);

  const saveName = useCallback(async () => {
    try {
      const { name: newName } = await nameForm.validateFields();
      await updateAssessment({ name: newName });
      setEditing(false);
    } catch (error) {
      // handle validation errors or update failures here if needed
      console.error(error);
    }
  }, [nameForm, updateAssessment]);

  return (
    <AssessmentSettingsSection>
      <Form form={nameForm} initialValues={{ name }}>
        <Form.Item name="name" style={{ marginBottom: 0 }}>
          {editing ? (
            <Input
              style={{ maxWidth: "10em" }}
              onPressEnter={saveName}
              disabled={submitting}
            />
          ) : (
            <Title level={ITEM_TITLE_LEVEL}>
              {name}
              <AuthzButton
                roles={["owner", "iamOwner"]}
                onClick={toggleEdit}
                icon={<EditOutlined />}
                style={{
                  border: "none",
                  marginLeft: "0.5em",
                  boxShadow: "none",
                  padding: 0,
                  background: colors.neutral["10"],
                }}
              />
            </Title>
          )}
        </Form.Item>
        {editing && (
          <div style={{ marginTop: "0.5em" }}>
            <Button.Group>
              <AuthzButton
                roles={["owner", "iamOwner"]}
                type="primary"
                icon={<CheckOutlined />}
                onClick={saveName}
                loading={submitting}
              >
                Confirm
              </AuthzButton>
              <AuthzButton
                roles={["owner", "iamOwner"]}
                icon={<CloseOutlined />}
                onClick={toggleEdit}
              >
                Cancel
              </AuthzButton>
            </Button.Group>
          </div>
        )}
      </Form>
    </AssessmentSettingsSection>
  );
};

const EditSchedulingSection: React.FC<AssessmentEditProps> = (props) => {
  const { assessment, formattedNextRun } = useContext(
    SelectedEnvironmentContext
  );

  const { authFetch, setSubmitting, submitting, updateAssessment } = props;

  const [editingSchedule, setEditingSchedule] = useState(false);

  const scheduleForm = useForm<FrequencyInputs>({
    defaultValues: {
      frequencyUnit: "days",
      frequencyAmount: 1,
    },
  });

  const onSubmitSchedulingForm: SubmitHandler<FrequencyInputs> = useCallback(
    async (data: FrequencyInputs) => {
      if (!assessment.doc) return;
      setSubmitting(true);
      const response = await authFetch(
        `assessment/${assessment.doc.id}/reschedule`,
        {
          method: "POST",
          json: {
            unit: data.frequencyUnit,
            amount: data.frequencyAmount,
          },
        }
      );
      if (response?.ok) {
        setEditingSchedule(false);
      }
      setSubmitting(false);
    },
    [assessment.doc, authFetch, setSubmitting]
  );

  const handleEditScheduling = useCallback(() => {
    if (editingSchedule) {
      scheduleForm.handleSubmit(onSubmitSchedulingForm)();
    } else {
      if (!editingSchedule && assessment.doc?.data.frequency) {
        scheduleForm.setValue(
          "frequencyUnit",
          assessment.doc.data.frequency.unit
        );
        scheduleForm.setValue(
          "frequencyAmount",
          assessment.doc.data.frequency.amount
        );
      }
      setEditingSchedule(true);
    }
  }, [editingSchedule, scheduleForm, onSubmitSchedulingForm, assessment.doc]);

  const enableScheduledRuns = useCallback(() => {
    if (!assessment.hasSchedule) {
      return;
    }
    updateAssessment({
      "frequency.disabled": deleteField(),
    });
  }, [assessment.hasSchedule, updateAssessment]);

  const disableScheduledRuns = useCallback(() => {
    if (!assessment.hasSchedule) {
      return;
    }
    // TODO: fix with migration
    const legacyEnableUpdates = assessment.doc?.data.frequency?.anchorDate
      ? {}
      : { "frequency.anchorDate": serverTimestamp() };
    updateAssessment({
      ...legacyEnableUpdates,
      "frequency.disabled": true,
      "frequency.runCount": 0,
    });
  }, [assessment.hasSchedule, assessment.doc, updateAssessment]);

  const scheduleText = useMemo(() => {
    const frequency = assessment.doc?.data.frequency;
    return frequency
      ? assessment.isScheduled
        ? `Runs every ${frequency.amount} ${pluralize(
            frequency.unit,
            frequency.amount
          )}.`
        : `Configured to run every ${frequency.amount} ${pluralize(
            frequency.unit,
            frequency.amount
          )}. Not currently running.`
      : "This assessment is not scheduled.";
  }, [assessment]);

  const renderFrequencyAmount = useCallback<
    ControllerProps<FrequencyInputs, "frequencyAmount">["render"]
  >(({ field: { ref: _ref, ...field } }) => <FrequencyAmount {...field} />, []);

  const renderFrequencyUnit = useCallback<
    ControllerProps<FrequencyInputs, "frequencyUnit">["render"]
  >(({ field: { ref: _ref, ...field } }) => <FrequencyUnit {...field} />, []);

  const cancelScheduleEdit = useCallback(() => setEditingSchedule(false), []);

  return (
    <AssessmentSettingsSection>
      <Title level={ITEM_TITLE_LEVEL}>Scheduling</Title>
      {editingSchedule ? (
        <form
          onSubmit={scheduleForm.handleSubmit(onSubmitSchedulingForm)}
          style={{ maxWidth: "500px" }}
        >
          <FrequencyInputGroup>
            <Controller
              name="frequencyAmount"
              control={scheduleForm.control}
              render={renderFrequencyAmount}
            />
            <Controller
              name="frequencyUnit"
              control={scheduleForm.control}
              render={renderFrequencyUnit}
            />
          </FrequencyInputGroup>
        </form>
      ) : (
        <Paragraph>
          {scheduleText} {formattedNextRun}
        </Paragraph>
      )}
      <Paragraph>
        <Button.Group>
          <AuthzButton
            roles={["owner", "iamOwner"]}
            type="default"
            onClick={handleEditScheduling}
            loading={submitting}
          >
            {editingSchedule ? "Update" : "Edit"}
          </AuthzButton>
          {editingSchedule && (
            <Button onClick={cancelScheduleEdit}>Cancel</Button>
          )}
          {!editingSchedule && !assessment.isScheduled && (
            <AuthzButton
              roles={["owner", "iamOwner"]}
              type="primary"
              onClick={enableScheduledRuns}
            >
              Enable
            </AuthzButton>
          )}
          {!editingSchedule && assessment.isScheduled && (
            <Paragraph>
              <AuthzButton
                roles={["owner", "iamOwner"]}
                type="default"
                danger
                onClick={disableScheduledRuns}
              >
                Disable
              </AuthzButton>
            </Paragraph>
          )}
        </Button.Group>
      </Paragraph>
    </AssessmentSettingsSection>
  );
};

const EditTargetsSection: React.FC<{
  targets: AssessmentScope[];
}> = ({ targets }) => (
  <AssessmentSettingsSection>
    <Title level={ITEM_TITLE_LEVEL}>Scope</Title>
    <Paragraph>This environment manages:</Paragraph>
    <TargetsList targets={targets} />
  </AssessmentSettingsSection>
);

const EnvironmentConfigurationPage: React.FC = () => {
  const authFetch = useAuthFetch();
  const { assessment } = useContext(SelectedEnvironmentContext);
  const assessmentData = assessment.doc?.data;
  const [submitting, setSubmitting] = useState(false);

  const updateAssessment = useCallback(
    async (data: object) => {
      if (!assessment.doc?.ref) return;

      setSubmitting(true);
      await updateDoc(assessment.doc?.ref, {
        ...data,
      });
      setSubmitting(false);
    },
    [assessment]
  );

  const editProps = {
    authFetch,
    setSubmitting,
    submitting,
    updateAssessment,
  };

  const targets = assessmentData?.targets;

  const { awsTargets, gcloudTargets } = useMemo(
    () => ({
      awsTargets: targets?.filter((t) => t.integration === "aws"),
      gcloudTargets: targets?.filter((t) => t.integration === "gcloud"),
    }),
    [targets]
  );

  const { loading, installed } = useInstalledIntegrations();

  const integrationMap: Record<
    AssessmentScopeIntegration,
    { targets: AssessmentScope[]; isInstalled: boolean }
  > = {
    gcloud: { targets: gcloudTargets ?? [], isInstalled: installed.gcloud },
    aws: { targets: awsTargets ?? [], isInstalled: installed.aws },
    k8s: { targets: [], isInstalled: false },
    workspace: { targets: [], isInstalled: false },
    // k8s and workspace are not yet implemented
  };

  const shouldRenderAlert = (integration: AssessmentScopeIntegration) => {
    if (loading) return false;
    const mapping = integrationMap[integration];
    if (!mapping) return false;
    return size(mapping.targets) > 0 && !mapping.isInstalled;
  };

  return (
    <>
      {shouldRenderAlert("gcloud") && (
        <Alert
          type="error"
          message="Your Google Cloud installation is not complete."
          description="Any existing assessments will not run correctly until you fix it."
          action={
            <Button
              type="primary"
              href={`../${AppPaths.Integrations}/gcloud/iam-assessment`}
            >
              Install IAM Assessments (Google Cloud)
            </Button>
          }
          style={{ marginBottom: "20px", maxWidth: "800px" }}
        />
      )}

      {shouldRenderAlert("aws") && (
        <Alert
          type="error"
          message="Your AWS installation is not complete."
          description="Any existing assessments will not run correctly until you fix it."
          action={
            <Button
              type="primary"
              href={`../${AppPaths.Integrations}/aws/iam-assessment`}
            >
              Install IAM Assessments (AWS)
            </Button>
          }
          style={{ marginBottom: "20px", maxWidth: "800px" }}
        />
      )}
      <EditNameSection {...editProps} />
      <EditSchedulingSection {...editProps} />
      <EditAssignmentSection {...editProps} />
      {assessmentData?.targets && assessmentData?.targets.length > 0 ? (
        <EditTargetsSection targets={assessmentData.targets} />
      ) : (
        <Empty description="No targets configured" />
      )}
      <DangerZone />
    </>
  );
};

export default EnvironmentConfigurationPage;
