import { camelCase, capitalize, compact, size } from "lodash";

import { DslMapping, parse } from "../../graph/dsl";
import { NodePredicate } from "../../graph/search";
import { Node, isNode } from "../../graph/types";
import {
  GcpResourceInfo,
  MemberKey,
  Service,
  frnToInfo,
} from "../../integrations/resources/gcloud/asset";
import { DAYS } from "../../time";
import { ItemAssessmentScope } from "../../types/assessment";
import {
  AssessmentNodes,
  AssessmentSchemaItem,
  AssessmentSchemaMap,
  GrantNode,
  IdentityType,
} from "../../types/assessment/data";
import { CredentialNode } from "../../types/assessment/data/credential";
import {
  FixOptions,
  Monitor,
  SavedMonitor,
} from "../../types/assessment/monitor";
import { widetype } from "../../util/collections";

export const STALE_CREDENTIAL_MILLIS = 90 * DAYS;

/** Converts special node types to values */
export const AssessmentMap: DslMapping<AssessmentNodes> = {
  usage: {
    keys: (n) => [n.data.type],
    attributes: {
      last90: (n) => [n.data.type],
    },
  },
  identity: {
    attributes: {
      // accessFoo are short term hacks until we make standalone directory assessment scopes
      accessAdd: (n) => compact([n.data.access?.add]),
      accessApprove: (n) => compact([n.data.access?.approve]),
      accessJoin: (n) => compact([n.data.access?.join]),
      accessView: (n) => compact([n.data.access?.view]),
      // ---
      type: (n) => [
        n.data.type,
        ...(n.data.type === "service-account" ? ["role"] : []),
        ...(n.data.isProviderManaged ? ["service-agent"] : []),
      ],
      status: (n) => [n.data.disabled ? "disabled" : "active"],
    },
    keys: (n) => compact([n.key, n.data.label]),
  },
  risk: {
    keys: (n) => compact([n.key, n.data.score]),
  },
  grant: {
    keys: (n) => compact([n.key, n.data.principal, n.data.privilegeSet]),
    attributes: {
      resource: (n) => [...n.data.resources],
      status: (n) => [n.data.disabled ? "disabled" : "enabled"],
    },
  },
  credential: {
    attributes: {
      enabledKey: (n) => [
        n.data.type === "key" && n.data.status === "enabled" ? "true" : "false",
      ],
      stale90: (n) => [
        n.data.createdTime &&
        n.data.createdTime < Date.now() - STALE_CREDENTIAL_MILLIS
          ? "true"
          : "false",
      ],
      last40: (n) => [
        n.data.lastAuthnTime > Date.now() - 40 * DAYS ? "used" : "unused",
      ],
      last90: (n) => [
        n.data.lastAuthnTime > Date.now() - 90 * DAYS ? "used" : "unused",
      ],
      last365: (n) => [
        // If analyzer auth time is not specified or the key was created less than 365 days ago, this usage is unknown
        n.data.maxAuthnLookbackDays && n.data.maxAuthnLookbackDays >= 365
          ? n.data.lastAuthnTime > Date.now() - 365 * DAYS
            ? "used"
            : n.data.createdTime !== undefined &&
              n.data.createdTime < Date.now() - 365 * DAYS
            ? "unused"
            : "unknown"
          : "unknown",
      ],
    },
  },
};

const AssessmentAliases = {
  // 2024-09-17 Temporary aliases to shim node name changes.
  // vvv Delete after 2024-09-24. vvv
  "binding:": "grant:",
  "binding=": "binding:",
  "permissionType:": "usage:type:",
  // ^^^ End of temporary aliases ^^^
  "role:": "grant:privilegeSet:",
  "role=": "grant=privilegeSet:",
  "login:": "credential:last90:",

  // Natural language aliases
  "gcpRoleBinding:": "grant:provider:gcp",
  "awsPolicyAssignment:": "grant:provider:aws",
};

export const identityPredicate: NodePredicate<AssessmentNodes> = (n) =>
  isNode("identity")(n) && n.data.type !== "unknown";

export const grantPredicate: NodePredicate<AssessmentNodes> =
  // 2024-09-17 "binding" alias temporary to shim node name changes.
  // Remove it after 2024-09-24.
  (n) => isNode("grant")(n) || isNode("binding")(n as any);

export const resourcePredicate: NodePredicate<AssessmentNodes> =
  isNode("resource");

const assessmentTypes: Record<string, string[]> = widetype.mapValues(
  AssessmentSchemaMap,
  (v, k) => [
    ...Object.keys(v as Record<string, AssessmentSchemaItem>),
    ...Object.keys(AssessmentMap[k]?.attributes ?? {}),
  ]
);
assessmentTypes.role = assessmentTypes.privilegeSet;
assessmentTypes.login = [];

export const assessmentParse = parse(
  AssessmentMap,
  AssessmentAliases,
  assessmentTypes
);

const gcpFixRewrite: Record<
  string,
  (
    info: GcpResourceInfo
  ) => (GcpResourceInfo & { component?: string }) | undefined
> = {
  [Service.kms]: (info) =>
    info.type === "keyRings" && info.id
      ? // using the full name allows us to omit the `--location` flag
        { ...info, component: "keyrings", service: "kms" }
      : undefined,
  [Service.crm]: (info) =>
    info.type && info.id
      ? { ...info, service: info.type, locator: info.id }
      : undefined,
  [Service.functions]: (info) =>
    info.type === "functions" && info.id ? info : undefined,
  [Service.iam]: (info) =>
    info.type === "serviceAccounts" && info.id
      ? { ...info, locator: info.id, component: "service-accounts" }
      : undefined,
  [Service.storage]: (info) => ({
    ...info,
    component: "buckets",
    locator: `gs://${info.locator}`,
  }),
};

/** Extracts project and principal identifier for project grants */
const gcpGrantFixData = (data: GrantNode["data"]) => {
  const { resources, principalType } = data;
  // GCP grants are always to a single resource
  const [resource] = resources;
  if (!resource) return undefined;

  const { api, id, locator, service, type } = frnToInfo(resource);
  if (!locator) return undefined;
  if (!service || !(service in gcpFixRewrite)) return undefined;
  const rewrite = gcpFixRewrite[service]({ api, id, locator, type, service });
  if (!rewrite) return;

  const memberKey = MemberKey[principalType];
  if (!memberKey) return undefined;
  return { ...rewrite, memberKey };
};

function getAwsIamCommandsForPrincipalType(principalType: IdentityType) {
  switch (principalType) {
    case "user":
      return {
        attachCmd: "attach-user-policy",
        detachCmd: "detach-user-policy",
        nameParam: "--user-name",
      };
    case "group":
      return {
        attachCmd: "attach-group-policy",
        detachCmd: "detach-group-policy",
        nameParam: "--group-name",
      };
    case "aws-iam-role":
      return {
        attachCmd: "attach-role-policy",
        detachCmd: "detach-role-policy",
        nameParam: "--role-name",
      };
    default:
      return {
        attachCmd: `# Unsupported`,
        detachCmd: `# Unsupported`,
        nameParam: "",
      };
  }
}

// see https://cloud.google.com/sdk/gcloud/reference/projects/add-iam-policy-binding
const argFromCondition = (condition: GrantNode["data"]["condition"]) =>
  typeof condition === "object"
    ? `^:^title=${condition.title}:expression=${condition.expression}${
        condition.description ? `:description=${condition.description}` : ""
      }`
    : "None";

const unusedGrantFixGenerator = {
  gcloud: {
    description: "To fix, run these commands in Google Cloud Shell:",
    type: "shell" as const,
    generate: ({ data }: GrantNode) => {
      const { condition, principal, privilegeSet } = data;
      const fixData = gcpGrantFixData(data);
      if (!fixData) return undefined;
      const { component, locator, memberKey, service } = fixData;
      const conditionArg = argFromCondition(condition);
      return `gcloud ${service}${
        component ? ` ${component}` : ""
      } remove-iam-policy-binding ${locator} \\
  --member=${memberKey}:${principal} \\
  --role=${privilegeSet} \\
  --condition=${conditionArg}`;
    },
  },
  aws: {
    description: "To fix, run these commands in AWS CloudShell:",
    type: "shell" as const,
    generate: ({ data }: GrantNode) => {
      const { principal, principalType, privilegeSet } = data;

      const { attachCmd, detachCmd, nameParam } =
        getAwsIamCommandsForPrincipalType(principalType);

      if (attachCmd.startsWith("#")) {
        return `# Unsupported principal type: ${principalType}`;
      }

      return `aws iam ${detachCmd} \\
${nameParam} ${principal} \\
--policy-arn ${privilegeSet}`;
    },
  },
};

const partialGrantFixGenerator = {
  gcloud: {
    description:
      "Remove excess unused privileges by running these commands in Google Cloud Shell:",
    type: "shell" as const,
    generate: ({ aggregates, data }: GrantNode, { scope }: FixOptions) => {
      const { condition, principal, privilegeSet } = data;
      const fixData = gcpGrantFixData(data);
      if (!fixData) return undefined;
      const { component, locator, memberKey, service } = fixData;
      const { unknown, used } = aggregates.privileges;
      const conditionArg = argFromCondition(condition);
      const perms = [...(unknown ?? []), ...(used ?? [])].map((p) => p.key);
      // resourcemanager permissions can't be granted on a project
      // really we should use queryGrantableRoles
      const validPerms = perms.filter((p) => !p.startsWith("resourcemanager"));
      const shortPrincipal = capitalize(camelCase(principal.split("@")[0]));
      const oldRole = privilegeSet.split("/").at(-1) ?? "";
      const shortOldRole = capitalize(camelCase(oldRole));
      // We have 32 characters total (64 bytes)
      const roleId = `Lp4${capitalize(
        shortPrincipal.slice(0, 16)
      )}${shortOldRole.slice(0, 12)}`;
      const compPart = component ? ` ${component}` : "";
      return `gcloud iam roles create ${roleId} \\
  --project=${scope.id} \\
  --stage="GA" \\
  --title="Least Privilege ${data.principal} ${oldRole}" \\
  --description="Least-privilege role for ${
    data.principal
  }. It replaces the previous over-privileged grant to ${
        data.privilegeSet
      }, and was determined via a P0 IAM assessment." \\
  --permissions=${validPerms.join(",")}
gcloud ${service}${compPart} add-iam-policy-binding ${locator} \\
  --member=${memberKey}:${principal} \\
  --role=projects/${scope.id}/roles/${roleId} \\
  --condition='${conditionArg}'
gcloud ${service}${compPart} remove-iam-policy-binding ${locator} \\
  --member=${memberKey}:${principal} \\
  --role=${privilegeSet} \\
  --condition='${conditionArg}'`;
    },
  },
  aws: {
    description:
      "Remove excessive privileges by creating a custom policy with only used permissions and detaching the old policy.",
    type: "shell" as const,

    generate: ({ data, aggregates, key }: GrantNode, { scope }: FixOptions) => {
      if (!scope.id) {
        return `# No project found for node ${key}. Unable to generate partial fix.`;
      }

      const {
        principal,
        privilegeSet: policyArn,
        principalType,
        resources,
      } = data;

      if (!principal || !policyArn) {
        return `# No principal or policy found for node ${key}. Unable to generate partial fix.`;
      }
      if (size(resources) === 0) {
        return `# No resource found for grant ${key}. Unable to generate partial fix.`;
      }
      if (size(resources) > 1) {
        return `# Multiple resources found for grant ${key}. Unable to generate partial fix.`;
      }

      const { attachCmd, detachCmd, nameParam } =
        getAwsIamCommandsForPrincipalType(principalType);
      if (attachCmd.startsWith("#")) {
        return `# Unsupported principal type: ${principalType}`;
      }

      const { used = [], unknown = [] } = aggregates?.privileges ?? {};
      const usedAndUnknownActions = [...used, ...unknown].map((p) => p.key);

      // If there are no used/unknown actions, just detach the old policy.
      if (!usedAndUnknownActions.length) {
        return `# This command set removes the old policy (“${policyArn}”)
aws iam ${detachCmd} \\
  ${nameParam} ${principal} \\
  --policy-arn ${policyArn}
      `;
      }

      const [resource] = resources;

      // Create a new least-privileged policy
      const policyDoc = JSON.stringify(
        {
          Version: "2012-10-17",
          Statement: [
            {
              Effect: "Allow",
              Action: usedAndUnknownActions,
              Resource: resource,
            },
          ],
        },
        null,
        2
      );

      const shortOldPolicy = policyArn.split("/").at(-1);
      if (!shortOldPolicy) {
        return `# No permission set found for node ${key}. Unable to generate partial fix.`;
      }

      const formattedOldPolicy = shortOldPolicy.slice(0, 36);
      const randomSuffix = Math.random().toString(36).substring(2, 8);
      const policyName = `Lp4$${formattedOldPolicy}${randomSuffix}`.replace(
        /[^A-Za-z0-9+=,.@_-]/g,
        ""
      );

      const policyFileName = `/tmp/${policyName}.json`;

      return `# This command set removes the old policy (“${policyArn}”) 
# and creates a new least-privileged policy with just the used/unknown actions.

cat > ${policyFileName} <<EOF
${policyDoc}
EOF

aws iam create-policy \\
  --policy-name ${policyName} \\
  --policy-document file://${policyFileName}

aws iam ${attachCmd} \\
  ${nameParam} ${principal} \\
  --policy-arn arn:aws:iam::${scope.id}:policy/${policyName}

aws iam ${detachCmd} \\
  ${nameParam} ${principal} \\
  --policy-arn ${policyArn}
      `;
    },
  },
};

const gcloudPublicAccessFix = (data: AssessmentNodes["grant"]) => {
  // IAM conditions are not allowed on public principals - we don't have to add the --condition flag to the gcloud command
  const { resources } = data;
  const [resource] = resources;
  const { id, service } = frnToInfo(resource);
  if (!id) return { error: "Grant has no resource" };
  switch (service) {
    // https://cloud.google.com/sdk/gcloud/reference/storage/buckets/add-iam-policy-binding
    case "storage":
      return {
        command: "storage buckets",
        resource: `gs://${id}`,
      };
    default:
      return {
        error: `P0 does not support public access remediation of ${service} resources.`,
      };
  }
};

const publicAccessFixGenerator = (status: "disabled" | "enabled") => ({
  gcloud: {
    description: `${
      status === "disabled" ? "Re-enable" : "Disable"
    } public access by running these commands in Google Cloud Shell:`,
    type: "shell" as const,
    generate: (
      { data }: GrantNode,
      { scope }: { scope: ItemAssessmentScope }
    ) => {
      const { command, resource, error } = gcloudPublicAccessFix(data);
      if (error) return `# ${error}`;
      return `gcloud ${command} ${
        status === "disabled" ? "add" : "remove"
      }-iam-policy-binding '${resource}'\\
  --member='allUsers' \\
  --role='${data.privilegeSet}' \\
  --project=${scope.id}`;
    },
  },
});

const credentialFixGenerator = (status: "disabled" | "enabled") => ({
  gcloud: {
    description: `${
      status === "disabled" ? "Re-enable" : "Disable"
    } these service-account keys by running these commands in Google Cloud Shell:`,
    type: "shell" as const,
    generate: (
      node: CredentialNode,
      { scope }: { scope: ItemAssessmentScope }
    ) => {
      // Note that credential key here is full key resource locator, not just key id
      const [account, _, key] = node.key.split("/").slice(6);
      return `gcloud iam service-accounts keys ${
        status === "disabled" ? "enable" : "disable"
      } \\
  '${key}' \\
  --iam-account='${account}' \\
  --project=${scope.id}`;
    },
  },
});

const accountFixGenerator = (status: "disabled" | "enabled") => ({
  gcloud: {
    description: `${
      status === "disabled" ? "Re-enable" : "Disable"
    } these service accounts by running these commands in Google Cloud Shell.`,
    type: "shell" as const,
    generate: (node: Node<AssessmentNodes, "identity">) =>
      `gcloud iam service-accounts ${
        status === "disabled" ? "enable" : "disable"
      } '${node.key}'`,
  },
  aws: {
    description: `${
      status === "disabled" ? "Re-enable" : "Disable"
    } these IAM roles by running these commands in AWS Cloud Shell.`,
    type: "shell" as const,
    generate: (node: Node<AssessmentNodes, "identity">) =>
      `aws iam ${
        status === "disabled" ? "detach-role-policy" : "attach-role-policy"
      } --role-name ${
        node.data.label
      } --policy-arn arn:aws:iam::aws:policy/AWSDenyAll`,
  },
});

export const PresetMonitors = Object.freeze({
  "Unused Grants": Monitor({
    show: "grant",
    scopes: ["k8s", "workspace"],
    // nodes that cannot reach a used or unknown permission
    search: assessmentParse(
      'usage:type:"unused" ^usage:type:!"unused" ^grant=isProviderManaged:"true" identity=status:"active"'
    ),
    label: "Unused grant",
    priority: "HIGH",
    cta: "No privileges given by this grant have been used in the last 90 days. Consider removing this grant.",
    remediation: (action: string) =>
      `No privileges given by this grant have been used in the last 90 days. P0 has automatically ${action} to remove this grant.`,
    description:
      "Grants for which no privileges have been used in the last 90 days.",
    vcsRemediateSupported: true,
  }),
  "Unused GCP Role Bindings": Monitor({
    show: "grant",
    scopes: ["gcloud"],
    search: assessmentParse(
      'usage:type:"unused" ^usage:type:!"unused" ^grant=isProviderManaged:"true" identity=status:"active"'
    ),
    label: "Unused GCP role binding",
    priority: "HIGH",
    cta: "No privileges given by this role binding have been used in the last 90 days. Consider removing this role binding.",
    remediation: (action: string) =>
      `No privileges given by this grant have been used in the last 90 days. P0 has automatically ${action} to remove this grant.`,
    description:
      "Role binding for which no privileges have been used in the last 90 days.",
    vcsRemediateSupported: true,
    fix: unusedGrantFixGenerator,
  }),
  "Unused AWS Policies": Monitor({
    show: "grant",
    scopes: ["aws"],
    search: assessmentParse(
      'usage:type:"unused" ^usage:type:!"unused" ^grant=isProviderManaged:"true" identity=status:"active"'
    ),
    label: "Unused AWS policy",
    priority: "HIGH",
    cta: "No privileges given by this policy have been used in the last 90 days. Consider removing this policy.",
    remediation: (action: string) =>
      `No privileges given by this policy have been used in the last 90 days. P0 has automatically ${action} to remove this policy.`,
    description:
      "Policies for which no privileges have been used in the last 90 days.",
    fix: unusedGrantFixGenerator,
  }),
  "Unused User Accounts": Monitor({
    show: "identity",
    scopes: ["aws", "gcloud", "k8s", "workspace"],
    search: assessmentParse(
      'identity=type:"user"->credential:last90:"unused" ^credential:last90:"used"'
    ),
    label: "Unused user account",
    priority: "HIGH",
    cta: "This user has not logged in within the last 90 days. Consider removing this user from your directory.",
    remediation: (action: string) =>
      `This user has not logged in within the last 90 days. P0 has automatically ${action} to remove this user.`,
    description: "Users that have not logged in within the last 90 days.",
  }),
  "Unused Service Accounts": Monitor({
    scopes: ["gcloud"],
    show: "identity",
    search: assessmentParse(
      'identity=type:"service-account"->credential:last40:"unused" ^credential:last40:"used" identity=status:"active"'
    ),
    label: "Unused service account",
    priority: "HIGH",
    cta: "This service account has not performed any actions within the last 40 days. Consider disabling this service account.",
    remediation: (action: string) =>
      `This service account has not performed any actions within the last 40 days. P0 has automatically ${action} to disable this service account.`,
    description: "Service accounts that have been inactive for 40 days.",
    fix: accountFixGenerator("enabled"),
    revert: accountFixGenerator("disabled"),
  }),
  "Privileged Group Open to Public": Monitor({
    scopes: ["aws", "gcloud"], // TODO: directory-scoped
    show: "identity",
    search: assessmentParse(
      'identity=type:"group" identity=accessJoin:"public" grant:->risk:CRITICAL'
    ),
    label: "Privileged group open to public",
    priority: "CRITICAL",
    cta:
      "This group has privileged access to your project, but its membership is open to the public. " +
      "Anyone on the Internet can join this group and gain access to your resources. " +
      "Restrict join access to this group.",
    description:
      "This group has privileged access, but anyone on the Internet can join it",
  }),
  "Privileged Group Open to Organization": Monitor({
    scopes: ["aws", "gcloud"], // TODO: directory-scoped
    show: "identity",
    search: assessmentParse(
      'identity=type:"group" identity=accessJoin:"domain" grant:->risk:CRITICAL'
    ),
    label: "Privileged group open to organization",
    priority: "CRITICAL",
    cta:
      "This group has privileged access to your organization, but its membership is open to anyone in your organization. " +
      "Any member of your organization can join this group and gain access to your resources. " +
      "Restrict join access to this group.",
    description:
      "This group has privileged access, but anyone in your organization can join it",
  }),
  "Privileged External Service-Account Access": Monitor({
    scopes: ["all"],
    show: "grant",
    search: assessmentParse(
      'grant=principalType:"service-account" identity=external:true risk:"CRITICAL"'
    ),
    label: "Privileged external service-account access",
    priority: "HIGH",
    cta:
      "This service account has privileged access to your organization, but its authentication is controlled outside the " +
      "assessed environment. Review this access to ensure that it is required.",
    description:
      "Privileged grant to service accounts with externally managed authentication.",
  }),
  "Privileged Cross-Resource Service-Account Access": Monitor({
    scopes: ["all"],
    show: "grant",
    search: assessmentParse(
      '^grant=principalType:!"service-account" grant=cross:true ^identity=external:true risk:"CRITICAL"'
    ),
    label: "Privileged cross-resource service-account access",
    priority: "MEDIUM",
    cta:
      "This service account has privileged access to a resource outside its parent resource (viz., it is managed in a " +
      "different account, project, or subscription). Review this access to ensure that it is required.",
    description:
      "Privileged grant to service accounts on a different parent resource.",
  }),
  "Lateral Movement": Monitor({
    scopes: ["all"],
    show: "grant",
    search: assessmentParse('^grant=isProviderManaged:"true" lateral:'),
    label: "Lateral movement",
    priority: "MEDIUM",
    cta:
      "This grant allows its principal to perform actions as one or more identities. " +
      "Review this access to ensure that it is required.",
    description:
      "Grants that allow principals to perform actions as another identity.",
  }),
  "Unused Service-Account Keys (Last 40 days)": Monitor<
    AssessmentNodes,
    "credential"
  >({
    scopes: ["gcloud"],
    show: "credential",
    search: assessmentParse(
      'credential=enabledKey:"true" credential=last40:"unused" identity=type:"service-account" identity=status:"active"'
    ),
    label: "Unused service-account key (last 40 days) ",
    priority: "HIGH",
    cta: "This service-account key has have not been used in the last 40 days. Consider removing this key.",
    remediation: (action: string) =>
      `This service-account key has not been used in the last 40 days. P0 has automatically ${action} to disable this key.`,
    description:
      "Service-account keys that have not been used in the last 40 days.",
    fix: credentialFixGenerator("enabled"),
    revert: credentialFixGenerator("disabled"),
  }),
  "Unused Service-Account Keys (Last 365 days)": Monitor<
    AssessmentNodes,
    "credential"
  >({
    scopes: ["gcloud"],
    show: "credential",
    search: assessmentParse(
      'credential=enabledKey:"true" credential=last365:"unused" identity=type:"service-account" identity=status:"active"'
    ),
    label: "Unused service-account key (last 365 days)",
    priority: "HIGH",
    cta: "This service-account key has have not been used in the last 365 days. Consider removing this key.",
    remediation: (action: string) =>
      `This service-account key has not been used in the last 365 days. P0 has automatically ${action} to disable this key.`,
    description:
      "Service-account keys that have not been used in the last 365 days.",
    fix: credentialFixGenerator("enabled"),
    revert: credentialFixGenerator("disabled"),
  }),
  "Unused Roles": Monitor({
    scopes: ["aws"],
    show: "identity",
    search: assessmentParse(
      'identity=type:"role" ^credential:lastAuthnTime: identity=status:"active"'
    ),
    label: "Unused IAM roles",
    priority: "HIGH",
    cta: "This role has not been accessed in the last 400 days. Consider removing this role.",
    remediation: (action: string) =>
      `This role has not been accessed in the last 400 days. P0 has automatically ${action} to remove this role.`,
    description: "Roles that have not been used in the last 400 days.",
    fix: accountFixGenerator("enabled"),
    revert: accountFixGenerator("disabled"),
  }),
  "Stale Service-Account Key": Monitor({
    scopes: ["gcloud"],
    show: "credential",
    search: assessmentParse(
      'credential:stale90:"true"->identity=type:"service-account"'
    ),
    label: "Stale service-account key",
    priority: "HIGH",
    cta: "This service-account key has not been rotated in the last 90 days. Google recommends rotating keys every 90 days.",
    description:
      "Service-account keys that have not been rotated in the last 90 days.",
    management: {
      description:
        "P0 will automatically rotate your service account keys every 90 days",
      inputPrompt:
        "Please enter the Google Secret Manager resource name for the secret containing keys for this service account",
    },
  }),
  "Disabled MFA": Monitor({
    scopes: ["aws", "gcloud", "k8s", "workspace"],
    show: "identity",
    search: assessmentParse('identity=mfa:"disabled"'),
    label: "Disabled multi-factor authentication",
    priority: "HIGH",
    cta: "This user does not have multi-factor authentication enabled. Consider enforcing MFA for this user.",
    description: "Users with disabled multi-factor authentication.",
  }),
  "Excess Privileges": Monitor({
    scopes: ["k8s", "workspace"],
    show: "grant",
    // nodes that can reach an unused permission AND can reach a used or unknown permission
    search: assessmentParse(
      'usage:type:"unused" usage:type:!"unused" ^grant=isProviderManaged:"true"'
    ),
    label: "Excess privileges",
    priority: "MEDIUM",
    cta: "Some of this grant's privileges have not been used in the last 90 days. Consider replacing this grant with a least-privileged role.",
    description:
      "Grants with some used and some unused privileges over the last 90 days.",
    fix: partialGrantFixGenerator,
    // TODO: uncomment when implementing management
    // management: {
    //   description:
    //     "P0 will automatically resize your grants to be least-privileged based on current usage every 90 days.",
    // },
  }),
  "Excessive AWS Policies": Monitor({
    scopes: ["aws"],
    show: "grant",
    // nodes that can reach an unused permission AND can reach a used or unknown permission
    search: assessmentParse(
      'usage:type:"unused" usage:type:!"unused" ^grant=isProviderManaged:"true"'
    ),
    label: "Excessive AWS policies",
    priority: "MEDIUM",
    cta: "This grant has more privileges than are required for its intended use. Consider replacing this grant with a least-privileged role.",
    description:
      "Grants with more privileges than are required for their intended use.",
    fix: partialGrantFixGenerator,
  }),
  "Excessive GCP Role Bindings": Monitor({
    scopes: ["gcloud"],
    show: "grant",
    // nodes that can reach an unused permission AND can reach a used or unknown permission
    search: assessmentParse(
      'usage:type:"unused" usage:type:!"unused" ^grant=isProviderManaged:"true"'
    ),
    label: "Excessive GCP role bindings",
    priority: "MEDIUM",
    cta: "This role binding has more privileges than are required for its intended use. Consider replacing this role binding with a least-privileged role binding.",
    description:
      "Role bindings with more privileges than are required for their intended use.",
    fix: partialGrantFixGenerator,
  }),
  "Privileged Access": Monitor({
    scopes: ["k8s", "workspace"],
    show: "grant",
    search: assessmentParse('risk:"CRITICAL" ^grant=isProviderManaged:"true"'),
    label: "Privileged access",
    priority: "MEDIUM",
    cta: "This grant allows access with critical risks. Consider replacing this grant with a low-sensitivity grant, and using just-in-time access for ephemeral access.",
    description: "Grants with any privileges that allow sensitive access.",
  }),
  "Privileged GCP Role Bindings": Monitor({
    scopes: ["gcloud"],
    show: "grant",
    search: assessmentParse('risk:"CRITICAL" ^grant=isProviderManaged:"true"'),
    label: "Privileged GCP role bindings",
    priority: "MEDIUM",
    cta: "This role binding allows access with critical risks. Consider replacing this role binding with a low-sensitivity role binding, and using just-in-time access for ephemeral access.",
    description:
      "Role bindings with any privileges that allow sensitive access.",
  }),
  "Privileged AWS Policies": Monitor({
    scopes: ["aws"],
    show: "grant",
    search: assessmentParse('risk:"CRITICAL" ^grant=isProviderManaged:"true"'),
    label: "Privileged AWS policies",
    priority: "MEDIUM",
    cta: "This policy allows access with critical risks. Consider replacing this policy with a low-sensitivity policy, and using just-in-time access for ephemeral access.",
    description: "Policies with any privileges that allow sensitive access.",
  }),
  "Unused Privileged Access": Monitor({
    scopes: ["k8s", "workspace"],
    show: "grant",
    search: assessmentParse(
      'usage:type:"unused"->risk:"CRITICAL" ^grant=isProviderManaged:"true"'
    ),
    label: "Unused privileged access",
    priority: "CRITICAL",
    cta: "This grant conveys privileges that allow critical risks, and these privileges have not been used in the last 90 days. Consider replacing this grant with a role that does not include these privileges.",
    description: "Grants with unused privileges that allow sensitive access.",
    fix: partialGrantFixGenerator,
    // TODO: uncomment when implementing management
    // management: {
    //   description:
    //     "P0 will automatically remove unused risky privileges every 90 days.",
    // },
  }),
  "GCP Role Bindings with Unused Privileged Access": Monitor({
    scopes: ["gcloud"],
    show: "grant",
    search: assessmentParse(
      'usage:type:"unused"->risk:"CRITICAL" ^grant=isProviderManaged:"true"'
    ),
    label: "GCP role bindings with unused privileged access",
    priority: "CRITICAL",
    cta: "This role binding conveys privileges that allow critical risks, and these privileges have not been used in the last 90 days. Consider replacing this role binding with a role that does not include these privileges.",
    description:
      "Role bindings with unused privileges that allow sensitive access.",
    fix: partialGrantFixGenerator,
  }),
  "AWS Policies with Unused Privileged Access": Monitor({
    scopes: ["aws"],
    show: "grant",
    search: assessmentParse(
      'usage:type:"unused"->risk:"CRITICAL" ^grant=isProviderManaged:"true"'
    ),
    label: "AWS policies with unused privileged access",
    priority: "CRITICAL",
    cta: "This policy conveys privileges that allow critical risks, and these privileges have not been used in the last 90 days. Consider replacing this policy with a policy that does not include these privileges.",
    description: "Policies with unused privileges that allow sensitive access.",
    fix: partialGrantFixGenerator,
  }),
  "Public Access": Monitor({
    scopes: ["aws", "gcloud", "k8s", "workspace"],
    show: "grant",
    search: assessmentParse('resource: grant=principalType:"public"'),
    label: "Public access",
    priority: "CRITICAL",
    cta: "This grant allows everyone on the internet access. Validate that this is intentional, and remove this grant if it is not.",
    description: "Grants that allow resource access to anyone on the Internet.",
    fix: publicAccessFixGenerator("enabled"),
    revert: publicAccessFixGenerator("disabled"),
  }),
});

export const convertSavedMonitor = (
  savedMonitor: SavedMonitor
): Omit<SavedMonitor, "searchTerm"> & Monitor => {
  const { searchTerm, ...rest } = savedMonitor;

  return {
    cta: savedMonitor.description ?? "",
    search: assessmentParse(searchTerm),
    ...rest,
  };
};
