import { ResizableTableColumnType } from "components/ResizableTable";
import moment from "moment";
import { AnyNode } from "shared/types/assessment/data";
import { FindingState } from "shared/types/assessment/finding";

export const SinceColumn = (
  findingsState: FindingState,
  at: (node: AnyNode) => number | undefined
): ResizableTableColumnType<AnyNode> => ({
  title:
    findingsState === "open"
      ? "Since"
      : findingsState === "ignored"
      ? "Ignored at"
      : "Resolved at",
  sorter: (left, right) => (at(left) ?? 0) - (at(right) ?? 0),
  render: (_: any, data: AnyNode) => {
    const date = at(data);
    return date && moment(date).format("ll");
  },
  width: 100,
});
