import { ExportOutlined } from "@ant-design/icons";
import Link from "antd/lib/typography/Link";
import { GraphTooltip } from "components/GraphTooltip";
import { riskLink } from "shared/assessment/render";

import { Risk } from "../../../../shared/types/catalog";
import { Severity } from "../Severity";
import { LinkDetailsSpan, LinkDiv, LinkNameSpan } from "./LinkDiv";

/** Renders a link to a single risk in the IAM Risk Catalog
 *
 * If the risk is remediated, renders struck through.
 */
export const RiskLink: React.FC<{
  risk: Pick<Risk, "id" | "name" | "score">;
}> = ({ risk }) => (
  <LinkDiv>
    {risk?.name ? (
      <>
        <GraphTooltip title={risk.name}>
          <LinkNameSpan>{risk?.name}</LinkNameSpan>
        </GraphTooltip>
        <LinkDetailsSpan>
          (<Severity severity={risk.score.toLocaleLowerCase()} />
          )&nbsp;
          <Link href={riskLink(risk)} target="catalog">
            <ExportOutlined />
          </Link>
        </LinkDetailsSpan>
      </>
    ) : (
      <>(No known risk)</>
    )}
  </LinkDiv>
);
