import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { Export } from "components/Export";
import { ResizableTableColumnType } from "components/ResizableTable";
import { useContext, useMemo } from "react";
import { AggregatedNode } from "shared/graph/aggregate";
import {
  AnyAggregates,
  AnyNode,
  AssessmentNodes,
} from "shared/types/assessment/data";

export type ExportColumnType<N extends AnyNode> =
  ResizableTableColumnType<N> & {
    export?: {
      toTsvCell: (node: N) => string;
      toJsonObject: (node: N) => any;
    };
  };

export const isExportColumn = (
  column: ResizableTableColumnType<AnyNode>
): column is ExportColumnType<any> & {
  export: NonNullable<ExportColumnType<any>["export"]>;
} => "export" in column && !!column.export;

export const AssessmentExport = (props: {
  selected: AggregatedNode<
    AssessmentNodes,
    keyof AssessmentNodes,
    AnyAggregates
  >[];
  columns: ResizableTableColumnType<AnyNode>[];
}) => {
  const { columns, selected } = props;

  const { assessment } = useContext(SelectedEnvironmentContext);
  const { scopeKey } = useContext(ScopeContext);

  const exportable = useMemo(() => columns.filter(isExportColumn), [columns]);

  const toTsv = useMemo(
    () => async () => {
      const header = exportable.map((col) => col.key);
      const rows = selected.map((row) =>
        exportable.map((col) => col.export.toTsvCell(row) || "").join("\t")
      );
      return [header.join("\t"), ...rows].join("\n");
    },
    [exportable, selected]
  );

  const toJson = useMemo(
    () => async () =>
      JSON.stringify(
        selected.map((row) =>
          Object.fromEntries(
            exportable.map((col) => [col.key, col.export.toJsonObject(row)])
          )
        ),
        undefined,
        2
      ),
    [exportable, selected]
  );

  return (
    <Export
      data={selected}
      filename={[assessment.doc?.data.name, scopeKey].join(" ")}
      options={{
        json: {
          label: "JSON",
          blob: toJson,
          extension: "json",
        },
        tsv: { label: "TSV", blob: toTsv, extension: "tsv" },
      }}
      title="Export"
    />
  );
};
