import { Dictionary } from "lodash";

import { discover } from "../graph/discover";
import { DirectedGraph, isNode } from "../graph/types";
import { AssessmentScope } from "../types/assessment";
import {
  AssessmentNodes,
  NodeFor,
  TargetNodeType,
} from "../types/assessment/data";
import { assessmentParse } from "./issues/presets";

export type InventoryItem<N extends TargetNodeType> = {
  label: string;
  filter: (data: NodeFor<N>["data"]) => boolean;
  predicate?: (scopes: AssessmentScope[]) => boolean;
  show: N;
  where: string;
  tooltip?: string;
};

const InventoryItem = <N extends TargetNodeType>(link: InventoryItem<N>) =>
  link;

export const generateInventoryData = (targets: AssessmentScope[]) => {
  const isAws = targets.some((t) => t.integration === "aws");
  const isGcp = targets.some((t) => t.integration === "gcloud");
  const isWorkspace = targets.some((t) => t.integration === "workspace");

  // Since each item has the same "show" as query keytype, and every query term is a "first"
  // match, can achieve a "show only" behavior using a simple `{type}=...` query.
  //
  // If the "show" was different than the keytype, we would instead have to use the
  // `^{type}={attribute}:!"{keyword}"` pattern.
  return {
    credential: {
      _heading: InventoryItem({
        filter: () => true,
        label: "Credentials",
        show: "credential",
        where: "",
      }),
      key: InventoryItem({
        filter: ({ type }) => type === "key",
        label: "Keys",
        show: "credential",
        where: 'credential=type:"key"',
      }),
      federatedLogin: InventoryItem({
        filter: ({ type }) => type === "federated",
        label: "Federated Login",
        show: "credential",
        where: 'credential=type:"federated"',
      }),
      shortLived: InventoryItem({
        filter: ({ type }) => type === "short-lived",
        label: "Short-lived Login",
        show: "credential",
        where: 'credential=type:"short-lived"',
      }),
    },
    identity: {
      _heading: InventoryItem({
        filter: () => true,
        label: "Identities",
        show: "identity",
        where: "",
      }),
      user: InventoryItem({
        filter: ({ type }) => type === "user",
        label: "Users",
        show: "identity",
        where: 'identity=type:"user"',
      }),
      group: InventoryItem({
        filter: ({ type }) => type === "group",
        label: "Groups",
        show: "identity",
        where: 'identity=type:"group"',
      }),
      ...(isAws
        ? {
            awsIamRoles: InventoryItem({
              filter: ({ provider, type }) =>
                provider === "aws" && type === "aws-iam-role",
              label: "AWS IAM Roles",
              predicate: (scopes) =>
                scopes.some((s) => s.integration === "aws"),
              show: "identity",
              where: 'identity=type:"aws-iam-role" identity=provider:"aws"',
            }),
            federatedRoles: InventoryItem({
              filter: ({ provider, type }) =>
                provider === "aws" && type === "federated",
              label: "AWS Federated Access Roles",
              predicate: (scopes) =>
                scopes.some((s) => s.integration === "aws"),
              show: "identity",
              where: 'identity=type:"federated" identity=provider:"aws"',
              tooltip:
                "Roles whose trust relationship allow access via OIDC or SAML.",
            }),
            permissionSetRoles: InventoryItem({
              filter: ({ provider, type }) =>
                provider === "aws" && type === "aws-permission-set-role",
              label: "AWS Permission-Set Roles",
              predicate: (scopes) =>
                scopes.some((s) => s.integration === "aws"),
              show: "identity",
              where:
                'identity=type:"aws-permission-set-role" identity=provider:"aws"',
              tooltip: "Roles generated by assigning AWS permission sets.",
            }),
          }
        : {}),
      ...(isGcp
        ? {
            gcpServiceAccounts: InventoryItem({
              filter: ({ provider, type }) =>
                provider === "gcp" && type === "service-account",
              label: "GCP Service Accounts",
              predicate: (scopes) =>
                scopes.some((s) => s.integration === "gcloud"),
              show: "identity",
              where: 'identity=type:"service-account" identity=provider:"gcp"',
            }),
          }
        : {}),
      kubernetes: InventoryItem({
        filter: ({ provider }) => provider === "k8s",
        label: "Kubernetes Service Accounts",
        show: "identity",
        where: "identity=provider:k8s",
      }),
    },
    grant: {
      _heading: InventoryItem({
        filter: () => true,
        label: "Grants",
        show: "grant",
        where: "",
      }),
      ...(isWorkspace
        ? {
            adminRoleBinding: InventoryItem({
              filter: ({ provider }) => provider === "workspace",
              label: "Workspace Admin Role Bindings",
              predicate: (scopes) =>
                scopes.some((s) => s.integration === "workspace"),
              show: "grant",
              where: 'grant=provider:"workspace"',
            }),
          }
        : {}),
      ...(isAws
        ? {
            awsPolicyAssignemnt: InventoryItem({
              filter: ({ provider }) => provider === "aws",
              label: "AWS Policy Assignments",
              predicate: (scopes) =>
                scopes.some((s) => s.integration === "aws"),
              show: "grant",
              where: 'grant=provider:"aws"',
            }),
          }
        : {}),
      ...(isGcp
        ? {
            gcpRoleBinding: InventoryItem({
              filter: ({ provider }) => provider === "gcp",
              label: "GCP Role Bindings",
              predicate: (scopes) =>
                scopes.some((s) => s.integration === "gcloud"),
              show: "grant",
              where: 'grant=provider:"gcp"',
            }),
          }
        : {}),
    },
  };
};

const _validateInventoryData: (
  scopes: AssessmentScope[]
) => Dictionary<Dictionary<InventoryItem<any>>> = generateInventoryData;

export const countNodes = async (
  link: InventoryItem<any>,
  graph: DirectedGraph<AssessmentNodes>
) => await discover(graph, isNode(link.show), assessmentParse(link.where));
