import styled from "styled-components";

import { join } from "../../../../utils/join";
import { GraphTooltip } from "../../../GraphTooltip";
import { HasAddTerm, ShowHideTerm } from "./ShowHide";

type Props = {
  getInverseTerm?: (val: string) => string;
  getSearchTerm: (val: string) => string;
  inputMap: Record<string, any[] | number>;
  termName: string;
  typeOptions: readonly string[];
};

// 0000 takes 2.5em in most aggressive rendered font
const CountSpan = styled.div`
  display: inline-block;
  width: 3.2em;
`;

const renderCount = (count: number) => (count === 0 ? "" : String(count));

const SingleLineDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const JoinDiv = styled.div`
  padding-left: 0.5em;
  padding-right: 0.5em;
  color: darkgray;
`;
export const CountAggregate: React.FC<Props & HasAddTerm> = ({
  getInverseTerm,
  getSearchTerm,
  inputMap,
  onAddTerm,
  termName,
  terms,
  typeOptions,
}) => {
  if (!inputMap) inputMap = {};
  for (const option of typeOptions) {
    if (!inputMap[option]) {
      inputMap[option] = [];
    }
  }

  const extracted = typeOptions.map((t) => {
    const value = inputMap[t];
    const count = typeof value === "number" ? value : value.length;
    return [t, count] as const;
  });

  return (
    <SingleLineDiv>
      {join(
        extracted.map(([pt, p], ix) =>
          p ? (
            <GraphTooltip
              key={ix}
              title={
                <ShowHideTerm
                  inverse={getInverseTerm?.(pt)}
                  term={getSearchTerm(pt)}
                  name={termName}
                  onAddTerm={onAddTerm}
                  terms={terms}
                />
              }
            >
              <CountSpan key={ix}>{renderCount(p)}</CountSpan>
            </GraphTooltip>
          ) : (
            <CountSpan key={ix} />
          )
        ),
        (ix) => (
          <JoinDiv key={`slash-${ix}`}>/</JoinDiv>
        )
      )}
    </SingleLineDiv>
  );
};
