import { Descriptions, Divider, Space } from "antd";
import { Timestamp } from "firebase/firestore";
import { identity } from "lodash";
import { colors } from "styles/variables";

import { Expando } from "../../Assessment/components/Expando";
import { FirstPlus } from "../../FirstPlus";
import { formatDate } from "../restStateUtils";
import { SnowflakeAPIRunDocument } from "../types";
import { RunProgressStatus } from "./RunProgressStatus";

export const RunSummary: React.FC<{
  doc: SnowflakeAPIRunDocument;
  createdTimestamp: Timestamp;
}> = ({ doc, createdTimestamp }) => {
  return (
    <div data-testid="drift-run-summary">
      <Descriptions
        title="Snowflake Anti-Entropy"
        layout="vertical"
        styles={{
          label: { color: colors.neutral["40"], fontWeight: "bold" },
        }}
      >
        <Descriptions.Item label="Performed At">
          {formatDate(createdTimestamp)}
        </Descriptions.Item>
        <Descriptions.Item label="Run Stage">
          <Space direction="vertical">
            <RunProgressStatus doc={doc} />
          </Space>
        </Descriptions.Item>
      </Descriptions>
      {doc.status !== "ERRORED_ERRORED" &&
        ((doc.type === "CHECK" && doc.status !== "PROCESSING") ||
          doc.type === "ENFORCE") && (
          <>
            <Divider />
            <Descriptions
              title="Scope"
              layout="vertical"
              styles={{
                label: { color: colors.neutral["40"], fontWeight: "bold" },
              }}
            >
              <Descriptions.Item label="Databases">
                {doc.config.managedObjects.databases === "*" ? (
                  "All"
                ) : (
                  <Expando
                    width={400}
                    items={[...doc.config.managedObjects.databases]}
                    header={
                      <FirstPlus
                        items={doc.config.managedObjects.databases}
                        first={identity}
                      />
                    }
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Warehouses">
                {doc.config.managedObjects.warehouses === "*" ? (
                  "All"
                ) : (
                  <Expando
                    width={400}
                    items={[...doc.config.managedObjects.warehouses]}
                    header={
                      <FirstPlus
                        items={doc.config.managedObjects.warehouses}
                        first={identity}
                      />
                    }
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Roles">
                {doc.config.managedObjects.roles === "*" ? (
                  "All"
                ) : (
                  <Expando
                    width={400}
                    items={[...doc.config.managedObjects.roles]}
                    header={
                      <FirstPlus
                        items={doc.config.managedObjects.roles}
                        first={identity}
                      />
                    }
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Users">
                {doc.config.managedObjects.users.length === 0 ? (
                  "None"
                ) : (
                  <Expando
                    width={400}
                    items={[...doc.config.managedObjects.users]}
                    header={
                      <FirstPlus
                        items={doc.config.managedObjects.users}
                        first={identity}
                      />
                    }
                  />
                )}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
    </div>
  );
};
