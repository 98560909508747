import { green } from "@ant-design/colors";
import { UserOutlined } from "@ant-design/icons";
import { identityTypeToLabel } from "shared/assessment/constants";
import { ConnectedNode } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";

import { IdentityCell } from "../../cells/Identity";
import {
  DefaultNodeHeaderHeight,
  DefaultNodeOneLineBodyHeight,
  DefaultNodeWidth,
} from "./shared";

export const CustomIdentityNodeParameters = {
  type: "identity" as const,
  titleText: (node: ConnectedNode<AssessmentNodes, "identity">) => {
    const { type: identityType } = node.data;
    return identityTypeToLabel[identityType];
  },
  IconComponent: UserOutlined,
  bodyBorderColor: green[2],
  bodyBackgroundColor: green[1],
  bodyContent: (node: ConnectedNode<AssessmentNodes, "identity">) => (
    <IdentityCell identity={node.data} />
  ),
  bodySize: () => DefaultNodeOneLineBodyHeight,
  headerSize: () => DefaultNodeHeaderHeight,
  width: () => DefaultNodeWidth,
};
