import {
  Button,
  Card,
  Col,
  Flex,
  Popover,
  Row,
  Statistic,
  Typography,
} from "antd";
import { TenantAwareLink } from "components/common/TenantAwareLink";
import { format } from "date-fns";
import { capitalize, size } from "lodash";
import { AppPaths } from "shared/routes/constants";
import { colors } from "styles/variables";

import { useFindingMetrics } from "../hooks/useFindingMetrics";

const { Text } = Typography;

interface FindingsMetricsProps {
  allMonitors: Record<string, any>;
}

interface OldestFindingProps {
  oldestFinding: any;
  allMonitors: Record<string, any>;
}

const OldestFindingPopover: React.FC<OldestFindingProps> = ({
  oldestFinding,
  allMonitors,
}) => {
  if (!oldestFinding) return null;
  return (
    <div>
      <Text strong>Priority: </Text>
      <Text>{capitalize(allMonitors[oldestFinding.monitorId]?.priority)}</Text>
      <br />
      <Text strong>Monitor: </Text>
      <Text>{oldestFinding.monitorId}</Text>
      <br />
      <Text strong>Node Type: </Text>
      <Text>{oldestFinding.node.type}</Text>
      <br />
      <Text strong>Scope: </Text>
      <Text>{oldestFinding.scopeKey}</Text>
      <br />
      <Text strong>First Seen: </Text>
      <Text>{format(new Date(oldestFinding.firstSeen), "P")}</Text>
      <br />
      <Button style={{ marginTop: 8 }}>
        <TenantAwareLink
          to={`${AppPaths.Posture}/monitors/${encodeURIComponent(
            oldestFinding.monitorId
          )}`}
        >
          View in Monitors
        </TenantAwareLink>
      </Button>
    </div>
  );
};

interface NewFindingsProps {
  newestFindings: any[];
  allMonitors: Record<string, any>;
}

const NewFindingsPopover: React.FC<NewFindingsProps> = ({
  newestFindings,
  allMonitors,
}) => {
  if (!newestFindings.length) return null;
  return (
    <div>
      <ul style={{ paddingLeft: 16, marginTop: 8, marginBottom: 0 }}>
        {newestFindings.map((finding) => (
          <li key={finding.findingId} style={{ marginBottom: 8 }}>
            <Text strong>
              {capitalize(allMonitors[finding.monitorId]?.priority)}:
            </Text>{" "}
            <Text>{finding.monitorId}</Text>
            <div>
              <Text type="secondary" style={{ fontSize: 12 }}>
                {format(new Date(finding.firstSeen), "P")}
              </Text>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

interface UrgentFindingsProps {
  urgentFindings: number;
  highFindings: number;
}

const UrgentFindingsPopover: React.FC<UrgentFindingsProps> = ({
  urgentFindings,
  highFindings,
}) => {
  if (urgentFindings === 0 && highFindings === 0) return null;
  const total = urgentFindings + highFindings;
  const urgentPercent =
    total > 0 ? Math.round((urgentFindings / total) * 100) : 0;

  return (
    <div style={{ maxWidth: 300 }}>
      <Text>
        <Text strong>{urgentFindings}</Text> urgent and{" "}
        <Text strong>{highFindings}</Text> high priority findings
      </Text>
      <div style={{ marginTop: 8 }}>
        <Text>{urgentPercent}% of elevated priority findings are urgent</Text>
      </div>
    </div>
  );
};

interface AvgTimeProps {
  avgTimeOpenDays: number;
}

const AvgTimePopover: React.FC<AvgTimeProps> = ({ avgTimeOpenDays }) => {
  if (avgTimeOpenDays === 0) return null;
  return (
    <div style={{ maxWidth: 300 }}>
      <Text>
        Average age of open urgent and high findings is{" "}
        <Text strong>{avgTimeOpenDays}</Text> days.
      </Text>
    </div>
  );
};

export const FindingsMetrics: React.FC<FindingsMetricsProps> = ({
  allMonitors,
}) => {
  const {
    criticalFindings: urgentFindings,
    highFindings,
    oldestFindingAgeDays,
    avgTimeOpenDays,
    newCriticalHighThisMonth: newUrgentHighThisMonth,
    oldestFinding,
    newestFindings,
    hasMetrics,
    loading,
  } = useFindingMetrics(allMonitors);

  const hideOldestPopover = !oldestFinding;
  const hideNewFindingsPopover = size(newestFindings) === 0;
  const hideUrgentPopover = urgentFindings === 0 && highFindings === 0;
  const hideAvgTimePopover = avgTimeOpenDays === 0;

  const statisticTitleStyle: React.CSSProperties = {
    fontSize: "14px",
    color: colors.neutral[40],
    textAlign: "center",
    display: "block",
  };

  const statisticValueStyle: React.CSSProperties = {
    fontSize: "32px",
    fontWeight: "normal",
    textAlign: "center",
  };

  return (
    <Card style={{ marginBottom: 24 }} loading={loading}>
      {hasMetrics ? (
        <>
          <Row>
            <Col span={6} />
            <Col span={18}>
              <Text
                style={{
                  fontSize: "14px",
                  color: colors.neutral[40],
                  display: "block",
                  marginBottom: "16px",
                  paddingBottom: "16px",
                  borderBottom: `1px solid ${colors.neutral[20]}`,
                  textAlign: "center",
                }}
              >
                Open Urgent and High Findings
              </Text>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Popover
                content={
                  <UrgentFindingsPopover
                    urgentFindings={urgentFindings}
                    highFindings={highFindings}
                  />
                }
                title=""
                trigger="hover"
                placement="bottom"
                open={hideUrgentPopover ? false : undefined}
              >
                <Statistic
                  title={
                    <Text style={statisticTitleStyle}>Urgent Findings</Text>
                  }
                  value={urgentFindings}
                  valueStyle={{
                    ...statisticValueStyle,
                    color:
                      urgentFindings > 0
                        ? colors.themeColors.red
                        : colors.neutral[40],
                  }}
                />
              </Popover>
            </Col>
            <Col span={6}>
              <Popover
                content={
                  <NewFindingsPopover
                    newestFindings={newestFindings}
                    allMonitors={allMonitors}
                  />
                }
                title="5 Newest Findings"
                trigger="hover"
                placement="bottom"
                open={hideNewFindingsPopover ? false : undefined}
              >
                <Statistic
                  title={
                    <Text style={statisticTitleStyle}>New This Month</Text>
                  }
                  value={newUrgentHighThisMonth}
                  valueStyle={statisticValueStyle}
                />
              </Popover>
            </Col>
            <Col span={6}>
              <Popover
                content={
                  <OldestFindingPopover
                    oldestFinding={oldestFinding}
                    allMonitors={allMonitors}
                  />
                }
                title=""
                trigger="hover"
                placement="bottom"
                open={hideOldestPopover ? false : undefined}
              >
                <Statistic
                  title={
                    <Text style={statisticTitleStyle}>
                      Oldest Finding Age (days)
                    </Text>
                  }
                  value={oldestFindingAgeDays}
                  valueStyle={statisticValueStyle}
                />
              </Popover>
            </Col>
            <Col span={6}>
              <Popover
                content={<AvgTimePopover avgTimeOpenDays={avgTimeOpenDays} />}
                title=""
                trigger="hover"
                placement="bottom"
                open={hideAvgTimePopover ? false : undefined}
              >
                <Statistic
                  title={
                    <Text style={statisticTitleStyle}>
                      Avg Time Open (days)
                    </Text>
                  }
                  value={avgTimeOpenDays}
                  valueStyle={statisticValueStyle}
                />
              </Popover>
            </Col>
          </Row>
        </>
      ) : (
        <Flex justify="center" align="center">
          <Text>Metrics unavailable</Text>
        </Flex>
      )}
    </Card>
  );
};
