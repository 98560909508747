import { Typography } from "antd";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { useContext, useMemo } from "react";
import { identityTypeToLabel } from "shared/assessment/constants";
import { providerLabels } from "shared/assessment/render";
import { awsUrlFor } from "shared/integrations/resources/aws/constants";
import { AppPaths } from "shared/routes/constants";
import { ALL_SCOPE_SENTINEL, toScope } from "shared/types/assessment";
import { Identity, TargetNodeType } from "shared/types/assessment/data";
import { assertNever } from "utils/assert";

import { GraphTooltip } from "../../../GraphTooltip";
import { ConditionalAnchor } from "../ConditionalAnchor";
import { LinkWithEnvironment } from "../LinkWithEnvironment";
import { identityTypeToEmoji } from "../node/identity/shared";
import { HasAddTerm, ShowHideTerm } from "./ShowHide";

const { Paragraph, Title } = Typography;

/** An identity that be constructed from a consumer or a grant principal */
type CommonIdentity = Omit<Identity, "external">;

export const IdentityExternalLink: React.FC<{
  data: Pick<Identity, "isProviderManaged" | "label" | "provider" | "type">;
  id: string;
}> = ({ data, id }) => {
  const { scopeKey, integrationMeta } = useContext(ScopeContext);
  const idcId = integrationMeta?.idc?.id;
  const principalUrl = useMemo(
    () =>
      data.provider === "aws" && scopeKey !== ALL_SCOPE_SENTINEL && idcId
        ? awsUrlFor(data.type)?.({
            idcId: idcId,
            accountId: toScope(scopeKey).id,
            roleName: id,
          })
        : undefined,
    [data, id, idcId, scopeKey]
  );
  return (
    <ConditionalAnchor
      wrappedComponent={<IdentityCell identity={{ ...data }} />}
      url={principalUrl}
    />
  );
};

export const IdentityLink: React.FC<{
  data: CommonIdentity;
  dataKey?: string;
  type?: "long" | "short";
  show?: TargetNodeType;
}> = ({ data, dataKey, show, type }) => {
  const { scopeKey } = useContext(ScopeContext);
  const keyForLink = dataKey ?? data.label;
  return show === "grant" ? (
    <LinkWithEnvironment
      withTenant={true}
      to={`${AppPaths.Inventory}/identity/${encodeURIComponent(
        data.label
      )}?show=identity&where=${encodeURIComponent(
        `identity="${keyForLink}"`
      )}&scope=${encodeURIComponent(scopeKey)}`}
    >
      {type === "short" ? "view" : "View this identity in detail"}
    </LinkWithEnvironment>
  ) : show === "identity" ? (
    <LinkWithEnvironment
      withTenant={true}
      to={`${AppPaths.Inventory}?show=grant&where=${encodeURIComponent(
        `identity:"${keyForLink}"`
      )}&scope=${encodeURIComponent(scopeKey)}`}
    >
      {type === "short" ? "view" : "Explore all grants for this identity"}
    </LinkWithEnvironment>
  ) : null;
};

const IdentityInnerDisplay: React.FC<
  Partial<HasAddTerm> & {
    data: CommonIdentity;
    termPrefix?: string;
  }
> = ({ data, onAddTerm, show, terms }) => {
  const { provider } = useContext(ScopeContext);
  const [userName, domain] = data.label.split("@");
  const addProps =
    terms !== undefined && onAddTerm ? { onAddTerm, terms } : undefined;
  return provider ? (
    <div>
      {data.type === "service-account" ? (
        <>
          <Title level={4}>{userName}</Title>
          {domain && (
            <Paragraph style={{ fontSize: "small" }}>@{domain}</Paragraph>
          )}
        </>
      ) : data.type === "public" ? (
        <Title level={4}>Public access</Title>
      ) : (
        <Title level={4}>{data.label}</Title>
      )}
      <Paragraph>
        {identityTypeToEmoji[data.type]}&nbsp;
        {identityTypeToLabel[data.type]}
        {data.provider ? (
          <>
            &nbsp;in {providerLabels[data.provider]}
            {data.parent ? <>&nbsp;({data.parent})</> : null}
          </>
        ) : null}
      </Paragraph>
      {data.trustedPrincipals?.isConditional && (
        <Paragraph>
          ⚠️ This principal has a conditional trust policy. There may be
          additional constraints on who can access it.
        </Paragraph>
      )}
      {/* This is currently broken when principals come from multiple directories (only a random directory is shown)
       * {data.directory && <Paragraph>{directoryToLabel[data.directory]}</Paragraph>} */}
      {data.isProviderManaged ? (
        provider === "all" ||
        provider === "azure-ad" ||
        provider === "okta" ||
        provider === "workspace" ? (
          <Paragraph>⚠️ This is a service-managed identity.</Paragraph>
        ) : provider === "aws" ? (
          <Paragraph>⚠️ This is a service role.</Paragraph>
        ) : provider === "gcp" ? (
          <Paragraph>
            ⚠️ This is a Google-managed service account. Select &ldquo;Include
            Google-provided role grants&rdquo; in your IAM console to view the
            grant. It is not contained in your project, so access on the project
            does not provide access to this service account.
          </Paragraph>
        ) : provider === "k8s" ? (
          <Paragraph>
            ⚠️ This is a built-in Kubernetes system service account.
          </Paragraph>
        ) : (
          assertNever(provider)
        )
      ) : null}
      {data.disabled ? (
        provider === "all" ||
        provider === "azure-ad" ||
        provider === "okta" ||
        provider === "workspace" ? (
          <Paragraph>
            ⚠️ This identity is disabled. It cannot be used unless it is first
            enabled.
          </Paragraph>
        ) : provider === "aws" ? (
          <Paragraph>
            ⚠️ This role is disabled via the AWSDenyAll policy. It cannot be
            used unless this policy is removed.
          </Paragraph>
        ) : /* The disabled field is not used in k8s. You cannot disable service accounts. */
        provider === "k8s" ? null : provider === "gcp" ? (
          <Paragraph>
            ⚠️ This is a disabled service account. It cannot be used unless it
            is first enabled.
          </Paragraph>
        ) : (
          assertNever(provider)
        )
      ) : null}
      <Paragraph>
        <IdentityLink data={data} show={show} />
      </Paragraph>
      {addProps && (
        <>
          <ShowHideTerm
            term={`identity="${data.label}"`}
            name="identities"
            {...addProps}
          />
          <ShowHideTerm
            term={`identity=type:"${
              data.type === "service-account" && data.isProviderManaged
                ? "service-agent"
                : provider === "aws" && data.type === "service-account"
                ? "role"
                : data.type
            }"`}
            name="identity types"
            {...addProps}
          />
          {data.type === "service-account" && data.parent && (
            <ShowHideTerm
              term={`identity=parent:"${data.parent}"`}
              name="identity parents"
              {...addProps}
            />
          )}
        </>
      )}
    </div>
  ) : null;
};

export const identityCellValue = (identity: CommonIdentity) =>
  identity.type === "public" ? identityTypeToLabel.public : identity.label;

export const IdentityCell = ({
  identity,
  noHover,
  onAddTerm,
  show,
  terms,
  termPrefix,
}: Partial<HasAddTerm> & {
  identity: CommonIdentity;
  noHover?: boolean;
  termPrefix?: string;
}) => {
  const content = (
    <Typography.Text ellipsis>
      {/* TypeScript can't figure out correct typing :( */}
      {identityTypeToEmoji[identity.type]}
      &nbsp;
      {identityCellValue(identity)}
    </Typography.Text>
  );
  return noHover ? (
    content
  ) : (
    <GraphTooltip
      title={
        <IdentityInnerDisplay
          data={identity}
          onAddTerm={onAddTerm}
          show={show}
          terms={terms}
          termPrefix={termPrefix}
        />
      }
      width="400px"
    >
      {content}
    </GraphTooltip>
  );
};
