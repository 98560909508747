import {
  Edge as FlowEdge,
  Node as FlowNode,
  Position as FlowPosition,
  NodeMouseHandler,
  NodeProps,
  ReactFlow,
  ReactFlowInstance,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { Grid } from "antd";
import { mapValues, uniqBy } from "lodash";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { keyOf } from "shared/graph/graph";
import { ConnectedNode, NodeOf } from "shared/graph/types";

import { NodeDrawer } from "./node/NodeDrawer";

type Position = {
  /** Pixel x-coordinate of this node */
  x: number;
  /** Pixel y-coordinate of this node */
  y: number;
};

/**
 * CustomNodeProps includes all the parameters that React-Flow passes to custom node renderers
 * The node types passed directly to ReactFlow should be function components with props of this type
 */
export type CustomNodeProps<A extends object, K extends keyof A> = NodeProps<
  FlowNode<{ node: ConnectedNode<A, K>; spec: NodeFlowSpec<A> }, string>
>;

export type NodeFlowRenderer<A extends object> = {
  [K in keyof A]: {
    /* Large is the full-size node renderer */
    Large: React.FC<CustomNodeProps<A, K>>;
    /* Small is the minimized node renderer that shows up as part of an expanded collapsible node */
    Small: React.FC<CustomNodeProps<A, K>>;
  };
};

export type NodeFlowSpec<A extends object> = {
  inner: ConnectedNode<A, keyof A>;
  display: "inner" | "leaf" | "root";
  position: Position;
  height: number;
  width: number;
};

export type NodeFlowEdge<A extends object> = {
  parent: ConnectedNode<A, keyof A>;
  child: ConnectedNode<A, keyof A>;
};

export type NodePropertiesRenderer<A extends object> = (
  node: NodeOf<A>
) => ReactNode;

export type FitViewOptions = Partial<{
  minZoom: number;
  maxZoom: number;
  duration: number;
  padding: number;
}>;

const GRAPH_BACKGROUND =
  "radial-gradient(ellipse, transparent, #fafcff 70%), linear-gradient(130deg, transparent 75%, rgba(0, 135, 68, .26) 54%, rgba(0, 135, 68, .26) 60%, transparent 70%), linear-gradient(112deg, transparent 35%, rgba(145, 235, 0, .15) 43%, rgba(145, 235, 0, .15) 0, transparent 65%)";

export type GraphVisualizationInstance<A extends object> = {
  selectNode: (node: NodeOf<A> | undefined) => void;
};

/** Renders a graph of nodes as a network visualization */
export const GraphVisualization = <A extends object>(props: {
  describer: NodePropertiesRenderer<A>;
  renderer: NodeFlowRenderer<A>;
  nodes: NodeFlowSpec<A>[];
  edges: NodeFlowEdge<A>[];
  viewStyle?: React.CSSProperties;
  onInit?: (instance: GraphVisualizationInstance<A>) => void;
  fitView?: boolean;
  fitViewOptions?: FitViewOptions;
}) => {
  const {
    describer,
    renderer,
    nodes,
    edges,
    viewStyle,
    onInit,
    fitView,
    fitViewOptions,
  } = props;
  const breakpoints = Grid.useBreakpoint();
  const [selectedNode, setSelectedNode] = useState<NodeOf<A>>();
  const [flowInstance, setFlowInstance] = useState<ReactFlowInstance>();

  const toFlowNode = useMemo(() => {
    return (spec: NodeFlowSpec<A>): FlowNode => {
      const { inner, display, position, width, height } = spec;
      return {
        id: keyOf(inner),
        type: inner.type.toString(),
        data: {
          node: inner,
          spec,
        },
        position: {
          x: position.x,
          y: position.y,
        },
        sourcePosition:
          display === "leaf" ? FlowPosition.Left : FlowPosition.Right,
        targetPosition:
          display === "root" ? FlowPosition.Right : FlowPosition.Left,
        width: width,
        height: height,
      };
    };
  }, []);

  const flowNodes: FlowNode[] = useMemo(
    () => uniqBy(nodes.map(toFlowNode), "id"),
    [nodes, toFlowNode]
  );

  const flowEdges: FlowEdge[] = useMemo(
    () =>
      uniqBy(
        edges.map(({ child, parent }) => ({
          id: `${keyOf(parent)}-${keyOf(child)}`,
          source: keyOf(parent),
          target: keyOf(child),
        })),
        "id"
      ),
    [edges]
  );

  const instance = useMemo<GraphVisualizationInstance<A>>(
    () => ({
      selectNode: setSelectedNode,
    }),
    [setSelectedNode]
  );

  // React flow abandons edges on the graph on update
  // Force clear to ensure that zombie edges do not remain on search update
  useEffect(() => {
    flowInstance?.setEdges([]);
  }, [flowEdges, flowInstance]);

  useEffect(() => {
    onInit?.(instance);
  }, [instance, onInit]);

  const closeNodeDrawer = useCallback(() => setSelectedNode(undefined), []);
  const handleNodeClick: NodeMouseHandler<FlowNode> = useCallback(
    (_, node) => {
      const spec = nodes.find((n) => keyOf(n.inner) === node.id);
      if (spec) setSelectedNode?.(spec.inner);
    },
    [nodes, setSelectedNode]
  );

  const nodeTypes = useMemo(
    () => mapValues(renderer, (r) => r.Large),
    [renderer]
  );

  // The size of the parent containers depends on the breakpoints, and this component fills the entire parent container.
  // So if the breakpoints are not yet known, ReactFlow will mount before the parent container has been correctly sized.
  //
  // useBreakpoint() initially returns an empty object, and forces a second render to update the breakpoints right afterwards.
  // See useBreakpoint() implementation: https://github.com/ant-design/ant-design/blob/master/components/grid/hooks/useBreakpoint.tsx
  // i.e. it initially returns {}, but then in the second render returns { xs: true, md: true, lg: false, ... }
  //
  // This is not normally a problem, because after the second render, typical use cases will automatically use the updated breakpoints
  // to re-render components with the correct breakpoint information.
  //
  // However, React Flow measures the size of its parent container in order to determine the initial position of its viewport,
  // (See https://reactflow.dev/learn/troubleshooting#the-react-flow-parent-container-needs-a-width-and-a-height-to-render-the)
  // This occurs the first time the <ReactFlow /> component is rendered, and may cause ReactFlow to incorrectly calculate the initial viewport position,
  // leading to a suboptimal initial view of the graph.
  //
  // To fix this, we force a re-render of the component after the breakpoints have been updated.
  if (Object.entries(breakpoints).length === 0) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: "rgb(250, 252, 255)",
        backgroundImage: GRAPH_BACKGROUND,
        // Necessary for inline NodeDrawer; see https://4x.ant.design/components/drawer/
        position: "relative",
        height: viewStyle?.height ?? "100%",
        width: viewStyle?.width ?? "100%",
        ...viewStyle,
      }}
    >
      <ReactFlow
        edges={flowEdges}
        // Allows viewing very large identity graphs in one screen, anything smaller just
        // looks like noise
        minZoom={5e-2}
        nodes={flowNodes}
        nodesConnectable={false}
        onInit={setFlowInstance}
        fitView={fitView}
        fitViewOptions={fitViewOptions}
        onNodeClick={handleNodeClick}
        nodeTypes={nodeTypes}
      />
      {selectedNode?.type && selectedNode.type !== "_collapsed" ? (
        <NodeDrawer
          onClose={closeNodeDrawer}
          node={selectedNode}
          describer={describer}
        />
      ) : null}
    </div>
  );
};
