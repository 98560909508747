import styled from "styled-components";

export const LegacyButtonGroup = styled.div`
  display: flex;
  button {
    border-radius: 0px;
    :first-child:not(:last-child) {
      border-radius: 2px 0px 0px 2px;
    }
    :last-child:not(:first-child) {
      border-radius: 0px 2px 2px 0px;
    }
  }
`;
