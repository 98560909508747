import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { ErrorDisplay } from "components/Error";
import { useAuthFetch } from "components/Login/hook";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isArray, noop, sortBy } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { AssessmentMap } from "shared/assessment/issues/presets";
import { Representation, fromRepresentation } from "shared/graph/marshall";
import { addDigrams, addTypeBoost } from "shared/graph/search";
import { ConnectedNode, DirectedGraph } from "shared/graph/types";
import {
  ALL_SCOPE_SENTINEL,
  AssessmentScopeIntegration,
  ProviderOrAll,
  scopeToProvider,
  toKey,
  toScope,
} from "shared/types/assessment";
import { AssessmentNodes } from "shared/types/assessment/data";
import { YieldConfig, sleep } from "shared/util/sleep";
import { StringParam, UrlUpdateType, useQueryParam } from "use-query-params";

import { GraphProcessingStep } from "../components/GraphStep";

type CachedMetagraph = {
  assessmentId: string;
  jobId: string;
  graph: DirectedGraph<AssessmentNodes>;
};
const N_BOOST_PHASES = 2;

export const transformGraphForUi = async (
  graph: Representation<AssessmentNodes>,
  setStep: (step: GraphProcessingStep) => void,
  setPercentage: (percentage: number) => void,
  options?: YieldConfig<ConnectedNode<AssessmentNodes, keyof AssessmentNodes>>
) => {
  setStep("converting");
  // b.c. the graph processing operations are synchronous, we need to give React
  // an opportunity to render prior to continuing
  await sleep(1);

  const output = {
    nodes: sortBy(fromRepresentation(graph).nodes, (n) => n.key),
  };
  const onSleep = (phaseIndex: number) => (_: any, nodeIndex: number) => {
    const percentage =
      (phaseIndex + nodeIndex / output.nodes.length) / N_BOOST_PHASES;
    setPercentage(percentage);
  };

  const before = performance.now();
  setStep("boosting");
  await sleep(1);
  await addTypeBoost(output, { ...options, onSleep: onSleep(0) });
  await addDigrams(output, AssessmentMap, {
    ...options,
    onSleep: onSleep(1),
  });
  // eslint-disable-next-line no-console
  console.log(
    "Time to add search boost",
    (performance.now() - before).toFixed(1),
    "ms"
  );

  return output;
};

type ScopeContext = {
  graph: DirectedGraph<AssessmentNodes> | undefined;
  setGraph: (graph: DirectedGraph<AssessmentNodes> | undefined) => void;
  integration: AssessmentScopeIntegration | "all";
  // TODO: Remove AWS hardcoding
  integrationMeta?: { idc?: { id: string } };
  provider: ProviderOrAll;
  scopeKey: string;
  setScopeKey: (scope: string, updateType?: UrlUpdateType) => void;
  step: GraphProcessingStep;
  setStep: (step: GraphProcessingStep) => void;
  percentage: number;
  setPercentage: (percentage: number) => void;
  validScopeKeys: Set<string>;
};

export const EmptyDefaultGraph = Object.freeze({
  nodes: [],
});

export const ScopeContext = createContext<ScopeContext>({
  graph: undefined,
  setGraph: noop,
  integration: ALL_SCOPE_SENTINEL,
  provider: ALL_SCOPE_SENTINEL,
  scopeKey: ALL_SCOPE_SENTINEL,
  setScopeKey: noop,
  step: "loading" as const,
  percentage: 0,
  setPercentage: noop,
  setStep: noop,
  validScopeKeys: new Set(ALL_SCOPE_SENTINEL),
});

export const ScopeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { details, last } = useContext(SelectedEnvironmentContext);
  const assessmentId = details?.assessmentId;
  const flags = useFlags();
  const [error, setError] = useState<string>();
  const authFetch = useAuthFetch(setError);
  const [graph, setGraph] = useState<DirectedGraph<AssessmentNodes>>();
  // Cache metagraph to save large downloads / data processing.
  // Only the metagraph as we could consume too much memory with single-target
  // caches.
  const [metaGraph, setMetaGraph] = useState<CachedMetagraph>();
  const [lastGraphScopeKey, setLastGraphScopeKey] = useState("");
  const [step, setStep] = useState<GraphProcessingStep>("requested");
  const [percentage, setPercentage] = useState<number>(0);
  const [integrationMeta, setIntegrationMeta] = useState<{
    idc?: { id: string };
  }>();
  const [scopeKey, setScopeKey] = useQueryParam("scope", StringParam);

  const effectiveScopeKey = useMemo(
    () => scopeKey || ALL_SCOPE_SENTINEL,
    [scopeKey]
  );

  const onError = useCallback((error: any) => {
    console.error(error);
    setError(error);
  }, []);

  const validScopeKeys = useMemo(() => {
    const valid = new Set<string>([ALL_SCOPE_SENTINEL]);
    if (!isArray(last.doc?.data.scope)) return valid;
    for (const scope of last.doc?.data.scope ?? []) {
      valid.add(toKey(scope));
    }
    return valid;
  }, [last.doc?.data.scope]);

  // TODO: Move this somewhere aws-specific
  useGuardedEffect(
    (cancellation) => async () => {
      if (effectiveScopeKey === ALL_SCOPE_SENTINEL) return; // TODO: remove eventually when we can derive url for each individual account
      if (toScope(effectiveScopeKey).integration !== "aws") return;
      if (!flags.assessmentAwsRoleDeeplink) return;
      setIntegrationMeta(undefined);
      const response = await authFetch(
        `assessment/scope/${effectiveScopeKey}/integration-meta`,
        { method: "GET" }
      );
      if (!response) return;

      const data = (await response.json()) as {
        additionalContext: { idc?: { id: string } };
      };
      if (cancellation.isCancelled) return;
      setIntegrationMeta(data.additionalContext);
    },
    [
      assessmentId,
      authFetch,
      effectiveScopeKey,
      flags.assessmentAwsRoleDeeplink,
      last.doc,
      scopeKey,
    ],
    onError
  );

  useGuardedEffect(
    (cancellation) => async () => {
      if (!assessmentId || !last.doc) return;
      if (last.doc.data.assessmentId !== assessmentId) {
        setGraph(undefined);
        setStep("requested");
        return;
      }

      setLastGraphScopeKey(effectiveScopeKey);
      setGraph(undefined);

      // If scope is all and there's an up-to-date cached metagraph, use that
      if (
        metaGraph?.assessmentId === assessmentId &&
        metaGraph?.jobId === last.doc?.id &&
        effectiveScopeKey === ALL_SCOPE_SENTINEL
      ) {
        setGraph(metaGraph.graph);
        setStep("aggregating");
        return;
      }

      cancellation.onCancel = () => {
        setLastGraphScopeKey(lastGraphScopeKey);
        setGraph(graph);
        setStep(step);
      };

      setStep("requested");
      const before = performance.now();
      const response = await authFetch(
        `assessment/${assessmentId}/job/${
          last.doc.id
        }/scope/${encodeURIComponent(effectiveScopeKey)}`,
        { method: "GET" }
      );

      // eslint-disable-next-line no-console
      console.log(
        "Initiated graph stream",
        (performance.now() - before).toFixed(1),
        "ms"
      );

      if (!response || cancellation.isCancelled) return;

      setStep("loading");
      const data = (await response.json()) as Representation<AssessmentNodes>;
      // eslint-disable-next-line no-console
      console.log(
        "Received graph stream",
        (performance.now() - before).toFixed(1),
        "ms"
      );
      if (cancellation.isCancelled) {
        // eslint-disable-next-line no-console
        console.log("Graph stream cancelled");
        return;
      }

      const beforeTransform = performance.now();
      const newGraph = data
        ? await transformGraphForUi(
            data,
            cancellation.guard(setStep),
            cancellation.guard(setPercentage),
            { cancellation, maxOccupancyMs: 100 }
          )
        : undefined;

      // eslint-disable-next-line no-console
      console.log(
        "Time to transform graph",
        (performance.now() - beforeTransform).toFixed(1),
        "ms"
      );

      // Cache the metagraph for future recall
      if (effectiveScopeKey === ALL_SCOPE_SENTINEL && newGraph) {
        setMetaGraph({ assessmentId, jobId: last.doc.id, graph: newGraph });
      }

      setGraph(newGraph);
      setStep("aggregating");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- previous values ('graph', 'lastGraphScopeKey', and 'step') in dependencies lead to infinite render loop. We set these again inside the hook.
    [
      assessmentId,
      authFetch,
      effectiveScopeKey,
      last.doc,
      metaGraph?.assessmentId,
      metaGraph?.jobId,
    ],

    onError
  );

  const integration =
    effectiveScopeKey === ALL_SCOPE_SENTINEL
      ? effectiveScopeKey
      : toScope(effectiveScopeKey).integration;

  const value: ScopeContext = useMemo(
    () => ({
      graph,
      setGraph,
      integration,
      integrationMeta,
      provider: scopeToProvider(integration),
      scopeKey: effectiveScopeKey,
      setScopeKey,
      step,
      setStep,
      percentage,
      setPercentage,
      validScopeKeys,
    }),
    [
      effectiveScopeKey,
      graph,
      integration,
      integrationMeta,
      percentage,
      setGraph,
      setPercentage,
      setScopeKey,
      setStep,
      step,
      validScopeKeys,
    ]
  );

  return (
    <ScopeContext.Provider value={value}>
      {error && (
        <ErrorDisplay title="Error loading environment" error={String(error)} />
      )}
      {children}
    </ScopeContext.Provider>
  );
};
