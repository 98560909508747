import { Typography } from "antd";
import Link from "antd/lib/typography/Link";
import { FrontendInstaller } from "install/types";
import { otherInstalled } from "shared/install/options";
import { OktaComponents } from "shared/integrations/directories/okta/components";
import { OktaIntegration } from "shared/integrations/directories/okta/types";

import { CommandDisplay } from "../CommandDisplay";
import { OKTA_CUSTOM_ROLE, OKTA_RESOURCE_SET } from "./constants";
import { oktaTerraform } from "./iac";

const listingDomains = otherInstalled<OktaIntegration>("listing");

export const oktaInstaller: FrontendInstaller<typeof OktaComponents> = {
  listing: {
    labeler: () => "Enter your Okta domain",
    instructions: (_context, id, item) => ({
      help: (
        <div>
          <Typography.Paragraph>
            To use P0 with Okta, start by creating an Okta Service App.
          </Typography.Paragraph>
        </div>
      ),
      commands: {
        console: (
          <div>
            <Typography.Paragraph>
              To install manually, follow the{" "}
              <Link
                href="https://developer.okta.com/docs/guides/implement-oauth-for-okta-serviceapp/main/"
                target="okta-docs"
                rel="noopener"
              >
                Okta Service App documentation
              </Link>
            </Typography.Paragraph>
            <Typography.Paragraph>
              Use the following public key:
            </Typography.Paragraph>
            <CommandDisplay
              commands={
                item.publicKey
                  ? JSON.stringify(JSON.parse(item.publicKey), undefined, 2)
                  : ""
              }
              maxRows={15}
            />
            <Typography.Paragraph>
              Then follow these steps:
            </Typography.Paragraph>
            <ul>
              <li>
                Uncheck the checkbox requiring &quot;Proof of possession&quot;.
              </li>
              <li>
                Add the <Typography.Text code>okta.users.read</Typography.Text>
                and <Typography.Text code>okta.groups.manage</Typography.Text>
                scopes to your P0 integration app. The groups management scope
                is required by Okta for listing all groups. The custom admin
                role below restricts the scope to view-only.
              </li>
              <li>
                Create a custom resource set{" "}
                <Typography.Text code>{OKTA_RESOURCE_SET}</Typography.Text> with
                all users and all groups
              </li>
              <li>
                Create a custom admin role{" "}
                <Typography.Text code>{OKTA_CUSTOM_ROLE}</Typography.Text> with
                permissions &quot;View users and their details&quot; and
                &quot;View groups and their details&quot;
              </li>
              <li>
                Assign the{" "}
                <Typography.Text code>{OKTA_CUSTOM_ROLE}</Typography.Text>{" "}
                custom admin role and{" "}
                <Typography.Text code>{OKTA_RESOURCE_SET}</Typography.Text>{" "}
                resource set to your P0 integration app.
              </li>
            </ul>
          </div>
        ),
        iac: [{ command: oktaTerraform.listing(id, item) }],
      },
    }),
    items: {},
  },
  "group-assignment": {
    instructions: () => ({
      help: (
        <Typography.Paragraph>
          To use Okta for group assignment, add the &quot;Group Membership
          Administrator&quot; role to your P0 integration app:
        </Typography.Paragraph>
      ),
      commands: {
        console: (
          <Typography.Paragraph>
            Follow these steps:
            <ol>
              <li>
                Navigate to your P0 integration app&apos;s configuration page.
              </li>
              <li>Select the &quot;Admin roles&quot; tab.</li>
              <li>Click &quot;Edit assignments&quot;.</li>
              <li>Click &quot;Add assignment&quot;.</li>
              <li>Choose &quot;Group Membership Administrator&quot;.</li>
            </ol>
          </Typography.Paragraph>
        ),
        iac: [{ command: oktaTerraform["group-assignment"] }],
      },
    }),
    optionProvider: async (context) => listingDomains(context),
    prerequisiteMessages: async (context) => {
      if (!listingDomains(context).length)
        return <div>Requires installed &quot;Listing&quot; component</div>;
    },
  },
};
