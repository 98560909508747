import { GraphTooltip } from "components/GraphTooltip";
import { VerticalDiv } from "components/divs";
import { mapValues } from "lodash";
import pluralize from "pluralize";
import { DefaultTitles } from "shared/assessment/constants";
import { isConnectedNode } from "shared/graph/types";
import {
  ALL_SCOPE_SENTINEL,
  AssessmentScopeIntegration,
  toScope,
} from "shared/types/assessment";
import { GrantNode } from "shared/types/assessment/data";

import { ExportColumnType } from "../../AssessmentExport";
import { ConditionDisplay } from "../../cells/Condition";
import { IdentityCell } from "../../cells/Identity";
import { PrivilegeAggregateWithWarnings } from "../../cells/PermissionAggregate";
import { PrivilegeSet } from "../../cells/PermissionSet";
import { PermissionTitle } from "../../cells/PermissionTitle";
import { Resource } from "../../cells/Resource";
import { RiskAggregate, riskSorter } from "../../cells/RiskAggregate";
import { ShowHideTerm } from "../../cells/ShowHide";
import { genericExport } from "../../export";
import { stringSorter } from "../../sort";
import { AssessmentColumnProps } from "../columns";
import { providerTitleElements } from "./shared";

/** Integrations for which "condition"s apply to grants */
const ConditionDisplayIntegrations: AssessmentScopeIntegration[] = ["gcloud"];

export const grantColumns = (
  props: AssessmentColumnProps,
  scopeKey: string
): ExportColumnType<GrantNode>[] => [
  {
    key: "principal",
    title: "Principal",
    ellipsis: true,
    sorter: (left, right) =>
      stringSorter(left.data.principal, right.data.principal),
    render: (_, node) => {
      // Use the parent node if possible so that "parent" data appears in
      // principal display.
      const principal = node.parents.find(isConnectedNode("identity"))
        ?.data ?? {
        ...node.data,
        label: node.data.principal,
        type: node.data.principalType,
      };
      return (
        <IdentityCell identity={principal} termPrefix="grant:" {...props} />
      );
    },
    export: genericExport((node) => node.data.principal),
    width: 200,
  },
  {
    key: "privilegeSet",
    title: providerTitleElements[props.provider]["privilegeSet"],
    ellipsis: true,
    sorter: (left, right) =>
      stringSorter(left.data.privilegeSet ?? "", right.data.privilegeSet ?? ""),
    render: (_, { data }) => (
      <PrivilegeSet
        privilegeSet={data.privilegeSet}
        {...props}
        provider={data.provider ?? ALL_SCOPE_SENTINEL}
      />
    ),
    export: genericExport((node) => node.data.privilegeSet),
    width: 200,
  },
  {
    key: "resource",
    title: DefaultTitles.resource,
    ellipsis: true,
    sorter: (left, right) =>
      stringSorter(left.data.resources[0], right.data.resources[0]),
    render: (_, node) => {
      if (!node.data.resources?.length) return null;
      return node.data.resources.length <= 2 ? (
        <VerticalDiv>
          {node.data.resources.map((r, ix) => (
            <Resource resource={node} key={node.key} index={ix} {...props} />
          ))}
        </VerticalDiv>
      ) : (
        <GraphTooltip
          title={node.data.resources.map((r, ix) => (
            <div style={{ maxHeight: "400px", overflowY: "auto" }} key={ix}>
              <Resource resource={node} index={ix} {...props} />
            </div>
          ))}
        >
          <Resource resource={node} index={0} {...props} />
          <div>and {node.data.resources.length - 1} more</div>
        </GraphTooltip>
      );
    },
    export: genericExport((node) => node.data.resources?.join(", ")),
    width: 200,
  },
  ...(scopeKey === ALL_SCOPE_SENTINEL ||
  !ConditionDisplayIntegrations.includes(toScope(scopeKey).integration)
    ? []
    : [
        {
          key: "condition",
          title: DefaultTitles.condition,
          sorter: ({ data: { condition: l } }, { data: { condition: r } }) => {
            return stringSorter(
              typeof l === "string" ? l : l?.title ?? l?.expression ?? "",
              typeof r === "string" ? r : r?.title ?? r?.expression ?? ""
            );
          },
          ellipsis: true,
          render: (_, { data: { condition } }) => {
            if (!condition) return null;
            if (typeof condition === "string") return condition;
            return (
              <GraphTooltip
                title={
                  <div style={{ maxWidth: "400px" }}>
                    <ConditionDisplay condition={condition} />
                    <ShowHideTerm
                      term={`condition:"${
                        condition.title ?? condition.expression
                      }"`}
                      name="conditions"
                      {...props}
                    />
                  </div>
                }
                width="400px"
              >
                {condition.title}
              </GraphTooltip>
            );
          },
          export: genericExport(({ data }) =>
            typeof data.condition === "string"
              ? data.condition
              : data.condition?.expression
          ),
          width: 200,
        } as ExportColumnType<GrantNode>,
      ]),
  {
    key: "risk",
    title: pluralize.plural(DefaultTitles.risk),
    sorter: (left, right) =>
      riskSorter(left.aggregates.risks, right.aggregates.risks),
    render: (_, node) => <RiskAggregate risks={node.aggregates.risks} />,
    export: genericExport((node) => node.aggregates.risks),
    width: 100,
    minWidth: 90,
  },
  {
    key: "privileges",
    title: (
      <PermissionTitle
        title={providerTitleElements[props.provider]["privileges"]}
      />
    ),
    render: (_, node) => (
      <PrivilegeAggregateWithWarnings node={node} {...props} />
    ),
    export: genericExport(({ aggregates: { privileges } }) =>
      mapValues(privileges, (p) => p.map((n) => n.key))
    ),
    width: 200,
  },
];
