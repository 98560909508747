import { createContext, useCallback, useMemo, useState } from "react";
import { NodeOf } from "shared/graph/types";
import { ALL_SCOPE_SENTINEL, ProviderOrAll } from "shared/types/assessment";
import { AssessmentNodes, NodeFor } from "shared/types/assessment/data";

import { GraphVisualizationInstance } from "../components/GraphVisualization";

/**
 * RendererParams and CustomNodeRendererOptions are parameters that can affect how nodes get rendered,
 * but at a more global scope (i.e. whether the particular graph being shown is an inventory graph,
 * a posture graph, or a findings drawer graph)
 */
export type CustomNodeRendererOptions = {
  detailed?: boolean;
  hideThis?: boolean;
  noHover?: boolean;
};

export type RendererParams = {
  currentLocaleNode: NodeFor<keyof AssessmentNodes> | undefined;
  provider: ProviderOrAll;
  options?: CustomNodeRendererOptions;
};

export type GraphContext = {
  rendererParams: RendererParams;
  open: Record<string, boolean>;
  toggleOpen: (key: string) => void;
  graphVisualizationInstance?: GraphVisualizationInstance<any>;
  setGraphVisualizationInstance: React.Dispatch<
    React.SetStateAction<GraphVisualizationInstance<any> | undefined>
  >;
};

/**
 * GraphContext is a React context that holds the parameters that affect how nodes get rendered.
 * It also can contain an instance of the GraphVisualization component, which can be used to
 * interact with the graph visualization imperatively. This context is used by custom nodes
 */
export const GraphContext = createContext<GraphContext>({
  rendererParams: {
    currentLocaleNode: undefined,
    provider: ALL_SCOPE_SENTINEL,
  },
  open: {},
  toggleOpen: () => {},
  setGraphVisualizationInstance: () => {},
});

export const GraphContextProvider: React.FC<
  React.PropsWithChildren & { rendererParams: RendererParams }
> = ({ children, rendererParams }) => {
  const [open, setOpen] = useState<Record<string, boolean>>({});
  const [graphVisualizationInstance, setGraphVisualizationInstance] = useState<{
    selectNode: <A extends object>(node: NodeOf<A> | undefined) => void;
  }>();
  const toggleOpen = useCallback((key: string) => {
    setOpen((prev) => ({ ...prev, [key]: !prev[key] }));
  }, []);

  const value = useMemo(
    () => ({
      rendererParams,
      open,
      toggleOpen,
      graphVisualizationInstance,
      setGraphVisualizationInstance,
    }),
    [
      rendererParams,
      open,
      toggleOpen,
      graphVisualizationInstance,
      setGraphVisualizationInstance,
    ]
  );
  return (
    <GraphContext.Provider value={value}>{children}</GraphContext.Provider>
  );
};
