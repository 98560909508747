import { MonitorPriorityCountsWithBins } from "../types";

export const mockMonitorsByPriority: MonitorPriorityCountsWithBins = {
  bins: [
    1738569600000, 1738310400000, 1737619200000, 1738224000000, 1736323200000,
    1736755200000, 1737014400000, 1737100800000, 1737360000000, 1735545600000,
    1735632000000, 1735804800000, 1736150400000,
  ],
  CRITICAL: {
    totalCount: 48,
    byTimestamp: [48, 50, 50, 50, 50, 50, 50, 50, 50, 60, 60, 60, 150],
  },
  HIGH: {
    totalCount: 133,
    byTimestamp: [
      133, 133, 133, 133, 200, 200, 200, 200, 200, 200, 200, 200, 200,
    ],
  },
  MEDIUM: {
    totalCount: 309,
    byTimestamp: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  LOW: {
    totalCount: 0,
    byTimestamp: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
};
