import CustomDatePicker from "components/DatePicker";
import { defaultRangePresets } from "constants/RangePickerConstants";
import moment, { Moment } from "moment";
import { useCallback, useContext } from "react";

import { FindingsContext } from "../contexts/FindingsContext";

export const FindingsDatePicker: React.FC = () => {
  const { range, setRange } = useContext(FindingsContext);
  const disabledRange = useCallback(
    (date?: Moment) =>
      !!date &&
      (date.isBefore(moment().startOf("day").subtract(30, "day")) ||
        date.isAfter(moment().endOf("day"))),
    []
  );

  const onUpdateRange = useCallback(
    (range: [Moment | null, Moment | null] | null) => {
      if (!range) setRange([undefined, undefined]);
      else {
        const [start, end] = range;
        setRange([start?.toDate(), end?.toDate()]);
      }
    },
    [setRange]
  );

  return (
    <CustomDatePicker.RangePicker
      allowEmpty={[true, true]}
      disabledDate={disabledRange}
      presets={defaultRangePresets({ includeTime: false })}
      value={[
        range[0] ? moment(range[0]) : null,
        range[1] ? moment(range[1]) : null,
      ]}
      onChange={onUpdateRange}
    />
  );
};
