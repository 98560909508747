import { CloseCircleOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Form,
  InputNumber,
  List,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
  message,
} from "antd";
import { useAuthFetch } from "components/Login/hook";
import { AuthzButton } from "components/common/AuthzButton";
import { useCallback, useContext, useMemo, useState } from "react";
import {
  sortApprovalOptions,
  timeUnitLabelOptions,
  timeUnitLabels,
} from "shared/permission-requests/util";
import { handleErrorWithToastMessage } from "utils/error";

import { useFirestoreDoc } from "../../providers/FirestoreProvider";
import {
  DEFAULT_WORKFLOW_CONFIG,
  WORKFLOW_CONFIGURATION_COLLECTION,
} from "../../shared/configuration/constant";
import {
  DurationOption,
  WorkflowConfiguration,
} from "../../shared/configuration/types";
import { Tenant } from "../Login";

const style: React.CSSProperties = { padding: "8px 0", flex: "1  1  0px" };

export const CustomExpiryOptions: React.FC = () => {
  const tenantId = useContext(Tenant);

  const optionsDoc = useFirestoreDoc<WorkflowConfiguration>(
    `o/${tenantId}/${WORKFLOW_CONFIGURATION_COLLECTION}/latest`,
    {
      live: true,
    }
  );

  const options = useMemo(
    () =>
      optionsDoc.doc?.data.options.sort(sortApprovalOptions) ||
      DEFAULT_WORKFLOW_CONFIG.options,
    [optionsDoc.doc]
  );

  const authFetch = useAuthFetch(handleErrorWithToastMessage);
  const [time, setTime] = useState<number | null>(1);
  const [timeUnit, setTimeUnit] = useState<keyof typeof timeUnitLabels>("m");

  const updateOptions = useCallback(
    async (method: "DELETE" | "POST" | "PUT", path: string) =>
      authFetch(`settings/expiry-option${path}`, {
        method,
        headers: { "Content-Type": "application/json" },
      }),
    [authFetch]
  );

  const handleAddOption = useCallback(async () => {
    if (time && timeUnit) {
      const response = await updateOptions(
        "PUT",
        `/unit/${timeUnit}/time/${time}`
      );
      if (!response) return;
      message.success("Option added");
    }
  }, [time, timeUnit, updateOptions]);

  const handleRemove = useCallback(
    async (reqOption: DurationOption) => {
      const response = await updateOptions(
        "DELETE",
        `/unit/${reqOption.unit}/time/${reqOption.time}`
      );
      if (!response) return;
      message.success("Option removed");
    },
    [updateOptions]
  );

  const handleReset = useCallback(async () => {
    const response = await updateOptions("POST", "/reset");
    if (!response) return;
    message.success("Options reset");
  }, [updateOptions]);

  const renderExpiryOption = useCallback(
    (item: DurationOption) => (
      <List.Item>
        <div>{item.value}</div>
        <div style={{ float: "right" }}>
          <Popconfirm
            placement="left"
            title="Delete Option"
            // Already memoized
            // eslint-disable-next-line react/jsx-no-bind
            onConfirm={() => handleRemove(item)}
            okText="Yes"
            cancelText="No"
          >
            <CloseCircleOutlined />
          </Popconfirm>
        </div>
      </List.Item>
    ),
    [handleRemove]
  );

  return (
    <div>
      <Typography.Title level={4}>Expiry Options</Typography.Title>
      <div>
        <Typography.Text type="secondary">
          Manage access duration options displayed for approvers.
          <br />
          Note, Approvers may always enter a custom time manually.
        </Typography.Text>
        <br />
        {optionsDoc.loading ? (
          <Skeleton.Input active block={true} />
        ) : (
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" xs={24} md={12}>
              <div style={style}>
                <List
                  size="small"
                  header={<div>Options</div>}
                  bordered
                  dataSource={options}
                  renderItem={renderExpiryOption}
                />
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <Form
                  name="wrap"
                  labelCol={{ flex: "110px" }}
                  labelAlign="left"
                  labelWrap
                  wrapperCol={{ flex: 1 }}
                  colon={false}
                  style={{ maxWidth: 700 }}
                >
                  <Space>
                    <Space.Compact>
                      <InputNumber min={1} value={time} onChange={setTime} />
                      <Select
                        options={timeUnitLabelOptions}
                        value={timeUnit}
                        onChange={setTimeUnit}
                        style={{ width: 97 }}
                      />
                    </Space.Compact>
                    <Divider />

                    <AuthzButton
                      roles={["owner"]}
                      type="primary"
                      onClick={handleAddOption}
                    >
                      Add Option
                    </AuthzButton>
                  </Space>
                </Form>
                <p>
                  <Popconfirm
                    placement="bottom"
                    title="Reset Options"
                    onConfirm={handleReset}
                    okText="Yes"
                    cancelText="No"
                  >
                    <AuthzButton roles={["owner"]} type="default">
                      Reset to default options
                    </AuthzButton>
                  </Popconfirm>
                </p>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};
