import Paragraph from "antd/lib/typography/Paragraph";
import { PROVISION_USER_ACCESS_DOCUMENT } from "shared/integrations/resources/aws/constants";
import { isAwsId } from "shared/integrations/resources/ssh/util";

import { SudoSshInstaller } from "../types";

const SUDO_SSH_YAML_DOCUMENT = (
  isEscapedForShell: string
) => `schemaVersion: "2.2"
description: "Grant/revoke password-less sudo access, add/remove an authorized ssh key, or create a user"
parameters:
  UserName:
    type: "String"
    description: "User name"
    allowedPattern: "^[a-z][-a-z0-9_]*$"
  Action:
    type: "String"
    description: "'grant' or 'revoke'"
    allowedValues:
    - grant
    - revoke
  RequestId:
    type: "String"
    description: "P0 access request identifier"
    allowedPattern: "^[a-zA-Z0-9]*$"
  PublicKey:
    type: "String"
    description: "SSH public key"
    allowedPattern: "^[^'\\n]*$"
    default: "N/A"
  Sudo:
    type: "String"
    description: "Whether to grant sudo access"
    allowedValues:
    - "false"
    - "true"
    default: "false"
mainSteps:
- precondition:
    StringEquals:
      - platformType
      - Linux
  action: aws:runShellScript
  name: InvokeLinuxScript
  inputs:
    runCommand:
      - |
        #!/bin/bash
        set -e
        
        ExitWithFailure() {
          MESSAGE="${isEscapedForShell}\$1"
          (>&2 "echo" "${isEscapedForShell}\$MESSAGE")
          exit 1
        }

        FindNextAvailableUID() {
          local MIN_UID=65536
          local MAX_UID=90000
          local NEW_UID=${isEscapedForShell}\$MIN_UID

          while id -u "${isEscapedForShell}\$NEW_UID" &>/dev/null; do
            ((NEW_UID++))
            if [ "${isEscapedForShell}\$NEW_UID" -gt "${isEscapedForShell}\$MAX_UID" ]; then
                echo "No available UID found in range ${isEscapedForShell}\$MIN_UID-${isEscapedForShell}\$MAX_UID" >&2
                return 1
            fi
          done

          echo "${isEscapedForShell}\$NEW_UID"
          return 0
        }

        EnsureUserExists() {
          local COMMAND CREATE_HOME_ARGUMENT
          local USERNAME="${isEscapedForShell}\$1"

          if id "${isEscapedForShell}\$USERNAME" &>/dev/null; then
              return 0  
          fi

          NEW_UID=${isEscapedForShell}\$(FindNextAvailableUID) || ExitWithFailure "No available UID found."

          if [ -f /usr/sbin/groupadd ] && [ -f /usr/sbin/useradd ]; then
            /usr/sbin/groupadd -g "${isEscapedForShell}\$NEW_UID" ${isEscapedForShell}\$USERNAME || ExitWithFailure 'Failed to create the specified group with groupadd.'
            /usr/sbin/useradd -m -u "${isEscapedForShell}\$NEW_UID" -g "${isEscapedForShell}\$NEW_UID" ${isEscapedForShell}\$USERNAME  || ExitWithFailure 'Failed to create the specified user with useradd.'
          elif [ -f /usr/sbin/adduser ]; then
            /usr/sbin/adduser -u "${isEscapedForShell}\$NEW_UID" --gecos "${isEscapedForShell}\$USERNAME" --disabled-password ${isEscapedForShell}\$USERNAME || ExitWithFailure 'Failed to create the specified user with adduser.'
          else
            ExitWithFailure 'Cannot create user: neither of the required commands adduser or useradd exist.'
          fi
        }

        EnsureLineInFile() {
          local LINE="${isEscapedForShell}\$1"
          local FILE="${isEscapedForShell}\$2"
      
          if ! grep -qF "${isEscapedForShell}\$LINE" "${isEscapedForShell}\$FILE"; then
              echo "${isEscapedForShell}\$LINE" | sudo tee -a "${isEscapedForShell}\$FILE" >/dev/null
          fi
        }

        EnsureContentInFile() {
          local CONTENT="${isEscapedForShell}\$1"
          local REQUEST_ID="${isEscapedForShell}\$2"
          local FILE_PATH="${isEscapedForShell}\$3"
          local PERMISSION="${isEscapedForShell}\$4"
          local COMMENT="# RequestID: ${isEscapedForShell}\$REQUEST_ID"
      
          sudo mkdir -p "${isEscapedForShell}\$(dirname "${isEscapedForShell}\$FILE_PATH")"

          if [ ! -e "${isEscapedForShell}\$FILE_PATH" ]; then
            sudo touch "${isEscapedForShell}\$FILE_PATH"
            sudo chmod "${isEscapedForShell}\$PERMISSION" "${isEscapedForShell}\$FILE_PATH"
          fi
      
          if ! (grep -qF "${isEscapedForShell}\$COMMENT" "${isEscapedForShell}\$FILE_PATH" && grep -qF "${isEscapedForShell}\$CONTENT" "${isEscapedForShell}\$FILE_PATH"); then
              echo "${isEscapedForShell}\$COMMENT" | sudo tee -a "${isEscapedForShell}\$FILE_PATH" >/dev/null
              echo "${isEscapedForShell}\$CONTENT" | sudo tee -a "${isEscapedForShell}\$FILE_PATH" >/dev/null
          fi
        }

        RemoveContentFromFile() {
          local REQUEST_ID="${isEscapedForShell}\$1"
          local FILE_PATH="${isEscapedForShell}\$2"
          local COMMENT="# RequestID: ${isEscapedForShell}\$REQUEST_ID"
        
          if [ -f "${isEscapedForShell}\$FILE_PATH" ]; then
              sudo sed -i "/^${isEscapedForShell}\$COMMENT$/,/^$/d" "${isEscapedForShell}\$FILE_PATH"
          fi
        }
      
        if [ '{{ Action }}' = "grant" ]
        then
          EnsureUserExists '{{ UserName }}'
          if [ -n '{{ Sudo }}' ] && [ '{{ Sudo }}' = "true" ]; then
            EnsureContentInFile '{{ UserName }} ALL=(ALL) NOPASSWD: ALL' '{{ RequestId }}' "/etc/sudoers-p0" "440"
            EnsureLineInFile "#include sudoers-p0" /etc/sudoers
          fi
          if [ -n '{{ PublicKey }}' ] && [ '{{ PublicKey }}' != "N/A" ]; then
            EnsureContentInFile '{{ PublicKey }}' '{{ RequestId }}' '/home/{{ UserName }}/.ssh/authorized_keys' "600"
            sudo chown -R '{{ UserName }}:{{ UserName }}' '/home/{{ UserName }}/.ssh'
          fi
        else
          RemoveContentFromFile '{{ RequestId }}' "/etc/sudoers-p0"
          RemoveContentFromFile '{{ RequestId }}' '/home/{{ UserName }}/.ssh/authorized_keys'
        fi
`;

const sudoSshCliFor = () => {
  const fileName = PROVISION_USER_ACCESS_DOCUMENT.documentName;
  return `cat << EOF>$HOME/${fileName}.yaml
${SUDO_SSH_YAML_DOCUMENT("\\")}
EOF
export REGIONS_ALL=$( \\
  aws account list-regions --output text \\
  --query 'Regions[?(RegionOptStatus!=\`DISABLED\` && RegionOptStatus!=\`DISABLING\`)].RegionName' \\
)
for region in $REGIONS_ALL; do 
  if aws ssm describe-document --name "${fileName}" > /dev/null 2>&1; then
      aws ssm delete-document --name "${fileName}" --region $region
  fi
  aws ssm create-document --name "${fileName}"  --document-type "Command" --document-format "YAML" --target-type "/AWS::EC2::Instance" --tags Key=managed-by,Value=terraform Key=used-by,Value=P0Security --content file://$HOME/${fileName}.yaml --region $region
done 
  `;
};

const sudoSshIacFor = () => {
  return `
resource "aws_ssm_document" "p0_manage_sudo_access" {
  name            = "${PROVISION_USER_ACCESS_DOCUMENT.documentName}"
  document_format = "YAML"
  document_type   = "Command"
  target_type     = "/AWS::EC2::Instance"

  content = <<DOC
${SUDO_SSH_YAML_DOCUMENT("")}
DOC
  tags = {
    managed-by = "terraform"
    used-by    = "P0Security"
  }
}`;
};

const sudoSshInstructions: SudoSshInstaller["instructions"] = (
  _context,
  id,
  _item,
  _field
) => {
  return isAwsId(id)
    ? {
        help: (
          <Paragraph>
            Create SSM command document for P0 to provision access to the
            instance. P0 requires the document to be created in all regions for
            the account.
          </Paragraph>
        ),
        commands: {
          shell: [{ command: sudoSshCliFor() }],
          iac: [{ command: sudoSshIacFor() }],
        },
      }
    : {
        help: <Paragraph>Click next to continue.</Paragraph>,
      };
};

export const sudoSshInstaller: SudoSshInstaller = {
  instructions: sudoSshInstructions,
};
