import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { CollectExportData, Export } from "components/Export";
import { Tenant } from "components/Login";
import { getDocs } from "firebase/firestore";
import { Dictionary } from "lodash";
import { useCallback, useContext, useMemo } from "react";
import { toAssessmentPath } from "shared/assessment/helper";
import { toKey } from "shared/types/assessment";
import { Finding } from "shared/types/assessment/finding";
import { Monitor } from "shared/types/assessment/monitor";

import { FindingsContext, findingsQuery } from "../contexts/FindingsContext";
import { ScopeContext } from "../contexts/ScopeContext";

// TODO: Add explanation for each finding (viz, list critical unused permissions, etc.)
const toTsv = (monitors: Dictionary<Monitor>) => async (findings: Finding[]) =>
  [
    "monitor\tscope\tshow\tkey",
    ...findings.map(
      (f) =>
        `${monitors[f.monitorId].label}\t${f.scopeKey}\t${f.node.type}\t${
          f.node.key
        }`
    ),
  ].join("\n");

const toJson = async (findings: Finding[]) =>
  JSON.stringify(findings, undefined, 2);

export const FindingsExport: React.FC = () => {
  const tenantId = useContext(Tenant);
  const { assessment, last } = useContext(SelectedEnvironmentContext);
  const { allMonitors, prioritized, state, trigger } =
    useContext(FindingsContext);
  const { scopeKey } = useContext(ScopeContext);

  const collect: CollectExportData<Finding> = useCallback(
    async (onProgress, cancellation) => {
      const scopes = last.doc?.data.scope ?? [];
      const output: Finding[] = [];
      let index = 0;
      const nQuery = prioritized.length * scopes.length;
      if (!assessment.doc) return output;
      for (const monitor of prioritized) {
        for (const scope of scopes) {
          if (cancellation?.current) return output;
          onProgress(
            `${monitor.label} (${index % scopes.length} / ${scopes.length})`,
            index / nQuery
          );
          index++;
          const snap = await getDocs(
            findingsQuery({
              assessmentPath: toAssessmentPath(tenantId, assessment.doc.id),
              monitorId: monitor.monitorId,
              scopeKey: toKey(scope),
              state,
              trigger,
              lastJob: last.doc?.data.jobId ?? "",
              // We don't have the option to filter on date range on the findings export page
              start: undefined,
              end: undefined,
            })
          );
          output.push(...snap.docs.map((d) => d.data() as Finding));
        }
      }
      return output;
    },
    [
      assessment.doc,
      last.doc?.data.scope,
      prioritized,
      state,
      tenantId,
      trigger,
      last.doc?.data.jobId,
    ]
  );

  const makeTsv = useMemo(() => toTsv(allMonitors), [allMonitors]);

  return assessment.doc ? (
    <Export
      data={collect}
      filename={`${assessment.doc.data.name}-findings-${scopeKey}`}
      options={{
        json: { label: "JSON", blob: toJson, extension: "json" },
        tsv: { label: "TSV", blob: makeTsv, extension: "tsv" },
      }}
      title="Export"
    />
  ) : null;
};
