import { Tag } from "antd";

/*
/**
 * A simple component that displays a colored tag indicator
 * @component
 
 * @param color - The color to display in the tag
 * @param style] - Optional CSS styles to apply to the tag
 * @returns {JSX.Element} A rectangular component with the provided color
 * @example
 * <ColorCodeIndicator color="blue" />
 * <ColorCodeIndicator color="#ff0000" style={{margin: '8px'}} />
 */

export const ColorCodeIndicator: React.FC<{
  color: string;
  style?: React.CSSProperties;
}> = ({ color, style }) => {
  return <Tag style={style} color={color} />;
};
