import { widetype } from "../../../util/collections";
import { ProviderOrAll, Providers } from "../../assessment";
import { RiskScores } from "../../catalog";
import { Distribute, IsEqual } from "../../util";
import {
  AssessmentNodes,
  CredentialType,
  IdentityType,
  LateralType,
  MfaStatus,
  Usage,
} from "./base";

const TEXT_BOOLEAN_CHOICES = ["false", "true"] as const;

const IdentityTypeChoices: Readonly<Record<IdentityType, string>> = {
  "aws-iam-role": "AWS IAM role",
  "aws-permission-set-role": "AWS permission-set role",
  domain: "any entity in the provider's domain",
  federated: "federated identity",
  group: "user group",
  "logged-in": "any authenticated identity",
  public: "anyone on the Internet",
  "service-account": "service account",
  unknown: "unknown",
  user: "user",
};

const MfaStatusChoices: Readonly<Record<MfaStatus, string>> = {
  disabled: "disabled",
  enabled: "enabled",
  unknown: "status unknown",
};

const CredentialTypeChoices: Readonly<Record<CredentialType, string>> = {
  federated: "federated from another IdP",
  key: "static key pair",
  "short-lived": "ephemeral login (via OIDC or impersonation)",
};

const LateralTypeChoices: Readonly<Record<LateralType, string>> = {
  grant: "via impersonation privileges",
  resource: "via a service-linked resource",
};

const UsageTypeChoices: Readonly<Record<Usage, string>> = {
  unknown: "usage is unknown",
  unused: "unused in the last 90 days",
  used: "used by the grant principal in the last 90 days.",
};

export type AssessmentSchemaItem = {
  choices?: Readonly<Record<string, string>> | readonly string[];
  help?: string;
  hidden?: boolean;
  label?: Record<ProviderOrAll, string> | string;
  type?: "date" | undefined;
};

const AssessmentSchema = {
  permissionSet: {
    createdDate: {
      help: "The date and time the permission set was created.",
      label: "created at",
      type: "date",
    },
    sessionDuration: {
      help: "The maximum duration of sessions that can be created using this permission set.",
      label: "session duration",
    },
    label: {
      help: "The human-readable name of the permission set.",
      label: "name",
    },
  },
  grant: {
    condition: {
      help: "This grant's condition expression, if present. E.g., an AWS policy-statement condition, Azure role-assignment condition, or GCP role-binding condition.",
    },
    cross: {
      label: "cross-resource access",
      choices: TEXT_BOOLEAN_CHOICES,
      help: "'true' if this principal is managed in a different parent resource than this grant.",
    },
    disabled: { label: "is disabled", choices: TEXT_BOOLEAN_CHOICES },
    isAncestorGrant: {
      label: "is ancestor grant",
      choices: TEXT_BOOLEAN_CHOICES,
      help: "'true' if this grant is inherited from an enclosing scope. E.g., a GCP folder-level grant.",
    },
    isProviderManaged: { hidden: true },
    parent: {
      help: "Resource in which this grant is defined. E.g, an AWS account, Azure subscription, or GCP project.",
    },
    privilegeSet: {
      label: {
        all: "privilege set",
        aws: "policy",
        "azure-ad": "role",
        gcp: "role",
        k8s: "role",
        okta: "role",
        workspace: "admin role",
      },
      help: "The privilege set (policy or role) granted by this grant.",
    },
    principal: {
      help: "The identity granted access by this grant.",
    },
    principalType: {
      label: "principal type",
      choices: IdentityTypeChoices,
      help: "The identity type of this grant's principal.",
    },
    provider: {
      label: "provider",
      choices: Providers,
      help: "The provider in which this grant is managed.",
    },
    resources: {
      label: "resource",
      help: "The resource on which access is granted.",
    },
  } as const,
  condition: { description: {}, expression: {}, title: {} } as const,
  credential: {
    type: { label: "credential type", choices: CredentialTypeChoices },
    source: { label: "credential source" },
    createdTime: { hidden: true, label: "created at", type: "date" },
    status: { label: "enablement", choices: ["disabled", "enabled"] },
    lastAuthnTime: { hidden: true, label: "last used at", type: "date" },
    maxAuthnLookbackDays: { hidden: true, label: "max lookback in days" },
  } as const,
  consumer: {
    //organization and userAgents not included here
    type: { label: "consumer type", choices: ["ip", "principal"] },
    label: {},
    lastUsedTime: { hidden: true, label: "last used at", type: "date" },
    identityType: { hidden: true },
    isProviderManaged: { hidden: true },
    provider: { hidden: true },
  } as const,
  identity: {
    // TODO: expand access options
    access: { hidden: true },
    department: {
      help: "Only applies to users. This user's department or organizational unit.",
    },
    disabled: {
      label: "is disabled",
      choices: TEXT_BOOLEAN_CHOICES,
      help: "Whether this identity is disabled.",
    },
    external: {
      label: "external access",
      choices: TEXT_BOOLEAN_CHOICES,
      help: "'true' if this identity is managed outside the assessed scope; 'false' otherwise.",
    },
    isProviderManaged: {
      label: "is provider managed",
      choices: TEXT_BOOLEAN_CHOICES,
      help: "'true' if this identity is fully managed by the provider. E.g., AWS service-linked role or GCP service agent are provider managed.",
    },
    label: {
      help: "This identity's human-readable name. May be the same as 'key' depending on provider.",
    },
    manager: {
      help: "Only applies to users. This user's manager identity.",
    },
    mfa: { label: "MFA status", choices: MfaStatusChoices },
    parent: {
      label: "parent resource",
      help: "The resource in which this identity is managed. E.g., an IdP instance for user accounts, or an Azure subscription, AWS account, or GCP project for cloud service accounts.",
    },
    provider: {
      label: "provider",
      choices: Providers,
      help: "The provider in which this identity is managed.",
    },
    type: {
      label: "type",
      choices: IdentityTypeChoices,
      help: "The type of identity. E.g., user, group, or service account.",
    },
    // Hide as this is a one-off legacy use case
    trustedPrincipals: { hidden: true },
  } as const,
  lateral: {
    identity: {
      label: "accessed identity",
      help: "The key of the accessible identity.",
    },
    resource: {
      help:
        "The accessed resource that allows lateral movement. If the movement is enabled via impersonation privileges, the resource is the IAM provider." +
        " E.g., Azure subscription, AWS account, or GCP project. If the movement is enabled via use of a service identity, the resource will be the linked" +
        " resource. E.g., VM instance.",
    },
    service: {
      help: "The enabling resource's service. E.g. 'compute', or, in the case of impersonation privileges, 'iam' or 'sts'.",
    },
    type: {
      label: "movement category",
      choices: LateralTypeChoices,
      help: "The mechanism by which lateral movement can be exploited; either via impersonation or a service-linked resource.",
    },
  } as const,
  privilege: {} as const,
  privilegeSet: {
    provider: {
      label: "provider",
      choices: Providers,
      help: "The provider in which this privilege set is managed.",
    },
  } as const,
  usage: {
    type: {
      label: "usage type",
      choices: UsageTypeChoices,
      help: "Privilege usage (within the last 90 days).",
    },
    count: { hidden: true },
  } as const,
  resource: {
    id: {},
    locator: {},
    service: {},
    type: {},
  } as const,
  risk: { name: {}, score: { choices: RiskScores } } as const,
} as const;
type AssessmentKey = keyof typeof AssessmentSchema;

const _validateAssessmentKeys: IsEqual<AssessmentKey, keyof AssessmentNodes> =
  true;
const _validateAssessmentSchema: {
  [K in keyof AssessmentNodes]: IsEqual<
    keyof (typeof AssessmentSchema)[K],
    keyof NonNullable<Distribute<AssessmentNodes[K] & object>>
  >;
} = widetype.mapValues(AssessmentSchema, () => true as const);

export const AssessmentSchemaMap: {
  [K in keyof AssessmentNodes]: Readonly<
    Record<keyof (typeof AssessmentSchema)[K], AssessmentSchemaItem>
  >;
} = AssessmentSchema;
