import { SearchOutlined, SwapOutlined } from "@ant-design/icons";
import { Button, Input, Segmented, Typography } from "antd";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { GraphTooltip } from "components/GraphTooltip";
import { WarningTriangle } from "components/icons";
import { mapValues, sortBy, uniq } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { USAGE_LOOKBACK_DAYS } from "shared/assessment/constants";
import { isNode } from "shared/graph/types";
import { DAYS } from "shared/time";
import { assertNever } from "shared/types";
import {
  ALL_SCOPE_SENTINEL,
  AssessmentScopeIntegrations,
  ProviderOrAll,
  providerToScope,
  toKey,
} from "shared/types/assessment";
import {
  GrantAggregates,
  GrantNode,
  NodeFor,
  Usage,
} from "shared/types/assessment/data";
import { RiskAggregate } from "shared/types/assessment/data/aggregates";
import { Risk, RiskScore } from "shared/types/catalog";
import { isa } from "shared/types/is";
import { widetype } from "shared/util/collections";

import { Cascade, CascadeNode } from "../../../Cascade";
import { CatalogContext } from "../../../Catalog/context";
import { CountAggregate } from "./Aggregate";
import { PrivilegeLink } from "./PrivilegeLink";
import { RiskLink } from "./RiskLink";
import { HasAddTerm } from "./ShowHide";

// Display ordering
const PRIVILEGE_SORTING: Record<string, number> = {
  unused: 0,
  used: 1,
  unknown: 2,
};
const USAGES = ["used", "unused", "unknown"] as const;
const NO_KNOWN_RISK = "no-risk";

export const RiskPriority: Record<RiskScore, number> = {
  CRITICAL: 0,
  HIGH: 1,
  MEDIUM: 2,
  BOOST: 3,
  EVASION: 4,
  LOW: 5,
};

const PrivilegeAggregate = ({
  counts,
  onAddTerm,
  terms,
}: {
  counts: Record<Usage, number>;
} & HasAddTerm) =>
  CountAggregate({
    getInverseTerm: (val) => `usage:!"${val}"`,
    getSearchTerm: (val) => `usage:"${val}"`,
    inputMap: counts,
    onAddTerm,
    termName: "privileges",
    terms,
    typeOptions: USAGES,
  });

export const PrivilegeAggregateWithWarnings: React.FC<
  { node: GrantNode } & HasAddTerm
> = ({ node, ...props }) => {
  const { accessLogs } = useContext(SelectedEnvironmentContext);
  const { scopeKey } = useContext(ScopeContext);
  const { aggregates, data } = node;
  const { provider } = data;
  const integration = providerToScope(provider);
  const grantScope =
    isa(AssessmentScopeIntegrations, integration) && data.parent
      ? toKey({ type: "project", id: data.parent, integration })
      : undefined;

  const counts: Record<string, number> =
    scopeKey === ALL_SCOPE_SENTINEL
      ? aggregates.usages
      : mapValues(aggregates.privileges, (p) => p.length);

  // Minimum date of aggregation
  const aggregatedFrom = grantScope
    ? // Either single-item or org-wide access logs
      // Note that org-wide log agg times are stored in a record keyed by integration only
      (accessLogs[grantScope] ?? accessLogs[integration])?.minAggregatedDate ??
      NaN
    : NaN;

  const warningText =
    data.principalType === "group"
      ? "Usage for group grants is unavailable."
      : // No need to display warning if all privileges are known (e.g. via GPC privilege recommender)
      counts.unknown &&
        // "!"" with "<"" to handle NaN timestamp properly; note this is not the same as ">="
        !(aggregatedFrom < Date.now() - USAGE_LOOKBACK_DAYS * DAYS)
      ? `P0 does not yet have sufficient access logs for this resource.`
      : undefined;
  const warnings = warningText ? (
    <>
      &nbsp;
      <GraphTooltip title={warningText}>
        <WarningTriangle />
      </GraphTooltip>
    </>
  ) : null;
  return (
    <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
      <PrivilegeAggregate counts={counts} {...props} />
      {warnings}
    </div>
  );
};

/** Converts a privilegeType ConnectedNode to a PrivilegeAggregate
 *
 * For use when aggregate values are unavailable. E.g., in graph
 * visualization.
 */
export const toPrivilegeAggregate = (
  node: NodeFor<"usage">
): GrantAggregates["privileges"] => ({
  [node.data.type]: node.children.filter(
    isNode("privilege")
  ) as NodeFor<"privilege">[],
});

const renderprivilege = (privilege: string, provider: ProviderOrAll) => ({
  label:
    provider === "all" ? (
      privilege
    ) : (
      <PrivilegeLink privilege={privilege} provider={provider} />
    ),
  key: privilege,
  children: [],
  sortValue: privilege,
});

const renderRisk = (
  risk: Pick<Risk, "id" | "name" | "score"> | typeof NO_KNOWN_RISK
) => ({
  label:
    risk === NO_KNOWN_RISK ? (
      <Typography.Text type="secondary">(no known risks)</Typography.Text>
    ) : (
      <RiskLink risk={risk} />
    ),
  key: risk === NO_KNOWN_RISK ? risk : risk.id,
  sortValue:
    risk === NO_KNOWN_RISK
      ? "7"
      : `${RiskPriority[risk.score] ?? 6}:${risk.name}`,
});

export const RiskGroupedPrivilegeList: React.FC<{
  privileges: GrantAggregates["privileges"];
  provider: ProviderOrAll;
  initialSelected?: Usage;
  /** If true, displays a privilege-type picker */
  showControl?: boolean;
}> = ({ privileges, initialSelected, provider, showControl }) => {
  const [mode, setMode] = useState<"privilege" | "risk">("risk");

  // Fall back to first non-empty privileges
  const firstNonempty = useMemo(
    () =>
      sortBy(widetype.entries(privileges), ([u]) => PRIVILEGE_SORTING[u]).find(
        ([_, v]) => v.length
      )?.[0],
    [privileges]
  );

  const [selected, setSelected] = useState<number | string>(
    initialSelected ?? firstNonempty ?? "unused"
  );
  const { risks } = useContext(CatalogContext);
  const [where, setWhere] = useState<string>();

  const toggleMode = useCallback(
    () =>
      setMode(
        mode === "privilege"
          ? "risk"
          : mode === "risk"
          ? "privilege"
          : assertNever(mode)
      ),
    [mode]
  );

  const onWhere = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setWhere(event.target.value),
    []
  );

  const byprivilege: Record<string, string[]> = useMemo(
    () =>
      Object.fromEntries(
        (privileges[selected as Usage] ?? [])
          .filter((k) => k.key.includes(where ?? ""))
          .map((n) => {
            const riskChildren = n.children.filter(isNode("risk"));
            const risks = riskChildren.length
              ? uniq(riskChildren.map((c) => c.key))
              : [NO_KNOWN_RISK];
            return [n.key, risks];
          })
      ),
    [privileges, selected, where]
  );

  const byRisk: Record<string, string[]> = useMemo(() => {
    const output: Record<string, string[]> = {};
    for (const [privilege, risks] of Object.entries(byprivilege)) {
      for (const risk of risks) {
        output[risk] ||= [];
        output[risk].push(privilege);
      }
    }
    return output;
  }, [byprivilege]);

  const options = useMemo(
    () => [
      {
        label: `Unused (${privileges.unused?.length ?? 0})`,
        value: "unused",
      } as const,
      {
        label: `Known used (${privileges.used?.length ?? 0})`,
        value: "used",
      } as const,
      {
        label: `Potentially used (${privileges.unknown?.length ?? 0})`,
        value: "unknown",
      } as const,
    ],
    [privileges]
  );

  const tree: CascadeNode[] = useMemo(() => {
    switch (mode) {
      case "risk":
        return Object.entries(byRisk).map(([r, perms]) => {
          const risk = risks[r];
          return {
            ...renderRisk(risk ?? NO_KNOWN_RISK),
            children: uniq(perms).map((p) => ({
              ...renderprivilege(p, provider),
              children: [],
            })),
          };
        });
      case "privilege":
        return Object.entries(byprivilege).map(([p, rr]) => {
          return {
            ...renderprivilege(p, provider),
            children: uniq(rr).map((r) => ({
              ...renderRisk(risks[r] ?? r),
              children: [],
            })),
          };
        });
      default:
        throw assertNever(mode);
    }
  }, [mode, byRisk, byprivilege, risks, provider]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.7em",
        alignItems: "flex-start",
      }}
    >
      <div style={{ display: "flex", gap: "0.7em" }}>
        {showControl && (
          <Segmented
            options={options}
            value={selected}
            onChange={setSelected}
          />
        )}
        <GraphTooltip title="Toggle display between risks and privileges">
          <Button
            icon={<SwapOutlined />}
            type={mode === "privilege" ? "primary" : undefined}
            onClick={toggleMode}
          />
        </GraphTooltip>
      </div>
      <Input
        placeholder="Search privileges"
        prefix={<SearchOutlined />}
        onChange={onWhere}
      />
      <Cascade tree={tree} height={228} />
    </div>
  );
};

export const RiskList: React.FC<{
  risks: RiskAggregate;
}> = ({ risks }) => {
  return (
    <Cascade
      tree={risks.map((r) => {
        return {
          children: [],
          ...renderRisk(r),
        };
      })}
      height={228}
    />
  );
};
