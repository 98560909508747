import { Button, Card } from "antd";
import { ReactElement } from "react";
import { colors } from "styles/variables";

export const ZeroState: React.FC<{
  cta: ReactElement;
  background: ReactElement;
  onHide: () => void;
  allowHide: boolean;
}> = ({ cta, background, onHide, allowHide }) => (
  <>
    <Card
      extra={allowHide ? <Button onClick={onHide}>Hide</Button> : null}
      style={{
        opacity: 1,
        width: "30em",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 10,
        position: "absolute",
      }}
      styles={{
        body: {
          opacity: 1,
          background: colors.neutral["00"],
          textAlign: "center",
        },
      }}
    >
      {cta}
    </Card>

    <div
      style={{
        opacity: 0.25,
        pointerEvents: "none",
        filter: "grayscale(100%)",
      }}
    >
      {background}
    </div>
  </>
);
