import { magenta } from "@ant-design/colors";
import { DefaultTitles } from "shared/assessment/constants";
import { ConnectedNode } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";

import {
  DefaultNodeHeaderHeight,
  DefaultNodeOneLineBodyHeight,
  DefaultNodeWidth,
} from "./shared";

export const CustomConditionNodeParameters = {
  type: "condition" as const,
  titleText: () => DefaultTitles.condition,
  bodyBorderColor: magenta[1],
  bodyBackgroundColor: magenta[0],
  bodyContent: (node: ConnectedNode<AssessmentNodes, "condition">) => (
    <label htmlFor="text">{node.data?.title}</label>
  ),
  bodySize: () => DefaultNodeOneLineBodyHeight,
  headerSize: () => DefaultNodeHeaderHeight,
  width: () => DefaultNodeWidth,
};
