import { Descriptions, Typography } from "antd";
import React from "react";
import { GrantCondition } from "shared/types/assessment/data";

export const ConditionDisplay: React.FC<{
  condition: NonNullable<GrantCondition>;
}> = ({ condition }) => (
  <Descriptions
    bordered
    column={1}
    styles={{ label: { color: "darkgray" } }}
    layout="horizontal"
    size="small"
  >
    {condition.title && (
      <Descriptions.Item label="Title">{condition.title}</Descriptions.Item>
    )}
    {condition.description && (
      <Descriptions.Item label="Description">
        {condition.description}
      </Descriptions.Item>
    )}
    <Descriptions.Item label="Expression">
      <Typography.Text code>{condition.expression}</Typography.Text>
    </Descriptions.Item>
  </Descriptions>
);
