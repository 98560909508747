import { RequestStatus } from "shared/types/request-status";
import { StyledTag } from "styles/StyledTags";

import { COLOR_MAP } from "../requestUtils";

const STATUS_MAPPING: Record<RequestStatus, keyof typeof COLOR_MAP> = {
  EXPIRED_NOTIFIED: "Expired",
  EXPIRED: "Expired",
  NEW: "Request Created",
  PENDING_APPROVAL: "Pending Approval",
  PENDING_APPROVAL_ESCALATED: "Pending Approval",
  APPROVED: "Approved",
  DENIED: "Denied",
  REVOKED: "Relinquished",
  DONE: "Granted",
  DRAFT: "Not yet created",
  APPROVED_NOTIFIED: "Approved",
  DENIED_NOTIFIED: "Denied",
  ERRORED_NOTIFIED: "Errored",
  DONE_NOTIFIED: "Granted",
  ERRORED_ERRORED: "Errored",
  ERRORED: "Errored",
  EXPIRY_SUBMITTED: "Expiring",
  REVOKE_SUBMITTED: "Relinquishing",
  REVOKED_NOTIFIED: "Relinquished",
  STAGED: "Staged",
  TRANSLATED: "N/A",
  CLEANUP_SUBMITTED: "Expiring",
  CLEANED_UP: "Expired",
  CLEANUP_ERRORED: "Errored",
} as const;

export const RequestStatusTag: React.FC<{
  status: RequestStatus;
  style?: React.CSSProperties;
}> = ({ status, style }) => {
  const friendly = STATUS_MAPPING[status];
  return (
    <StyledTag
      color={COLOR_MAP[friendly]}
      style={{
        ...style,
        textAlign: "center",
        width: "100%",
        maxWidth: "15em",
      }}
      key={status}
      ellipsis={true}
    >
      {STATUS_MAPPING[status]}
    </StyledTag>
  );
};
