import { Environment } from "../../../types/environment";

export const P0_GCLOUD_ORGANIZATION_ID = "698375260981";

export const GCLOUD_BOUNDARY_TEST_ROLE = "browser";

export const GRANT_GCLOUD_PERMISSIONS_LABEL = "Grant P0 permissions";

export const GCLOUD_COMPONENT_SETUP = {
  "access-logs": {
    logging: {
      filter: `(
  log_id("cloudaudit.googleapis.com/data_access")
    OR log_id("cloudaudit.googleapis.com/activity")
)
AND protoPayload.authenticationInfo.principalEmail !~ "^system"`,
      sinkId: "p0-audit-log-sink",
    } as const,
    customRole: {
      id: "p0AccessLogCollector",
      name: "P0 Access Logs Collector",
    } as const,
    requiredPermissions: ["logging.sinks.create", "logging.sinks.get"],
  } as const,
  "iam-write": {
    predefinedRole: "roles/iam.securityAdmin",
    customRole: { id: "p0IamAdmin", name: "P0 IAM Admin" } as const,
    requiredPermissions: [
      "bigquery.datasets.get",
      // Resource-level permissions on datasets require update because the IAM policy is on the dataset object
      "bigquery.datasets.update",
      "compute.instances.get",
      "iam.roles.create",
      "iam.roles.delete",
      "iam.roles.get",
      "iam.roles.list",
      "iam.serviceAccounts.getIamPolicy",
      "iam.serviceAccounts.setIamPolicy",
      "resourcemanager.projects.get",
      "resourcemanager.projects.getIamPolicy",
      "resourcemanager.projects.setIamPolicy",
    ] as const,
  } as const,
  "iam-assessment": {
    customRole: { id: "p0IamAuditor", name: "P0 IAM Auditor" } as const,
    orgLevelPermissions: [
      "resourcemanager.organizations.get",
      "resourcemanager.organizations.getIamPolicy",
      "resourcemanager.folders.get",
      "resourcemanager.folders.list",
      "resourcemanager.folders.getIamPolicy",
      "resourcemanager.projects.list",
    ],
    requiredPermissions: [
      "cloudasset.assets.analyzeIamPolicy",
      "cloudasset.assets.searchAllIamPolicies",
      "cloudasset.assets.searchAllResources",
      "compute.instances.list",
      "compute.projects.get",
      "compute.zones.list",
      "essentialcontacts.contacts.list",
      "iam.roles.get",
      "iam.roles.list",
      "iam.serviceAccountKeys.list",
      "iam.serviceAccounts.get",
      "monitoring.timeSeries.list",
      "recommender.iamPolicyInsights.list",
      "resourcemanager.projects.get",
      "resourcemanager.projects.getIamPolicy",
      "run.jobs.list",
      "run.revisions.list",
    ] as const,
  } as const,
  "org-wide-policy": {
    customRole: {
      id: "p0OrgIamPolicyReader",
      name: "P0 Organization IAM Policy Reader",
    } as const,
    requiredPermissions: [
      "resourcemanager.organizations.get",
      "resourcemanager.organizations.getIamPolicy",
      "resourcemanager.folders.get",
      "resourcemanager.folders.getIamPolicy",
    ] as const,
  } as const,
  // iam-write-2 is feature flagged iam-write configuration
  // this configuration provides roles, permissions and service accounts
  // needed for iam management to work with security perimeter
  // it also contains security perimeter config inside for permission validation on
  // individual project installs. Will be removed/renamed to iam-write when all
  // clients are migrated.
  "iam-write-2": {
    predefinedRole: "roles/iam.securityReviewer",
    customRole: { id: "p0IamManager", name: "P0 IAM Manager" } as const,
    requiredPermissions: [
      "cloudasset.assets.analyzeIamPolicy",
      "cloudasset.assets.searchAllIamPolicies",
      "cloudasset.assets.searchAllResources",
      "compute.instances.get",
      "iam.roles.create",
      "iam.roles.delete",
      "resourcemanager.projects.get",
    ] as const,
    securityPerimeter: {
      predefinedRole: "roles/iam.securityAdmin",
      customRole: { id: "p0IamWriter", name: "P0 IAM Writer" } as const,
      requiredPermissions: [
        "bigquery.datasets.get",
        "bigquery.datasets.update",
        "iam.serviceAccounts.get",
        "resourcemanager.projects.get",
      ] as const,
    } as const,
  },
  "iam-write-security-perimeter": {
    services: [
      "cloudasset.googleapis.com",
      "cloudresourcemanager.googleapis.com",
      "compute.googleapis.com",
      "iam.googleapis.com",
      "run.googleapis.com",
    ],
    customRole: {
      id: "p0SecurityPerimeterInvoker",
      name: "P0 security perimeter invoker",
    } as const,
    requiredPermissions: [
      "resourcemanager.projects.get",
      "resourcemanager.projects.getIamPolicy",
      "run.routes.invoke",
      "run.services.get",
      "run.services.getIamPolicy",
    ] as const,
    projectReaderRole: {
      customRole: {
        id: "p0SecurityPerimeterReader",
        name: "P0 security perimeter reader role",
      } as const,
      requiredPermissions: ["resourcemanager.projects.get"] as const,
    },
  } as const,
} as const;

export const setupPermissions = (
  scope: "organization" | "project",
  data: (typeof GCLOUD_COMPONENT_SETUP)[keyof typeof GCLOUD_COMPONENT_SETUP]
) =>
  [
    ...("requiredPermissions" in data ? data.requiredPermissions : []),
    ...(scope === "organization" && "orgLevelPermissions" in data
      ? data.orgLevelPermissions
      : []),
  ].sort();

export const ALL_P0_GCLOUD_ROLES = Object.values(GCLOUD_COMPONENT_SETUP).map(
  (s) => ("customRole" in s ? s.customRole.id : undefined)
) as readonly string[];

export const GCLOUD_BASIC_ROLES: readonly string[] = [
  "owner",
  "editor",
  "viewer",
] as const;

export const PROJECT_TESTABLE_PERMISSIONS_TABLE =
  "project_testable_permissions";

export const serviceAccountForSecurityPerimeter = (projectId: string) => ({
  name: "p0-security-perimeter-sa",
  email: `p0-security-perimeter-sa@${projectId}.iam.gserviceaccount.com`,
});

export const GCLOUD_SECURITY_PERIMETER_SETUP_IMAGES = {
  "iam-write-security-perimeter": {
    cloudRunNameFor: (environment: Environment) =>
      `p0-security-perimeter-${environment}`,
    imageName: "docker.io/p0security/p0-security-perimeter-gcloud",
    digest: "sha-d8092dc",
  } as const,
} as const;

export const GCLOUD_CLOUD_RUN_REGION = "us-west1";

export const GCLOUD_SECURITY_PERIMETER_FEATURE_FLAG = "gcloudSecurityPerimeter";
