import { addEnvironmentToPath } from "components/Assessment/environment";
import { useAuthFetch } from "components/Login/hook";
import {
  differenceInMinutes,
  formatDistanceToNow,
  milliseconds,
} from "date-fns";
import { Timestamp, limit, orderBy, where } from "firebase/firestore";
import { IAM_ASSESSMENTS_COLLECTION } from "firestore/constants";
import { useLocalStorage } from "hooks/useLocalStorage";
import { Dictionary } from "lodash";
import {
  FirestoreDoc,
  useFirestoreCollection,
  useFirestoreDoc,
} from "providers/FirestoreProvider";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AccessLogsInfo, IamAssessment } from "shared/types/assessment";
import {
  AssessmentJob,
  TerminalAssessmentStatuses,
} from "shared/types/assessment/job";
import { isa } from "shared/types/is";

import { Environment, EnvironmentDocsContext } from "./EnvironmentDocsContext";

export const ENVIRONMENT_LOCAL_STORAGE_KEY = "selected-environment";
export type StoredEnvironment = { id: string };

export type SelectedEnvironment = {
  accessLogs: Dictionary<AccessLogsInfo>;
  details: Environment | undefined;
  assessment: {
    doc: FirestoreDoc<IamAssessment> | undefined;
    hasSchedule: boolean;
    isScheduled: boolean;
    loading: boolean;
  };
  current: {
    doc: FirestoreDoc<AssessmentJob> | undefined;
    loading: boolean;
    isCompleted: boolean;
    isInProgress: boolean;
  };
  last: {
    doc: FirestoreDoc<AssessmentJob> | undefined;
    loading: boolean;
  };
  runAssessmentNow: () => void;
  formattedNextRun: string;
};

const defaultSelectedEnvironmentValue: SelectedEnvironment = {
  accessLogs: {},
  assessment: {
    doc: undefined,
    loading: true,
    hasSchedule: false,
    isScheduled: false,
  },
  details: undefined,
  current: {
    doc: undefined,
    loading: true,
    isCompleted: false,
    isInProgress: false,
  },
  last: { doc: undefined, loading: true },
  runAssessmentNow: () => {},
  formattedNextRun: "",
};

export const SelectedEnvironmentContext = createContext<SelectedEnvironment>(
  defaultSelectedEnvironmentValue
);

SelectedEnvironmentContext.displayName = "EnvironmentContext";

export const EnvironmentProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return <SelectedEnvironmentProvider>{children}</SelectedEnvironmentProvider>;
};

export const SelectedEnvironmentProvider: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => {
  const { all, hasEnvironments, isLoading } = useContext(
    EnvironmentDocsContext
  );

  const [searchParams] = useSearchParams();

  // we're overriding the environment here so we cannot use useNavigateWithEnv
  // nosemgrep:link-component-in-environment-folders
  const navigate = useNavigate();

  const location = useLocation();
  const [storedEnv, _] = useLocalStorage<StoredEnvironment>(
    ENVIRONMENT_LOCAL_STORAGE_KEY
  );

  useEffect(() => {
    const env = searchParams.get("environment");
    try {
      if ((!env || !all.has(env)) && all.size > 0) {
        const defaultEnvId =
          storedEnv && all.has(storedEnv.id)
            ? storedEnv.id
            : all.keys().next().value;
        if (defaultEnvId) {
          navigate(addEnvironmentToPath(location.pathname, defaultEnvId), {
            replace: true,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [all, searchParams, navigate, location, storedEnv]);

  const details = useMemo(() => {
    const envId = searchParams.get("environment");
    return envId ? all.get(envId) : undefined;
  }, [all, searchParams]);

  const authFetch = useAuthFetch();

  const assessmentId = details?.assessmentId;
  const assessmentPath = assessmentId
    ? `${IAM_ASSESSMENTS_COLLECTION}/${assessmentId}`
    : undefined;
  const assessmentDoc = useFirestoreDoc<IamAssessment>(assessmentPath, {
    live: true,
    tenantAware: true,
  });

  const { docs: currentDocs } = useFirestoreCollection<AssessmentJob>(
    "job-state",
    {
      live: true,
      queryConstraints: [
        where("assessmentId", "==", assessmentId ?? ""),
        orderBy("lastUpdatedTimestamp", "desc"),
        limit(1),
      ],
      tenantAware: true,
    }
  );

  const { docs: lastCompletedDocs, loading } =
    useFirestoreCollection<AssessmentJob>("job-state", {
      live: true,
      queryConstraints: [
        where("assessmentId", "==", assessmentId ?? ""),
        where("status", "==", "COMPLETED"),
        orderBy("lastUpdatedTimestamp", "desc"),
        limit(1),
      ],
      tenantAware: true,
    });

  // Turn off liveness here to save Firestore load. Note that this can lead to out-of-date or stale access-log warnings.
  // However, these are unlikely to change while the user is viewing the page.
  const { docs: accessLogsDocs } = useFirestoreCollection<AccessLogsInfo>(
    assessmentPath ? `${assessmentPath}/access-logs` : undefined,
    { live: false, tenantAware: true }
  );

  const accessLogs = useMemo(
    () =>
      Object.fromEntries(
        (accessLogsDocs ?? []).map((doc) => [doc.id, doc.data])
      ),
    [accessLogsDocs]
  );

  const selectedEnvironment: SelectedEnvironment = useMemo(() => {
    try {
      const isScheduled =
        !assessmentDoc.doc?.data?.frequency?.disabled &&
        // TODO: fix with migration
        assessmentDoc.doc?.data?.frequency?.anchorDate !== undefined;

      return {
        accessLogs,
        assessment: {
          doc: assessmentDoc.doc,
          loading: assessmentDoc.loading,
          hasSchedule: assessmentDoc.doc?.data?.frequency !== undefined,
          isScheduled,
        },
        current: {
          doc: currentDocs?.[0],
          loading: currentDocs === undefined,
          isCompleted:
            currentDocs?.length === 0 ||
            Boolean(
              currentDocs?.[0] &&
                isa(TerminalAssessmentStatuses, currentDocs[0].data.status)
            ),
          isInProgress: Boolean(
            currentDocs?.[0] &&
              !isa(TerminalAssessmentStatuses, currentDocs[0].data.status)
          ),
        },
        details,
        last: {
          doc: lastCompletedDocs?.[0],
          loading,
        },
        runAssessmentNow: () => {
          authFetch(`assessment/${assessmentId}/run`, {
            method: "POST",
          });
        },
        formattedNextRun: (() => {
          if (!isScheduled || assessmentDoc.doc?.data.frequency?.disabled) {
            return "";
          }

          if (!assessmentDoc.doc?.data?.frequency?.anchorDate) {
            return "Not scheduled for additional runs";
          }

          const unitMillis = milliseconds({
            [assessmentDoc.doc?.data?.frequency.unit]: 1,
          });
          const anchor = (
            assessmentDoc.doc?.data?.frequency.anchorDate as Timestamp
          ).toMillis();
          const nextRunDate = new Date(
            Math.ceil((Date.now() - anchor) / unitMillis) * unitMillis + anchor
          );

          if (differenceInMinutes(nextRunDate, new Date()) <= 1) {
            return "Next run in less than one minute.";
          }

          return `Next run in ${formatDistanceToNow(nextRunDate)}.`;
        })(),
      };
    } catch (e) {
      console.error(e);
      return defaultSelectedEnvironmentValue;
    }
  }, [
    assessmentDoc.doc,
    assessmentDoc.loading,
    accessLogs,
    currentDocs,
    details,
    lastCompletedDocs,
    loading,
    authFetch,
    assessmentId,
  ]);

  const render = !isLoading && (!hasEnvironments || !!details);
  return (
    <SelectedEnvironmentContext.Provider value={selectedEnvironment}>
      {render ? children : null}
    </SelectedEnvironmentContext.Provider>
  );
};
