import { ExportOutlined } from "@ant-design/icons";
import Link from "antd/lib/typography/Link";
import { GraphTooltip } from "components/GraphTooltip";
import { privilegeLink } from "shared/assessment/render";
import { Provider } from "shared/types/assessment";

import { LinkDetailsSpan, LinkDiv, LinkNameSpan } from "./LinkDiv";

/** Renders a link to the IAM Risk Catalog for a privilege
 *
 * If the privilege is remediated by currently selected recommendations,
 * will render struck-through.
 */
export const PrivilegeLink = ({
  privilege,
  provider,
}: {
  privilege: string;
  provider: Provider;
}) => {
  const ref = privilegeLink(provider, privilege);
  return (
    <LinkDiv>
      <GraphTooltip title={privilege}>
        <LinkNameSpan>{privilege}</LinkNameSpan>
      </GraphTooltip>
      <LinkDetailsSpan>
        <Link href={ref} target="catalog">
          <ExportOutlined />
        </Link>
      </LinkDetailsSpan>
    </LinkDiv>
  );
};
