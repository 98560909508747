export const colors = {
  primary: {
    "00": "#e6f7ff",
    "40": "#40a9ff",
    "50": "#1890ff",
    "60": "#096dd9",
    "100": "#001a33",
  },
  neutral: {
    "00": "#ffffff",
    "05": "#fafafa",
    "10": "#f5f5f5",
    "15": "#f0f0f0",
    "20": "#d9d9d9",
    "30": "#bfbfbf",
    "40": "#8c8c8c",
    "50": "#595959",
    "60": "#434343",
    "70": "#262626",
    "80": "#1f1f1f",
    "90": "#141414",
    "100": "#000000",
  },
  success: {
    "00": "#f6ffed",
    "30": "#95de64",
    "40": "#73d13d",
    "50": "#52c41a",
    "60": "#389e0d",
    "70": "#237804",
    "100": "#092b00",
  },
  // Tag colors are taken from the AntD tag colors  https://ant.design/components/tag#tag-demo-borderless
  // except for grey, which is custom, and the yellow text, which is slightly darkened to fit contrast standards
  // Styled manually since our AntD version does not support the bordlerless tags

  /*
    In our app tag colors generally correspond to these use cases:
    - Blue: Success or Informational messages
    - Red: Errors or critical issues
    - Orange: High severity warnings or issues
    - Yellow: Moderate severity warnings or issues
    - Grey: Low severity warnings or issues. Default states
    - Light Grey: Numerical data
  */
  tagColors: {
    blue: {
      bg: "#e6f4ff",
      text: "#0958d9",
    },
    red: {
      text: "#cf1322",
      bg: "#fff1f0",
    },
    yellow: {
      text: "#8c5900",
      bg: "#fffbe6",
    },
    lightGrey: {
      bg: "#fafafa",
      text: "#000000",
    },
    grey: {
      bg: "#cbc6c6",
      text: "#000000",
    },
    orange: {
      text: "#844306",
      bg: "#FFE4AD",
    },
  },
  // TODO: Remove any colors that are not used anywhere
  themeColors: {
    red: "#f94144",
    darkOrange: "#f3722c",
    lightOrange: "#f8961e",
    orange: "#f9844a",
    yellow: "#f9c74f",
    lightGreen: "#d9f7be",
    green: "#90be6d",
    blueGreen: "#43aa8b",
    darkCyan: "#4d908e",
    darkBlue: "#577590",
    lightRed: "#ffccc7",
    lightBlue: "#277da1",
    lightYellow: "#ffffb8",
    veryLightBlue: "#d6e4ff",
    purple: "#6a4c93",
  },

  graphColors: {
    purple: "#6A4C93",
    blue: "#5DA939",
    pink: "#F5A6E6",
    darkGreen: "#3F6634",
    lightGreen: "#EEF5DB",
  },
};
