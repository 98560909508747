import { CloseOutlined } from "@ant-design/icons";
import { ColumnTitle } from "antd/lib/table/interface";
import { GraphTooltip } from "components/GraphTooltip";
import React, { useCallback } from "react";
import { PermissionRequest } from "shared/types/permission";
import { StyledTag } from "styles/StyledTags";
import { colors } from "styles/variables";

export const FilterTag: React.FC<{
  text: React.Key;
  fieldName: string;
  handleDelete: (
    selectedFilter: React.Key,
    columnName: ColumnTitle<PermissionRequest>
  ) => void;
}> = ({ text, fieldName, handleDelete }) => {
  const closeTag = useCallback(() => {
    handleDelete(text, fieldName);
  }, [fieldName, handleDelete, text]);
  return (
    <GraphTooltip placement="top" title={text}>
      <StyledTag
        closable={{
          closeIcon: (
            <CloseOutlined style={{ color: colors.tagColors.blue.text }} />
          ),
        }}
        color={colors.tagColors.blue}
        onClose={closeTag}
        style={{
          height: "2em",
          maxWidth: "20em",
        }}
      >
        {fieldName}
        {": "}
        {text}
      </StyledTag>
    </GraphTooltip>
  );
};
