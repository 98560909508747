import { UserApprovalOptions } from "shared/types/workflow/types";
import { StyledTag } from "styles/StyledTags";
import { colors } from "styles/variables";

type Props = {
  approvalOptions: UserApprovalOptions;
};

export const ApprovalOptionsPreview: React.FC<Props> = ({
  approvalOptions,
}) => (
  <>
    {approvalOptions.allowOneParty && (
      <StyledTag color={colors.tagColors.red}>Allow one party</StyledTag>
    )}
    {approvalOptions.requireReason && (
      <StyledTag color={colors.tagColors.grey}>Require reason</StyledTag>
    )}
  </>
);
