import { Flex, Select, Typography } from "antd";
import { PriorityTooltip } from "components/Assessment/components/monitor/PriorityTooltip";
import { FindingsContext } from "components/Assessment/contexts/FindingsContext";
import { useNavigateWithEnv } from "components/Assessment/hooks/useNavigateWithEnv";
import { priorityColor } from "components/Assessment/utils/monitor";
import { omit, orderBy, size } from "lodash";
import pluralize from "pluralize";
import { DefaultOptionType, FilterFunc } from "rc-select/lib/Select";
import { FlattenOptionData } from "rc-select/lib/interface";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MonitorRanking, priorityLabels } from "shared/assessment/constants";
import { AppPaths } from "shared/routes/constants";
import { FindingState } from "shared/types/assessment/finding";
import { MonitorPriority } from "shared/types/assessment/monitor";
import { StyledTag } from "styles/StyledTags";
import { colors } from "styles/variables";
import { createEnumParam, useQueryParam, withDefault } from "use-query-params";

import { useDashboardFindings } from "../hooks/useDashboardFindings";
import { mockMonitorsByPriority } from "../zeroStateData/findings-zero-state";
import { FindingGraph } from "./FindingGraph";
import { OpenFindings } from "./OpenFindings";
import { Pane } from "./Pane";
import { ColorCodeIndicator } from "./common/ColorCodeIndicator";
import { PanelErrorBoundary } from "./common/PanelErrorBoundary";

const INITIAL_SELECTED_PRIORITIES = new Set<MonitorPriority>([
  "CRITICAL",
  "HIGH",
]);

type MonitorOption = {
  label: string;
  value: string;
  priority: MonitorPriority;
};

export const FindingsBySeverity: React.FC<{
  isLoading: boolean;
  hasEnvironments: boolean;
}> = ({ isLoading, hasEnvironments }) => {
  const { allMonitors } = useContext(FindingsContext);

  const [priorities, setPriorities] = useState(INITIAL_SELECTED_PRIORITIES);
  const [selectedMonitorIds, setSelectedMonitorIds] = useState<string[]>([]);
  const [findingsStartDate, setFindingsStartDate] = useState<number>(0);
  const {
    loading: findingsLoading,
    monitorsByPriority,
    startDate,
  } = useDashboardFindings(selectedMonitorIds);

  useEffect(() => {
    // this is a somewhat brittle hack until we have a better way to retrieve
    // the start date of the assessment runs
    // when no filter is present, set the start date to the start date of the findings
    if (selectedMonitorIds.length === 0) {
      setFindingsStartDate(startDate);
    }
  }, [startDate, selectedMonitorIds]);

  const monitorsEmpty = useMemo(
    () =>
      !findingsLoading &&
      Object.values(omit(monitorsByPriority, "bins")).every(
        ({ totalCount }) => totalCount === 0
      ),
    [monitorsByPriority, findingsLoading]
  );

  const [scopeKey, _] = useQueryParam(
    "state",
    withDefault(createEnumParam([...FindingState]), "open")
  );

  const navigate = useNavigateWithEnv();
  const handleViewFindings = useCallback(() => {
    navigate(`../${AppPaths.Posture}?state=${scopeKey}`);
  }, [navigate, scopeKey]);

  const handleSeverityChange = useCallback((v: MonitorPriority) => {
    setPriorities(
      (prev) =>
        new Set(
          prev.has(v)
            ? [...prev.keys()].filter((k) => k !== v)
            : [...prev.keys(), v]
        )
    );
  }, []);

  const monitorOptions = useMemo<MonitorOption[]>(
    () =>
      orderBy(
        Object.values(allMonitors),
        [(i) => MonitorRanking[i.priority], "monitorId"],
        ["asc", "asc"]
      ).map((monitor) => ({
        label: `${monitor.label} (${priorityLabels[
          monitor.priority
        ].toUpperCase()})`,
        value: monitor.monitorId,
        priority: monitor.priority,
      })),
    [allMonitors]
  );

  const loading = useMemo(
    () =>
      isLoading ||
      // this is a hack to prevent the empty state from showing when the initial
      // assessment is running. we should be able to remove this once we have
      // a better way to handle the empty state.
      (hasEnvironments && monitorsEmpty && !size(selectedMonitorIds)) ||
      findingsLoading,
    [
      isLoading,
      hasEnvironments,
      monitorsEmpty,
      findingsLoading,
      selectedMonitorIds,
    ]
  );

  const filterOption = useCallback<FilterFunc<DefaultOptionType>>(
    (input, option) => {
      if (!option?.label) return false;
      return option.label
        .toString()
        .toLowerCase()
        .includes(input.toLowerCase());
    },
    []
  );

  const renderMonitorLabel = useCallback(
    (option: FlattenOptionData<DefaultOptionType>) => {
      const monitorOption = option.data as MonitorOption;
      const { color } = priorityColor[monitorOption?.priority ?? "CRITICAL"];
      return (
        <Flex align="center">
          <ColorCodeIndicator color={color} style={{ height: "1em" }} />
          <Typography.Text>{option.label}</Typography.Text>
        </Flex>
      );
    },
    []
  );

  const renderFilteredMonitors = useMemo(() => {
    return (
      <Typography.Text>
        {`${selectedMonitorIds.length} ${pluralize(
          "monitor",
          selectedMonitorIds.length
        )}`}
      </Typography.Text>
    );
  }, [selectedMonitorIds]);

  const renderTag = useCallback(
    (props: { value: string; label: React.ReactNode }) => {
      return (
        <StyledTag color={colors.tagColors.lightGrey}>{props.label}</StyledTag>
      );
    },
    []
  );

  return (
    <Pane
      title="Open Findings"
      showMore={{ text: "View in Posture", onClick: handleViewFindings }}
      tooltipPayload={<PriorityTooltip />}
      additionalControls={
        <Select
          options={monitorOptions}
          value={selectedMonitorIds}
          onChange={setSelectedMonitorIds}
          autoClearSearchValue={false}
          filterOption={filterOption}
          mode="multiple"
          style={{ width: 200 }}
          size="middle"
          dropdownStyle={{ width: 500 }}
          allowClear
          maxTagCount={0}
          tagRender={renderTag}
          placeholder="Filter by monitor"
          optionRender={renderMonitorLabel}
          maxTagPlaceholder={renderFilteredMonitors}
        />
      }
    >
      <div style={{ display: "flex", width: "100%" }}>
        <PanelErrorBoundary title="Open Finding">
          <OpenFindings
            monitorsByPriority={
              hasEnvironments ? monitorsByPriority : mockMonitorsByPriority
            }
            isLoading={loading}
            handleChange={handleSeverityChange}
            priorities={priorities}
          />
        </PanelErrorBoundary>
        <PanelErrorBoundary title="Finding Graph">
          <FindingGraph
            isLoading={loading}
            monitors={
              hasEnvironments ? monitorsByPriority : mockMonitorsByPriority
            }
            priorities={priorities}
            startDate={findingsStartDate}
          />
        </PanelErrorBoundary>
      </div>
    </Pane>
  );
};
