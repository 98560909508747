import { Button, Grid, Space, Typography } from "antd";
import type { TablePaginationConfig } from "antd/lib/table";
import { ColumnTitle, FilterValue } from "antd/lib/table/interface";
import { useNavigateWithEnv } from "components/Assessment/hooks/useNavigateWithEnv";
import { Prefix } from "components/GraphTable/Prefix";
import { PermissionIntegrationLogo } from "components/Integrations/IntegrationLogo";
import {
  directories,
  resourceIntegrations,
} from "components/Integrations/constants";
import { useUser } from "components/Login/hook";
import {
  ResizableTable,
  ResizableTableColumnType,
  ResizableTableKeys,
} from "components/ResizableTable";
import { formatDistance } from "date-fns";
import { usePagination } from "hooks/usePagination";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  cloneDeep,
  compact,
  gt,
  lt,
  startCase,
  throttle,
  toLower,
  uniq,
} from "lodash";
import moment from "moment";
import { FirestoreDoc } from "providers/FirestoreProvider";
import { RangePickerProps } from "rc-picker";
import React, { useCallback, useContext, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { PermissionRequest } from "shared/types/permission";
import { integrationLabels } from "shared/types/workflow/constants";
import styled from "styled-components";

import { IntegrationsContext } from "../contexts/IntegrationsContext";
import {
  deserializeQueryParamValue,
  getEvidenceTypeAndIdIfEvidence,
  permissionAccount,
  requestDescription,
  serializeQueryParamValue,
  statusToDisplayText,
} from "../requestUtils";
import { FilterTag } from "./FilterTag";
import { RequestDetailDrawer } from "./RequestDetailsDrawer";
import { RequestDateFilters } from "./RequestHistoryDateFilters";
import { SearchBar } from "./RequestHistorySearchBar";
import { RequestStatusTag } from "./RequestStatusTag";
import { RequestHistoryRequestedApprovedBy } from "./RequestorApprover";
import "./Requests.less";

const StyledTable = styled(ResizableTable<PermissionRequest>)`
  .ant-table-thead > tr > th {
    text-wrap: balance;
    // Avoid break in middle of words
    overflow-wrap: normal;
  }

  td {
    .ant-typography {
      // Prevent overly aggressive Location break
      word-break: normal;
    }
  }
`;

type RequestColumn = Pick<
  ResizableTableColumnType<PermissionRequest>,
  "width"
> & {
  columnName: string;
  renderFunc: (
    val: string,
    data: PermissionRequest,
    displayName: string,
    subText?: string
  ) => JSX.Element | undefined;
  toText: (r: PermissionRequest) => string | undefined;
  toSubText?: (r: PermissionRequest) => string | undefined;
};

const useTimestampRangeQuery = (
  paramStart: string,
  paramEnd: string
): [
  RangePickerProps<moment.Moment>["value"],
  (range: RangePickerProps<moment.Moment>["value"]) => void,
] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const rawStart = searchParams.get(paramStart);
  const rawEnd = searchParams.get(paramEnd);

  const range: [moment.Moment | null, moment.Moment | null] | null =
    useMemo(() => {
      if (!rawStart && !rawEnd) {
        return null;
      }
      const parsedStart = moment(rawStart, moment.ISO_8601, true);
      const parsedEnd = moment(rawEnd, moment.ISO_8601, true);
      return [
        parsedStart.isValid() ? parsedStart : null,
        parsedEnd.isValid() ? parsedEnd : null,
      ];
    }, [rawStart, rawEnd]);

  const setRange = useCallback(
    (timestamp: RangePickerProps<moment.Moment>["value"]) => {
      setSearchParams((prev) => {
        const next = new URLSearchParams(prev);
        if (timestamp === null) {
          next.delete(paramStart);
          next.delete(paramEnd);
          return next;
        }

        const [start = null, end = null] = timestamp ?? [];
        if (start === null) {
          next.delete(paramStart);
        } else {
          next.set(paramStart, new Date(start.format()).toISOString());
        }

        if (end === null) {
          next.delete(paramEnd);
        } else {
          next.set(paramEnd, new Date(end.format()).toISOString());
        }

        return next;
      });
    },
    [setSearchParams, paramStart, paramEnd]
  );

  return [range, setRange];
};

const quickFilterAction = (
  columnName: string,
  filter: string,
  tableFilters: TableFilters,
  setTableFilters: (filters: TableFilters) => void
) => {
  const newFilters = {
    ...tableFilters,
    [columnName]: tableFilters[columnName]?.includes(filter)
      ? tableFilters[columnName]
      : (tableFilters[columnName] ?? [])?.concat([filter]),
  };
  setTableFilters(newFilters);
};

type RequestColumnName = RequestColumn["columnName"];
type TableFilters = Partial<Record<RequestColumnName, FilterValue | null>>;

export const Requests: React.FC<{
  requests: FirestoreDoc<PermissionRequest>[];
}> = ({ requests }) => {
  const { requestId } = useParams();
  const flags = useFlags();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigateWithEnv();

  const { user } = useUser();
  const userEmail = user?.email;

  const { md } = Grid.useBreakpoint();

  const showRequestInfo = useCallback(
    (id: string) => {
      navigate(id);
    },
    [navigate]
  );

  const data = useMemo(
    () => requests.map((doc) => ({ id: doc.id, ...doc.data, showRequestInfo })),
    [requests, showRequestInfo]
  );

  const searchQuery = searchParams.get("q") || undefined;
  const setSearchQuery = useCallback(
    (query: string | null | undefined) => {
      setSearchParams((prev) => {
        const next = new URLSearchParams(prev);
        if (!query) {
          next.delete("q");
          return next;
        }
        next.set("q", query);
        return next;
      });
    },
    [setSearchParams]
  );

  // request timestamp filter
  const [requestedDateFilter, setRequestedDateFilter] = useTimestampRangeQuery(
    "requestedStart",
    "requestedEnd"
  );

  // approval timestamp filter
  const [approvedDateFilter, setApprovedDateFilter] = useTimestampRangeQuery(
    "approvedStart",
    "approvedEnd"
  );

  const integrations = useContext(IntegrationsContext);

  const requestColumns: RequestColumn[] = useMemo(
    () => [
      {
        columnName: "status",
        toText: (r) => statusToDisplayText(r.status),
        renderFunc: (_val, data) => (
          <>
            <div style={{ marginBottom: 4 }}>
              <RequestStatusTag status={data.status} />
            </div>
            {data.lastUpdatedTimestamp && (
              <div className="sub-text">
                {formatDistance(data.lastUpdatedTimestamp, Date.now(), {
                  addSuffix: true,
                })}
              </div>
            )}
            {data.isAwaitingExpiry && data.expiryTimestamp && (
              <div className="sub-text">
                Expires in {formatDistance(data.expiryTimestamp, Date.now())}
              </div>
            )}
          </>
        ),
        width: 100,
      },
      {
        columnName: "requestor",
        toText: (r) => r.requestor,
        renderFunc: (_val, data, displayName) => (
          <RequestHistoryRequestedApprovedBy
            username={displayName}
            timestamp={data.requestedTimestamp}
            slackUrl={data.notifications?.slack?.approvalConversationUrl}
          />
        ),
        width: 200,
      },
      {
        columnName: "integration",
        toText: (r) => integrationLabels[r.type],
        renderFunc: (_val, data, displayName, subText) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              {PermissionIntegrationLogo[data.type]} {displayName}
              {<div className="sub-text">{`Type: ${subText}`}</div>}
            </div>
          </div>
        ),
        width: 150,
        toSubText: (r) =>
          "type" in r.permission
            ? startCase(r.permission.type)
            : "access" in r
            ? startCase(r.access)
            : "",
      },
      {
        columnName: "location",
        renderFunc: (_val, _data, displayName) => (
          <Typography>{displayName}</Typography>
        ),
        width: 150,
        toText: (r) => {
          const location = permissionAccount(r, integrations);
          const integrationMap = [
            ...cloneDeep(resourceIntegrations(flags)),
            ...cloneDeep(directories),
          ];

          return location
            ? location
            : integrationMap.find((i) => i.key === r.type)?.label;
        },
      },
      {
        columnName: "resource",
        toText: (r) => requestDescription(r)?.replace(/.*\| /, ""),
        renderFunc: (_val, _data, displayName) => (
          <div style={{ maxWidth: "100%" }}>
            <Typography>{displayName}</Typography>
          </div>
        ),
        width: 300,
      },
      {
        columnName: "approver",
        toText: (r) => {
          if (!r.approvalDetails) return "";
          const { email, id, name, approvalSource } = r.approvalDetails;
          const displayText =
            approvalSource === "evidence" ? "Evidence" : email || name || id;
          return displayText === "persistent"
            ? "By persistent access"
            : displayText;
        },
        renderFunc: (_val, data, displayName) =>
          data.approvalDetails && (
            <RequestHistoryRequestedApprovedBy
              slackUrl={data.notifications?.slack?.approvalConversationUrl}
              username={displayName}
              timestamp={data.approvalDetails?.approvedTimestamp}
              evidence={getEvidenceTypeAndIdIfEvidence(data)}
            />
          ),
        width: 200,
      },
    ],
    [flags, integrations]
  );

  const requestColumnNames = requestColumns.map((item) => item.columnName);

  const useTableFilters = (): [
    TableFilters,
    (filters: TableFilters) => void,
  ] => {
    const [searchParams, setSearchParams] = useSearchParams();

    const tableFilters: TableFilters = useMemo(() => {
      const deserializedFiltersArray = requestColumnNames.map((columnName) => ({
        columnName,
        value: searchParams
          .getAll(columnName)
          .map((value) => deserializeQueryParamValue(value)),
      }));
      return deserializedFiltersArray.reduce(
        (prev, curr) => ({ ...prev, [curr.columnName]: curr.value }),
        {}
      );
    }, [searchParams]);

    const innerSetFilters = useCallback(
      (filters: TableFilters) => {
        // transform new filters into array format
        const newFiltersArray = requestColumnNames.map((columnName) => ({
          columnName,
          value: filters[columnName] || null,
        }));

        // serialize filter values
        const serializedFilters: Partial<
          Record<RequestColumnName, string[] | null>
        > = newFiltersArray.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.columnName]:
              curr.value?.map((item) => serializeQueryParamValue(item)) || null,
          };
        }, {});

        setSearchParams((prev) => {
          const next = new URLSearchParams(prev);
          for (const [columnName, columnFilterValues] of Object.entries(
            serializedFilters
          )) {
            next.delete(columnName);
            if (
              columnFilterValues === null ||
              columnFilterValues === undefined ||
              columnFilterValues.length === 0
            ) {
              continue;
            }

            // set filters
            for (const v of columnFilterValues) {
              if (!!v) next.append(columnName, v);
            }
          }

          return next;
        });
      },
      [setSearchParams]
    );

    return [tableFilters, innerSetFilters];
  };

  const [tableFilters, setTableFilters] = useTableFilters();

  const [pagination, setPagination] = usePagination();

  const updateRequestedDateFilter = useCallback(
    (filter: RangePickerProps<moment.Moment>["value"]) => {
      setRequestedDateFilter(filter);
    },
    [setRequestedDateFilter]
  );

  const updateApprovedDateFilter = useCallback(
    (filter: RangePickerProps<moment.Moment>["value"]) => {
      setApprovedDateFilter(filter);
    },
    [setApprovedDateFilter]
  );

  const updateSearchQuery = useCallback(
    (query: typeof searchQuery) => {
      setSearchQuery(query);
    },
    [setSearchQuery]
  );

  const updatePagination = useCallback(
    (page: number, pageSize: number) => setPagination({ page, pageSize }),
    [setPagination]
  );

  const onTableChange = useCallback(
    (
      _pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>
    ) => {
      setTableFilters(filters);
    },
    [setTableFilters]
  );

  const pendingQuickFilterAction = useCallback(() => {
    quickFilterAction(
      "status",
      "Pending Approval",
      tableFilters,
      setTableFilters
    );
  }, [tableFilters, setTableFilters]);

  const requestedByMeQuickFilterAction = useCallback(() => {
    if (userEmail) {
      quickFilterAction("requestor", userEmail, tableFilters, setTableFilters);
    }
  }, [tableFilters, setTableFilters, userEmail]);

  const activeGrantsQuickFilterAction = useCallback(() => {
    quickFilterAction("status", "Granted", tableFilters, setTableFilters);
  }, [tableFilters, setTableFilters]);

  const clearQuickFilterAction = useCallback(() => {
    /*
     * Note: it's not save to call the other functions individually as one might expect like this:
     *
     * updateSearchQuery("");
     * updateApprovedDateFilter(null);
     * updateRequestedDateFilter(null);
     *
     * It is better to batch related calls to setSearchParams, and the reason is related to the
     * fact that it directly call's the browser's navigation API under the hood. Not batching
     * the calls can sometimes lead to unexpected behavior.
     *
     * See https://github.com/remix-run/react-router/issues/12179
     * and https://github.com/remix-run/react-router/issues/11752
     *
     */
    setSearchParams(new URLSearchParams());
  }, [setSearchParams]);

  const columns = useMemo((): ResizableTableColumnType<PermissionRequest>[] => {
    const buildColumn = ({
      columnName: dataIndex,
      toText,
      renderFunc,
      width,
      toSubText,
    }: RequestColumn): ResizableTableColumnType<PermissionRequest> => ({
      title: startCase(toLower(dataIndex)),
      dataIndex,
      filteredValue: tableFilters?.[dataIndex] ?? [],
      filterSearch: true,
      filters: (() => {
        return compact(uniq(data.map(toText))).map((value) => ({
          text: value,
          value,
        }));
      })(),
      onFilter: (value, record) => {
        return toText(record) === value || toSubText?.(record) === value;
      },
      width: width,
      sorter: (a, b) => (toText(a) ?? "").localeCompare(toText(b) ?? ""),
      render: (val, data) =>
        renderFunc(val, data, toText(data) ?? "", toSubText?.(data)),
    });

    return [
      {
        width: 65,
        preventResize: true,
        title: "Details",
        render: (data: { showRequestInfo: (id: any) => void; id: any }) => (
          <Button
            type="link"
            size="small"
            // Memoization performed by Table
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => {
              data.showRequestInfo(data.id);
            }}
          >
            view
          </Button>
        ),
      },
      ...requestColumns.map((c) => buildColumn(c)),
    ];
  }, [data, tableFilters, requestColumns]);

  const deleteFilter = useCallback(
    (selectedFilter: React.Key, columnName: ColumnTitle<PermissionRequest>) => {
      const fieldDataIndex = requestColumns.find(
        (c) => startCase(c.columnName) === columnName
      )?.columnName;
      if (fieldDataIndex) {
        const newFilters = {
          ...tableFilters,
          [fieldDataIndex]: tableFilters[fieldDataIndex]?.filter(
            (v) => v !== selectedFilter
          ),
        };
        setTableFilters(newFilters);
      }
    },
    [tableFilters, setTableFilters, requestColumns]
  );

  const filterData = useMemo(() => {
    const requestMatchesAnyDisplayText = (
      request: PermissionRequest,
      query?: string
    ) => {
      const normalizedQuery = query?.toLowerCase() ?? "";
      for (const col of requestColumns) {
        if (
          col.toText(request)?.toLowerCase().includes(normalizedQuery) ||
          col.toSubText?.(request)?.toLowerCase().includes(normalizedQuery)
        ) {
          return true;
        }
      }
    };

    return throttle(
      () =>
        data.filter((item: PermissionRequest) => {
          const { approvalDetails, requestedTimestamp } = item;
          const approvedAfterFilter = approvedDateFilter?.[0];
          if (
            approvedAfterFilter &&
            !gt(
              approvalDetails?.approvedTimestamp,
              approvedAfterFilter.valueOf()
            )
          ) {
            return false;
          }

          const approvedBeforeFilter = approvedDateFilter?.[1];
          if (
            approvedBeforeFilter &&
            !lt(
              approvalDetails?.approvedTimestamp,
              approvedBeforeFilter.valueOf()
            )
          ) {
            return false;
          }
          const requestedAfterFilter = requestedDateFilter?.[0];
          if (
            requestedAfterFilter &&
            requestedTimestamp <= requestedAfterFilter.valueOf()
          ) {
            return false;
          }

          const requestedBeforeFilter = requestedDateFilter?.[1];
          if (
            requestedBeforeFilter &&
            requestedTimestamp >= requestedBeforeFilter.valueOf()
          ) {
            return false;
          }

          if (searchQuery) {
            if (!requestMatchesAnyDisplayText(item, searchQuery)) {
              return false;
            }
          }
          return true;
        }),
      300
    )();
  }, [
    approvedDateFilter,
    data,
    requestedDateFilter,
    searchQuery,
    requestColumns,
  ]);

  return (
    <Space direction="vertical" size="middle">
      <div style={{ width: "75%" }}>
        <SearchBar
          onChange={updateSearchQuery}
          requests={requests}
          searchQuery={searchQuery}
        />
      </div>
      <div style={{ width: "75%" }}>
        <RequestDateFilters
          approvedDateFilter={approvedDateFilter}
          requestedDateFilter={requestedDateFilter}
          onApprovalFilterChange={updateApprovedDateFilter}
          onRequestFilterChange={updateRequestedDateFilter}
        />
      </div>
      <Space
        direction={md ? "horizontal" : "vertical"}
        size="middle"
        style={{ width: "75%", minHeight: md ? "2em" : "unset" }}
      >
        <Prefix style={{ width: "40em" }} prefix="Quick Filters">
          <Space size="middle">
            <Button
              type="link"
              size="small"
              onClick={activeGrantsQuickFilterAction}
            >
              Active Grants
            </Button>
            <Button type="link" size="small" onClick={pendingQuickFilterAction}>
              Pending Approval
            </Button>
            {userEmail && (
              <Button
                type="link"
                size="small"
                onClick={requestedByMeQuickFilterAction}
              >
                Requested by Me
              </Button>
            )}
            <Button type="link" size="small" onClick={clearQuickFilterAction}>
              Clear
            </Button>
          </Space>
        </Prefix>
        <Space size={1} wrap>
          {tableFilters &&
            Object.entries(tableFilters).map(([key, value]) => {
              tableFilters;
              return value?.map((v) => (
                <FilterTag
                  key={`${key}-${v}`}
                  text={v.toLocaleString()}
                  fieldName={startCase(
                    requestColumns.find((c) => c.columnName === key)?.columnName
                  )}
                  handleDelete={deleteFilter}
                />
              ));
            })}
        </Space>
      </Space>
      <StyledTable
        tableId={ResizableTableKeys.frequentRequests}
        onChange={onTableChange}
        size="small"
        tableLayout="fixed"
        columns={columns}
        dataSource={filterData}
        rowKey="id"
        pagination={{
          current: pagination.page,
          defaultCurrent: 1,
          pageSize: pagination.pageSize,
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          onChange: updatePagination,
        }}
      />
      {requestId && <RequestDetailDrawer requestId={requestId} />}
    </Space>
  ); // Each row must have a unique ID; silence the runtime warning by adding the rowKey attribute
};
