import { priorityColor } from "components/Assessment/utils/monitor";
import { priorityLabels } from "shared/assessment/constants";
import { MonitorBase } from "shared/types/assessment/monitor";
import { StyledTag } from "styles/StyledTags";
import { colors } from "styles/variables";

export const MonitorPriority: React.FC<{ monitor: MonitorBase }> = ({
  monitor,
}) => (
  <StyledTag
    color={priorityColor[monitor?.priority].tagColor ?? colors.tagColors.grey}
    style={{
      width: "5em",
      textAlign: "center",
      marginRight: "0",
    }}
  >
    {priorityLabels[monitor.priority]}
  </StyledTag>
);
