import { purple } from "@ant-design/colors";
import { DefaultTitles } from "shared/assessment/constants";
import { ConnectedNode } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";

import { toConsumerText } from "../../cells/ConsumersList";
import {
  DefaultNodeHeaderHeight,
  DefaultNodeOneLineBodyHeight,
  DefaultNodeWidth,
} from "./shared";

export const CustomConsumerNodeParameters = {
  type: "consumer" as const,
  titleText: () => DefaultTitles.consumer,
  bodyBorderColor: purple[1],
  bodyBackgroundColor: purple[0],
  bodyContent: (node: ConnectedNode<AssessmentNodes, "consumer">) => (
    <div>{toConsumerText(node.data)}</div>
  ),
  bodySize: () => DefaultNodeOneLineBodyHeight,
  headerSize: () => DefaultNodeHeaderHeight,
  width: () => DefaultNodeWidth,
};
