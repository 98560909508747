import { MonitorPriority } from "shared/types/assessment/monitor";
import { colors } from "styles/variables";

export const priorityColor: Record<
  MonitorPriority,
  {
    color: string;
    text: string;
    tagColor: {
      bg: string;
      text: string;
    };
  }
> = {
  CRITICAL: {
    color: colors.themeColors.red,
    text: "#5c0011",
    tagColor: colors.tagColors.red,
  },
  HIGH: {
    color: colors.themeColors.lightOrange,
    text: "#614700",
    tagColor: colors.tagColors.orange,
  },
  MEDIUM: {
    color: colors.themeColors.yellow,
    text: "#ffffff",
    tagColor: colors.tagColors.yellow,
  },
  LOW: {
    color: colors.themeColors.darkBlue,
    text: "#595959",
    tagColor: colors.tagColors.grey,
  },
};
