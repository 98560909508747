import { Descriptions, List } from "antd";
import { ScopeContext } from "components/Assessment/contexts/ScopeContext";
import { sortBy } from "lodash";
import { useContext } from "react";
import { grantNodeToPrivilegeSet } from "shared/assessment/render";
import {
  ALL_SCOPE_SENTINEL,
  AssessmentScopeIntegrations,
  providerToScope,
  toKey,
} from "shared/types/assessment";
import { GrantNode } from "shared/types/assessment/data";
import { isa } from "shared/types/is";

import { ConditionDisplay } from "../../cells/Condition";
import {
  IdentityCell,
  IdentityExternalLink,
  IdentityLink,
} from "../../cells/Identity";
import { NodeLink } from "../../cells/NodeLink";
import {
  RiskGroupedPrivilegeList,
  RiskList,
} from "../../cells/PermissionAggregate";
import { Resource } from "../../cells/Resource";
import { NodeDescriptions } from "../NodeDescriptions";
import { DisplayList } from "../shared";
import { providerTitleElements } from "./shared";

const { Item } = Descriptions;

export const GrantNodeDisplay: React.FC<{
  node: GrantNode;
}> = ({ node }) => {
  const { scopeKey } = useContext(ScopeContext);
  const { data } = node;
  const { provider } = data;
  const integration = providerToScope(provider);

  let grantScope: string | undefined;
  // toKey throws an error if the data does not parse as a scope
  try {
    grantScope =
      isa(AssessmentScopeIntegrations, integration) && data.parent
        ? toKey({ type: "project", id: data.parent, integration })
        : undefined;
  } catch (e) {
    console.error("Error parsing grant scope", e);
  }
  return (
    <>
      <NodeDescriptions>
        <Item label="Principal">
          <IdentityExternalLink
            data={{
              ...node.data,
              type: node.data.principalType,
              label: node.data.principal,
            }}
            id={node.data.principal}
          />
        </Item>
        <Item
          label={
            providerTitleElements[node.data.provider ?? ALL_SCOPE_SENTINEL][
              "privilegeSet"
            ]
          }
        >
          {grantNodeToPrivilegeSet(node)}
        </Item>
        <Item label="Resources">
          <DisplayList>
            {node.data.resources.map((r, ix) => (
              <List.Item key={ix}>
                <NodeLink
                  node={{ key: r, type: "resource" }}
                  // resources are not visible in All Scopes, so we need to
                  // override the scope to the specific scope the grant
                  // belongs to
                  scopeOverride={grantScope}
                />
                &nbsp;
                <Resource resource={node} index={ix} />
              </List.Item>
            ))}
          </DisplayList>
        </Item>
        {node.data.condition && typeof node.data.condition === "object" && (
          <Item label="Condition">
            <ConditionDisplay condition={node.data.condition} />
          </Item>
        )}

        <Item label="Risks">
          {scopeKey === ALL_SCOPE_SENTINEL ? (
            <RiskList risks={node.aggregates.risks} />
          ) : (
            <RiskGroupedPrivilegeList
              privileges={node.aggregates.privileges}
              provider={node.data.provider}
              showControl
            />
          )}
        </Item>

        <Item label="Accessible by">
          <DisplayList>
            {sortBy(node.aggregates.principals, ["principalType", "id"]).map(
              (p, ix) => (
                <List.Item key={ix}>
                  <IdentityLink data={p} type="short" show="grant" />{" "}
                  <IdentityCell identity={p} />
                </List.Item>
              )
            )}
          </DisplayList>
        </Item>
      </NodeDescriptions>
    </>
  );
};
