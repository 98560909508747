import { Drawer } from "antd";
import { useNavigateWithEnv } from "components/Assessment/hooks/useNavigateWithEnv";
import { Tenant } from "components/Login";
import { useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";

import { RequestLogs } from "./RequestLogs";

export const RequestDetailDrawer: React.FC<{ requestId: string }> = ({
  requestId,
}) => {
  const tenantId = useContext(Tenant);
  const location = useLocation();
  const navigate = useNavigateWithEnv();

  const closeDrawer = useCallback(
    () => navigate({ ...location, pathname: "../" }, { relative: "path" }),
    [location, navigate]
  );

  return (
    <Drawer
      title="Request Details"
      width="75%"
      open
      onClose={closeDrawer}
      styles={{ body: { paddingBottom: 80 } }}
    >
      <RequestLogs requestId={requestId} tenantId={tenantId} />
    </Drawer>
  );
};
