import * as bfj from "bfj";
import { mapValues, omit } from "lodash";
import { Readable } from "stream";
import { parser } from "stream-json";
import { streamObject } from "stream-json/streamers/StreamObject";

import { keyOf } from "./graph";
import { ConnectedNode, DirectedGraph, Node, NodeOf } from "./types";

type Edge = { parent: string; child: string };
export type Representation<G extends object> = {
  allNodes: Record<string, Node<G, keyof G>>;
  edges: Edge[];
};

/** Produces a JSON-friendly (non-recursive) graph representation */
export const toRepresentation = <G extends object>(
  graph: DirectedGraph<G>
): Representation<G> => {
  const nodes: NodeOf<G>[] = [];
  // Use numeric index keys to shrink representation size
  const lookup: Record<string, string> = {};
  const edges: Edge[] = [];
  for (const n of graph.nodes) {
    const key = keyOf(n);
    if (!(key in lookup)) {
      const ix = nodes.length;
      nodes.push(omit(n, "children", "parents"));
      lookup[key] = String(ix);
    }
  }
  const allNodes = Object.fromEntries(Object.entries(nodes));
  for (const n of graph.nodes) {
    for (const p of n.parents) {
      edges.push({ parent: lookup[keyOf(p)], child: lookup[keyOf(n)] });
    }
  }

  return { allNodes, edges };
};

/** Produce a referential graph representation from a JSON-friendly representation */
export const fromRepresentation = <G extends object>(
  data: Representation<G>
) => {
  const connected: Record<string, ConnectedNode<G, keyof G>> = mapValues(
    data.allNodes,
    (n) => ({ ...n, children: [], parents: [] })
  );
  for (const edge of data.edges) {
    connected[edge.parent]?.children.push(connected[edge.child]);
    connected[edge.child]?.parents.push(connected[edge.parent]);
  }
  return { nodes: Object.values(connected) };
};

/** Serializes a directed graph as JSON */
export const serialize = <G extends object>(
  graph: DirectedGraph<G>,
  indent?: number
): Readable =>
  bfj.streamify(toRepresentation(graph), {
    space: indent,
    // yieldRate is how many fields in the graph representation to parse (DFS) before yielding to the event loop
    yieldRate: 16,
  });

/** Deserializes a JSON representation of a directed graph */
export const deserialize = async <G extends object>(
  stream: Readable
): Promise<DirectedGraph<G>> => {
  const inner = await new Promise((resolve, reject) => {
    // TODO: may need to stream allNodes, edges, and parentNodes separately
    const pipeline = stream.pipe(parser()).pipe(streamObject());
    const data: any = {};

    pipeline.on("data", (event) => {
      data[event.key] = event.value;
    });
    pipeline.on("end", () => {
      resolve(data);
    });
    pipeline.on("error", (err) => {
      reject(err);
    });
  });
  // Do this outside the 'end' event so that errors propagate.
  return fromRepresentation(inner as Representation<G>);
};
