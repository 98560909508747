import { orange } from "@ant-design/colors";
import { UnlockOutlined } from "@ant-design/icons";
import { GraphTooltip } from "components/GraphTooltip";
import { capitalize } from "lodash";
import { DefaultTitles } from "shared/assessment/constants";
import { ConnectedNode, isNode } from "shared/graph/types";
import {
  AssessmentNodes,
  GrantNode,
  IdentityNode,
} from "shared/types/assessment/data";

import { RendererParams } from "../../../contexts/GraphContext";
import {
  RiskGroupedPrivilegeList,
  toPrivilegeAggregate,
} from "../../cells/PermissionAggregate";
import {
  DefaultNodeHeaderHeight,
  DefaultNodeOneLineBodyHeight,
  DefaultNodeWidth,
} from "./shared";

export const CustomUsageNodeParameters = {
  type: "usage" as const,
  titleText: (
    _: ConnectedNode<AssessmentNodes, "usage">,
    rendererParams: RendererParams
  ) => (rendererParams.provider === "aws" ? "Actions" : DefaultTitles.usage),
  IconComponent: UnlockOutlined,
  bodyBorderColor: orange[2],
  bodyBackgroundColor: orange[1],
  bodyContent: (
    node: ConnectedNode<AssessmentNodes, "usage">,
    rendererParams: RendererParams
  ) => {
    const { currentLocaleNode, provider } = rendererParams;
    const getPrivilege = (node: ConnectedNode<AssessmentNodes, "usage">) => {
      return (
        (currentLocaleNode as GrantNode | IdentityNode)?.aggregates
          ?.privileges ?? toPrivilegeAggregate(node)
      );
    };

    const privileges = getPrivilege(node);
    const hoverElement = !node.data.count && (
      <RiskGroupedPrivilegeList
        privileges={privileges}
        initialSelected={node.data.type}
        provider={provider}
      />
    );
    const labelElement = (
      <>
        {node.data.type === "unknown"
          ? "Potentially used"
          : capitalize(node.data.type)}{" "}
        privileges (
        {node.data.count ?? node.children.filter(isNode("privilege")).length})
      </>
    );

    return hoverElement ? (
      <GraphTooltip width="500px" title={hoverElement}>
        {labelElement}
      </GraphTooltip>
    ) : (
      labelElement
    );
  },
  bodySize: () => DefaultNodeOneLineBodyHeight,
  headerSize: () => DefaultNodeHeaderHeight,
  width: () => DefaultNodeWidth,
};
