import { ExportOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal, Progress, Typography, message } from "antd";
import { DropdownButtonType } from "antd/lib/dropdown";
import { isArray } from "lodash";
import {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

const { Text, Title } = Typography;

type ExportOption<T> = {
  label: ReactNode;
  buttonType?: DropdownButtonType;
  icon?: React.ReactNode;
  blob: (data: T[]) => Promise<string>;
  extension: string;
};

export type CollectExportData<T> = (
  onProgress: (message: string, progress: number) => void,
  cancellation: RefObject<boolean>
) => Promise<T[]>;

const NO_PROGRESS = { progress: 0, message: "" };

export const Export = <T,>({
  data,
  filename,
  options,
  title,
}: {
  data: CollectExportData<T> | T[];
  filename: string;
  options: Record<string, ExportOption<T>>;
  title?: string;
}) => {
  const anchorRef = useRef<HTMLAnchorElement | null>(null);
  const cancellation = useRef(false);
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const [downloadName, setDownloadName] = useState<string>();
  const [collecting, setCollecting] = useState(false);
  const [progress, setProgress] = useState(NO_PROGRESS);
  const [loading, setLoading] = useState<boolean>(false);

  const collectData = useCallback(async (collect: CollectExportData<T>) => {
    setCollecting(true);
    setProgress(NO_PROGRESS);
    cancellation.current = false;
    const result = await collect((message, progress) => {
      setProgress({ progress, message });
    }, cancellation);
    setCollecting(false);
    return result;
  }, []);

  const handleExport = useCallback(
    async (selected?: { key: string }) => {
      cancellation.current = false;
      const result = isArray(data) ? data : await collectData(data);
      if (cancellation.current) return;
      if (!result?.length || !selected) {
        // Export button shouldn't even render, just being defensive here.
        message.warning("Nothing to export");
        return;
      }
      // Must clear URL in order for new downloads to trigger
      setDownloadUrl(undefined);
      setLoading(true);
      try {
        const option = options[selected.key];
        const blob = await option.blob(result);
        const url = URL.createObjectURL(new Blob([blob]));
        setDownloadUrl(url);
        setDownloadName(`${filename}.${option.extension}`);
        setLoading(false);
      } catch (e) {
        console.error(e);
        message.error("Error exporting ");
        setLoading(false);
      }
    },
    [collectData, data, filename, options]
  );

  const cancelExport = useCallback(() => {
    cancellation.current = true;
  }, []);

  const menuProps = useMemo(
    () => ({
      items: Object.entries(options).map(([key, { label }]) => ({
        key,
        label: `Export as ${label}`,
      })),
      onClick: handleExport,
    }),
    [handleExport, options]
  );

  // We have to click the hidden anchor inside a useEffectHook to ensure that
  // the setDownloadUrl state update completed and the anchor will download the new data.
  // Otherwise the first click on the button downloads an empty blob - the null state.
  useEffect(() => {
    if (downloadUrl) anchorRef.current?.click();
  }, [downloadUrl]);

  return (
    <>
      <Dropdown menu={menuProps} disabled={loading || !data?.length}>
        <Button icon={loading ? <LoadingOutlined /> : <ExportOutlined />}>
          {title}
        </Button>
      </Dropdown>
      <Modal
        closable={false}
        footer={<Button onClick={cancelExport}>Cancel</Button>}
        maskClosable={false}
        open={collecting}
      >
        <Title level={4}>Exporting data...</Title>
        <Progress percent={progress.progress * 100} showInfo={false} />
        <div>{progress.message}</div>
        <div style={{ marginTop: "1em" }}>
          <Text type="secondary">
            To speed up export, filter monitors or select a single target.
          </Text>
        </div>
      </Modal>
      <a hidden href={downloadUrl} download={downloadName} ref={anchorRef}>
        Click to download
      </a>
    </>
  );
};
