import { Typography } from "antd";
import { GraphTooltip } from "components/GraphTooltip";
import { policyName } from "shared/integrations/resources/aws/util";
import { roleName } from "shared/integrations/resources/gcloud/util";
import { ALL_SCOPE_SENTINEL, ProviderOrAll } from "shared/types/assessment";
import { assertNever } from "utils/assert";

import { HasAddTerm, ShowHideTerm } from "./ShowHide";

const awsLabel = (policyStatement: string | undefined) => {
  if (!policyStatement) return null;
  const [policy, statement] = policyStatement.split(":");
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {policy}
      {statement && (
        <Typography.Text type="secondary">
          {isNaN(+statement) ? statement : `Statement ${statement}`}
        </Typography.Text>
      )}
    </div>
  );
};

const k8sLabel = (privilegeSet: string) => {
  const splitIndex = privilegeSet.lastIndexOf(":");
  const roleId = privilegeSet.substring(0, splitIndex);
  const ruleIndex = privilegeSet.substring(splitIndex + 1);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {roleId}
      <Typography.Text type="secondary">{`Rule ${ruleIndex}`}</Typography.Text>
    </div>
  );
};

export const privilegeSetName = (
  provider: ProviderOrAll,
  privilegeSet: string | undefined
) =>
  privilegeSet
    ? provider === "aws"
      ? awsLabel(policyName(privilegeSet))
      : provider === "gcp"
      ? roleName(privilegeSet)
      : provider === "k8s"
      ? k8sLabel(privilegeSet)
      : provider === "all" ||
        provider === "azure-ad" ||
        provider === "okta" ||
        provider === "workspace"
      ? privilegeSet
      : assertNever(provider)
    : undefined;

export const PrivilegeSet: React.FC<
  {
    privilegeSet?: string;
    provider?: ProviderOrAll;
  } & HasAddTerm
> = ({ privilegeSet, provider, ...props }) => {
  const name = privilegeSetName(provider ?? ALL_SCOPE_SENTINEL, privilegeSet);
  return name ? (
    <GraphTooltip
      width="fit-content"
      title={
        <>
          {name}
          <ShowHideTerm
            term={`role:"${privilegeSet}"`}
            name="roles"
            {...props}
          />
        </>
      }
    >
      {name}
    </GraphTooltip>
  ) : null;
};
