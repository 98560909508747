import { InfoCircleTwoTone } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { GraphTooltip } from "components/GraphTooltip";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useContext, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  InventoryItem as InventoryItemType,
  countNodes,
  generateInventoryData,
} from "shared/assessment/inventory";
import { DirectedGraph } from "shared/graph/types";
import { ALL_SCOPE_SENTINEL } from "shared/types/assessment";
import { AssessmentNodes } from "shared/types/assessment/data";
import styled from "styled-components";
import { staticError } from "utils/console";

import { ScopeContext } from "../contexts/ScopeContext";
import { useControls } from "../hooks/useControls";
import { useInventoryCounts } from "../hooks/useInventoryCounts";

const InventoryItemDiv = styled.div`
  &:not(:first-child) {
    margin-left: 16px;
  }
`;

const InventoryItem: React.FC<{
  link: InventoryItemType<any>;
  graph: DirectedGraph<AssessmentNodes>;
}> = ({ link, graph }) => {
  const { controls } = useControls();
  const [count, setCount] = useState<number>();
  const { scopeKey } = useContext(ScopeContext);
  const { doc: allInventoryCounts, loading } = useInventoryCounts();

  useGuardedEffect(
    (cancellation) => async () => {
      if (loading) return;
      const count =
        scopeKey !== ALL_SCOPE_SENTINEL || !allInventoryCounts
          ? (await countNodes(link, graph)).nodes.length
          : allInventoryCounts?.[link.show]?.[link.label] ?? 0;
      cancellation.guard(setCount)(count);
    },
    [graph, link, scopeKey, allInventoryCounts, loading],
    staticError
  );

  const [search] = useSearchParams();
  const url = useMemo(() => {
    const params = new URLSearchParams(search);
    params.set("show", link.show);
    params.set("where", link.where);
    return `?${params.toString()}`;
  }, [link, search]);

  const isCurrent =
    controls.show === link.show && controls.where === link.where;

  return (
    <InventoryItemDiv>
      {count && !isCurrent ? (
        <Link to={url}>{link.label}</Link>
      ) : (
        <Typography.Text
          type="secondary"
          style={isCurrent ? { fontWeight: "bold" } : {}}
        >
          {link.label}
        </Typography.Text>
      )}
      &nbsp;
      {link.tooltip && (
        <>
          <GraphTooltip title={link.tooltip}>
            <InfoCircleTwoTone />
          </GraphTooltip>
          &nbsp;
        </>
      )}
      <Typography.Text type="secondary">({count})</Typography.Text>
    </InventoryItemDiv>
  );
};

export const Inventory: React.FC<object> = () => {
  const { assessment } = useContext(SelectedEnvironmentContext);
  const { graph } = useContext(ScopeContext);

  const allLinks = useMemo(
    () =>
      assessment.doc
        ? generateInventoryData(assessment.doc.data.targets)
        : undefined,
    [assessment]
  );

  return graph && allLinks ? (
    <Row style={{ maxWidth: "1000px", width: "100%" }}>
      {Object.values(allLinks).map((links, category) => (
        <Col key={category} span={8}>
          {Object.values(links).map((link, key) => (
            <InventoryItem key={key} graph={graph} link={link} />
          ))}
        </Col>
      ))}
    </Row>
  ) : null;
};
