import { Result } from "antd";
import ContactSupport from "components/Integrations/components/ContactSupport";
import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { colors } from "styles/variables";

interface PanelErrorBoundaryProps {
  children: React.ReactNode;
  title: string;
}

interface ErrorFallbackProps extends FallbackProps {
  title?: string;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ title }) => {
  return (
    <Result
      style={{ backgroundColor: colors.neutral["00"], height: "100%" }}
      status="error"
      title={`Error Loading ${title} Data`}
      subTitle={
        <>
          We&apos;re having trouble loading this content. Please try refreshing
          the page. If this issue persists, please
          <ContactSupport subject={"Error Loading"} />.
        </>
      }
    />
  );
};

export const PanelErrorBoundary: React.FC<PanelErrorBoundaryProps> = ({
  children,
  title,
}) => {
  const Fallback = React.useCallback(
    (props: FallbackProps) => <ErrorFallback {...props} title={title} />,
    [title]
  );

  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
};
