import { Button } from "antd";
import { useCallback, useMemo } from "react";
import { assertNever } from "shared/types";
import { TargetNodeType } from "shared/types/assessment/data";
import styled from "styled-components";

export type HasAddTerm = {
  terms: string | undefined;
  show?: TargetNodeType;
  onAddTerm: (term: string) => void;
};

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
`;

const LeftButton = styled(Button)`
  text-align: left;
`;

const AddTerm: React.FC<
  HasAddTerm & {
    mode: "hide" | "only" | "show";
    name?: string;
    term: string;
  }
> = ({ mode, name, term, terms, onAddTerm }) => {
  const updateTerm = useMemo(
    () =>
      mode === "show"
        ? term
        : term.startsWith("^")
        ? term.slice(1)
        : `^${term}`,
    [mode, term]
  );
  const onClick = useCallback(
    () => onAddTerm(updateTerm),
    [onAddTerm, updateTerm]
  );
  return (
    <LeftButton
      size="small"
      style={{ fontSize: "13px" }}
      type="link"
      onClick={onClick}
      disabled={!!terms?.split(" ").find((t) => t === updateTerm)}
    >
      {mode === "show"
        ? "\u2795 Show"
        : mode === "hide"
        ? "\u2796 Hide"
        : mode === "only"
        ? "\u2797 Show only"
        : assertNever(mode)}{" "}
      {name ?? "items"} like this
    </LeftButton>
  );
};

export const ShowHideTerm: React.FC<
  HasAddTerm & {
    inverse?: string;
    term: string;
    name?: string;
  }
> = (props) => (
  <ColumnDiv>
    <AddTerm mode="show" {...props} />
    <AddTerm mode="hide" {...props} />
    {props.inverse && <AddTerm mode="only" {...props} term={props.inverse} />}
  </ColumnDiv>
);
