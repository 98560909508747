import { Drawer } from "antd";
import { startCase } from "lodash";
import { ReactNode, useMemo } from "react";
import { NodeOf } from "shared/graph/types";

export const NodeDrawer = <G extends object>(props: {
  node?: NodeOf<G>;
  onClose: () => void;
  describer: (node: NodeOf<G>) => ReactNode;
}) => {
  const { describer, node, onClose } = props;

  const drawerTitle = useMemo(
    () => (node ? startCase(String(node.type)) : undefined),
    [node]
  );

  return (
    <Drawer
      styles={{
        body: { padding: 0 },
        header: { padding: "10px" },
      }}
      // Poorly documented API that forces inline drawer display
      getContainer={false}
      mask={false}
      onClose={onClose}
      open={!!node}
      placement="right"
      title={drawerTitle}
      width="min(80%, 450px)"
    >
      {!!node ? describer(node) : null}
    </Drawer>
  );
};
