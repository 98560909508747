import { InfoCircleTwoTone } from "@ant-design/icons";
import { GraphTooltip } from "components/GraphTooltip";
import { TooltipCode } from "components/TooltipCode";

export const PriorityTooltip = () => (
  <GraphTooltip
    title="Priority"
    width="500px"
    overlay={
      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        <li>
          <TooltipCode>Urgent</TooltipCode>&nbsp;&minus;&nbsp;Urgent findings
          indicate the presence of a security vulnerability and require action.
        </li>
        <li>
          <TooltipCode>High</TooltipCode>&nbsp;&minus;&nbsp;High findings
          indicate that security best practices are being violated. A
          vulnerability may or may not be present.
        </li>
        <li>
          <TooltipCode>Medium</TooltipCode>&nbsp;&minus;&nbsp;Medium findings
          are informational and can be used to further understand your security
          posture.
        </li>
        <li>
          <TooltipCode>Low</TooltipCode>&nbsp;&minus;&nbsp;Low findings present
          no security risk and can be used to inventory your system.
        </li>
      </ul>
    }
  >
    <InfoCircleTwoTone />
  </GraphTooltip>
);
