import moment from "moment";
import { RangePickerProps } from "rc-picker";

export const defaultRangePresets: ({
  includeTime,
}: {
  includeTime: boolean;
}) => RangePickerProps<moment.Moment>["presets"] = ({ includeTime }) => [
  includeTime
    ? {
        label: "Last 24 hours",
        value: [moment().subtract(24, "hours"), moment()],
      }
    : {
        label: "Last 1 day",
        value: [moment().subtract(1, "day"), moment()],
      },
  {
    label: "Last 7 days",
    value: [moment().subtract(7, "days"), moment()],
  },
  {
    label: "Last 30 days",
    value: [moment().subtract(30, "days"), moment()],
  },
];
