import { StarOutlined } from "@ant-design/icons";
import { Grid } from "antd";
import { NewMonitorModal } from "components/Assessment/components/MonitorAdmin";
import { useControls } from "components/Assessment/hooks/useControls";
import { GraphTooltip } from "components/GraphTooltip";
import { AuthzButton } from "components/common/AuthzButton";
import { useCallback, useState } from "react";

export const CreateMonitorButton: React.FC<{
  disabled: boolean;
}> = ({ disabled }) => {
  const { controls } = useControls();
  const { md } = Grid.useBreakpoint();
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = useCallback(() => setModalOpen(false), [setModalOpen]);
  const openModal = useCallback(() => setModalOpen(true), [setModalOpen]);
  return (
    <>
      <GraphTooltip
        title={
          controls.where
            ? "Create a monitor from this search query"
            : "Enter a search query to get started"
        }
      >
        <AuthzButton
          disabled={disabled || !controls.where}
          icon={<StarOutlined />}
          roles={["owner", "iamOwner"]}
          onClick={openModal}
        >
          {md ? "Create Monitor" : ""}
        </AuthzButton>
      </GraphTooltip>
      <NewMonitorModal
        open={modalOpen}
        footer={false}
        closeModal={closeModal}
        searchTerm={controls.where}
        show={controls.show}
      />
    </>
  );
};
