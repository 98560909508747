import {
  Button,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { ErrorDisplay } from "components/Error";
import { useAuthFetch } from "components/Login/hook";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { capitalize, compact, sortBy } from "lodash";
import pluralize from "pluralize";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  ControllerProps,
  ControllerRenderProps,
  UseFormStateReturn,
} from "react-hook-form";
import {
  AssessmentScope,
  AssessmentScopeIntegration,
  GroupAssessmentScope,
  isGroupAssessmentScope,
  isItemAssessmentScope,
} from "shared/types/assessment";
import { mapWith } from "shared/util/collections";
import { assertNever } from "utils/assert";

import { EnvironmentCreationContext } from "../contexts/EnvironmentCreationContext";
import { scopeLabel } from "./Targets";

const { Text } = Typography;

export type TargetInput = {
  targets: {
    scopes: AssessmentScope[];
    subtractiveScopes: AssessmentScope[];
  };
};

export type TargetSelectProps = {
  control: Control<TargetInput>;
  currentIntegration: AssessmentScopeIntegration;
};

type ScopeOption = {
  value: string;
  label?: string;
  type: AssessmentScope["type"];
};

const toOption = (scope: AssessmentScope): ScopeOption | undefined =>
  scope.type === "organization"
    ? undefined
    : scope.type === "group"
    ? { value: scope.value, label: scope.label, type: scope.type }
    : scope.type === "project"
    ? { value: scope.id, label: scope.label, type: scope.type }
    : assertNever(scope);

const fromOption =
  (integration: AssessmentScopeIntegration, type: AssessmentScope["type"]) =>
  (option: ScopeOption): AssessmentScope => {
    const base = { integration, label: option.label };
    // TODO: remove GCP-specific hard-coding
    return type === "organization"
      ? { ...base, type }
      : type === "group"
      ? { ...base, type, key: "folder", value: option.value }
      : type === "project"
      ? { ...base, type, id: option.value }
      : assertNever(type);
  };

const TargetInput = ({
  field,
  state,
  currentIntegration,
}: {
  field: ControllerRenderProps<TargetInput, "targets">;
  state: UseFormStateReturn<TargetInput>;
  currentIntegration: AssessmentScopeIntegration;
}) => {
  const [available, setAvailable] = useState<AssessmentScope[]>();
  const [error, setError] = useState<string>();
  const [type, setType] = useState<AssessmentScope["type"]>("project");
  const { formInputs, setFormInputs } = useContext(EnvironmentCreationContext);
  const authFetch = useAuthFetch(setError);

  const value = field.value ?? {
    type: "project",
    scopes: [],
    subtractiveScopes: [],
  };

  useEffect(() => {
    setAvailable(undefined);
  }, [currentIntegration]);

  useGuardedEffect(
    (cancellation) => async () => {
      const response = await authFetch(
        `assessment/_meta/integrations/${currentIntegration}/scopes`,
        { method: "GET" }
      );
      if (!response) return;
      const { scopes } = await response.json();
      if (cancellation.isCancelled) return;
      setAvailable(scopes);
    },
    [authFetch, currentIntegration],
    setError
  );

  const radioOptions = useMemo(() => {
    const options = [{ value: "organization", label: "Organization" }];
    if (available?.find(isGroupAssessmentScope)) {
      options.push({
        value: "group",
        label:
          pluralize(capitalize(scopeLabel[currentIntegration].group)) ??
          "Groups",
      });
    }
    if (available?.find(isItemAssessmentScope)) {
      options.push({
        value: "project",
        label:
          pluralize(capitalize(scopeLabel[currentIntegration].project)) ??
          "Items",
      });
    }
    return options;
  }, [available, currentIntegration]);

  const selectOptions = useMemo(
    () =>
      sortBy(
        compact((available ?? []).filter((a) => a.type === type).map(toOption)),
        (o) => o.label ?? o.value
      ),
    [available, type]
  );

  const inPath = (
    prefixes: GroupAssessmentScope[],
    value: string | undefined
  ): boolean => {
    if (prefixes.some((prefix) => prefix.label === value)) {
      return false;
    }
    return (
      !!value &&
      prefixes.some((prefix) => prefix.label && value.startsWith(prefix.label))
    );
  };

  const subtractSelectOptions = useMemo(
    () =>
      sortBy(
        compact(
          mapWith(available ?? [], function* (a) {
            if (type === "organization" && a.type !== type) {
              yield toOption(a);
            } else if (
              type === "group" &&
              a.type !== "organization" &&
              inPath(value.scopes as GroupAssessmentScope[], a.label)
            ) {
              yield toOption(a);
            }
          })
        ),
        (o) => o.label ?? o.value
      ),
    [available, type, value.scopes]
  );
  const selectedValues = useMemo(
    () => compact(value.scopes.map(toOption)).map((s) => s.value),
    [value.scopes]
  );
  const selectedSubtractiveValues = useMemo(
    () => compact(value.subtractiveScopes.map(toOption)).map((s) => s.value),
    [value.subtractiveScopes]
  );

  useEffect(() => {
    setType("project");
    field.onChange({
      type: "project",
      scopes: [],
      subtractiveScopes: [],
    });
    // We only want to reset field when the integration changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIntegration]);

  const onChangeRadio = useCallback(
    (event: RadioChangeEvent) => {
      field.onChange({
        type: event.target.value,
        scopes: [],
        subtractiveScopes: [],
      });
      setType(event.target.value);
    },
    [field]
  );

  const onSubmitAddScope = useCallback(() => {
    if (type === "organization") {
      field.value.scopes = [
        { integration: currentIntegration, type: "organization" },
      ];
    }
    setFormInputs({
      targets: {
        scopes: [...formInputs.targets.scopes, ...field.value.scopes],
        subtractiveScopes: [
          ...formInputs.targets.subtractiveScopes,
          ...field.value.subtractiveScopes,
        ],
      },
    });
  }, [
    field.value,
    formInputs.targets,
    currentIntegration,
    setFormInputs,
    type,
  ]);

  const onChangeItems = useCallback(
    (key: "scopes" | "subtractiveScopes") =>
      (_: string[], options: ScopeOption | ScopeOption[] | undefined) => {
        if (!options) {
          return;
        }

        const currentTarget = field.value;

        const scopes: AssessmentScope[] = [options]
          .flat()
          .map((s) => fromOption(currentIntegration, s.type)(s));
        field.onChange({ ...currentTarget, [key]: scopes });
      },
    [field, currentIntegration]
  );
  const onChangeScopes = useMemo(
    () => onChangeItems("scopes"),
    [onChangeItems]
  );
  const onChangeSubtractiveScopes = useMemo(
    () => onChangeItems("subtractiveScopes"),
    [onChangeItems]
  );
  const filterSearch = useCallback(
    (term: string, option: ScopeOption | undefined) =>
      option?.label?.toLowerCase().includes(term.toLowerCase()) ?? true,
    []
  );

  return !available && !error ? (
    <Spin />
  ) : (
    <Space
      direction="vertical"
      size="small"
      style={{ width: "100%", marginBottom: "1em" }}
    >
      {error && (
        <ErrorDisplay
          title="Could not load available assessment targets"
          error={error}
        />
      )}
      <Radio.Group
        options={radioOptions}
        optionType="button"
        buttonStyle="solid"
        value={type}
        onChange={onChangeRadio}
      />
      {
        // Default to project select to encourage lower-burden assessments
        type !== "organization" && (
          <Select<string[], ScopeOption>
            defaultValue={[]}
            mode="multiple"
            onChange={onChangeScopes}
            options={selectOptions}
            filterOption={filterSearch}
            placeholder={`Select ${pluralize(
              scopeLabel[currentIntegration][type] ?? capitalize(type)
            )}`}
            style={{ width: "100%" }}
            value={selectedValues}
          />
        )
      }
      {type !== "project" && (
        <>
          <span>
            Excluding these targets: <Text type="secondary">(optional)</Text>
          </span>
          <Select<string[], ScopeOption>
            defaultValue={[]}
            mode="multiple"
            onChange={onChangeSubtractiveScopes}
            filterOption={filterSearch}
            options={subtractSelectOptions}
            placeholder={
              type === "organization"
                ? `Select ${pluralize(
                    scopeLabel[currentIntegration]["project"] ??
                      capitalize("project")
                  )} and ${pluralize(
                    scopeLabel[currentIntegration]["group"] ??
                      capitalize("group")
                  )} to exclude`
                : `Select ${pluralize(
                    scopeLabel[currentIntegration]["project"] ??
                      capitalize("project")
                  )} to exclude`
            }
            style={{ width: "100%" }}
            value={selectedSubtractiveValues}
          />
        </>
      )}
      <Button
        type="primary"
        onClick={onSubmitAddScope}
        disabled={
          type !== "organization" &&
          selectedValues.length == 0 &&
          selectedSubtractiveValues.length == 0
        }
      >
        Add scopes
      </Button>
      {state.errors.targets && (
        <span style={{ color: "red" }} role="alert">
          A selection is required.
        </span>
      )}
    </Space>
  );
};

export const TargetSelect = ({
  control,
  currentIntegration,
}: TargetSelectProps): React.ReactElement => {
  const renderTargets = useCallback<
    ControllerProps<TargetInput, "targets">["render"]
  >(
    ({ field, formState }) => (
      <TargetInput
        field={field}
        state={formState}
        currentIntegration={currentIntegration}
      />
    ),
    [currentIntegration]
  );
  return (
    <Controller
      name="targets"
      rules={{ required: true }}
      control={control}
      render={renderTargets}
    />
  );
};
