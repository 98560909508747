import { Dashboard } from "components/Dashboard/Dashboard";
import { EnvironmentSelector } from "components/Environment/components/EnvironmentSelector";
import { EnvironmentProvider } from "components/Environment/contexts/SelectedEnvironmentContext";
import { Outlet, Route } from "react-router-dom";
import { AppPaths } from "shared/routes/constants";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { Page } from "../App/Page";
import { CatalogProvider } from "../Catalog/CatalogProvider";
import { NavigateWithEnvironment } from "./components/NavigateWithEnvironment";
import { SettingsRoutes } from "./components/Settings/Routes";
import { FindingsProvider } from "./contexts/FindingsContext";
import { ScopeProvider } from "./contexts/ScopeContext";
import { Explore } from "./pages/Explore";
import { Findings } from "./pages/Findings";
import { MonitorResults } from "./pages/MonitorResults";

export const AssessmentRoutes = (
  <Route
    element={
      <EnvironmentProvider>
        <CatalogProvider>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <ScopeProvider>
              <Outlet />
            </ScopeProvider>
          </QueryParamProvider>
        </CatalogProvider>
      </EnvironmentProvider>
    }
  >
    <Route
      path={AppPaths.Dashboard}
      element={
        <Page title="Dashboard">
          <FindingsProvider>
            <Dashboard />
          </FindingsProvider>
        </Page>
      }
    />

    <Route
      element={
        <>
          <EnvironmentSelector />
          <Outlet />
        </>
      }
    >
      {SettingsRoutes}
      <Route
        path={AppPaths.Inventory}
        element={
          <Page title="Inventory">
            <Explore />
          </Page>
        }
      >
        <Route index element={<></>} />
        <Route path=":nodeType/:nodeKey" element={<></>} />
      </Route>

      <Route
        path={AppPaths.Posture}
        element={
          <Page title="Posture">
            <FindingsProvider>
              <Outlet />
            </FindingsProvider>
          </Page>
        }
      >
        <Route index element={<NavigateWithEnvironment to="monitors" />} />
        <Route path="monitors" element={<Findings />} />
        <Route path="monitors/:monitorId" element={<MonitorResults />}>
          <Route index element={<></>} />
          <Route path="findings/:findingId" element={<></>} />
          {/* Prevent re-direction all the way to requests;
           * use 'replace' so that back button works
           */}
          <Route
            path="*"
            element={
              <NavigateWithEnvironment
                to={{ pathname: "findings", search: "?scope=all" }}
                replace
              />
            }
          />
        </Route>
      </Route>
    </Route>
  </Route>
);
