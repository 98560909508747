import { DatePicker } from "antd";
import type { Moment } from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";

// Default date library for Antd v5 is DayJS but we still use moment
// https://ant.design/docs/react/use-custom-date-library
// TODO: Switch to using date-fns across the board (ENG-3817)
const CustomDatePicker =
  DatePicker.generatePicker<Moment>(momentGenerateConfig);

export default CustomDatePicker;
