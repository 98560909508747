import { SettingOutlined } from "@ant-design/icons";
import dagre from "@dagrejs/dagre";
import { Node as FlowNode, Handle, NodeProps } from "@xyflow/react";
import { Alert } from "antd";
import {
  FitViewOptions,
  GraphVisualization,
  NodeFlowEdge,
  NodeFlowRenderer,
  NodeFlowSpec,
  NodePropertiesRenderer,
} from "components/Assessment/components/GraphVisualization";
import {
  DefaultNodeHeaderHeight,
  DefaultNodeOneLineBodyHeight,
  DefaultNodeWidth,
} from "components/Assessment/components/node/renderers/shared";
import { GraphContext } from "components/Assessment/contexts/GraphContext";
import { VerticalSpacedDiv } from "components/divs";
import { compact, flatten, uniqBy } from "lodash";
import pluralize from "pluralize";
import React, { useCallback, useContext, useMemo } from "react";
import { DiscoverMatch } from "shared/graph/discover";
import { keyOf } from "shared/graph/graph";
import { GraphSearchSettings } from "shared/graph/settings";
import { ConnectedNode, Node, NodeOf } from "shared/graph/types";
import { DirectedGraph } from "shared/graph/types";
import styled from "styled-components";
import { join } from "utils/join";

import { Collapsed, CollapsedNode, collapseGraph } from "./collapse";

type DivProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "ref"
>;

export type NodeDimensions = {
  width: number;
  height: number;
};

export type NodeFlowSizer<A extends object> = {
  [K in keyof A]: {
    /* large indicates how much space the node takes up in the graph */
    large: (node: Node<A, K>) => NodeDimensions;
    /* small indicates how much space a subnode of a collapsible node should take up */
    small: (node: Node<A, K>) => NodeDimensions;
  };
};

export type DiscoverVisualizationProps<G extends object> = DivProps & {
  matches: DiscoverMatch<DirectedGraph<G>>[];
  renderer: NodeFlowRenderer<G>;
  sizer: NodeFlowSizer<G>;
  settings: GraphSearchSettings;
  properties: NodePropertiesRenderer<G>;
  viewStyle?: DivProps["style"];
  fitView?: boolean;
  fitViewOptions?: FitViewOptions;
};

const NodeExpandedDiv = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
`;

const NodeInnerDiv = styled.div`
  overflow-x: clip;
`;

const NodeCollapsedDiv = styled.div`
  align-content: center;
`;

type CollapsibleNodeProps<G extends object> = NodeProps<
  FlowNode<
    {
      node: ConnectedNode<G & Collapsed<G>, keyof G | "_collapsed">;
      spec: NodeFlowSpec<G & Collapsed<G>>;
    },
    string
  >
>;

const CollapsibleNodeGapAndPadding = 8; // pixels

const CollapsibleNode = <G extends object>({
  nodeProps,
  renderer,
  sizer,
}: {
  nodeProps: CollapsibleNodeProps<G>;
  renderer: NodeFlowRenderer<G>;
  sizer: NodeFlowSizer<G>;
}) => {
  const { data } = nodeProps;
  const { node } = data as {
    node: ConnectedNode<Collapsed<G>, "_collapsed">;
  };

  const { open, toggleOpen, graphVisualizationInstance } =
    useContext(GraphContext);
  if (node.type !== "_collapsed") {
    return null;
  }

  const openKey = keyOf(node);
  const header = (
    <>
      <span>
        {`${node.data.nodes.length} ${pluralize(
          String(node.data.nodes[0].type)
        )}`}
      </span>
      <span style={{ paddingLeft: "4px" }}>
        (
        {
          // Rendered data are in a useMemo
          // eslint-disable-next-line react/jsx-no-bind
          <a type="link" onClick={() => toggleOpen(openKey)}>
            {open[openKey] ? "collapse" : "expand"}
          </a>
        }
        )
      </span>
    </>
  );
  const { sourcePosition, targetPosition } = nodeProps;

  const body = open[openKey] ? (
    <NodeExpandedDiv
      style={{
        padding: "8px",
        border: "1px solid gray",
        borderRadius: "5px",
        backgroundColor: "lightgray",
      }}
    >
      {(node.data.nodes as NodeOf<G>[]).map((n, ix) => {
        if (n.type === "_collapsed") {
          return null;
        }
        const size = sizer[n.type].small(n);
        const { width, height } = size;
        const SmallRenderer = renderer[n.type].Small;
        return (
          <NodeInnerDiv
            key={ix}
            style={{
              width: width,
              maxWidth: width,
              height: height,
              maxHeight: height,
              overflow: "clip",
            }}
            // Rendered data are in a useMemo
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(e) => {
              if (n.type !== "_collapsed") {
                graphVisualizationInstance?.selectNode(n);
                // stopping the propagation here prevents the event handler
                // from being called on one of its parent nodes
                // (e.g. the _collapsed node)
                e.stopPropagation();
              }
            }}
          >
            {n.type !== "_collapsed" ? (
              <SmallRenderer
                {...nodeProps}
                data={{
                  spec: nodeProps.data.spec as NodeFlowSpec<G>,
                  node: n as ConnectedNode<G, keyof G>,
                }}
              />
            ) : null}
          </NodeInnerDiv>
        );
      })}
    </NodeExpandedDiv>
  ) : (
    <NodeCollapsedDiv style={{ position: "relative", height: "unset" }}>
      <div
        style={{
          padding: "10px",
          borderRadius: "5px",
          border: `1px solid gray`,
          backgroundColor: "lightgray",
        }}
        // Rendered data are in a useMemo
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => toggleOpen(openKey)}
      >
        Click to expand.
      </div>
    </NodeCollapsedDiv>
  );

  return (
    <div>
      <div>{header}</div>
      <div style={{ position: "relative" }}>
        {body}
        {targetPosition ? (
          <Handle type="target" position={targetPosition} />
        ) : null}
        {sourcePosition ? (
          <Handle type="source" position={sourcePosition} />
        ) : null}
      </div>
    </div>
  );
};

const CollapsibleNodeRenderer = <G extends object>(
  renderer: NodeFlowRenderer<G>,
  sizer: NodeFlowSizer<G>
): {
  Large: React.FC<CollapsibleNodeProps<G>>;
  Small: React.FC<CollapsibleNodeProps<G>>;
} => ({
  Large: (props) => (
    <CollapsibleNode nodeProps={props} renderer={renderer} sizer={sizer} />
  ),
  Small: () => null,
});

const CollapsibleNodeSizer = <G extends object>(
  sizer: NodeFlowSizer<G>,
  open: Record<string, boolean>
): {
  large: (
    node: Node<G & Collapsed<G>, keyof G | "_collapsed">
  ) => NodeDimensions;
  small: (
    node: Node<G & Collapsed<G>, keyof G | "_collapsed">
  ) => NodeDimensions;
} => ({
  large: (node) => {
    if (node.type !== "_collapsed") {
      return { width: 0, height: 0 };
    }

    if (!open[keyOf(node)]) {
      return {
        width: DefaultNodeWidth,
        height: DefaultNodeOneLineBodyHeight + DefaultNodeHeaderHeight,
      };
    }

    const subNodes = (node as Node<G & Collapsed<G>, "_collapsed">).data
      .nodes as NodeOf<G>[];
    const sizes = subNodes.map((n) => sizer[n.type].small(n));

    // split array into chunks
    const chunkSize = 2;
    const columnWidths = new Map<number, number>();
    const rowHeights = new Map<number, number>();
    for (let i = 0; i < sizes.length; i++) {
      const col = i % chunkSize;
      const row = Math.floor(i / chunkSize);
      const spec = sizes[i];
      columnWidths.set(col, Math.max(columnWidths.get(col) ?? 0, spec.width));
      rowHeights.set(row, Math.max(rowHeights.get(row) ?? 0, spec.height));
    }

    // calculate the total size occupied by an expanded collapsible node,
    // including padding and gap
    const width = Array.from(columnWidths.values()).reduce((a, b) => a + b, 0);
    const height = Array.from(rowHeights.values()).reduce((a, b) => a + b, 0);
    return {
      width: width + CollapsibleNodeGapAndPadding * (columnWidths.size + 1),
      height:
        height +
        CollapsibleNodeGapAndPadding * (rowHeights.size + 1) +
        DefaultNodeHeaderHeight,
    };
  },
  small: () => ({
    width: 0,
    height: 0,
  }),
});

/** Renders a graph visualization of queried paths
 */
export const DiscoverVisualization = <G extends object>(
  props: DiscoverVisualizationProps<G>
) => {
  const {
    matches,
    renderer,
    sizer,
    settings,
    properties,
    viewStyle,
    fitView,
    fitViewOptions,
    ...divProps
  } = props;
  const { open, setGraphVisualizationInstance } = useContext(GraphContext);

  const fullNodeRenderer: NodeFlowRenderer<G & Collapsed<G>> = useMemo(
    () =>
      ({
        ...renderer,
        _collapsed: CollapsibleNodeRenderer(renderer, sizer),
      }) as any,
    [renderer, sizer]
  );

  const fullNodeSizer: NodeFlowSizer<G & Collapsed<G>> = useMemo(
    () =>
      ({
        ...sizer,
        _collapsed: CollapsibleNodeSizer(sizer, open),
      }) as any,
    [sizer, open]
  );

  const fullDescriber: NodePropertiesRenderer<G & Collapsed<G>> = useCallback(
    (node: any) => (node.type === "_collapsed" ? null : properties(node)),
    [properties]
  );

  const { nodes, edges, warnings } = useMemo(() => {
    const before = performance.now();

    const noDataWarning =
      matches.length === 0 ? (
        <span key="no-data">
          Query returned no data. Please modify your search.
        </span>
      ) : null;

    const resultLimitWarning =
      matches.length > settings.maxResults ? (
        <span key="result-limit">
          Query returned {matches.length} results, of which the first{" "}
          {settings.maxResults} are shown.
        </span>
      ) : null;
    let pathLimitWarning: JSX.Element | null = null;
    const limited = matches.slice(0, settings.maxResults);
    const edges: [ConnectedNode<G, keyof G>, ConnectedNode<G, keyof G>][] = [];

    // These nodes do not connect to any other nodes (e.g. if there is no query)
    const isolatedNodes: Record<string, ConnectedNode<G, keyof G>> = {};
    for (const n of limited) {
      if (n.matches.every((m) => m.paths.every((p) => p.length < 2))) {
        isolatedNodes[keyOf(n.node)] = n.node;
      }
      for (const m of n.matches) {
        if (m.paths.length > settings.maxPaths) {
          pathLimitWarning = (
            <span key="path-limit">
              Query returned one or more matches for which only the first{" "}
              {settings.maxPaths} paths are shown.
            </span>
          );
        }
        for (const p of m.paths.slice(0, settings.maxPaths)) {
          let s = p[0];
          for (const n of p.slice(1)) {
            edges.push([s, n]);
            s = n;
          }
        }
      }
    }
    const uniqueEdges = uniqBy(edges, (e) => e.map(keyOf));

    const collapsedEdges = collapseGraph(uniqueEdges);

    const howToFixWarning =
      resultLimitWarning || pathLimitWarning ? (
        <div key="edit-info">
          You can edit these limits using the <SettingOutlined /> control above.
        </div>
      ) : undefined;

    const uniqueNodes = uniqBy(
      [
        ...(Object.values(isolatedNodes) as CollapsedNode<G>[]),
        ...flatten(collapsedEdges),
      ],
      (n) => keyOf(n)
    );

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({
      // Inter-node separation in pixels
      nodesep: 24,
      edgesep: 0,
      rankdir: "LR",
      // `tight-tree` is ~4-5x faster than the default `network-simplex` algo,
      // at the expense of sometimes leaving intermediate nodes placed too high
      // or too low. But for some customers this can turn layout times from a minute
      // to 15 seconds.
      ranker: "tight-tree",
      ranksep: 196,
    });
    for (const node of uniqueNodes) {
      const size = fullNodeSizer[node.type].large(node as any);

      const { width, height } = size;
      dagreGraph.setNode(keyOf(node), {
        width: width,
        height: height,
      });
    }
    for (const [parent, child] of collapsedEdges) {
      dagreGraph.setEdge(keyOf(parent), keyOf(child));
    }

    // This consumes the majority of render time. Since the library is synchronous, there is
    // unfortunately no way to prevent it from blocking the main UI render without resorting to
    // a web worker
    dagre.layout(dagreGraph);

    let maxX = 0,
      maxY = 0;
    for (const n of uniqueNodes) {
      const d = dagreGraph.node(keyOf(n));
      maxX = Math.max(d.x, maxX);
      maxY = Math.max(d.y, maxY);
    }
    const flowNodes: NodeFlowSpec<G & Collapsed<G>>[] = uniqueNodes.map((n) => {
      const d = dagreGraph.node(keyOf(n));
      return {
        inner: n,
        display: "inner" as const,
        height: d.height,
        width: d.width,
        position: {
          x: d.x - 0.5 * d.width,
          y: d.y - 0.5 * d.height,
        },
      };
    });
    const flowEdges: NodeFlowEdge<G & Collapsed<G>>[] = collapsedEdges.map(
      ([parent, child]) => ({
        parent,
        child,
      })
    );
    const after = performance.now();
    /* eslint-disable no-console */
    console.log(
      "Time to construct graph visualization",
      (after - before).toFixed(1),
      "ms"
    );
    console.log("  nodes:", flowNodes.length, "edges:", flowEdges.length);
    /* eslint-enable no-console */
    return {
      nodes: flowNodes,
      edges: flowEdges,
      warnings: compact([
        noDataWarning,
        resultLimitWarning,
        pathLimitWarning,
        howToFixWarning,
      ]),
    };
  }, [matches, settings, fullNodeSizer]);

  return (
    <VerticalSpacedDiv {...divProps}>
      {warnings.length ? (
        <div
          style={{
            left: "+12px",
            height: 0,
            marginTop: "0",
            position: "relative",
            width: "fit-content",
            top: "+20px",
            zIndex: "1",
          }}
        >
          <Alert
            type="warning"
            message={join(warnings, " ")}
            // Float over graph visualization
            style={{
              maxWidth: "1000px",
            }}
          />
        </div>
      ) : null}
      <GraphVisualization<G & Collapsed<G>>
        edges={edges}
        nodes={nodes}
        describer={fullDescriber}
        viewStyle={viewStyle}
        onInit={setGraphVisualizationInstance}
        renderer={fullNodeRenderer}
        fitView={fitView}
        fitViewOptions={fitViewOptions}
      />
    </VerticalSpacedDiv>
  );
};
