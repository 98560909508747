import { Alert, Button, Col, Row, Select, Spin, Typography } from "antd";
import { useAuthFetch } from "components/Login/hook";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  MsTeamsApi,
  MsTeamsConfig,
} from "shared/integrations/notifiers/ms-teams/types";
import { IntegrationConfig } from "shared/integrations/shared";

import { useFirestoreDoc } from "../../../providers/FirestoreProvider";
import { ClientConfigError } from "../../../shared/integrations/notifiers/slack/types";
import { Tenant } from "../../Login/util";
import { OAuth2IntegrationCard } from "../IntegrationCard";
import { TeamsLogo } from "./TeamsLogo";

interface ChanelSelectorProps {
  channels: MsTeamsApi.Channel[];
  channelId: string | undefined;
  handleSetChannel: (value: string) => void;
  teamId: string | undefined;
  fetching: boolean;
  loading: boolean;
}

const ChannelSelector: React.FC<ChanelSelectorProps> = ({
  channels,
  channelId,
  handleSetChannel,
  teamId,
  fetching,
  loading,
}) => (
  <Select
    options={channels.map((channel) => ({
      label: channel.displayName,
      value: channel.id,
    }))}
    value={channelId}
    onChange={handleSetChannel}
    placeholder={teamId ? "Select a channel" : "Select a team first"}
    disabled={!teamId || fetching || loading}
    style={{ minWidth: "12em" }}
  />
);

const Preamble: React.FC = () => {
  return (
    <>
      <Typography.Text>
        Complete these prerequisite steps to add the P0 Security bot to
        Microsoft Teams.
        <br /> You must have the{" "}
        <Typography.Text strong={true}>
          Privileged Role Administrator
        </Typography.Text>{" "}
        or <Typography.Text strong={true}>Global Administrator</Typography.Text>{" "}
        role in your Microsoft Entra ID directory.
      </Typography.Text>
      <br />
      <br />
      <ul style={{ paddingLeft: "15px" }}>
        <li>
          Click <a href="https://app.p0.dev//p0-security-teams-app.zip">here</a>{" "}
          to download the zip package of the P0 Security custom Teams app.
        </li>
        <li>
          Make the app available to users in your organization (
          <a href="https://learn.microsoft.com/en-us/microsoftteams/teams-custom-app-policies-and-settings#upload-a-custom-app-using-teams-admin-center">
            Teams docs
          </a>
          )
          <ul style={{ paddingLeft: "15px" }}>
            <li>
              Access app management in the{" "}
              <a href="https://admin.teams.microsoft.com/policies/manage-apps">
                Admin Center
              </a>
            </li>
            <li>
              Choose{" "}
              <Typography.Text strong={true}>
                Actions{" > "}Upload new app
              </Typography.Text>
            </li>
            <li>Upload the downloaded zip package</li>
          </ul>
        </li>
      </ul>
      <Typography.Text>
        After completing the above steps, click the button below to continue
        with the installation:{" "}
      </Typography.Text>
      <br />
      <br />
      <Alert
        description={
          <>
            It may take hours before the P0 Security app becomes available.
            <br />
            If you encounter errors during installation, try again later.
          </>
        }
        type="info"
        showIcon
      />
      <br />
    </>
  );
};

export const Teams: React.FC = () => {
  const tenantId = useContext(Tenant);
  const path = `o/${tenantId}/integrations/ms-teams`;
  const config = useFirestoreDoc<IntegrationConfig & MsTeamsConfig>(path, {
    live: true,
  });

  const clientError = useFirestoreDoc<ClientConfigError>(
    `${path}/client_errors/latest`,
    {
      live: true,
    }
  );

  const [complete, setComplete] = useState(false);
  const [teams, setTeams] = useState<MsTeamsApi.Team[]>([]);
  const [channels, setChannels] = useState<MsTeamsApi.Channel[]>([]);
  const [teamId, setTeamId] = useState<string | undefined>(undefined);
  const [channelId, setChannelId] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [fetching, setFetching] = useState(false);
  const [showSwitchButton, setShowSwitchButton] = useState(false);

  const fetch = useAuthFetch(setError, setFetching);

  const msTeamsTenantId = useMemo(
    () => config.doc?.data?.msTeamsTenantId,
    [config.doc]
  );
  const isInstalled = useMemo(
    () => config.doc?.data?.state === "installed",
    [config.doc?.data?.state]
  );
  const errorMessage = useMemo(
    () => error ?? clientError?.doc?.data?.message,
    [error, clientError]
  );
  const loading = useMemo(
    () => config.loading || clientError.loading || fetching,
    [clientError.loading, config.loading, fetching]
  );

  useGuardedEffect(
    (cancellation) => async () => {
      if (msTeamsTenantId && isInstalled) {
        const response = await fetch(`integrations/ms-teams/teams`, {});
        if (!response || !response.ok) return;
        const data = await response.json();
        cancellation.guard(setTeams)(data.teams);
      }
    },
    [msTeamsTenantId, fetch, isInstalled],
    setError
  );

  useGuardedEffect(
    (cancellation) => async () => {
      if (msTeamsTenantId && isInstalled && teamId) {
        const response = await fetch(
          `integrations/ms-teams/teams/${teamId}/channels`,
          {}
        );
        if (!response || !response.ok) return;
        const data = await response.json();
        cancellation.guard(setChannels)(data.channels);
      }
    },
    [msTeamsTenantId, fetch, teamId, isInstalled],
    setError
  );

  useGuardedEffect(
    (cancellation) => async () => {
      const data = config.doc?.data;
      if (cancellation.isCancelled) return;
      if (data && data.appId && data.channelId && data.teamId && isInstalled) {
        setChannelId(data.channelId);
        setTeamId(data.teamId);
        setComplete(true);
      }
    },
    [config.doc?.data, fetch, teamId, isInstalled],
    setError
  );

  useEffect(() => {
    setShowSwitchButton(
      config.doc?.data && config.doc?.data?.channelId !== channelId
    );
  }, [channelId, config.doc]);

  const handleSubmit = useCallback(async () => {
    await fetch(`integrations/ms-teams/connection`, {
      method: "POST",
      json: {
        teamId,
        channelId,
      },
    });
  }, [teamId, channelId, fetch]);

  const handleSetChannel = useCallback(
    (channelId: string) => {
      setChannelId(channelId);
    },
    [setChannelId]
  );

  const handleSetTeam = useCallback(
    (teamId: string) => {
      setTeamId(teamId);
      setChannelId(undefined);
    },
    [setTeamId, setChannelId]
  );

  const handleRemove = useCallback(async () => {
    await fetch(`integrations/ms-teams/connection`, {
      method: "DELETE",
    });
    setComplete(false);
    setError(undefined);
    setTeamId(undefined);
    setChannelId(undefined);
    setTeams([]);
    setChannels([]);
  }, [fetch]);

  return (
    <OAuth2IntegrationCard
      mode="consent"
      title="Microsoft Teams"
      integration="ms-teams"
      integrationDoc={config.doc}
      logo={<TeamsLogo />}
      preamble={
        !isInstalled && !complete && !loading && !error ? (
          <Preamble />
        ) : undefined
      }
      onRemove={msTeamsTenantId ? handleRemove : undefined}
    >
      {loading ? (
        <Spin />
      ) : errorMessage ? (
        <Alert type="error" message={errorMessage} />
      ) : complete ? (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Typography.Text>
              <b>Connected</b>
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text>
              <b>Team:</b>{" "}
              {teams.find((t) => t.id === teamId)?.displayName ?? teamId}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text>
              <b>Channel:</b>{" "}
            </Typography.Text>
            <ChannelSelector
              channels={channels}
              channelId={channelId}
              handleSetChannel={handleSetChannel}
              teamId={teamId}
              fetching={fetching}
              loading={loading}
            />
            <div>
              <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
                Only standard channels are eligible{" "}
              </Typography.Text>
            </div>
          </Col>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!showSwitchButton || !teamId || !channelId}
            loading={fetching}
            style={{ marginTop: "1em" }}
          >
            Switch Channel
          </Button>
        </Row>
      ) : (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <div>
              <Typography.Text>
                <b>Team</b>
              </Typography.Text>
            </div>
            <Select
              options={teams.map((team) => ({
                label: team.displayName,
                value: team.id,
              }))}
              value={teamId}
              onChange={handleSetTeam}
              placeholder="Select a team"
              disabled={fetching || loading}
              style={{ minWidth: "12em" }}
            />
          </Col>
          <Col span={24}>
            <div>
              <Typography.Text>
                <b>Channel</b>
              </Typography.Text>
            </div>
            <ChannelSelector
              channels={channels}
              channelId={channelId}
              handleSetChannel={handleSetChannel}
              teamId={teamId}
              fetching={fetching}
              loading={loading}
            />
            <div>
              <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
                Only standard channels are eligible{" "}
              </Typography.Text>
            </div>
          </Col>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!teamId || !channelId}
            loading={fetching}
            style={{ marginTop: "1em" }}
          >
            Connect
          </Button>
        </Row>
      )}
    </OAuth2IntegrationCard>
  );
};

export { TeamsLogo };
