import { InfoCircleTwoTone } from "@ant-design/icons";
import { isArray, mapValues, sortBy } from "lodash";
import { ProviderOrAll } from "shared/types/assessment";
import {
  AssessmentNodeLabels,
  AssessmentSchemaItem,
  AssessmentSchemaMap,
} from "shared/types/assessment/data";
import { widetype } from "shared/util/collections";

import { GraphTooltip } from "../../GraphTooltip";
import {
  Connection,
  Keytype,
  SearchConstraint,
  StagedSearch,
} from "./term-builder";

type KeytypeOption = { value: Keytype; label: string };
type ConnectionOption = { value: Connection; label: string };
type ConstraintOption = { value: SearchConstraint; label: string };

type SchemaAttribute = AssessmentSchemaItem & {
  label: NonNullable<AssessmentSchemaItem["label"]>;
  value: string;
};

const connectOptions: ConnectionOption[] = [
  { value: "are", label: "are" },
  { value: "reach", label: "can reach" },
];

const anythingOptions: KeytypeOption[] = [
  { value: "_anything", label: "anything" },
];
const nodeOptions: KeytypeOption[] = sortBy(
  widetype.entries(AssessmentNodeLabels).map(([key, label]) => ({
    value: key,
    label,
  })),
  "label"
);

const equalConstraintOptions: ConstraintOption[] = [
  { value: "equal", label: "equal to" },
];

const constraintOptions: ConstraintOption[] = [
  { value: "contain", label: "containing" },
  ...equalConstraintOptions,
];

/** Utilities for generated drop-down select options in the interactive term builder */
export const termBuilderOptions = {
  /** Options for the "attribute" select */
  attribute: {
    /** The universal "key" attribute */
    key: {
      value: "_key",
      label: (
        <>
          key&nbsp;
          <GraphTooltip title="This datum's identifier">
            <InfoCircleTwoTone />
          </GraphTooltip>
        </>
      ),
    },

    /** Converts a schema item to an attribute option */
    fromItem:
      (provider: ProviderOrAll) =>
      ({ label, help, value }: SchemaAttribute) => ({
        value,
        label: (
          <>
            {typeof label === "string" ? label : label[provider] ?? value}
            {help ? (
              <>
                &nbsp;
                <GraphTooltip title={help}>
                  <InfoCircleTwoTone />
                </GraphTooltip>
              </>
            ) : null}
          </>
        ),
      }),
  },

  /** Options for the "connect" select */
  connect: connectOptions,

  /** Options for the "constraint" select */
  constraint: {
    /** Available constraints when the selected attribute is enumerable */
    choices: equalConstraintOptions,
    /** Available constraints when the selected attribute is unbound */
    all: constraintOptions,
  },

  /** Options for the "type" select */
  keytype: [...anythingOptions, ...nodeOptions] as KeytypeOption[],

  /** When the attribute is enumerable, options for the "keyword" select */
  keyword: {
    /** Generates keyword options from schema choices */
    fromChoices: (choices: AssessmentSchemaItem["choices"]) =>
      !choices
        ? undefined
        : isArray(choices)
        ? choices.map((c) => ({ value: c, label: c }))
        : Object.entries(choices as Record<string, string>).map(
            ([value, label]) => ({ value, label })
          ),
  },
} as const;

/** All visible attributes in {@link AssessmentSchemaMap}, with defined labels */
export const schemaValues = mapValues(AssessmentSchemaMap, (m) =>
  Object.entries(m)
    .map(([k, v]) => ({ ...v, label: v.label ?? k, value: k }))
    .filter((v) => !v.hidden)
);

/** Converts a {@link StagedSearch} to an {@link AssessmentSchemaItem} */
export const toSchemaItem = (
  staged: StagedSearch
): AssessmentSchemaItem | undefined =>
  (AssessmentSchemaMap as any)[staged.keytype]?.[staged.attribute];
