// TODO: Make shared a sub-module so we can guarantee this package
import stringify from "json-stable-stringify";
import { size } from "lodash";

import { YieldConfig, eventYieldingFor } from "../util/sleep";
import { ConnectedNode, DirectedGraph, Direction, Node } from "./types";

type Dictionary<T> = Record<string, T>;

type DirectedEdge<G extends object> = {
  parent: ConnectedNode<G, keyof G>;
  child: ConnectedNode<G, keyof G>;
};

/** Internal utility for deduplicating nodes */
export const keyOf = <G extends object>(node: Node<G, keyof G>) =>
  `${String(node.type)}:${node.key}`;

/** Cycle-avoiding DFS graph traversal algorithm
 *
 * Visits every node in the graph.
 */
export const dfs = async <G extends object, T>(
  graph: DirectedGraph<G>,
  direction: Direction,
  config: {
    /** Filter predicate applied when spanning a node's relations */
    predicate?: (node: ConnectedNode<G, keyof G>) => boolean;
    init: (node: ConnectedNode<G, keyof G>) => T;
    combine: (left: T, right: T, direction?: Direction) => T;
    finalize?: (node: ConnectedNode<G, keyof G>, memo: T) => void;
  } & YieldConfig<ConnectedNode<G, keyof G>>
): Promise<Record<string, T>> => {
  const { predicate, init, combine, finalize } = config;
  const visited = new Set<string>();
  const cache: Dictionary<T> = {};
  const output: Dictionary<T> = {};
  const input = config.predicate
    ? graph.nodes.filter(config.predicate)
    : graph.nodes;

  await eventYieldingFor(
    input,
    async (top) => {
      const stack: {
        mode: "combine" | "span";
        node: ConnectedNode<G, keyof G>;
      }[] = [{ mode: "span", node: top }];

      while (stack.length) {
        const result = stack.pop();
        if (!result) continue;
        if (config.cancellation?.isCancelled) return;

        const { mode, node } = result;
        const key = keyOf(node);
        if (key in cache) continue;

        // Prevent cycles
        const visitKey = `${mode}:${key}`;
        if (visited.has(visitKey)) continue;
        visited.add(visitKey);

        const relations = node[direction];
        const iteratees = predicate ? relations.filter(predicate) : relations;

        // Ascending or leaf node
        if (!iteratees.length || mode === "combine") {
          let memo = init(node);
          for (const sub of iteratees) {
            const subKey = keyOf(sub);
            if (!(subKey in cache)) continue;
            memo = combine(memo, cache[subKey], direction);
          }
          finalize?.(node, memo);
          cache[key] = memo;
          memo = null as any;
        }

        // Descending and not a repeat visit
        if (iteratees.length && mode === "span") {
          // Descend to all children nodes; we'll revisit this node again
          // in "combine" mode
          stack.push({ mode: "combine", node });
          for (const sub of iteratees) {
            if (keyOf(sub) in cache) continue;
            stack.push({ mode: "span", node: sub });
          }
        }
      }
      const key = keyOf(top);
      output[key] = cache[key];
    },
    config
  );
  return output;
};

/** Creates a directed graph by adding nodes and edges
 *
 * Automatically handles duplicates. The built graph may be cyclic.
 */
export class GraphBuilder<G extends object> {
  #nodes: Dictionary<ConnectedNode<G, keyof G>> = {};
  #edges: Dictionary<DirectedEdge<G>> = {};

  #toDictKey = (type: keyof G, key: string) => {
    if (typeof type === "string" && type.includes(":")) {
      throw new Error("Node type may not include ':' symbol");
    }
    if (typeof key !== "string") {
      throw Object.assign(new Error("Node key is not a string"), {
        type,
        key,
      });
    }
    return `${String(type)}:${key}`;
  };

  #merge<L extends keyof G>(
    dictKey: string,
    type: L,
    key: string,
    data: G[L],
    /** If 'old', only adds new fields from `data`; otherwise, overrides existing fields */
    prefer: "new" | "old"
  ) {
    const previous = this.#nodes[dictKey] ?? {};
    const updated = {
      type,
      key,
      data:
        prefer === "old"
          ? { ...data, ...previous.data }
          : { ...previous.data, ...data },
      children: previous.children ?? [],
      parents: previous.parents ?? [],
    };
    this.#nodes[dictKey] = updated;
    return updated;
  }

  /** Adds a new node
   *
   * If the node already exists, silently continues
   */
  add<L extends keyof G>(
    type: L,
    item: G[L] & { key: string }
  ): ConnectedNode<G, L> {
    const dictKey = this.#toDictKey(type, item.key);
    const { key, ...data } = item;
    if (this.#nodes[dictKey] === undefined) {
      this.#merge(dictKey, type, key, data as G[L], "old");
    }
    return this.#nodes[dictKey] as ConnectedNode<G, L>;
  }

  lookup<L extends keyof G>(
    type: L,
    key: string
  ): ConnectedNode<G, L> | undefined {
    const dictKey = this.#toDictKey(type, key);
    return this.#nodes[dictKey] as ConnectedNode<G, L>;
  }

  /** Updates an existing node
   *
   * If a node does not yet exist, creates it.
   *
   * If `prefer` is 'new', updates existing node's fields with all present
   * fields in `item`. Otherwise, only updates missing fields.
   */
  merge<L extends keyof G>(
    type: L,
    item: G[L] & { key: string },
    prefer: "new" | "old"
  ): ConnectedNode<G, L> {
    const dictKey = this.#toDictKey(type, item.key);
    const { key, ...data } = item;
    return this.#merge(dictKey, type, key, data as G[L], prefer);
  }

  /** Updates an existing node's missing fields with new data
   * from another ConnectedNode
   *
   * If a node does not yet exist, creates it with no parents and children
   *
   * If `prefer` is 'new', updates existing node's fields with all present
   * fields in `item`. Otherwise, only updates missing fields.
   */
  mergeNode(node: ConnectedNode<G, keyof G>, prefer: "new" | "old") {
    const { key, type, data } = node;
    const dictKey = this.#toDictKey(type, key);
    // "old" node is an empty with no children or parents
    // Effectively copies the node when called with a key for the first time
    return this.#merge(dictKey, type, key, data, prefer);
  }

  connect(parent: ConnectedNode<G, keyof G>, child: ConnectedNode<G, keyof G>) {
    const key = stringify([parent.type, parent.key, child.type, child.key]);
    if (this.#edges[key] === undefined) {
      this.#edges[key] = { parent, child };
    }
    return this;
  }

  build(): DirectedGraph<G> {
    for (const edge of Object.values(this.#edges)) {
      edge.parent.children.push(edge.child);
      edge.child.parents.push(edge.parent);
    }

    return { nodes: Object.values(this.#nodes) };
  }

  size() {
    return {
      nodes: size(this.#nodes),
      edges: size(this.#edges),
    };
  }
}
