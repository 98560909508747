import { InfoCircleTwoTone } from "@ant-design/icons";
import { Typography } from "antd";
import { GraphTooltip } from "components/GraphTooltip";
import { isAfter, subDays } from "date-fns";
import { ReactNode } from "react";
import { DEFAULT_AUTHN_LOOKBACK_DAYS } from "shared/assessment/constants";
import { Identity, IdentityType } from "shared/types/assessment/data";

import { Timestamp } from "./Timestamp";

const { Text } = Typography;

export const authnHelp: Record<IdentityType, ReactNode> = {
  "aws-iam-role":
    "AWS IAM roles inherit authentication from their trust policies.",
  "aws-permission-set-role":
    "Permission-set roles are only used for federated access.",
  domain: "Domains do not have authentication credentials.",
  federated: "Authentication information is not yet available.",
  group: "Groups do not have authentication credentials.",
  "logged-in":
    "This is an arbitrary user, and does not refer to a user with a unique set of authentication credentials.",
  public: "Public Internet users are not authenticated.",
  "service-account":
    "Authentication information is only available for user-managed service accounts.",
  unknown: "Authentication time is unknown.",
  user: "Authentication information is unknown for this user. Install the directory integration corresponding to this user account to see its authentication details.",
};

export const LastAuthenticated: React.FC<{
  identity: Identity;
  last: number;
  createdAt?: number;
  lookback?: number;
}> = ({ last, identity: identity, lookback, createdAt }) => {
  const lookbackDays = lookback ?? DEFAULT_AUTHN_LOOKBACK_DAYS;
  return (
    <>
      {isNaN(last) ? (
        <Text type="secondary">
          <GraphTooltip title={authnHelp[identity?.type ?? "unknown"]}>
            <InfoCircleTwoTone />
          </GraphTooltip>
          &nbsp;(Unknown)
        </Text>
      ) : identity.type !== "user" && last === 0 ? (
        <Text type="secondary">
          {`Unused ${
            !createdAt || isAfter(createdAt, subDays(Date.now(), lookbackDays))
              ? `since creation`
              : `for ${lookback ?? DEFAULT_AUTHN_LOOKBACK_DAYS} days`
          }`}
        </Text>
      ) : (
        <Timestamp at={last} />
      )}
    </>
  );
};
