import { Card, Layout, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthConst from "../../constants/AuthConst";
import { Splash } from "../Splash";
import { LoginInputs } from "./Login";
import { LoginForm } from "./components/LoginForm";
import { useUser } from "./hook";

const { Content } = Layout;

type ErrorDetails = {
  heading: string;
  body: string;
} | null;

// @TODO: We should add in logic to better handle errors in these cases:
// If we have an orgSlug (and it's valid), but there's an error, we should provide a link to redirect the user to their tenant.
// If we do not have an orgSlug (or it's invalid), we should redirect the user to the homepage with the organization search function.
// This functionality should come when the homepage is created.
export const EmailCallbackHandler: React.FC = () => {
  const { finishSignInWithEmail } = useUser();
  const [searchParams] = useSearchParams();
  const tenantId = searchParams.get("tenantId");
  const orgSlug = searchParams.get("orgSlug");
  const navigate = useNavigate();

  const [error, setError] = useState<ErrorDetails>(null);
  const [needEmail, setNeedEmail] = useState<boolean>(false);

  // Attempt to sign in when landing on the page.
  useEffect(() => {
    handleSignIn();
  });

  const handleSignIn = useCallback(() => {
    if (!!tenantId && !!orgSlug) {
      try {
        finishSignInWithEmail(tenantId)
          ?.then(() => {
            navigate(`/o/${orgSlug}/`);
          })
          .catch(() => {
            setError({
              heading: "There was a problem signing you in...",
              body: "Your login link may have expired or been used already.",
            });
          });
      } catch (error) {
        if (error === AuthConst.ERRORS.MISSING_EMAIL) {
          setError({
            heading: "Missing your email",
            body: "Please provide your email again to finish your sign in.",
          });
          setNeedEmail(true);
        }
      }
    } else {
      setError({
        heading: "Bad request",
        body: "Looks like there was a problem with your request (missing parameters). Please find your organization and login.",
      });
    }
  }, [finishSignInWithEmail, navigate, orgSlug, tenantId]);

  // We were missing the email in localStorage, so now we have it.
  const handleSubmitEmail = useCallback(
    async ({ email }: LoginInputs) => {
      // Inline validation has passed by now.
      window.localStorage.setItem(AuthConst.USER_EMAIL_LOCALSTORAGE_KEY, email);
      setError(null);
      setNeedEmail(false);
      handleSignIn();
    },
    [handleSignIn]
  );

  const cardHeading = !!error ? error.heading : "Finishing your sign in...";

  return (
    <Layout className="site-layout">
      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
          minHeight: 280,
        }}
      >
        <Splash>
          <Card title={cardHeading} styles={{ body: { textAlign: "center" } }}>
            {!!error ? (
              <>
                <p>{error.body}</p>
              </>
            ) : (
              <Spin />
            )}
            {needEmail && <LoginForm onLogin={handleSubmitEmail} />}
          </Card>
        </Splash>
      </Content>
    </Layout>
  );
};
