import { IdentityType } from "shared/types/assessment/data";

export const identityTypeToEmoji: Record<IdentityType, string> = {
  "aws-permission-set-role": "🤝",
  "aws-iam-role": "⚙️",
  "logged-in": "🌎",
  "service-account": "⚙️",
  domain: "🌐",
  federated: "🤝",
  group: "👥",
  public: "🌎",
  unknown: "",
  user: "👤",
};
