import { Modal, Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { useRoutingRulesStore } from "store/routingRulesStore";

import { RoutingEditAlert } from "..";
import CodeEditor from "../CodeEditor";
import { RoutingRuleEditorProvider } from "../store/RoutingRuleEditorContext";
import { DEFAULT_RULE, EXAMPLE_RULE } from "../utils";
import { RuleEditor } from "./RuleEditor";

type Props = {
  isNewRule: boolean;
  handleSubmitRule: (rule: any) => Promise<void>;
};

export const EditRuleModal: React.FC<Props> = ({
  isNewRule,
  handleSubmitRule,
}) => {
  const {
    showConfigRulesModal,
    isSubmitting,
    selectedRule,
    selectedYamlRule,
    setSelectedRule,
    setShowConfigRulesModal,
  } = useRoutingRulesStore();

  const [useYaml, setUseYaml] = useState(false);

  const [yamlDirty, setYamlDirty] = useState(false);
  const [ruleEditorDirty, setRuleEditorDirty] = useState(false);

  const [modal, contextHolder] = Modal.useModal();

  const setDefaultRule = useCallback(() => {
    setSelectedRule(DEFAULT_RULE);
  }, [setSelectedRule]);

  const setSampleExample = useCallback(() => {
    setSelectedRule(EXAMPLE_RULE);
  }, [setSelectedRule]);

  const closeModal = useCallback(() => {
    if ((yamlDirty && useYaml) || (ruleEditorDirty && !useYaml)) {
      modal.confirm({
        title: "Discard changes?",
        content: "You have unsaved changes that will be lost.",
        onOk() {
          setShowConfigRulesModal(false);
        },
        maskClosable: true,
      });
    } else {
      setShowConfigRulesModal(false);
    }
  }, [setShowConfigRulesModal, yamlDirty, useYaml, ruleEditorDirty, modal]);

  const changeEditor = useCallback(
    (e: SegmentedValue) => setUseYaml(e === "yaml"),
    []
  );

  const onYamlDirty = useCallback(
    (dirty: boolean) => {
      setYamlDirty(dirty);
    },
    [setYamlDirty]
  );

  const onRuleEditorDirty = useCallback(
    (dirty: boolean) => {
      setRuleEditorDirty(dirty);
    },
    [setRuleEditorDirty]
  );

  return (
    <>
      <Modal
        open={showConfigRulesModal}
        style={{ top: 10 }}
        title={`Request Routing - ${isNewRule ? "New" : "Edit"}`}
        onCancel={closeModal}
        maskClosable={false}
        footer={null}
        width={1000}
        destroyOnClose
      >
        <RoutingEditAlert />
        <Segmented
          options={[
            {
              label: "Interactive Editor",
              value: "form",
            },
            {
              label: "YAML",
              value: "yaml",
            },
          ]}
          value={useYaml ? "yaml" : "form"}
          onChange={changeEditor}
          style={{ marginBottom: 20 }}
        />
        {useYaml ? (
          <CodeEditor
            onDirty={onYamlDirty}
            handleSubmitWorkflow={handleSubmitRule}
            rule={selectedYamlRule}
            setDefaultRule={setDefaultRule}
            setSampleExample={setSampleExample}
          />
        ) : (
          <RoutingRuleEditorProvider>
            <RuleEditor
              isSubmitting={isSubmitting}
              handleSubmitRule={handleSubmitRule}
              onDirty={onRuleEditorDirty}
              initialRule={
                !isNewRule && !isEmpty(selectedRule) ? selectedRule : undefined
              }
            />
          </RoutingRuleEditorProvider>
        )}
      </Modal>
      {contextHolder}
    </>
  );
};
