import { TargetsList } from "components/Assessment/components/Targets";
import { ErrorDisplay } from "components/Error";
import { Tenant } from "components/Login";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { isEqual } from "lodash";
import { DB, useFirestoreCollection } from "providers/FirestoreProvider";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { IamAssessment } from "shared/types/assessment";
import {
  AssessmentJob,
  AssessmentProgressStatus,
  JobStatus,
} from "shared/types/assessment/job";
import { isDefined } from "shared/types/is";
import { assertNever } from "utils/assert";

const fetchStatusDoc = async (tenantId: string, assessmentId: string) => {
  const result = await getDocs(
    query(
      collection(DB, `o/${tenantId}/job-state`),
      where("assessmentId", "==", assessmentId),
      orderBy("lastUpdatedTimestamp", "desc"),
      limit(1)
    )
  );
  const jobDocs = result.docs.map((doc) => ({ ...doc.data() }));
  const [firstDoc] = jobDocs;
  return firstDoc as AssessmentJob;
};

export type Environment = {
  // TODO: This is a hack to patch transitioning from standalone assessments to
  // a single inventory / posture collection per environment. We'll map the environment
  // to an assessment, then migrate off of assessments entirely.
  assessmentId: string;
  // Environment ID
  // To start, this is the same as the assessment ID. Ultimately we will migrate to a
  // separate collection for environments
  id: string;
  /** Human-readable name for this environment */
  label: string;
  /** Status of the environment */
  status: AssessmentProgressStatus;
  detailedStatus: JSX.Element;
  /** Color theme for this environment, used for visually disambiguating environments */
  theme: string | undefined;
};

type EnvironmentDocsContext = {
  all: Map<string, Environment>;
  hasEnvironments: boolean;
  isLoading: boolean;
};

export const jobStatusToAssessmentStatus = (
  status: JobStatus
): AssessmentProgressStatus | undefined => {
  switch (status) {
    case "COMPLETED":
      return "COMPLETED";
    case "COLLECTING":
    case "DETECTING":
    case "MANAGING":
    case "GRAPHING":
    case "PREPARING":
      return "IN_PROGRESS";
    case "CREATED":
      return "NOT_STARTED";
    case "ERRORED":
    case "ERRORED_ERRORED":
      return "ERRORED";
    default:
      assertNever(status);
  }
};

const defaultEnvironmentDocsContextValue: EnvironmentDocsContext = {
  all: new Map(),
  hasEnvironments: false,
  isLoading: true,
};

export const EnvironmentDocsContext = createContext<EnvironmentDocsContext>(
  defaultEnvironmentDocsContextValue
);

EnvironmentDocsContext.displayName = "EnvironmentDocsContext";
export const EnvironmentDocsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return <Provider>{children}</Provider>;
};

export const Provider: React.FC<React.PropsWithChildren<object>> = ({
  children,
}) => {
  const tenantId = useContext(Tenant);
  const [jobDocs, setJobDocs] = useState<AssessmentJob[]>([]);
  const [error, setError] = useState<string>();

  const onError = useCallback((error: any) => {
    console.error(error);
    setError(error);
  }, []);

  // TODO: migrate to separate environment collection
  const { docs: environmentDocs, loading: isLoading } =
    useFirestoreCollection<IamAssessment>("iam-assessments", {
      live: true,
      tenantAware: true,
    });

  useGuardedEffect(
    (cancellation) => async () => {
      const statusDocs = await Promise.all(
        environmentDocs?.map((d) => fetchStatusDoc(tenantId, d.id)) ?? []
      );
      if (cancellation.isCancelled) return;
      setJobDocs((prevDocs) =>
        isEqual(prevDocs, statusDocs) ? prevDocs : statusDocs
      );
    },
    [environmentDocs, tenantId],
    onError
  );

  const { all, hasEnvironments } = useMemo(() => {
    const environments = new Map<string, Environment>(
      environmentDocs?.map((d) => {
        try {
          const currentJobDoc =
            jobDocs.length === environmentDocs.length
              ? jobDocs
                  .filter(isDefined)
                  .filter((job) => job?.assessmentId === d.id)?.[0]
              : undefined;
          return [
            d.id,
            {
              assessmentId: d.id,
              label: d.data.name,
              id: d.id,
              status: currentJobDoc
                ? jobStatusToAssessmentStatus(currentJobDoc.status) ??
                  "NOT_STARTED"
                : "NOT_STARTED",
              detailedStatus: (
                <>
                  <TargetsList targets={d.data.targets ?? []} />
                  {currentJobDoc?.status === "ERRORED" && (
                    <ErrorDisplay error={currentJobDoc.error?.message} />
                  )}
                </>
              ),
              theme: undefined,
            },
          ];
        } catch (e) {
          onError(e);
          return [
            d.id,
            {
              assessmentId: d?.id,
              label: d?.data?.name,
              id: d?.id,
              status: "ERRORED",
              detailedStatus: (
                <>
                  <TargetsList targets={d?.data?.targets ?? []} />
                  <ErrorDisplay error="Error loading environment" />
                </>
              ),
              theme: undefined,
            },
          ] as [string, Environment];
        }
      }) ?? []
    );
    return { all: environments, hasEnvironments: !!environmentDocs?.length };
  }, [environmentDocs, jobDocs, onError]);

  return (
    <EnvironmentDocsContext.Provider
      value={{ all, hasEnvironments, isLoading }}
    >
      {error && (
        <ErrorDisplay title="Error loading environment" error={String(error)} />
      )}
      {children}
    </EnvironmentDocsContext.Provider>
  );
};
