import { Segmented } from "antd";
import { Prefix } from "components/GraphTable/Prefix";
import { useCallback, useContext } from "react";

import { FindingsContext } from "../contexts/FindingsContext";

export const FindingsSelect: React.FC<object> = () => {
  const { trigger, setTrigger, state, setState } = useContext(FindingsContext);

  const updateTriggerQuery = useCallback(
    (trigger: number | string) => setTrigger(String(trigger)),
    [setTrigger]
  );
  const updateStateQuery = useCallback(
    (state: number | string) => setState(String(state)),
    [setState]
  );

  return (
    <Prefix prefix="Findings" textWidth="md" style={{ width: "unset" }}>
      <Segmented
        options={[
          { label: "All", value: "all" },
          { label: "New", value: "new" },
        ]}
        onChange={updateTriggerQuery}
        value={trigger}
      />
      <Segmented
        options={[
          { label: "Open", value: "open" },
          { label: "Ignored", value: "ignored" },
          { label: "Resolved", value: "resolved" },
        ]}
        onChange={updateStateQuery}
        value={state}
      />
    </Prefix>
  );
};
