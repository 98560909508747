import { Grid, Space } from "antd";
import CustomDatePicker from "components/DatePicker";
import { Prefix } from "components/GraphTable/Prefix";
import { defaultRangePresets } from "constants/RangePickerConstants";
import moment, { Moment } from "moment";
import { RangePickerProps } from "rc-picker";
import { useCallback } from "react";

export const RequestDateFilters: React.FC<{
  approvedDateFilter?: RangePickerProps<Moment>["value"];
  requestedDateFilter?: RangePickerProps<Moment>["value"];
  onApprovalFilterChange: (value: RangePickerProps<Moment>["value"]) => void;
  onRequestFilterChange: (value: RangePickerProps<Moment>["value"]) => void;
}> = ({
  approvedDateFilter,
  onApprovalFilterChange,
  onRequestFilterChange,
  requestedDateFilter,
}) => {
  const timeFormat = "HH:mm";
  const dateTimeFormat = `YYYY-MM-DD ${timeFormat}`;

  const { md } = Grid.useBreakpoint();

  const disableAfterToday = useCallback(
    (current: Moment) => current && current > moment().endOf("day"),
    []
  );

  return (
    <Space size="middle" direction={md ? "horizontal" : "vertical"}>
      <Space>
        <Prefix prefix="Requested on" style={{ width: "7em" }} />
        <CustomDatePicker.RangePicker
          allowEmpty={[true, true]}
          disabledDate={disableAfterToday}
          presets={defaultRangePresets({ includeTime: true })}
          onChange={onRequestFilterChange}
          value={requestedDateFilter}
          showTime={{
            format: timeFormat,
          }}
          format={dateTimeFormat}
        />
      </Space>
      <Space>
        <Prefix prefix="Approved on" style={{ width: "7em" }} />
        <CustomDatePicker.RangePicker
          allowEmpty={[true, true]}
          disabledDate={disableAfterToday}
          presets={defaultRangePresets({ includeTime: true })}
          onChange={onApprovalFilterChange}
          value={approvedDateFilter}
          showTime={{
            format: timeFormat,
          }}
          format={dateTimeFormat}
        />
      </Space>
    </Space>
  );
};
