import { GraphTooltip } from "components/GraphTooltip";
import { AppPaths } from "shared/routes/constants";
import { assertNever } from "shared/types";
import { Evidence } from "shared/types/evidence";

import { TenantAwareLink } from "../../common/TenantAwareLink";
import { JitPaths } from "../paths";

export const renderEvidence = (
  evidence: readonly [Evidence["source"], string] | "unknown" | undefined,
  richOutput: boolean // Whether to include links and tooltips, or just plain text
) => {
  if (!evidence) {
    return "";
  }

  if (evidence === "unknown") {
    return "Unknown evidence ID";
  }

  const [source, id] = evidence;

  switch (source) {
    case "request":
      return richOutput ? (
        <>
          Evidence from{" "}
          <TenantAwareLink to={`${AppPaths.Jit}/${JitPaths.Activity}/${id}`}>
            request
          </TenantAwareLink>
        </>
      ) : (
        `Evidence from request ${id}`
      );
    case "allow":
      return richOutput ? (
        <GraphTooltip
          title={
            <>
              Evidence from <code>p0 allow</code> is not yet supported in the
              web view, and can be managed using the P0 CLI.
            </>
          }
        >
          <span style={{ textDecoration: "underline dashed" }}>
            Evidence ID: <code>{id}</code>
          </span>
        </GraphTooltip>
      ) : (
        `Evidence from p0 allow ${id}`
      );
    default:
      assertNever(source);
  }
};
