import { volcano } from "@ant-design/colors";
import { KeyOutlined } from "@ant-design/icons";
import { RendererParams } from "components/Assessment/contexts/GraphContext";
import { DefaultTitles } from "shared/assessment/constants";
import { ConnectedNode } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";

import { CredentialDisplay } from "../../cells/Credential";
import {
  DefaultNodeHeaderHeight,
  DefaultNodeOneLineBodyHeight,
  DefaultNodeWidth,
} from "./shared";

export const CustomCredentialNodeParameters = {
  type: "credential" as const,
  titleText: (
    node: ConnectedNode<AssessmentNodes, "credential">,
    rendererParams: RendererParams
  ) => {
    const { provider } = rendererParams;
    const { source, type } = node.data;
    if (source === "csp") {
      if (provider === "gcp") {
        if (type === "key") {
          return "Service-account key";
        }
        if (type === "short-lived") {
          return "Service-account impersonation";
        }
      }
      if (provider === "aws" && type === "key") {
        return "User (access key)";
      }
    }
    return DefaultTitles.credential;
  },
  IconComponent: KeyOutlined,
  bodyBorderColor: volcano[2],
  bodyBackgroundColor: volcano[1],
  bodyContent: (node: ConnectedNode<AssessmentNodes, "credential">) => (
    <CredentialDisplay credential={node.data} id={node.key} />
  ),
  bodySize: () => DefaultNodeOneLineBodyHeight,
  headerSize: () => DefaultNodeHeaderHeight,
  width: () => DefaultNodeWidth,
};
