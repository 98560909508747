import { List, Segmented, Spin } from "antd";
import { VerticalSpacedDiv } from "components/divs";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { Dictionary, mapValues, omitBy, size, sortBy } from "lodash";
import { useMemo, useState } from "react";
import { identityTypeToLabel } from "shared/assessment/constants";
import { dfs } from "shared/graph/graph";
import { ConnectedNode, isConnectedNode } from "shared/graph/types";
import {
  AssessmentNodes,
  Identity,
  IdentityNode,
} from "shared/types/assessment/data";
import { TIME_TO_DEBOUNCE, sleep } from "shared/util/sleep";
import { staticError } from "utils/console";

import { DisplayList } from "../shared";
import { identityTypeToEmoji } from "./shared";

const MemberOptions = ["all", "direct", "indirect"] as const;
export type MemberOption = (typeof MemberOptions)[number];

const arrayToRecord = (
  nodes: ConnectedNode<AssessmentNodes, keyof AssessmentNodes>[]
) =>
  Object.fromEntries(
    nodes.filter(isConnectedNode("identity")).map((n) => [n.key, n.data])
  );

const MemberIdentityDisplay = ({ identity }: { identity: Identity }) => (
  <>
    {identityTypeToEmoji[identity.type]}
    &nbsp;
    {identity.type === "public" ? identityTypeToLabel.public : identity.label}
  </>
);

const renderMemberItem = (identity: AssessmentNodes["identity"]) => {
  return (
    <List.Item key={identity.label}>
      <div style={{ display: "flex", gap: "1em" }}>
        <MemberIdentityDisplay
          data-testid={identity.label}
          identity={identity}
        />
      </div>
    </List.Item>
  );
};

export const GroupMembers: React.FC<{ node: IdentityNode }> = ({ node }) => {
  const [membershipType, setMembershipType] = useState<MemberOption>("all");
  const [result, setResult] = useState<Record<MemberOption, Identity[]>>();

  useGuardedEffect(
    (cancellation) => async () => {
      setResult(undefined);
      await sleep(TIME_TO_DEBOUNCE);
      if (cancellation.isCancelled) return;
      const direct = arrayToRecord(node.parents);
      const traversed = await dfs<
        AssessmentNodes,
        Record<string, AssessmentNodes["identity"]>
      >({ nodes: [node] }, "parents", {
        init: (node) => arrayToRecord(node.parents),
        combine: (left, right) => ({ ...left, ...right }),
      });
      if (cancellation.isCancelled) return;
      const all = traversed[`identity:${node.key}`];
      const indirect = omitBy(all, (_, key) => key in direct);
      const localResult = mapValues(
        { all, direct, indirect },
        (r: Dictionary<Identity>) => sortBy(Object.values(r), (i) => i.label)
      );
      setResult(localResult);
    },
    [node],
    staticError // nosemgrep: use-static-error-handler-in-use-guarded-effect
  );

  const options = useMemo(
    () => [
      {
        label: `All (${size(result?.all) ?? 0})`,
        value: "all",
      },
      {
        label: `Direct (${size(result?.direct) ?? 0})`,
        value: "direct",
      },
      {
        label: `Indirect (${size(result?.indirect) ?? 0})`,
        value: "indirect",
      },
    ],
    [result]
  );

  return result === undefined ? (
    <Spin style={{ height: "430px" }} />
  ) : (
    <VerticalSpacedDiv data-testid={"group-members-loaded"}>
      <div style={{ display: "flex", gap: "1em" }}>
        <Segmented
          options={options}
          value={membershipType}
          onChange={setMembershipType as any}
        />
      </div>
      <DisplayList<Identity>
        dataSource={result[membershipType]}
        renderItem={renderMemberItem}
      />
    </VerticalSpacedDiv>
  );
};
