import {
  CheckCircleFilled,
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Input,
  Popover,
  Switch,
  Tabs,
  Typography,
} from "antd";
import { ErrorDisplay } from "components/Error";
import { isEqual, isUndefined, omitBy } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import {
  headingsFromComponentType,
  ruleComponents,
} from "shared/types/workflow/constants";
import { RoutingRule } from "shared/types/workflow/types";
import { useRoutingRulesStore } from "store/routingRulesStore";

import { RoutingRuleEditorContext } from "../store/RoutingRuleEditorContext";
import { StyledRuleMetadataEditor } from "../styles";
import { AccessEditor } from "./editors/AccessEditor";
import { RequestorEditor } from "./editors/RequestorEditor";
import { ResourceEditor } from "./editors/ResourceEditor";

type Props = {
  initialRule?: RoutingRule;
  isSubmitting?: boolean;
  onDirty: (dirty: boolean) => void;
  handleSubmitRule: (rule: RoutingRule) => void;
};

export const RuleEditor: React.FC<Props> = ({
  initialRule,
  isSubmitting,
  onDirty,
  handleSubmitRule,
}) => {
  const { errors } = useRoutingRulesStore();

  const {
    name,
    disabled,
    resource,
    requestor,
    approval,
    setName,
    setDisabled,
    setResource,
    setRequestor,
    setApproval,
    isComponentValid,
    isValidRule,
  } = useContext(RoutingRuleEditorContext);

  useEffect(() => {
    if (initialRule) {
      setResource(initialRule.resource);
      setRequestor(initialRule.requestor);
      setApproval(initialRule.approval);
      setName(initialRule?.name || "");
      setDisabled(initialRule.disabled ?? false);
    }
  }, [
    initialRule,
    setResource,
    setRequestor,
    setApproval,
    setName,
    setDisabled,
  ]);

  const [resourceFilterEditInProgress, setResourceFilterEditInProgress] =
    React.useState<boolean>(false);
  const [accessRuleEditInProgress, setAccessRuleEditInProgress] =
    React.useState<boolean>(false);

  const isNew = useMemo(() => initialRule === undefined, [initialRule]);

  const isDirty = useMemo(() => {
    return !isEqual(
      omitBy(
        {
          ...initialRule,
          name: initialRule?.name || undefined,
          disabled: initialRule?.disabled ?? false,
        },
        isUndefined
      ),
      omitBy(
        {
          name: name || undefined,
          disabled: disabled ?? false,
          requestor,
          resource,
          approval,
        },
        isUndefined
      )
    );
  }, [initialRule, name, disabled, requestor, resource, approval]);

  useEffect(() => {
    onDirty(
      isDirty || resourceFilterEditInProgress || accessRuleEditInProgress
    );
  }, [
    isDirty,
    onDirty,
    resourceFilterEditInProgress,
    accessRuleEditInProgress,
  ]);

  const enableSaveButton = useMemo(
    () => !resourceFilterEditInProgress && isValidRule && (isNew || isDirty),
    [isNew, isValidRule, isDirty, resourceFilterEditInProgress]
  );

  const updateName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
    },
    [setName]
  );

  const updateDisabled = useCallback(
    (checked: boolean) => {
      setDisabled(!checked);
    },
    [setDisabled]
  );

  const submitRule = useCallback(() => {
    // Technically "isValidRule" also does the "!" check, but TS doesn't know that in this file, so we need to do it again.
    if (!isValidRule || !resource || !requestor) {
      return;
    }
    handleSubmitRule({
      name,
      disabled,
      requestor,
      resource,
      approval,
    });
  }, [
    isValidRule,
    resource,
    requestor,
    handleSubmitRule,
    name,
    disabled,
    approval,
  ]);

  const createRuleComponentLabel = useCallback(
    (component: (typeof ruleComponents)[number]) => {
      return (
        <span style={{ display: "flex", alignItems: "center", gap: 4 }}>
          {isComponentValid(component) ? (
            <CheckCircleFilled />
          ) : (
            <ExclamationCircleOutlined />
          )}
          {headingsFromComponentType[component]}
        </span>
      );
    },
    [isComponentValid]
  );

  return (
    <>
      {errors.map((e, ix) => (
        <ErrorDisplay error={e} key={ix} />
      ))}
      <StyledRuleMetadataEditor>
        <Typography.Text>Rule name (optional):</Typography.Text>
        <Input value={name} onChange={updateName} style={{ marginBottom: 0 }} />
        <Typography.Text>
          Enable routing rule{" "}
          <Popover
            content={
              <div style={{ maxWidth: 350 }}>
                Disabled routing rules are not evaluated as part of requests.
              </div>
            }
            trigger={"click"}
          >
            <InfoCircleTwoTone />
          </Popover>
          :
        </Typography.Text>
        {/* The switch represents whether the routing rule is enabled but the flag is whether the routing rule is
        disabled, so we need to invert the boolean when displaying and again persisting. */}
        <Switch
          value={!(disabled ?? false)}
          onChange={updateDisabled}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          style={{ marginBottom: 0, width: 45 }}
        />
      </StyledRuleMetadataEditor>
      <Divider />
      <Tabs
        tabPosition="left"
        items={ruleComponents.map((component) => ({
          key: component,
          children:
            component === "resource" ? (
              <ResourceEditor
                onResourceFilterEditInProgress={setResourceFilterEditInProgress}
              />
            ) : component === "requestor" ? (
              <RequestorEditor />
            ) : (
              <AccessEditor
                onAccessRuleEditInProgress={setAccessRuleEditInProgress}
              />
            ),
          label: createRuleComponentLabel(component),
        }))}
      />
      <Divider />
      <div style={{ textAlign: "right" }}>
        {isDirty && (
          <Typography.Text type="secondary" style={{ marginRight: 10 }}>
            Unsaved changes
          </Typography.Text>
        )}
        <Button
          type="primary"
          onClick={submitRule}
          disabled={!enableSaveButton}
          loading={isSubmitting}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
