import { Typography } from "antd";
import { GraphTooltip } from "components/GraphTooltip";
import { useMemo } from "react";
import { IdentityNode } from "shared/types/assessment/data";

import { HasAddTerm, ShowHideTerm } from "./ShowHide";

const Mfa = (props: HasAddTerm) => (
  <GraphTooltip
    title={
      <>
        <div>⚠️ MFA is disabled for this user</div>
        <ShowHideTerm
          {...props}
          name="MFA settings"
          term='identity=mfa:"disabled"'
        />
      </>
    }
  >
    1️⃣
  </GraphTooltip>
);

const OpenJoin = (props: HasAddTerm) => (
  <GraphTooltip
    title={
      <>
        <div>⚠️ Anyone on the Internet can join this group</div>
        <ShowHideTerm
          {...props}
          name="join settings"
          term='identity=accessJoin:"open"'
        />
      </>
    }
  >
    🌐
  </GraphTooltip>
);

const DomainJoin = (props: HasAddTerm) => (
  <GraphTooltip
    title={
      <>
        <div>⚠️ Anyone in your domain can join this group</div>
        <ShowHideTerm
          {...props}
          name="join settings"
          term='identity=accessJoin:"domain"'
        />
      </>
    }
  >
    🙋‍♂️
  </GraphTooltip>
);

const MemberAdd = (props: HasAddTerm) => (
  <GraphTooltip
    title={
      <>
        <div>⚠️ Any member of this group can directly add new members</div>
        <ShowHideTerm
          {...props}
          name="member add settings"
          term='identity=accessAdd:"group"'
        />
      </>
    }
  >
    ➕
  </GraphTooltip>
);

const MemberApprove = (props: HasAddTerm) => (
  <GraphTooltip
    title={
      <>
        <div>⚠️ Any member of this group can approve requests to join </div>
        <ShowHideTerm
          {...props}
          name="join approval settings"
          term='identity=accessApprove:"group"'
        />
      </>
    }
  >
    ✅
  </GraphTooltip>
);

export const hasIdentityAccessWarning = ({ data }: IdentityNode) => ({
  mfa: data.mfa === "disabled",
  publicJoin: data.access?.join === "public",
  domainJoin: data.access?.join === "domain",
  memberAdd: data.access?.add === "group",
  memberApprove: data.access?.approve === "group",
});

export const IdentityAccess: React.FC<{ node: IdentityNode } & HasAddTerm> = ({
  node,
  ...props
}) => {
  const { mfa, publicJoin, domainJoin, memberAdd, memberApprove } = useMemo(
    () => hasIdentityAccessWarning(node),
    [node]
  );
  return (
    <>
      {mfa && <Mfa {...props} />}
      {publicJoin && <OpenJoin {...props} />}
      {domainJoin && <DomainJoin {...props} />}
      {memberAdd && <MemberAdd {...props} />}
      {memberApprove && <MemberApprove {...props} />}
    </>
  );
};

export const IdentityAccessTitle: React.FC = () => (
  <GraphTooltip
    title={
      <>
        <Typography.Paragraph>
          Displays potential violations of identity access controls.
        </Typography.Paragraph>
        <Typography.Paragraph type="secondary" style={{ fontSize: "small" }}>
          Requires P0 to be connected to your identity directory.
        </Typography.Paragraph>
      </>
    }
  >
    Access Warnings
  </GraphTooltip>
);
