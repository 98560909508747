import { Tag, TagProps } from "antd";
import { PropsWithChildren } from "react";
import styled from "styled-components";

const EllipsisTag = styled(Tag)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

/**
 * The Tags in this file are wrappers around the AntD tags with styling and colors
 * @param content The text to display inside the tags
 */
export const StyledTag: React.FC<
  PropsWithChildren<
    Omit<TagProps, "color"> & {
      color: {
        bg: string;
        text: string;
      };
      ellipsis?: boolean;
    }
  >
> = ({ children, ellipsis, ...props }) => {
  const tagProps = {
    ...props,
    color: props.color.bg,
    style: { ...props.style, color: props.color.text },
  };
  return ellipsis ? (
    <EllipsisTag {...tagProps}>{children}</EllipsisTag>
  ) : (
    <Tag {...tagProps}>{children}</Tag>
  );
};
