import { cloneDeep, omit } from "lodash";
import {
  ApprovalRule,
  Filter,
  IntegrationResourceRule,
  RequestorRule,
  ResourceRule,
} from "shared/types/workflow/types";
import { assertNever } from "utils/assert";

export const newResourceRuleOfType = (
  type: ResourceRule["type"]
): ResourceRule => {
  switch (type) {
    case "any":
    case "some":
      return { type };
    case "integration":
      return { type, service: "aws", filters: {} };
    default:
      return assertNever(type);
  }
};

export const updateResourceService = (
  service: IntegrationResourceRule["service"],
  resource: ResourceRule
): ResourceRule => {
  if (resource.type !== "integration") {
    return resource;
  }
  return { type: "integration", service, filters: {} };
};

export const newResourceRuleWithFilter = <T extends IntegrationResourceRule>(
  resource: T,
  filterKey: keyof T["filters"],
  filter: Filter,
  oldFilterKey?: keyof T["filters"]
): T => {
  const { service } = resource;
  if (!service) {
    return resource;
  }
  const currentFilters = cloneDeep(resource.filters) || {};
  const newFilters = {
    ...(oldFilterKey ? omit(currentFilters, oldFilterKey) : currentFilters),
    [filterKey]: filter,
  };
  return {
    ...resource,
    filters: newFilters,
  };
};

export const newResourceRuleWithoutFilter = <T extends IntegrationResourceRule>(
  resource: T,
  filterKey: string
): T => {
  const { service } = resource;
  if (!service) {
    return resource;
  }
  const currentFilters = resource.filters || {};
  return {
    ...resource,
    filters: omit(currentFilters, filterKey),
  };
};

export const newRequestorRuleOfType = (
  type: RequestorRule["type"]
): RequestorRule => {
  switch (type) {
    case "any":
    case "some":
      return { type };
    case "user":
      return { type, uid: "" };
    case "group":
      return { type, groups: [{ id: "", label: "", directory: "okta" }] };
    default:
      return assertNever(type);
  }
};

export const newApprovalRuleOfType = (
  type: ApprovalRule["type"]
): ApprovalRule => {
  switch (type) {
    case "persistent":
    case "deny":
      return { type };
    case "p0":
      return { type, options: {} };
    case "auto":
      return { type, integration: "pagerduty", options: {} };
    case "group":
      return {
        type,
        groups: [{ id: "", label: "", directory: "okta" }],
        options: {},
      };
    case "escalation":
      return { type, integration: "pagerduty", services: [] };
    case "requestor-profile":
      return { type, directory: "okta", options: {} };
    default:
      return assertNever(type);
  }
};
