import { distinct, paint } from "../../graph/aggregate";
import { ConnectedNode, Node, isNode } from "../../graph/types";
import { AssessmentNodes } from "../../types/assessment/data";
import {
  AssessmentReducer,
  PrivilegeAggregate,
} from "../../types/assessment/data/aggregates";

export const grantAggregate: AssessmentReducer<
  Node<AssessmentNodes, "grant">[]
> = distinct(
  "grant",
  (node) => node,
  (node) => node.key
);

export const privilegeAggregate: AssessmentReducer<PrivilegeAggregate> = paint(
  (n) => (isNode("usage")(n) ? n.data.type : undefined),
  distinct(
    "privilege",
    (node) => node as ConnectedNode<AssessmentNodes, "privilege">,
    (node) => node.key
  )
);

export const keyAggregate = <K extends keyof AssessmentNodes>(
  key: K
): AssessmentReducer<(AssessmentNodes[K] & { id: string })[]> =>
  distinct(
    key,
    (node) => ({ id: node.key, ...node.data }),
    (node) => node.id
  );
