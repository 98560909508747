import { OrderedListOutlined } from "@ant-design/icons";
import { ZeroStatePage } from "components/Dashboard/components/common/ZeroStatePage";
import { EnvironmentDocsContext } from "components/Environment/contexts/EnvironmentDocsContext";
import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { VerticalSpacedDiv } from "components/divs";
import { useContext, useMemo } from "react";
import { useParams } from "react-router";
import { AppPaths } from "shared/routes/constants";

import { AssessmentGraph } from "../components/AssessmentGraph";
import { Inventory } from "../components/Inventory";
import { ScopeSelect } from "../components/ScopeSelect";
import { DetailsColumn } from "../components/cells/DetailsColumn";
import { EmptyDefaultGraph, ScopeContext } from "../contexts/ScopeContext";
import { useControls } from "../hooks/useControls";
import { QueryResult } from "./QueryResult";

export const Explore: React.FC = () => {
  const { orgSlug, nodeType, nodeKey } = useParams();
  const { last } = useContext(SelectedEnvironmentContext);
  const { hasEnvironments } = useContext(EnvironmentDocsContext);
  const assessmentId = last.doc?.data.assessmentId;
  const { controls, setControls } = useControls();
  const { graph, scopeKey } = useContext(ScopeContext);

  const action = useMemo(() => {
    if (!assessmentId || !orgSlug || !scopeKey) return undefined;
    return [
      DetailsColumn((data) => {
        const searchParams = new URLSearchParams(window.location.search);
        const base = `/o/${orgSlug}/${AppPaths.Inventory}`;
        const end = `/${encodeURIComponent(data.type)}/${encodeURIComponent(
          data.key
        )}?${searchParams.toString()}`;
        return {
          disabled: data.key === nodeKey,
          key: data.key,
          to: `${base}${end}`,
        };
      }),
    ];
  }, [assessmentId, nodeKey, orgSlug, scopeKey]);

  const scopeNode = useMemo(() => <ScopeSelect includeAll={true} wide />, []);

  return hasEnvironments ? (
    <>
      <VerticalSpacedDiv style={{ gap: "18px", maxWidth: "1400px" }}>
        <Inventory />
        <AssessmentGraph
          graph={graph ?? EmptyDefaultGraph}
          scopeKey={scopeKey}
          controls={controls}
          onControls={setControls}
          extraColumns={action}
          scopeNode={scopeNode}
          showCreateMonitor={true}
        />
      </VerticalSpacedDiv>
      {nodeKey && nodeType && <QueryResult />}
    </>
  ) : (
    <ZeroStatePage
      title="Discover assets in your cloud environments"
      description="Cloud inventory helps you discover and manage assets across your cloud environments."
      icon={<OrderedListOutlined />}
      docLink="https://docs.p0.dev/what-is-p0/iam-assessment"
    />
  );
};
