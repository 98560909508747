import { LoadingOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import { VerticalSpacedDiv } from "components/divs";

export type GraphProcessingStep =
  | "aggregating"
  | "boosting"
  | "converting"
  | "done"
  | "loading"
  | "requested";

const STEPS: Record<GraphProcessingStep, { index: number; label: string }> = {
  requested: { index: 0, label: "Requesting IAM graph" },
  loading: { index: 1, label: "Downloading IAM graph" },
  converting: { index: 2, label: "Converting data" },
  boosting: { index: 3, label: "Adding search boost" },
  aggregating: { index: 4, label: "Adding graph aggregation" },
  done: { index: 5, label: "Done" },
};

// 'done' state is not displayed, but don't show 100% while work is ongoing
const MAX_LOADING_INDEX = 4.1;

export const GraphProcessingStep: React.FC<{
  step: GraphProcessingStep;
  percentage?: number;
}> = ({ step, percentage = 0 }) => {
  const { index, label } = STEPS[step];
  return (
    <VerticalSpacedDiv style={{ width: "400px" }}>
      {/* -2 as 'done' state is not displayed in progress */}
      <Progress
        percent={100 * ((index + percentage) / MAX_LOADING_INDEX)}
        showInfo={false}
      />
      <div>
        <LoadingOutlined style={{ marginRight: "12px" }} />
        {label}
      </div>
    </VerticalSpacedDiv>
  );
};
