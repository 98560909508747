import { useUser } from "components/Login/hook";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

// Session configuration
export const SESSION_START_ITEM_KEY = "sessionStart";
export const IDLE_MODAL_TIMEOUT_MS = 5 * 60 * 1000; // 5 minutes
export const MAX_INACTIVITY_TIME_MS = 30 * 60 * 1000; // 30 minutes
export const MAX_SESSION_DURATION_MS = 8 * 60 * 60 * 1000; // 8 hours
export const SESSION_WARNING_THRESHOLD_MS = 7 * 60 * 60 * 1000 + 54 * 60 * 1000; // 7h54m (6 minutes before timeout)
const MODAL_TRIGGER_TIME_MS = MAX_INACTIVITY_TIME_MS - IDLE_MODAL_TIMEOUT_MS;
const SESSION_CHECK_INTERVAL = 15 * 1000; // 15 seconds
const COUNTDOWN_INTERVAL = 500; // 0.5 seconds

// Helper to format time as HH:MM:SS or MM:SS
export const formatTimeRemaining = (ms: number): string => {
  const hours = Math.floor(ms / 3600000);
  const minutes = Math.floor((ms % 3600000) / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);

  return hours > 0
    ? `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`
    : `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
};

export interface SessionTimeoutState {
  isModalVisible: boolean;
  isSessionWarning: boolean;
  displayTime: number;
  closeModal: () => void;
  signOut: () => void;
}

// One minute cooldown after modal dismissal
const MODAL_DISMISSAL_COOLDOWN_MS = 60 * 1000;

export const useSessionTimeout = (): SessionTimeoutState => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [displayTime, setDisplayTime] = useState<number>(IDLE_MODAL_TIMEOUT_MS);
  const [isSessionWarning, setIsSessionWarning] = useState(false);
  const [lastModalDismissalTime, setLastModalDismissalTime] =
    useState<number>(0);
  const modalStartTimeRef = useRef<number>(0);
  const { signOut } = useUser();

  // Helper to allow users to 'dismiss' the session warning modal
  const isInCooldownPeriod = useCallback(() => {
    return Date.now() - lastModalDismissalTime < MODAL_DISMISSAL_COOLDOWN_MS;
  }, [lastModalDismissalTime]);

  // Helper function to check session duration
  const checkSessionDuration = useCallback(() => {
    try {
      const sessionStart = Number(
        sessionStorage.getItem(SESSION_START_ITEM_KEY)
      );
      const sessionDuration = Date.now() - sessionStart;

      if (sessionDuration > MAX_SESSION_DURATION_MS) {
        signOut();
      } else if (
        sessionDuration > SESSION_WARNING_THRESHOLD_MS &&
        !isModalVisible &&
        !isInCooldownPeriod()
      ) {
        setIsModalVisible(true);
        setIsSessionWarning(true);
        setDisplayTime(MAX_SESSION_DURATION_MS - sessionDuration);
      }
    } catch (error) {
      signOut();
    }
  }, [signOut, isModalVisible, isInCooldownPeriod]);

  useEffect(() => {
    if (!sessionStorage.getItem(SESSION_START_ITEM_KEY)) {
      sessionStorage.setItem(SESSION_START_ITEM_KEY, Date.now().toString());
    }

    // Run initial check when component mounts
    checkSessionDuration();
  }, [checkSessionDuration]);

  // Check for session expiration periodically
  useEffect(() => {
    const interval = setInterval(checkSessionDuration, SESSION_CHECK_INTERVAL);
    return () => clearInterval(interval);
  }, [checkSessionDuration]);

  // Handle modal countdown and auto-signout
  useEffect(() => {
    if (!isModalVisible) return;

    modalStartTimeRef.current = Date.now();
    const timeoutDuration = isSessionWarning
      ? MAX_SESSION_DURATION_MS -
        (Date.now() -
          Number(sessionStorage.getItem(SESSION_START_ITEM_KEY) || Date.now()))
      : IDLE_MODAL_TIMEOUT_MS;

    const signoutTimeout = setTimeout(signOut, timeoutDuration);
    const countdownInterval = setInterval(() => {
      if (isSessionWarning) {
        const sessionStart = Number(
          sessionStorage.getItem(SESSION_START_ITEM_KEY) || Date.now()
        );
        setDisplayTime(
          Math.max(0, MAX_SESSION_DURATION_MS - (Date.now() - sessionStart))
        );
      } else {
        setDisplayTime(
          Math.max(
            0,
            IDLE_MODAL_TIMEOUT_MS - (Date.now() - modalStartTimeRef.current)
          )
        );
      }
    }, COUNTDOWN_INTERVAL);

    return () => {
      clearTimeout(signoutTimeout);
      clearInterval(countdownInterval);
    };
  }, [isModalVisible, signOut, isSessionWarning]);

  const { reset } = useIdleTimer({
    timeout: MODAL_TRIGGER_TIME_MS,
    onIdle: () => {
      if (!isInCooldownPeriod()) {
        setIsModalVisible(true);
        setIsSessionWarning(false);
      }
    },
    debounce: 500,
  });

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    setIsSessionWarning(false);
    setLastModalDismissalTime(Date.now());
    reset();
  }, [reset]);

  return {
    isModalVisible,
    isSessionWarning,
    displayTime,
    closeModal,
    signOut,
  };
};
