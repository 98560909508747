import { Page } from "components/App/Page";
import { JIT_ROUTING_ROLES } from "components/App/roles";
import { AuthzGuard } from "components/Login";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ResourcesProvider } from "providers/ResourcesProvider";
import { Navigate, Route } from "react-router";
import { AppPaths } from "shared/routes/constants";

import { Jit } from "./Jit";
import { IntegrationsProvider } from "./Requests/contexts/IntegrationsContext";
import { ActiveEvidence } from "./Requests/pages/ActiveEvidencePage";
import { ActiveRequests } from "./Requests/pages/ActiveRequestsPage";
import { RequestHistory } from "./Requests/pages/RequestHistoryPage";
import { RoutingRules } from "./Routing";
import { JitPaths } from "./paths";

export const JitRoutes = () => {
  const flags = useFlags();
  return (
    <Route path={AppPaths.Jit} element={<Jit />}>
      <Route index element={<Navigate to="activity" replace />} />
      <Route
        path={JitPaths.Activity}
        element={
          <IntegrationsProvider>
            <Page title={"JiT Activity"}>
              <ActiveRequests />
            </Page>
          </IntegrationsProvider>
        }
      >
        <Route path=":requestId" element={<></>} />
      </Route>
      {flags.showEvidenceStore && (
        <Route
          path={JitPaths.PriorApprovals}
          element={
            <IntegrationsProvider>
              <Page title={"Prior Approvals"}>
                <ActiveEvidence />
              </Page>
            </IntegrationsProvider>
          }
        />
      )}
      <Route
        path={JitPaths.History}
        element={
          <IntegrationsProvider>
            <Page title={"JiT History"}>
              <RequestHistory />
            </Page>
          </IntegrationsProvider>
        }
      >
        <Route path=":requestId" element={<></>} />
      </Route>
      <Route element={<AuthzGuard requirement={JIT_ROUTING_ROLES} />}>
        <Route
          path={JitPaths.Routing}
          element={
            <Page title="JiT Routing">
              <ResourcesProvider>
                <RoutingRules />
              </ResourcesProvider>
            </Page>
          }
        />
      </Route>
    </Route>
  );
};
