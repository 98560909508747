import { Alert, Drawer, Spin, Typography } from "antd";
import { SelectedEnvironmentContext } from "components/Environment/contexts/SelectedEnvironmentContext";
import { DiscoverVisualization } from "components/GraphTable/DiscoverVisualization";
import { Heading } from "components/Heading";
import { useGuardedEffect } from "hooks/useGuardedEffect";
import { useLocalStorage } from "hooks/useLocalStorage";
import {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router";
import { assessmentParse } from "shared/assessment/issues/presets";
import { aggregate } from "shared/graph/aggregate";
import { DiscoverMatch, discoverPaths } from "shared/graph/discover";
import { NodePredicate } from "shared/graph/search";
import {
  DEFAULT_GRAPH_SEARCH_SETTINGS,
  GraphSearchSettings,
} from "shared/graph/settings";
import {
  ConnectedNode,
  DirectedGraph,
  NodeOf,
  Reducers,
} from "shared/graph/types";
import {
  AnyAggregates,
  AssessmentNodes,
  TargetNodeTypes,
  isTargetNodeType,
} from "shared/types/assessment/data";
import { isa } from "shared/types/is";
import { TIME_TO_DEBOUNCE, sleep } from "shared/util/sleep";
import { staticError } from "utils/console";

import { NodeDisplay } from "../components/node/NodeDisplay";
import { GraphContextProvider } from "../contexts/GraphContext";
import { ScopeContext } from "../contexts/ScopeContext";
import { useNavigateWithEnv } from "../hooks/useNavigateWithEnv";
import { nodeDataFromShow } from "./node";
import { NodeProperties } from "./node/NodeProperties";
import {
  AssessmentNodeRenderers,
  AssessmentNodeSizers,
} from "./node/NodeRenderer";
import { NodeText } from "./node/NodeText";

const ResultDetailFitViewOptions = {
  maxZoom: 0.8,
};

export const ResultDetailContainer: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => (
  // 108px subtraction here is necessary to correct for padding / headings
  <div style={{ minHeight: "calc(100% - 108px)", position: "relative" }}>
    {children}
  </div>
);

export const ResultDetailDrawer: React.FC<
  React.PropsWithChildren<{ title: ReactNode }>
> = ({ children, title }) => {
  const location = useLocation();
  const navigate = useNavigateWithEnv();

  const closeDrawer = useCallback(
    () => navigate({ ...location, pathname: "../.." }, { relative: "path" }),
    [location, navigate]
  );

  return (
    <Drawer
      push={false}
      styles={{ body: { padding: "12px" } }}
      getContainer={false}
      mask={false}
      onClose={closeDrawer}
      open
      title={<div style={{ display: "flex", gap: "0.3em" }}>{title}</div>}
      width={"max(50%, min(80%, 1000px))"}
    >
      {children}
    </Drawer>
  );
};

export type ResultDetailProps = {
  header?: ReactNode;
  mode: "finding" | "query result";
  node: { key: string; type: string };
  terms: string[];
  title?: ReactNode;
};

export const ResultDetail: React.FC<ResultDetailProps> = ({
  node: { key, type },
  ...props
}) => {
  const nodeData = useMemo(
    () => (isa(TargetNodeTypes, type) ? nodeDataFromShow[type] : undefined),
    [type]
  );
  if (!nodeData)
    return (
      <Alert message="Unexpected node type" description={type} type="error" />
    );

  const { predicate, reducers } = nodeData;
  return (
    <InnerResultDetail
      from={predicate}
      node={{ key, type }}
      reducers={reducers as Reducers<AssessmentNodes, AnyAggregates>}
      {...props}
    />
  );
};

const InnerResultDetail = <A extends AnyAggregates>({
  from,
  header,
  mode,
  node: { key, type },
  reducers,
  terms,
  title,
}: ResultDetailProps & {
  from: NodePredicate<AssessmentNodes>;
  reducers: Reducers<AssessmentNodes, A>;
}): ReactElement => {
  const { last } = useContext(SelectedEnvironmentContext);
  const { loading } = last;
  const { graph, provider, step } = useContext(ScopeContext);
  const [results, setResults] =
    useState<DiscoverMatch<DirectedGraph<AssessmentNodes>>[]>();
  const [settings, _] = useLocalStorage<GraphSearchSettings>(
    "graph-search-settings"
  );
  const [node, setNode] = useState<
    ConnectedNode<AssessmentNodes, keyof AssessmentNodes> | undefined
  >(undefined);

  useGuardedEffect(
    (cancellation) => async () => {
      setNode(undefined);
      const node = graph?.nodes.find((n) => n.type === type && n.key === key);
      if (!node) return;
      // Debounce state update
      await sleep(TIME_TO_DEBOUNCE);
      if (cancellation.isCancelled) return;
      const result = (await aggregate({ nodes: [node] }, reducers)).nodes[0];
      setNode(result);
    },
    [graph?.nodes, key, reducers, type],
    staticError
  );

  const rendererParams = useMemo(
    () => ({
      currentLocaleNode: node,
      provider,
    }),
    [provider, node]
  );

  const stopOn = useMemo(
    () =>
      mode === "finding"
        ? []
        : (settings ?? DEFAULT_GRAPH_SEARCH_SETTINGS).stopOn,
    [mode, settings]
  );

  useGuardedEffect(
    (cancellation) => async () => {
      if (!node) return undefined;
      // Only ever one node
      const graph = {
        nodes: [node as ConnectedNode<AssessmentNodes, keyof AssessmentNodes>],
      };
      const [discovered] = await discoverPaths({
        graph,
        from,
        settings: { stopOn },
        search: assessmentParse(terms.join(" ")),
      });
      const results = discovered?.matches;
      if (!results) return cancellation.guard(setResults)([]);
      setResults([{ node: node, matches: results }]);
    },
    [node, from, terms, stopOn],
    staticError // nosemgrep use-static-error-handler-in-use-guarded-effect
  );

  // Only show paths if they include at least one edge
  const showPaths = useMemo(
    () =>
      results?.some(({ matches }) =>
        matches.some(({ paths }) => paths.some((p) => p.length > 1))
      ),
    [results]
  );

  const nodeTitle = useMemo(
    () =>
      title ??
      (node &&
        NodeText(node, provider, { noHover: true, hideThis: true })[
          node.type
        ]?.(node as any)),
    [node, provider, title]
  );

  const nodeProperties = useCallback(
    (node: NodeOf<AssessmentNodes>) => (
      <NodeProperties node={node} provider={provider} />
    ),
    [provider]
  );

  return (
    <ResultDetailDrawer title={nodeTitle}>
      {header}
      {loading || !node || step !== "done" ? (
        <Spin />
      ) : !graph || !isTargetNodeType(node) ? (
        <Heading title="Not Found" />
      ) : (
        <div style={{ width: "100%" }} data-testid={"result-detail"}>
          {showPaths && !!results && (
            <>
              <Typography.Title level={4} style={{ marginTop: "0.5em" }}>
                {mode === "query result" ? "Query Path" : "Attack Path"}
              </Typography.Title>
              <GraphContextProvider rendererParams={rendererParams}>
                <DiscoverVisualization
                  matches={results}
                  renderer={AssessmentNodeRenderers}
                  sizer={AssessmentNodeSizers}
                  settings={{
                    maxResults: 1,
                    maxPaths: 100,
                    stopOn,
                  }}
                  properties={nodeProperties}
                  viewStyle={{ height: "500px", width: "100%" }}
                  fitView={true}
                  fitViewOptions={ResultDetailFitViewOptions}
                />
              </GraphContextProvider>
            </>
          )}
          <Typography.Title level={4} style={{ marginTop: "0.5em" }}>
            Details
          </Typography.Title>
          <div style={{ maxWidth: "1000px" }}>
            <NodeDisplay node={node} />
          </div>
        </div>
      )}
    </ResultDetailDrawer>
  );
};
