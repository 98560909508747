import type { TableColumnType, TableProps } from "antd";
import { Table as AntTable } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { useLocalStorage } from "hooks/useLocalStorage";
import { sum } from "lodash";
import React, { ReactEventHandler, useCallback, useMemo } from "react";
import { Resizable, type ResizeCallbackData } from "react-resizable";
import styled from "styled-components";

type ResizeHandler = (
  e: React.MouseEvent<HTMLElement>,
  data: ResizeCallbackData
) => void;

interface ResizableTableProps<T extends object> extends TableProps<T> {
  columns: ResizableTableColumnsType<T>;
  rowSelection?: Omit<TableRowSelection<T>, "columnWidth">;
  tableId: string;
}

const stopPropagation = (event: React.MouseEvent | React.SyntheticEvent) => {
  event.stopPropagation();
};

/**
 * A type that extends the antd TableColumnsType type,
 * but forces the width property to be a number.
 *
 * This is because the resizable library only works with
 * numeric widths.
 *
 * By default, columns in this table will be resizable
 * unless the optional preventResize prop is set to true
 */

interface BaseResizableTableColumnType<T extends object>
  extends TableColumnType<T> {
  width: number;
  preventResize?: boolean;
}

export type ResizableTableColumnType<T extends object> =
  BaseResizableTableColumnType<T> &
    ({ key: string } | { title: string }) & {
      minWidth?: number;
    };

export type ResizableTableColumnsType<RecordType extends object> =
  ResizableTableColumnType<RecordType>[];

const MIN_COLUMN_WIDTH = 50;
const DEFAULT_COLUMN_WIDTH = 100;
const MAX_COLUMN_WIDTH = 500;
const SELECT_BOX_COLUMN_WIDTH = 35;

// These keys should be unique across the app
export const ResizableTableKeys = {
  frequentRequests: "frequent-requests",
  graph: "graph",
  jitStatistics: "jit-statistics",
};

// Styled component for the resize handle
const ResizeHandle = styled.span`
  bottom: 0;
  cursor: col-resize;
  height: 100%;
  inset-inline-end: -5px;
  position: absolute;
  width: 10px;
  z-index: 1;
`;

// antd styling will clip the resize handle
// override this styling to ensure that handle is shown
const ResizableInner = styled.th`
  overflow: visible !important;
`;

const ResizableTitle: React.FC<
  Readonly<
    React.HTMLAttributes<any> & {
      onResize: ResizeHandler;
      preventResize?: boolean;
      width: number;
    }
  >
> = ({ onResize, width, preventResize, ...restProps }) => {
  const component = <ResizableInner {...restProps} />;

  // if the column's width is smaller than the minimum
  // or larger than the maximum, resizing it will make
  // it visually "jump" on the screen when it immediately
  // resizes to the minimum/maximum width so we just
  // return a non-resizable component in that case
  if (isNaN(width) || preventResize) {
    return component;
  }

  return (
    <Resizable
      width={width ?? MIN_COLUMN_WIDTH}
      height={0}
      minConstraints={[MIN_COLUMN_WIDTH, MIN_COLUMN_WIDTH]}
      maxConstraints={[MAX_COLUMN_WIDTH, MAX_COLUMN_WIDTH]}
      // stopPropagation here prevents sort change during resize
      handle={<ResizeHandle onClick={stopPropagation} />}
      onResize={onResize}
      onResizeStart={stopPropagation}
      onResizeStop={stopPropagation}
      draggableOpts={{ enableUserSelectHack: true }}
    >
      {component}
    </Resizable>
  );
};

const toColumnKey = (column: ResizableTableColumnType<any>) => {
  return `${column.key ?? column.title}`;
};

/**
 * A table component that allows the columns to be resized.
 *
 * Takes the same props as the antd Table component, except that
 * column widths must be numbers.
 *
 * Any column that has a numeric width greater than 70 will be
 * resizable.  The 70 minimum is necessary to keep any sorting and filtering
 * options visible.
 *
 * Adapted from https://ant.design/components/table#table-demo-resizable-column
 */
export const ResizableTable = <T extends object>(
  props: ResizableTableProps<T>
) => {
  const { columns, components, tableId, ...restProps } = props;
  const widthsToKey = useMemo(
    () => Object.fromEntries(columns.map((c) => [toColumnKey(c), c.width])),
    [columns]
  );
  const [columnWidths, setColumnWidths] = useLocalStorage<any>(
    `resizable-table-columns-${tableId}`,
    widthsToKey
  );

  const handleResize = useCallback(
    (key: string): ResizeHandler =>
      (event, { node }) => {
        // Note that the recommended resize would take the width from the second
        // argument's `size` property. However, this ends up with the mouse disconnected
        // from the resize handle's location, making for an awkward / broken resize
        // experience. Instead, just directly read the mouse location and the handle's
        // parent's position.
        event.stopPropagation();
        const { parentElement } = node;
        if (!parentElement) return;
        // Gets the actual width of the column by calculating the distance from the
        // column left x and the current mouse x position
        const width = event.clientX - parentElement.getBoundingClientRect().x;
        setColumnWidths({
          ...columnWidths,
          [key]: Math.min(Math.max(width, MIN_COLUMN_WIDTH), MAX_COLUMN_WIDTH),
        });
      },
    [columnWidths, setColumnWidths]
  );

  const onHeaderCell = useCallback(
    (key: string, column: ResizableTableColumnType<T>) => () => ({
      onResize: handleResize(key) as ReactEventHandler,
      preventResize: column?.preventResize,
      width: columnWidths[key] ?? column?.width,
    }),
    [columnWidths, handleResize]
  );

  const mergedColumns = useMemo(
    () =>
      columns.map<ResizableTableColumnType<T>>((column) => {
        const key = toColumnKey(column);
        const width = columnWidths[key] ?? column.width ?? DEFAULT_COLUMN_WIDTH;

        return {
          ...column,
          width: Math.max(column.minWidth ?? 0, width),
          onHeaderCell: onHeaderCell(key, column),
        };
      }),
    [columnWidths, columns, onHeaderCell]
  );

  return (
    <AntTable<T>
      // Turn off the tooltip by default, but allow it to be overridden
      showSorterTooltip={false}
      {...restProps}
      components={{ ...components, header: { cell: ResizableTitle } }}
      columns={mergedColumns}
      // Set the column width of row selection, if row selection is enabled for this table
      rowSelection={
        "rowSelection" in restProps
          ? { ...restProps.rowSelection, columnWidth: SELECT_BOX_COLUMN_WIDTH }
          : undefined
      }
      // Fixed layout + defined width is necessary to make width actually be in pixels
      // See https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout
      tableLayout="fixed"
      style={{
        width: sum(mergedColumns.map((c) => c.width)),
      }}
    />
  );
};
