import { red } from "@ant-design/colors";
import { ForwardOutlined } from "@ant-design/icons";
import { DefaultTitles } from "shared/assessment/constants";
import { ConnectedNode } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";

import { Lateral } from "../../cells/Lateral";
import {
  DefaultNodeHeaderHeight,
  DefaultNodeTwoLineBodyHeight,
  DefaultNodeWidth,
} from "./shared";

export const CustomLateralNodeParameters = {
  type: "lateral" as const,
  titleText: () => DefaultTitles.lateral,
  IconComponent: ForwardOutlined,
  bodyBorderColor: red[2],
  bodyBackgroundColor: red[1],
  bodyContent: (node: ConnectedNode<AssessmentNodes, "lateral">) => (
    <Lateral node={node} />
  ),
  bodySize: () => DefaultNodeTwoLineBodyHeight,
  headerSize: () => DefaultNodeHeaderHeight,
  width: () => DefaultNodeWidth,
};
