import { Button, Flex, Result } from "antd";
import { Logo } from "components/Integrations/Logo";
import ContactSupport from "components/Integrations/components/ContactSupport";
import { useUser } from "components/Login/hook";

export const DisabledTenant: React.FC<{ orgSlug?: string }> = ({ orgSlug }) => {
  const { signOut } = useUser();
  return (
    <Flex justify="center" align="center">
      <Result
        title="Organization disabled due to inactivity."
        icon={<Logo logo="/p0-logo-ext.png" title="P0 Logomark" size={300} />}
        subTitle={
          <>
            Please
            <ContactSupport
              subject={`Re-enable organization${orgSlug ? " " + orgSlug : ""}`}
            />
            .
          </>
        }
        extra={
          <Button type="primary" onClick={signOut}>
            Logout
          </Button>
        }
      />
    </Flex>
  );
};
