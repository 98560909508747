import { SettingOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Popover, Switch } from "antd";
import { useCallback } from "react";
import {
  DEFAULT_GRAPH_SEARCH_SETTINGS,
  DISCOVER_ONLY_DEFAULT_SETTINGS,
  GraphSearchSettings,
} from "shared/graph/settings";
import styled from "styled-components";

export type GraphSearchSettingsDisables = Partial<
  Record<keyof GraphSearchSettings, boolean> //If exists, disables that setting with the give value
>;

export type GraphSearchSettingsControlProps = {
  disables: GraphSearchSettingsDisables;
  settings: GraphSearchSettings;
  setSettings: (settings: GraphSearchSettings) => void;
};

const GraphSearchSettingsForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 12px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const GraphSearchSettingsControl: React.FC<
  GraphSearchSettingsControlProps
> = ({ disables, settings, setSettings }) => {
  const handleChangeMaxResults = useCallback(
    (maxResults: number | null) =>
      setSettings({
        ...settings,
        maxResults: maxResults ?? DEFAULT_GRAPH_SEARCH_SETTINGS.maxResults,
      }),
    [setSettings, settings]
  );
  const handleChangeMaxPaths = useCallback(
    (maxPaths: number | null) =>
      setSettings({
        ...settings,
        maxPaths: maxPaths ?? DEFAULT_GRAPH_SEARCH_SETTINGS.maxPaths,
      }),
    [setSettings, settings]
  );
  const handleIncludeLateral = useCallback(
    (state: boolean) => {
      const hasLateral = settings.stopOn?.includes("lateral");
      setSettings({
        ...settings,
        stopOn: state
          ? settings.stopOn?.filter((v) => v !== "lateral") ??
            DEFAULT_GRAPH_SEARCH_SETTINGS.stopOn
          : !hasLateral
          ? [...(settings.stopOn ?? []), "lateral"]
          : settings.stopOn,
      });
    },
    [setSettings, settings]
  );
  return (
    // Hide the popover if all the settings are disabled
    !disables.maxResults ||
      !disables.maxPaths ||
      !(disables.stopOn !== undefined) ? (
      <Popover
        content={
          <GraphSearchSettingsForm>
            {!disables.maxResults && (
              <Form.Item label="Max results">
                <InputNumber
                  disabled={disables.maxResults}
                  min={1}
                  max={1000}
                  value={
                    disables.maxResults
                      ? DISCOVER_ONLY_DEFAULT_SETTINGS.maxResults
                      : settings.maxResults
                  }
                  onChange={handleChangeMaxResults}
                />
              </Form.Item>
            )}
            {!disables.maxPaths && (
              <Form.Item label="Max paths">
                <InputNumber
                  disabled={disables.maxPaths}
                  min={1}
                  max={1000}
                  value={
                    disables.maxPaths
                      ? DISCOVER_ONLY_DEFAULT_SETTINGS.maxPaths
                      : settings.maxPaths
                  }
                  onChange={handleChangeMaxPaths}
                />
              </Form.Item>
            )}
            {!(disables.stopOn !== undefined) && (
              <Form.Item label="Include lateral movement?">
                <Switch
                  checked={
                    disables.stopOn !== undefined
                      ? !disables.stopOn
                      : !settings.stopOn?.includes("lateral")
                  }
                  disabled={disables.stopOn !== undefined}
                  onChange={handleIncludeLateral}
                />
              </Form.Item>
            )}
          </GraphSearchSettingsForm>
        }
        placement="bottom"
        trigger="click"
      >
        <Button icon={<SettingOutlined />} />
      </Popover>
    ) : null
  );
};
