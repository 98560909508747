import { Typography } from "antd";
import { ResizableTableColumnType } from "components/ResizableTable";
import { AnyNode } from "shared/types/assessment/data";

import { LinkWithEnvironment } from "../LinkWithEnvironment";

const { Text } = Typography;

export const DetailsColumn = (
  fromData: (
    data: AnyNode
  ) => { disabled?: boolean; key: string; to: string } | undefined
): ResizableTableColumnType<AnyNode> => ({
  width: 50,
  ellipsis: true,
  key: "details",
  render: (_: any, data: AnyNode) => {
    const props = fromData(data);
    return (
      props &&
      (props.disabled ? (
        <Text type="secondary">view</Text>
      ) : (
        <LinkWithEnvironment {...props}>view</LinkWithEnvironment>
      ))
    );
  },
});
