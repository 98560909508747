import { red } from "@ant-design/colors";
import { WarningOutlined } from "@ant-design/icons";
import { CatalogContext } from "components/Catalog/context";
import { useContext } from "react";
import { DefaultTitles } from "shared/assessment/constants";
import { ConnectedNode } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";

import { RiskLink } from "../../cells/RiskLink";
import {
  DefaultNodeHeaderHeight,
  DefaultNodeOneLineBodyHeight,
  DefaultNodeWidth,
} from "./shared";

export const HydratedRiskLink: React.FC<{ id: string }> = ({ id }) => {
  const { risks } = useContext(CatalogContext);
  return id in risks ? <RiskLink risk={risks[id]} /> : <>{id}</>;
};

export const CustomRiskNodeParameters = {
  type: "risk" as const,
  titleText: () => DefaultTitles.risk,
  IconComponent: WarningOutlined,
  bodyBorderColor: red[2],
  bodyBackgroundColor: red[1],
  bodyContent: (node: ConnectedNode<AssessmentNodes, "risk">) => (
    <HydratedRiskLink key={node.key} id={node.key} />
  ),
  bodySize: () => DefaultNodeOneLineBodyHeight,
  headerSize: () => DefaultNodeHeaderHeight,
  width: () => DefaultNodeWidth,
};
