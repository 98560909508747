import { NodeDimensions } from "components/GraphTable/DiscoverVisualization";
import { capitalize, mapValues } from "lodash";
import { useContext, useMemo } from "react";
import { AssessmentNodes } from "shared/types/assessment/data";

import { GraphContext } from "../../contexts/GraphContext";
import { CustomNodeProps } from "../GraphVisualization";
import { CustomNodeBase, CustomNodeBody, CustomNodeHeader } from "./CustomNode";
import {
  AssessmentNodeCatalog,
  CustomAssessmentNodeParameters,
} from "./renderers";

// CustomAssessmentNodeProps is the set of props React Flow will pass
// to a custom node renderer
export type CustomAssessmentNodeProps<K extends keyof AssessmentNodes> =
  CustomNodeProps<AssessmentNodes, K>;

type CustomAssessmentNodeTemplate<K extends keyof AssessmentNodes> = {
  renderers: {
    /* Large is the full-size node renderer */
    Large: React.FC<CustomAssessmentNodeProps<K>>;
    /* Small is the minimized node renderer that shows up as part of an expanded collapsible node */
    Small: React.FC<CustomAssessmentNodeProps<K>>;
  };
  sizers: {
    large: () => NodeDimensions;
    small: () => NodeDimensions;
  };
};

// GenerateTemplate creates a CustomNodeTemplate from a CustomAssessmentNodeParameters
const GenerateTemplate = <K extends keyof AssessmentNodes>(
  parameters: CustomAssessmentNodeParameters<K>
): CustomAssessmentNodeTemplate<K> => {
  const {
    bodyContent,
    IconComponent,
    titleText,
    bodyBorderColor,
    bodyBackgroundColor,
  } = parameters;

  const width = parameters.width();
  const headerHeight = parameters.headerSize();
  const bodyHeight = parameters.bodySize();

  const Large: React.FC<CustomAssessmentNodeProps<K>> = (
    props: CustomAssessmentNodeProps<K>
  ) => {
    const { data } = props;
    const { node } = data;
    const { rendererParams } = useContext(GraphContext);
    const title = useMemo(
      () => titleText(node, rendererParams),
      [node, rendererParams]
    );
    const body = useMemo(
      () => bodyContent(node, rendererParams),
      [node, rendererParams]
    );

    return (
      <CustomNodeBase
        nodeProps={props}
        rendererParams={rendererParams}
        height={headerHeight + bodyHeight}
        width={width}
      >
        <CustomNodeHeader
          title={title}
          IconComponent={IconComponent}
          height={headerHeight}
          width={width}
        />
        <CustomNodeBody
          backgroundColor={bodyBackgroundColor}
          borderColor={bodyBorderColor}
          nodeProps={props}
          height={bodyHeight}
          width={width}
        >
          {body}
        </CustomNodeBody>
      </CustomNodeBase>
    );
  };
  Large.displayName = `Custom${capitalize(parameters.type)}NodeLarge`;

  const Small: React.FC<CustomAssessmentNodeProps<K>> = (
    props: CustomAssessmentNodeProps<K>
  ) => {
    const { data } = props;
    const { node } = data;
    const { rendererParams } = useContext(GraphContext);
    const body = useMemo(
      () => bodyContent(node, rendererParams),
      [node, rendererParams]
    );

    // Remove source and target position from props on the small renderer
    // so that edges don't point to them (they point to the parent collapsible node instead)
    const propsWithoutHandles = useMemo(
      () => ({
        ...props,
        sourcePosition: undefined,
        targetPosition: undefined,
      }),
      [props]
    );

    return (
      <CustomNodeBase
        nodeProps={propsWithoutHandles}
        rendererParams={rendererParams}
        height={bodyHeight}
        width={width}
      >
        <CustomNodeBody
          backgroundColor={bodyBackgroundColor}
          borderColor={bodyBorderColor}
          nodeProps={propsWithoutHandles}
          height={bodyHeight}
          width={width}
        >
          {body}
        </CustomNodeBody>
      </CustomNodeBase>
    );
  };
  Small.displayName = `Custom${capitalize(parameters.type)}NodeSmall`;

  return {
    renderers: {
      Large,
      Small,
    },
    sizers: {
      large: () => ({
        width,
        height: bodyHeight + headerHeight,
      }),
      small: () => ({
        width,
        height: bodyHeight,
      }),
    },
  };
};

// AssessmentNodeTemplates is a mapping of node types to their corresponding CustomNodeTemplates
const AssessmentNodeTemplates: {
  [K in keyof AssessmentNodes]: CustomAssessmentNodeTemplate<K>;
} = {
  condition: GenerateTemplate(AssessmentNodeCatalog.condition),
  consumer: GenerateTemplate(AssessmentNodeCatalog.consumer),
  credential: GenerateTemplate(AssessmentNodeCatalog.credential),
  grant: GenerateTemplate(AssessmentNodeCatalog.grant),
  identity: GenerateTemplate(AssessmentNodeCatalog.identity),
  lateral: GenerateTemplate(AssessmentNodeCatalog.lateral),
  privilege: GenerateTemplate(AssessmentNodeCatalog.privilege),
  permissionSet: GenerateTemplate(AssessmentNodeCatalog.permissionSet),
  privilegeSet: GenerateTemplate(AssessmentNodeCatalog.privilegeSet),
  resource: GenerateTemplate(AssessmentNodeCatalog.resource),
  risk: GenerateTemplate(AssessmentNodeCatalog.risk),
  usage: GenerateTemplate(AssessmentNodeCatalog.usage),
};

// AssessmentNodeRenderers is a mapping of node types to their corresponding renderers
export const AssessmentNodeRenderers: {
  [K in keyof AssessmentNodes]: CustomAssessmentNodeTemplate<K>["renderers"];
} = mapValues(AssessmentNodeTemplates, (t) => t.renderers as any);

// AssessmentNodeSizers is a mapping of node types to their corresponding sizers
export const AssessmentNodeSizers: {
  [K in keyof AssessmentNodes]: CustomAssessmentNodeTemplate<K>["sizers"];
} = mapValues(AssessmentNodeTemplates, (t) => t.sizers as any);
