import { Space, Typography } from "antd";
import { ErrorDisplay } from "components/Error";
import ContactSupport from "components/Integrations/components/ContactSupport";
import React, { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";

const fallbackRender = ({ error }: { error: Error }) => (
  <Space direction="vertical" role="alert">
    <ErrorDisplay error={error} />
    <Typography.Text>
      If this error persists, please
      <ContactSupport />
    </Typography.Text>
  </Space>
);

/**
 * This component wraps our ErrorDisplay component in an ErrorBoundary.
 * The ErrorDisplay component is used to display red error message at the top
 * of the page to the user.
 */
export const ErrorBoundaryDisplay: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <ErrorBoundary fallbackRender={fallbackRender}>{children}</ErrorBoundary>
  );
};
