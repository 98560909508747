import { map } from "lodash";
import pluralize from "pluralize";

import { DURATION_OPTIONS_SECONDS } from "../configuration/constant";
import { DurationOption } from "../configuration/types";
import { ValidationError } from "../types/error";
import { PermissionRequest } from "../types/permission";

const acceptedDurationUnitsBase = ["hour", "minute", "day", "week", "second"]; // "second" is only used in e2e tests
export const acceptedDurationUnits = acceptedDurationUnitsBase.reduce(
  (acc, unit) => [...acc, unit, pluralize(unit)],
  [] as string[]
);

// Units must match moment.unitOfTime.Base values from moment.js
// This conversion takes the first letter to get one of: "h", "m", "d", "w", "s" (only used in e2e tests)
export const convertBaseToUnit = (base: string) => base.slice(0, 1);

export const DURATION_PATTERN = new RegExp(
  `(\\d+)(${acceptedDurationUnitsBase.map(convertBaseToUnit).join("|")})`
);

export const CUSTOM_DURATION_PATTERN = new RegExp(
  `^(\\d+)\\s+(${acceptedDurationUnits.join("|")})$`
);

const durationUnitConversionRatios = {
  d: 24,
  w: 24 * 7,
};

export const timeUnitLabels = {
  m: "minute",
  h: "hour",
  d: "day",
  w: "week",
  // Exclude "second" because it's only used in e2e tests
};

export const timeUnitLabelOptions = map(timeUnitLabels, (text, value) => ({
  label: text,
  value,
}));

type TimeUnit = keyof typeof timeUnitLabels;

export const isValidTimeUnit = (value: string): value is TimeUnit =>
  value in timeUnitLabels;

export const isValidDuration = (duration: string) => {
  return DURATION_PATTERN.test(duration);
};

export function convertToDurationString(option: DurationOption): string;
export function convertToDurationString(
  time: number | string,
  unit: string
): string;
export function convertToDurationString(
  arg1: DurationOption | number | string,
  arg2?: string
): string {
  if (typeof arg1 === "object") {
    return convertToDurationString(arg1.time, arg1.unit);
  }
  return `${arg1}${arg2}`;
}

export const convertDurationToDurationOption = (
  timeString: string,
  unit: string
): DurationOption => {
  if (!isValidTimeUnit(unit)) {
    throw new ValidationError(
      "unit",
      { unit },
      `Invalid expiration unit. Please use one of the following units: ${acceptedDurationUnits.join(
        ", "
      )}.`
    );
  }

  const time = parseInt(timeString);
  const baseUnit = timeUnitLabels[unit];
  const value = time === 1 ? baseUnit : pluralize(baseUnit);

  return { time, unit, value };
};

/**
 * You should use this function with a try-catch block to handle invalid durations.
 * @param duration a string representing a duration in the format of "number" + "unit" (e.g. "1h", "30m", "2d", "1w"). Only accepts "m", "h", "d", "w" as units.
 * @returns a object representing the duration, with week or day values converted to hours.
 */
export const convertToDurationOption = (duration: string): DurationOption => {
  const match = duration.match(DURATION_PATTERN);
  if (!isValidDuration(duration) || match === null) {
    throw new ValidationError(
      "convert duration",
      { duration },
      "Invalid duration."
    );
  }

  // "Unit" here is implied to be 'm' | 'h' | 'd' | 'w' due to the Regex match above.
  const [_, time, unit] = match;
  let outputTime = parseInt(time);
  if (["d", "w"].includes(unit)) {
    const baseTime = outputTime;
    const baseUnit = timeUnitLabels[unit as "d" | "w"];
    outputTime *= durationUnitConversionRatios[unit as "d" | "w"];
    return {
      time: outputTime,
      unit: "h",
      // Since we're multiplying week/day values to get hours, we can guarantee this will be plural.
      value: `${baseTime} ${baseTime === 1 ? baseUnit : pluralize(baseUnit)}`,
    };
  }
  // Make TypeScript happy. This is guaranteed because of the above logic.
  const typedUnit = unit as "h" | "m";
  return {
    time: outputTime,
    unit: typedUnit,
    value: `${outputTime} ${
      outputTime === 1
        ? timeUnitLabels[typedUnit]
        : pluralize(timeUnitLabels[typedUnit])
    }`,
  };
};

/**
 *
 * @param duration a custom duration string in the format of "number" + "unit" (e.g. "30 minutes", "1 hour", "2 days", "1 week"). Only accepts "minutes", "hours", "days", "weeks" as units.
 * @returns a string representing the duration in the format of "number" + "short unit" (e.g. "30m", "1h", "2d", "1w").
 */
export const parseCustomDuration = (duration: string) => {
  const match = duration.match(CUSTOM_DURATION_PATTERN);
  if (!match || match.length < 3) {
    throw new ValidationError(
      "duration",
      { duration },
      "Invalid duration format. Please enter a number followed by a unit (e.g., '30 minutes', '1 hour', '2 days', '1 week')."
    );
  }

  const [_, time, unitMatch] = match;
  const unit = unitMatch.toLowerCase();

  if (!acceptedDurationUnits.includes(unit)) {
    throw new ValidationError(
      "duration",
      { duration },
      `Invalid duration unit. Please use one of the following units: ${acceptedDurationUnits.join(
        ", "
      )}.`
    );
  }

  return convertToDurationString(time, convertBaseToUnit(unit));
};

export const sortApprovalOptions = (
  option1: DurationOption,
  option2: DurationOption
) =>
  option1.time * DURATION_OPTIONS_SECONDS[option1.unit] -
  option2.time * DURATION_OPTIONS_SECONDS[option2.unit];

export const notificationsAreEnabled = (request: PermissionRequest) =>
  request?.approvalDetails?.approvalSource !== "persistent";

export const isCustomDuration = (duration: string) => {
  return CUSTOM_DURATION_PATTERN.test(duration);
};
