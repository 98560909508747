import { Input } from "antd";
import styled from "styled-components";

/** Fixes disgusting prefix styling default for antd inputs */
export const SearchInput = styled(Input.Search)`
  button.ant-input-search-button {
    width: 32px;
  }
  .ant-input-prefix {
    align-items: baseline;
    margin-top: 4px;
    max-width: 100%;
  }
  .ant-input {
    margin-bottom: unset;
  }
`;
