import { IssuesCloseOutlined } from "@ant-design/icons";
import { ZeroStatePage } from "components/Dashboard/components/common/ZeroStatePage";
import { EnvironmentDocsContext } from "components/Environment/contexts/EnvironmentDocsContext";
import { SearchInput } from "components/antd";
import { SpaceBetweenDiv, VerticalSpacedDiv } from "components/divs";
import { ChangeEvent, useCallback, useContext } from "react";

import { FindingsExport } from "../components/FindingsExport";
import { FindingsMetrics } from "../components/FindingsMetrics";
import { FindingsSelect } from "../components/FindingsSelect";
import { MonitorList } from "../components/MonitorList";
import { ScopeSelect } from "../components/ScopeSelect";
import { FindingsContext } from "../contexts/FindingsContext";

export const Findings: React.FC = () => {
  const { hasEnvironments } = useContext(EnvironmentDocsContext);
  const { monitorFilter, setMonitorFilter, allMonitors } =
    useContext(FindingsContext);
  const updateMonitorFilter = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setMonitorFilter(event.target.value),
    [setMonitorFilter]
  );

  return hasEnvironments ? (
    <div style={{ maxWidth: 800 }}>
      <FindingsMetrics allMonitors={allMonitors} />
      <VerticalSpacedDiv style={{ marginBottom: "8px" }}>
        <SpaceBetweenDiv>
          <FindingsSelect />
          <ScopeSelect includeAll />
          <FindingsExport />
        </SpaceBetweenDiv>

        <SearchInput
          onChange={updateMonitorFilter}
          placeholder="Filter monitors..."
          value={monitorFilter}
        />
      </VerticalSpacedDiv>

      <MonitorList actionTypes={[]} />
    </div>
  ) : (
    <ZeroStatePage
      title="Manage the posture of your cloud identities"
      description="Posture helps you identify and remediate risks in your cloud environments."
      icon={<IssuesCloseOutlined />}
      docLink="https://docs.p0.dev/what-is-p0/iam-assessment"
    />
  );
};
