import {
  ApiFilled,
  ClockCircleFilled,
  DashboardFilled,
  DatabaseFilled,
  EyeFilled,
  LinkOutlined,
  LockFilled,
  LogoutOutlined,
  SettingFilled,
} from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import * as Sentry from "@sentry/react";
import { Button, Image, Layout, Menu, Result, Space } from "antd";
import { ItemType } from "antd/lib/menu/interface";
import { AssessmentRoutes } from "components/Assessment/Routes";
import { LinkWithEnvironment } from "components/Assessment/components/LinkWithEnvironment";
import { useNavigateWithEnv } from "components/Assessment/hooks/useNavigateWithEnv";
import CreateAccountPage from "components/CreateAccount/v2/CreateAccountPage";
import {
  EnvironmentDocsContext,
  EnvironmentDocsProvider,
} from "components/Environment/contexts/EnvironmentDocsContext";
import ContactSupport from "components/Integrations/components/ContactSupport";
import { JitRoutes } from "components/Jit/Routes";
import { OidcRedirect } from "components/Login/pkce";
import SessionManager from "components/Session/SessionManager";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useCallback, useContext, useMemo } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { useLocation, useParams } from "react-router";
import { Link, NavLink, Outlet, Route, Routes } from "react-router-dom";
import { AppPaths } from "shared/routes/constants";
import styled from "styled-components";

import { useIsPrint } from "../../hooks/useIsPrint";
import { useScreen } from "../../hooks/useScreen";
import { PolicyRoutes } from "../../policies/Routes";
import { getNavLinkClassName } from "../../utils/activeNavLinkClassName";
import { Admin } from "../Admin/Admin";
import { GithubSetupRedirect } from "../Integrations/Github/GithubSetupRedirect";
import { OAuth2Redirect } from "../Integrations/OAuth2";
import { IntegrationRoutes } from "../Integrations/Routes";
import {
  AccountSelect,
  AuthzGuard,
  DeviceAuth,
  EmailCallbackHandler,
  Login,
  UserAuthz,
  UserRole,
} from "../Login";
import { useUser } from "../Login/hook";
import { snowflakeRestStateRoutes } from "../RestState/Routes";
import { SandboxAlert, addSandboxMenu, sandboxRoutes } from "../Sandbox/Routes";
import { OnboardWorkflows } from "../Welcome/OnboardWorkflows";
import Success from "../Welcome/Success";
import { Welcome } from "../Welcome/Welcome";
import "./App.less";
import { Footer } from "./Footer";
import { LoggedInUserNavItem } from "./LoggedInUserNavItem";
import { OnboardingRedirect } from "./OnboardingRedirect";
import { Page } from "./Page";
import { DisabledTenant } from "./pages/DisabledTenant";
import { NotFound } from "./pages/NotFound";
import {
  CONFIG_ROUTES_ROLES,
  ENVIRONMENT_ROLES,
  ENV_SETTINGS_ROLES,
} from "./roles";

const StyledLogoImage = styled(Image)`
  margin-top: 10px;
`;

const StyledSmallLogoImage = styled(Image)`
  margin-top: 10px;
  margin-left: -20px;
`;

const { Sider, Content } = Layout;

const P0NavIcon: React.FC = () => {
  const { isDesktop: isDesktopOrLaptop } = useScreen();
  const component = useCallback(
    () =>
      isDesktopOrLaptop ? (
        <StyledLogoImage
          preview={false}
          src={"/p0-logomark.svg"}
          alt="P0"
          width={160}
        />
      ) : (
        <StyledSmallLogoImage
          preview={false}
          src={"/p0-small-logomark-light.svg"}
          alt="P0"
          width={40}
          height={30}
        />
      ),
    [isDesktopOrLaptop]
  );

  return <Icon component={component} />;
};

const topItems = (orgSlug: string | undefined): ItemType[] => [
  {
    key: "p0",
    title: "",
    label: (
      <Link
        to={`/o/${orgSlug}`}
        style={{ display: "flex", alignItems: "center" }}
      >
        <P0NavIcon />
      </Link>
    ),
  },
  {
    key: "divider-top",
    type: "divider",
    className: "nav-divider logo-divider",
  },
];

const SidebarItems = (
  userAuthz: Set<UserRole>,
  flags: Record<string, any>,
  isSandbox?: boolean,
  hasEnvironments?: boolean
) => {
  const items: ItemType[] = [];

  if (ENVIRONMENT_ROLES.find((r) => userAuthz.has(r))) {
    items.push({
      key: AppPaths.Dashboard,
      icon: <DashboardFilled />,
      label: (
        <LinkWithEnvironment to={AppPaths.Dashboard}>
          Dashboard
        </LinkWithEnvironment>
      ),
    });

    items.push({
      key: AppPaths.Inventory,
      icon: <DatabaseFilled />,
      label: (
        <LinkWithEnvironment to={AppPaths.Inventory}>
          Inventory
        </LinkWithEnvironment>
      ),
    });
    items.push({
      key: AppPaths.Posture,
      icon: <EyeFilled />,
      label: (
        <LinkWithEnvironment to={AppPaths.Posture}>Posture</LinkWithEnvironment>
      ),
    });
  }
  if (isSandbox) {
    addSandboxMenu(items, userAuthz.has("owner"));
  }

  items.push({
    key: AppPaths.Jit,
    icon: <ClockCircleFilled />,
    label: (
      <NavLink to={AppPaths.Jit} className={getNavLinkClassName}>
        Just-in-time
      </NavLink>
    ),
  });

  if (hasEnvironments && ENV_SETTINGS_ROLES.find((r) => userAuthz.has(r))) {
    items.push({
      key: AppPaths.Settings,
      icon: <SettingFilled />,
      label: (
        <LinkWithEnvironment to={AppPaths.Settings}>
          Settings
        </LinkWithEnvironment>
      ),
    });
  }
  if (CONFIG_ROUTES_ROLES.find((r) => userAuthz.has(r))) {
    // Add middle divider only if user has any of the roles that will show items in the middle section
    items.push({
      key: "divider-mid",
      type: "divider",
      className: "nav-divider",
    });
  }

  if (userAuthz.has("owner") || userAuthz.has("approver")) {
    items.push({
      key: AppPaths.Integrations,
      icon: <ApiFilled />,
      label: (
        <NavLink to={AppPaths.Integrations} className={getNavLinkClassName}>
          Integrations
        </NavLink>
      ),
    });
  }
  if (userAuthz.has("owner") || userAuthz.has("restStateManager")) {
    if (flags.snowflakeRestStateManagement) {
      items.push({
        key: AppPaths.RestState,
        icon: <DatabaseFilled />,
        label: (
          <NavLink
            to={`${AppPaths.RestState}/snowflake`}
            className={getNavLinkClassName}
          >
            Rest State
          </NavLink>
        ),
      });
    }
  }
  if (userAuthz.has("owner")) {
    items.push({
      key: AppPaths.P0Admin,
      icon: <LockFilled />,
      label: (
        <NavLink to={AppPaths.P0Admin} className={getNavLinkClassName}>
          P0 Management
        </NavLink>
      ),
    });
  }
  items.push({
    key: "divider-bottom",
    type: "divider",
    className: "nav-divider",
  });
  items.push({
    key: "docs",
    icon: <LinkOutlined />,
    label: (
      <Link to="https://docs.p0.dev" target="_blank" rel="noreferrer">
        Documentation
      </Link>
    ),
  });
  return items;
};

const bottomItems = (signOut: () => Promise<void>) => [
  {
    key: "logout",
    icon: <LogoutOutlined />,
    label: <div>Logout</div>,
    onClick: signOut,
  },
];

const DisabledTenantRedirect: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { tenant } = useUser();
  const { orgSlug } = useParams();
  return tenant.state === "found" && tenant.disabled ? (
    <DisabledTenant orgSlug={orgSlug} />
  ) : (
    <>{children}</>
  );
};

const AppSider: React.FC<object> = () => {
  const userAuthz = useContext(UserAuthz);
  const flags = useFlags();
  const isPrint = useIsPrint();
  const { tenant, signOut } = useUser();
  const { orgSlug } = useParams();

  const { pathname } = useLocation();
  const selectedNavKeys = useMemo(() => {
    const [_, _o, _tenant, path] = pathname.split("/");

    return Object.values(AppPaths).filter((p) => p === path);
  }, [pathname]);

  const { hasEnvironments } = useContext(EnvironmentDocsContext);

  // Note that style is hard-coded on the Sider element, so we can't control
  // it with CSS. Instead, just delete the sider for printing.
  return !isPrint ? (
    <Sider breakpoint="lg">
      <Menu
        className="p0-nav"
        mode="inline"
        theme="dark"
        selectable={false}
        items={topItems(orgSlug)}
      />
      <Menu
        className="p0-nav tall"
        theme="dark"
        mode="inline"
        selectedKeys={selectedNavKeys}
        items={SidebarItems(
          userAuthz,
          flags,
          tenant.state === "found" && tenant.isSandbox,
          hasEnvironments
        )}
      />
      <Space direction="vertical">
        <LoggedInUserNavItem />
        <Menu
          className="p0-nav"
          mode="inline"
          theme="dark"
          selectable={false}
          items={bottomItems(signOut)}
        />
      </Space>
    </Sider>
  ) : null;
};

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const ErrorFallback: React.FC<FallbackProps> = ({
  resetErrorBoundary,
  error,
}) => {
  const navigate = useNavigateWithEnv();

  const { pathname } = useLocation();

  const currentPath = useMemo(() => {
    const segments = pathname.split("/");
    const path = segments.length >= 4 ? segments[3] : AppPaths.Dashboard;
    return Object.values(AppPaths).find((p) => p === path);
  }, [pathname]);

  Sentry.captureException(error);

  const handleBack = useCallback(() => {
    resetErrorBoundary();
    navigate(currentPath ?? AppPaths.Dashboard, {
      replace: true,
    });
  }, [navigate, resetErrorBoundary, currentPath]);

  const handleBackToDashboard = useCallback(() => {
    resetErrorBoundary();
    navigate(AppPaths.Dashboard, {
      replace: true,
    });
  }, [navigate, resetErrorBoundary]);

  return (
    <StyledWrapper>
      <Result
        status="500"
        title="500"
        subTitle={
          <>
            Sorry, something went wrong. If the problem persists, please
            <ContactSupport subject={"Error Page - Issue Description"} />.
          </>
        }
        extra={
          <>
            <Button type="primary" onClick={handleBack}>
              Go Back
            </Button>
            <Button type="default" onClick={handleBackToDashboard}>
              Go to Dashboard
            </Button>
          </>
        }
      />
    </StyledWrapper>
  );
};

const AppLayout: React.FC<object> = () => {
  const { tenant } = useUser();
  const { pathname } = useLocation();
  return (
    <Layout>
      <SessionManager>
        <EnvironmentDocsProvider>
          <AppSider />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            resetKeys={[pathname]}
          >
            <Layout className="site-layout">
              <Content className="site-layout-background">
                {tenant.state === "found" && tenant.isSandbox && (
                  <SandboxAlert />
                )}
                <Outlet />
              </Content>
              <Footer />
            </Layout>
          </ErrorBoundary>
        </EnvironmentDocsProvider>
      </SessionManager>
    </Layout>
  );
};

export const App: React.FC = () => {
  const flags = useFlags();

  return (
    <Routes>
      {PolicyRoutes}
      <Route path="/integration/auth/_redirect" element={<OAuth2Redirect />} />
      <Route
        path="/integration/github/_redirect"
        element={<GithubSetupRedirect />}
      />
      <Route path="/oidc/auth/_redirect" element={<OidcRedirect />} />
      {/* Allow signups is an environment-level flag. Does not make sense to set it per-tenant since this endpoint is public and outside the scope of a logged-in user */}
      {flags.allowSignups && (
        <Route path="/create-account" element={<CreateAccountPage />} />
      )}
      <Route path="/login/email-callback" element={<EmailCallbackHandler />} />
      <Route
        path="/o/:orgSlug/authorize/cli"
        element={
          <Login>
            <DeviceAuth />
          </Login>
        }
      />
      {flags.onboardingFlowV1 && (
        <Route
          path="/o/:orgSlug/welcome"
          element={
            <Page title="Welcome">
              <Login>
                <Outlet />
              </Login>
            </Page>
          }
        >
          <Route index element={<Welcome />} />
          <Route path="onboarding/:component?" element={<OnboardWorkflows />} />
          <Route path="onboarding/success" element={<Success />} />
        </Route>
      )}
      <Route
        path="/o/:orgSlug/"
        element={
          <Login>
            <OnboardingRedirect>
              <DisabledTenantRedirect>
                <AppLayout />
              </DisabledTenantRedirect>
            </OnboardingRedirect>
          </Login>
        }
      >
        <Route element={<Outlet key={Date.now()} />}>
          <Route
            index
            element={
              <AuthzGuard
                requirement={ENVIRONMENT_ROLES}
                redirectAuthz={AppPaths.Dashboard}
                redirectNoAuthz={AppPaths.Jit}
              />
            }
          />

          <Route element={<AuthzGuard requirement={ENVIRONMENT_ROLES} />}>
            {AssessmentRoutes}
          </Route>
        </Route>

        {JitRoutes()}

        {sandboxRoutes()}

        <Route element={<AuthzGuard requirement={["owner"]} />}>
          <Route
            path={AppPaths.P0Admin}
            element={
              <Page title="P0 Management">
                <Admin />
              </Page>
            }
          />
        </Route>
        <Route element={<AuthzGuard requirement={["approver", "owner"]} />}>
          {IntegrationRoutes()}
        </Route>
        <Route
          element={<AuthzGuard requirement={["owner", "restStateManager"]} />}
        >
          {flags.snowflakeRestStateManagement && snowflakeRestStateRoutes()}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route
        path="*"
        element={
          <Layout>
            <AccountSelect />
          </Layout>
        }
      />
    </Routes>
  );
};
