import { Page } from "components/App/Page";
import { ENVIRONMENT_ROLES, ENV_SETTINGS_ROLES } from "components/App/roles";
import { Settings } from "components/Assessment/pages/Settings";
import { AuthzGuard } from "components/Login";
import { Outlet, Route } from "react-router";
import { AppPaths } from "shared/routes/constants";

import { NavigateWithEnvironment } from "../NavigateWithEnvironment";
import EnvironmentConfigurationPage from "./pages/EnvironmentConfiguration";
import EnvironmentMonitorPage from "./pages/EnvironmentMonitors";
import EnvironmentStatusPage from "./pages/EnvironmentStatus";
import { SettingsPaths } from "./paths";

export const SettingsRoutes = (
  <Route path={AppPaths.Settings} element={<Settings />}>
    <Route
      element={
        <AuthzGuard requirement={ENVIRONMENT_ROLES}>
          <Outlet />
        </AuthzGuard>
      }
    >
      <Route
        index
        element={
          <NavigateWithEnvironment
            to={SettingsPaths.EnvironmentStatus}
            replace
          />
        }
      />
      <Route
        path={SettingsPaths.EnvironmentStatus}
        element={
          <Page title={"Status"}>
            <EnvironmentStatusPage />
          </Page>
        }
      />
    </Route>
    <Route
      element={
        <AuthzGuard requirement={ENV_SETTINGS_ROLES}>
          <Outlet />
        </AuthzGuard>
      }
    >
      <Route
        path={SettingsPaths.EnvironmentConfiguration}
        element={
          <Page title={"Configuration"}>
            <EnvironmentConfigurationPage />
          </Page>
        }
      />
      <Route
        path={SettingsPaths.Monitors}
        element={
          <Page title={"Monitors"}>
            <EnvironmentMonitorPage />
          </Page>
        }
      />
    </Route>
  </Route>
);
