import { Button, Spin, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ErrorDisplay } from "components/Error";
import { Tenant } from "components/Login";
import { useAuthFetch, useHasRole, useUser } from "components/Login/hook";
import { ErrorBoundaryDisplay } from "components/common/errors/ErrorBoundaryDisplay";
import { FrontendInstaller } from "install/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sortBy } from "lodash";
import pluralize from "pluralize";
import { useFirestoreDoc } from "providers/FirestoreProvider";
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import { visibleComponentEntries } from "shared/install/options";
import {
  ConfigOf,
  InstallSpec,
  ItemComponent,
  ItemConfigOf,
} from "shared/install/types";
import { getEnvironment } from "utils/environment";

import { IntegrationCard, IntegrationCardOverrides } from "../IntegrationCard";
import { OwnerAlert } from "../OwnerAlert";
import { Component } from "./Component";
import { Root } from "./Root";

export type InstallProps<T extends InstallSpec> = {
  cardOverride?: IntegrationCardOverrides;
  components: T;
  installer: FrontendInstaller<T>;
  integration: string;
  itemKind: string;
  logo: ReactElement | string;
  onInstall?: (config: ConfigOf<T>) => void;
  title: string;
};

type ComponentDatum<T> = {
  component: ItemComponent<any>;
  key: string;
  items: ItemConfigOf<ConfigOf<T>[keyof T]>[];
};

const installColumns = <T extends InstallSpec>(
  components: T,
  itemKind: string
): ColumnsType<ComponentDatum<T>> => [
  {
    key: "label",
    title: "Available components",
    render: (_, { component }) => (
      <Button type="link" size="small" className="button-link">
        {component.label}
      </Button>
    ),
  },
  {
    key: "items",
    title: "Installed?",
    width: "125px",
    render: (_, { component, items }) => {
      const inProgress = (items ?? []).filter((i) => i.state !== "installed");
      return items?.length
        ? component.type === "singleton"
          ? inProgress.length
            ? "In progress"
            : "Installed"
          : `${items.length} ${pluralize(itemKind, items.length)}${
              inProgress.length ? ` (${inProgress.length} in progress)` : ""
            }`
        : "Not installed";
    },
  },
  {
    key: "use",
    title: "Use",
    render: (_, { component }) => component.description,
  },
];

const ComponentSelect = <T extends InstallSpec>({
  components,
  config,
  itemKind,
}: InstallProps<T> & { config: ConfigOf<T> }) => {
  const navigate = useNavigate();
  const flags = useFlags();

  const columns = useMemo(
    () => installColumns(components, itemKind),
    [components, itemKind]
  );

  const data = useMemo(() => {
    const data = visibleComponentEntries(components, flags).map(
      ([key, component]) => ({
        component,
        key,
        items: Object.values(config?.[key] ?? []),
      })
    ) as any as ComponentDatum<T>[];
    return sortBy(data, ({ component }) => component.label);
  }, [components, config, flags]);

  const getRowEvents = useCallback(
    (record: ComponentDatum<T>) => ({
      onClick: () => {
        navigate(`./${record.key}`);
      },
    }),
    [navigate]
  );

  return (
    <Table<ComponentDatum<T>>
      className="table-styles"
      columns={columns}
      dataSource={data}
      pagination={{ position: ["none", "none"] as any }}
      onRow={getRowEvents}
      rowClassName="enabled-row"
    />
  );
};

/** The top-level entry point for componentized integration installs
 *
 * Each component is rendered as a row in a top-level table. Components
 * are rendered according to the integration's component schema using the
 * {@link Component} React component.
 */
// TODO: flatten table w.r.t. installed items
export const Install = <T extends Record<string, ItemComponent<any>>>(
  props: InstallProps<T>
) => {
  const { cardOverride, integration, installer, title, components, logo } =
    props;
  const { user } = useUser();
  const [error, setError] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);
  const authFetch = useAuthFetch(setError, setIsFetching);
  const { component } = useParams<{ component: string }>();
  const tenantId = useContext(Tenant);
  const { orgSlug } = useParams();
  const { orgData } = useUser();
  const canEdit = useHasRole("owner");
  const flags = useFlags();

  const integrationPath = useMemo(
    () => `o/${tenantId}/integrations/${integration}`,
    [integration, tenantId]
  );

  const integrationDoc = useFirestoreDoc<ConfigOf<T>>(integrationPath, {
    live: true,
  });

  const onRemove = useCallback(async () => {
    await authFetch(`integrations/${integration}/config`, {
      method: "DELETE",
    });
  }, [authFetch, integration]);

  const clearError = useCallback(() => setError(undefined), []);

  // A doc reload will cancel any in-progress authFetch hooks; potentially
  // preventing isFetching from being reset
  useEffect(() => {
    setIsFetching(false);
  }, [integrationDoc.doc?.data]);

  const config = integrationDoc.doc?.data;

  const context = useMemo(
    () => ({
      ...getEnvironment(),
      authFetch,
      config,
      orgData: orgData && orgSlug ? { ...orgData, slug: orgSlug } : undefined,
      tenantId,
      user,
      featureFlags: flags,
    }),
    [authFetch, user, tenantId, config, orgSlug, orgData, flags]
  );

  return !component || component in components ? (
    <IntegrationCard
      title={title}
      integration={integration}
      component={component}
      componentTitle={component && components[component].label}
      canRemove={!!integrationDoc.doc && !component}
      integrationDoc={integrationDoc.doc}
      logo={logo}
      onRemove={onRemove}
      {...cardOverride}
    >
      <ErrorBoundaryDisplay>
        {integrationDoc.loading ? (
          <Spin />
        ) : (
          <div>
            {error && (
              <ErrorDisplay
                title={`Error configuring ${title}`}
                error={error}
                onClose={clearError}
                data-testid="error-display"
              />
            )}
            {!integrationDoc.doc?.data.root &&
            "root" in installer &&
            "root" in components ? (
              canEdit ? (
                <Root
                  authFetch={authFetch}
                  context={context}
                  isFetching={isFetching}
                  {...props}
                />
              ) : (
                <OwnerAlert />
              )
            ) : (
              <div data-testid="install-inner-div">
                {component && component in components ? (
                  <Component
                    {...props}
                    authFetch={authFetch}
                    canEdit={canEdit}
                    config={config}
                    context={context}
                    componentKey={component}
                    error={error}
                    isFetching={isFetching}
                  />
                ) : (
                  <>
                    {"root" in components && (
                      <Root
                        authFetch={authFetch}
                        context={context}
                        isFetching={isFetching}
                        {...props}
                        components={components as any}
                        disabled
                      />
                    )}
                    <Typography.Paragraph>
                      To install, please select a component below:
                    </Typography.Paragraph>
                    <ComponentSelect<T> {...props} config={config} />
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </ErrorBoundaryDisplay>
    </IntegrationCard>
  ) : (
    <Navigate to=".." relative="path" replace />
  );
};
