import pluralize from "pluralize";

import { assertNever, assertNeverError } from "../../../types";
import { GrantEntry } from "../../../types/assessment/data";
import { KUBE_SYSTEM_NAMESPACES } from "./constants";
import { Api, K8sPrivilege, K8sResource, KubernetesRole } from "./types";

// The separator does not occur in Kubernetes object names and IDs
// See https://kubernetes.io/docs/concepts/overview/working-with-objects/names/
export const K8S_SEPARATOR = "/";

export const KUBE_SYSTEM_ROLE_BINDING_PREFIXES = KUBE_SYSTEM_NAMESPACES.map(
  (namespace: string) =>
    // the beginning of the output of roleBindingToPermissionSet function
    ["Role", namespace, ""].join(K8S_SEPARATOR)
);

export const KUBE_SYSTEM_PRINCIPAL_PREFIXES = KUBE_SYSTEM_NAMESPACES.map(
  // the beginning of the output of toEntryPrincipal function
  (namespace: string) => [namespace, ""].join(K8S_SEPARATOR)
);

export const grantKey = (entry: GrantEntry) => {
  const { principalType, principal, privilegeSet } = entry;
  return `${principalType}:${principal}:${privilegeSet}`;
};

/** Extracts only the apiGroup from a concatenation of group and version.
 *
 * The "core" API group is represented by empty string in k8s.
 * This function replaces the empty string with "core".
 * Example `version` values:
 * "v1": the first version of the "core" API group
 * "apps/v1": the first version of the "apps" API group
 * "storage.k8s.io/v1": the first version of the "storage.k8s.io" API group
 */

export const getApiGroup = (version?: string) => {
  // If there is no `/` then only the version number is included and the apiGroup is "core"
  const [apiGroup] = version?.includes("/") ? version.split("/") : ["core"];
  return apiGroup;
};

export const bindingToRole = (binding: Api.Binding): KubernetesRole => {
  switch (binding.kind) {
    case "ClusterRoleBinding":
      return {
        kind: "ClusterRole",
        name: binding.roleRef.name,
      };
    case "RoleBinding":
      return binding.roleRef.kind === "Role"
        ? {
            kind: "Role",
            namespace: binding.metadata.namespace,
            name: binding.roleRef.name,
          }
        : binding.roleRef.kind === "ClusterRole"
        ? {
            kind: "ClusterRole",
            name: binding.roleRef.name,
          }
        : assertNever(binding.roleRef.kind);
    default:
      throw assertNeverError(binding);
  }
};

export const toEntryPermissionSet = (
  binding: Api.Binding,
  role: KubernetesRole,
  ruleIndex: number
) => {
  // You cannot assign a namespaced Role via a non-namespaced ClusterRoleBinding.
  // We likely have bug somewhere in the processing if we end up with this combination,
  // meaning our graph will be flawed. Fail the assessment rather than showing wrong data.
  if (binding.kind === "ClusterRoleBinding" && role.kind === "Role") {
    throw new Error("ClusterRoleBinding cannot reference a Role");
  }
  return `${roleIdentifier(role)}:${ruleIndex}`;
};

export const toEntryPrincipalType: (
  kind: Api.SubjectKind,
  name: string
) => GrantEntry["principalType"] = (kind, name) => {
  switch (kind) {
    case "Group":
      return name === "system:unauthenticated" ? "public" : "group";
    case "ServiceAccount":
      return "service-account";
    case "User":
      return name === "system:anonymous" ? "public" : "user";
    default:
      throw assertNeverError(kind);
  }
};

export const toEntryPrincipal = (
  clusterId: string,
  name: string,
  namespace?: string
) => {
  const local = namespace ? [namespace, name].join(K8S_SEPARATOR) : name;
  return `kubernetes:${clusterId}:${local}`;
};

export const friendlyApiGroup = (apiGroup: string) =>
  apiGroup === "" ? "core" : apiGroup;

/**
 * Converts a `kind` value to a `resource type`. Resource type is the name of
 * a resource type returned from the API Resources k8s endpoint.
 *
 * Uses a rule to lower-case and pluralize the `kind` value.
 * E.g. "Pod" -> "pods", "HorizontalPodAutoscaler" -> "horizontalpodautoscalers"
 *
 * Instead, we could look up the value based on `kind` in the resource-type data we collected
 */
export const toResourceType = (kind: string) => pluralize(kind.toLowerCase());

/** Short, non-unique name for the resource */
export const resourceId = (resource: Api.Resource) => {
  const { namespace, name } = resource.metadata;
  if (!namespace) {
    return name;
  }
  return [namespace, name].join(K8S_SEPARATOR);
};

export function resourceKey(resource: Api.Resource): string;
export function resourceKey(k8sResource: K8sResource): string;
export function resourceKey(resource: Api.Resource | K8sResource): string {
  const { apiGroup, resourceType, namespace, name } =
    "metadata" in resource
      ? {
          apiGroup: getApiGroup(resource.apiVersion),
          resourceType: toResourceType(resource.kind),
          namespace: resource.metadata.namespace,
          name: resource.metadata.name,
        }
      : resource;
  const friendlyApi = friendlyApiGroup(apiGroup);
  if (!namespace) {
    return [friendlyApi, resourceType, name].join(K8S_SEPARATOR);
  }
  return [friendlyApi, resourceType, namespace, name].join(K8S_SEPARATOR);
}

export const resourceFromKey = (key: string): K8sResource | undefined => {
  // TODO @ENG-3147 some resource types contain the separator, e.g. "pods/eviction"
  // Those end up with incorrect name here (only the first half of the name is extracted, e.g. "pods")
  const parts = key.split(K8S_SEPARATOR);
  // [apiGroup, resourceType, name] for non-namespaced resources
  // [apiGroup, resourceType, namespace, name] for namespaced resources
  const [apiGroup, resourceType, third, fourth] = parts;
  // Basically, `namespace` is undefined if the resource is not namespaced
  return {
    apiGroup,
    resourceType,
    namespace: fourth ? third : undefined,
    name: fourth ?? third,
  };
};

/** May replace the `namespace` property of the given K8sResource with a wildcard
 *
 * If the `namespace` is undefined it means that the rule applies to all namespaces,
 * as long as the resource is namespaced. Replace `undefined` with wildcard.
 * If the resource is not namespaced, the `namespace` remains `undefined`.
 */
const wildcardNamespaceIfNeeded = (
  resource: K8sResource,
  getApiResource: (resourceType: string) => Api.APIResource | undefined
) => {
  const { apiGroup, resourceType, namespace, name } = resource;
  // If there is a concrete namespace, return unchanged
  if (namespace) {
    return resource;
  }
  // An undefined `namespace` is encoded differently depending on whether the resourceType
  // is namespaced or not. Look up that information from API resources.
  const apiResource = getApiResource(resourceType);
  // Falls back to `namespaced = true` if resourceType is not found among API resources
  // TODO @ENG-3145 This is not alwasys correct, however, there are "more" namespaced resources than non-namespaced
  const namespaced = apiResource?.namespaced ?? true;
  // If resourceType in the rule is namespaced, then namespace is a wildcard.
  // The rule applies to all resources in all namespaces.
  if (namespaced) {
    return {
      apiGroup,
      resourceType,
      namespace: "*",
      name,
    };
  }
  // If the resourceType in the rule is non-namespaced, then namespace is undefined
  return resource;
};

/** Converts a rule from a Role/ClusterRole to an array of K8sResources.
 *
 * The K8sResource may have wildcards in its properties to represent the rule applies
 * to all API Groups / resource types / namespaces / resource names.
 */
export const toK8sResources = (
  rule: Api.Rule,
  namespace: string | undefined,
  getApiResource: (resourceType: string) => Api.APIResource | undefined
): K8sResource[] =>
  // If `resourceNames` is missing it means that the rule applies to all resources.
  // Replace with a `*` to indicate this, even though it is not a valid resource name.
  (rule.resourceNames ?? ["*"]).flatMap((name) =>
    rule.resources.flatMap((resourceType) =>
      // apiGroups must be present at this point - we don't iterate over rules that don't have it
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      rule.apiGroups!.map((apiGroup) =>
        wildcardNamespaceIfNeeded(
          {
            apiGroup,
            resourceType,
            namespace,
            name,
          },
          getApiResource
        )
      )
    )
  );

/** The part after the apiGroup corresponds to GCP's dot-separated GKE permissions.
 * E.g. "container.pods.create" - except that the first "container" part is omitted here. */
export const toEntryPrivilege = (p: K8sPrivilege) =>
  `${p.apiGroup}/${p.resourceType}.${p.verb}`;

export function roleIdentifier(role: KubernetesRole): string;
export function roleIdentifier(role: Api.Role): string;
export function roleIdentifier(role: Api.Role | KubernetesRole): string {
  // Api.Role
  if ("metadata" in role) {
    return role.kind === "ClusterRole"
      ? [role.kind, role.metadata.name].join(K8S_SEPARATOR)
      : role.kind === "Role"
      ? [role.kind, role.metadata.namespace, role.metadata.name].join(
          K8S_SEPARATOR
        )
      : assertNever(role);
  }
  // KubernetesRole
  return role.kind === "ClusterRole"
    ? [role.kind, role.name].join(K8S_SEPARATOR)
    : role.kind === "Role"
    ? [role.kind, role.namespace, role.name].join(K8S_SEPARATOR)
    : assertNever(role);
}
