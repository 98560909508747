import { Button } from "antd";
import React, { useCallback, useState } from "react";

interface ConcealProps {
  label: string;
  content: React.ReactNode;
  isHidden: boolean;
}

/** Simple component which conceals text behind a button which reveals the content when clicked */
const Conceal: React.FC<ConcealProps> = ({ label, content, isHidden }) => {
  const [isRevealed, setIsRevealed] = useState<boolean>(!isHidden);

  const handleRevealContent = useCallback(() => {
    setIsRevealed(true);
  }, []);

  return (
    <>
      {!isRevealed ? (
        <Button
          type="link"
          onClick={handleRevealContent}
          style={{ padding: 0, margin: 0 }}
        >
          Show {label}
        </Button>
      ) : (
        content
      )}
    </>
  );
};

export default Conceal;
