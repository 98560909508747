import { Skeleton, Space, Typography } from "antd";
import { priorityColor } from "components/Assessment/utils/monitor";
import { EnvironmentDocsContext } from "components/Environment/contexts/EnvironmentDocsContext";
import { VerticalDiv } from "components/divs";
import { format } from "date-fns";
import { compact, map, max, omit, reverse, sortBy, sum, values } from "lodash";
import { useContext, useMemo } from "react";
import {
  Bar,
  BarChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { MonitorRanking, priorityLabels } from "shared/assessment/constants";
import {
  MonitorPriority,
  monitorPriorities,
} from "shared/types/assessment/monitor";
import { widetype } from "shared/util/collections";
import { colors } from "styles/variables";

import { MonitorPriorityCountsWithBins } from "../types";
import { ColorCodeIndicator } from "./common/ColorCodeIndicator";

// Fallback value to the default y-axis max value
const GRAPH_FALLBACK_MAX = 4;

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  const data = useMemo(
    () =>
      compact(
        monitorPriorities.map(
          (priority) => payload?.find((d) => d.dataKey === priority)
        )
      ),
    [payload]
  );

  return active && payload && data.some((d) => d.value) ? (
    <VerticalDiv
      style={{
        backgroundColor: colors.neutral["00"],
        padding: "10px",
        gap: "4px",
      }}
    >
      {data.map(
        (p) =>
          p && (
            <Space size="small" key={p.name}>
              <ColorCodeIndicator
                color={
                  p?.dataKey
                    ? priorityColor[p.dataKey as MonitorPriority].color
                    : ""
                }
                style={{ height: "1em" }}
              />
              <Typography.Text key={p.name}>
                {`${p.name}: ${p.value}`}
              </Typography.Text>
            </Space>
          )
      )}
    </VerticalDiv>
  ) : null;
};

export const FindingGraph: React.FC<{
  isLoading: boolean;
  monitors: MonitorPriorityCountsWithBins;
  priorities: Set<MonitorPriority>;
  startDate: number;
}> = ({ monitors, priorities, isLoading, startDate }) => {
  const categories = useMemo(
    () => sortBy([...priorities.keys()], (p) => MonitorRanking[p]),
    [priorities]
  );

  const { hasEnvironments } = useContext(EnvironmentDocsContext);

  const data = useMemo(
    () =>
      monitors.bins.map((bin, ix) => ({
        date: format(bin, "P"),
        ...widetype.fromEntries(
          categories.map((cat) => [cat, monitors[cat].byTimestamp[ix]])
        ),
      })),
    [categories, monitors]
  );

  // Identifies the bin prior to which no data are available
  const startX = useMemo(() => {
    const startBin = monitors.bins.filter((bin) => bin < startDate).at(-1);
    return startBin ? format(startBin, "P") : undefined;
  }, [startDate, monitors]);

  // Identifies the maximum chart height in units of displayed data
  const maxValue = useMemo(() => {
    const computedMax = max(
      data.map((d) =>
        sum(
          values(widetype.pickBy(omit(d, "date"), (_, k) => priorities.has(k)))
        )
      )
    );
    return computedMax || GRAPH_FALLBACK_MAX;
  }, [data, priorities]);

  const bars = useMemo(
    () =>
      reverse(
        map(categories, (category) => (
          <Bar
            key={category}
            dataKey={category}
            name={priorityLabels[category]}
            label={priorityLabels[category]}
            fill={priorityColor[category].color}
            stackId="a"
          />
        ))
      ),
    [categories]
  );

  return (
    <ResponsiveContainer width="100%">
      {isLoading ? (
        <Skeleton active />
      ) : (
        <BarChart
          data={data}
          barSize={100}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis dataKey="date" />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "white" }}
            isAnimationActive={false}
          />
          {bars}
          {startX && hasEnvironments && (
            <ReferenceArea
              // Note that this label does not wrap, so it's useful to be concise
              label="Not yet run"
              isFront
              x1={format(monitors.bins[0], "P")}
              x2={startX}
              y1={0}
              y2={maxValue}
            />
          )}
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};
