import { purple } from "@ant-design/colors";
import { FileProtectOutlined } from "@ant-design/icons";
import { RendererParams } from "components/Assessment/contexts/GraphContext";
import { providerTitles } from "shared/assessment/constants";
import { ConnectedNode } from "shared/graph/types";
import { AssessmentNodes } from "shared/types/assessment/data";

import {
  DefaultNodeHeaderHeight,
  DefaultNodeOneLineBodyHeight,
  DefaultNodeWidth,
} from "./shared";

export const CustomPermissionSetNodeParameters = {
  type: "permissionSet" as const,
  titleText: (
    _: ConnectedNode<AssessmentNodes, "permissionSet">,
    rendererParams: RendererParams
  ) => {
    const { provider } = rendererParams;
    const titles = providerTitles[provider];
    return "permissionSet" in titles ? titles.permissionSet : "";
  },
  IconComponent: FileProtectOutlined,
  bodyBorderColor: purple[1],
  bodyBackgroundColor: purple[0],
  bodyContent: (node: ConnectedNode<AssessmentNodes, "permissionSet">) => (
    <div>
      <img src="/aws/idc.svg" width={14} height={14} alt="AWS IDC logo" />
      &nbsp;
      {node.data.label}
    </div>
  ),
  bodySize: () => DefaultNodeOneLineBodyHeight,
  headerSize: () => DefaultNodeHeaderHeight,
  width: () => DefaultNodeWidth,
};
